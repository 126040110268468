import React from "react";
import { observer } from "mobx-react-lite";
import {
  R,
  C,
  C4,
  C6,
  SectionDivider,
  Panel,
  PanelBody,
  PanelHeader,
  ListItem,
  TeamItem,
  Text,
  Badge,
  Note,
  IconEmptyBox,
  UserAvatar,
} from "@fundrecs/ui-library";
import { EditableText } from "../editable/EditableText";
import { EditableSelect } from "../editable/EditableSelect";
import { meStore } from "../../store/Store";

const UserAccountSettings = observer(() => {
  const loggedInUser = meStore.getMe();
  const { name, email, superAdmin } = loggedInUser;

  //Set value using meStore to ensure the team and role data stays up-to-date with the store
  const allTeamsAndRoles = createTeamsAndRolesObject(loggedInUser);
  const teamsAndRoles = allTeamsAndRoles.teamsAndRoles;
  const adminRoles = allTeamsAndRoles.adminRoles;
  const defaultTeam = meStore.getDefaultTeam();
  const adminRoleDescriptions = {
    "Super Admin": "You have been assigned full authority for all functionality within Fusion.",
    "Connection Admin": "You have been assigned full authority over file import and file export setup areas in the admin console.",
    "Team & User Admin": "You have been assigned full authority over teams and users management areas in the admin console.",
  };

  /**
   * This method is called before submit a request to the API to update an object.
   * The object param represents the object to be updated before sending the request.
   * The key param represents the key the backend expects in the object.
   * The value is whatever the user types within the input.
   *
   * We take a copy of the current object and then combine it with
   * the newly updated values.
   *
   * We then return this object.
   * @param object
   * @param key
   * @param value
   */
  const handleChange = (object, key, value) => {
    const updatedObject = { ...object, ...{ [key]: value } };
    return updatedObject;
  };

  const submitUser = async (key, value) => {
    const requestObject = handleChange(loggedInUser, key, value);

    const toasts = {
      success: `User ${key} successfully changed`,
      error: `Unable to change user ${key}! Please try again`,
    };

    const { data, success } = await meStore.update(requestObject, toasts);

    if (success) {
      meStore.setMe(data);
    }
  };

  return (
    <Panel panelId="userAccountSettingsPanel">
      <PanelBody>
        <PanelHeader description="You can manage your account settings, view your teams etc. from here" header="Manage account" />
        <R props="pt-32 pb-24">
          <Text size="sm" variant="secondary" weight="bold">
            Account settings
          </Text>
        </R>
        <R props={"pt-12 pb-12"}>
          <C4>
            <Text variant="tertiary" size="sm" weight="regular" element="div">
              Avatar
            </Text>
          </C4>
          <C6>
            <UserAvatar size="md" />
          </C6>
        </R>
        <SectionDivider />
        <R props={"pt-12 pb-12"}>
          <EditableText
            value={name}
            name="name"
            label="Full name"
            onSave={(value) => {
              submitUser("name", value);
            }}
            warningText=""
          />
        </R>
        <SectionDivider />
        <R props={"pt-12 pb-12"}>
          <C4>
            <Text variant="tertiary" size="sm" weight="regular" element="div">
              Email address
            </Text>
          </C4>
          <C6>
            <Text variant="primary" size="sm" weight="medium" element="div">
              {email}
            </Text>
          </C6>
        </R>
        <SectionDivider />
        {!adminRoles.length ? (
          <R props={"pt-12 pb-12"}>
            <C4>
              <Text variant="tertiary" size="sm" weight="regular" element="div">
                Admin access
              </Text>
            </C4>
            <C6>
              <Text variant="secondary" size="sm" weight="medium" element="span">
                {"No administration access"}
              </Text>
              <div className="pb-4"></div>
              <Text variant="tertiary" size="xs" weight="regular" element="div">
                {"You have been assigned standard user access for one or more teams in the organisation."}
              </Text>
            </C6>
          </R>
        ) : (
          adminRoles.map((adminRole, index) => {
            return (
              <R props={"pt-12 pb-12"} key={index}>
                <C4>
                  <Text variant="tertiary" size="sm" weight="regular" element="div">
                    {index === 0 ? "Admin access" : ""}
                  </Text>
                </C4>
                <C6>
                  <Text variant="secondary" size="sm" weight="medium" element="span">
                    {adminRole.name}
                  </Text>
                  <div className="pb-4"></div>
                  <Text variant="tertiary" size="xs" weight="regular" element="div">
                    {adminRoleDescriptions[adminRole.name]}
                  </Text>
                </C6>
              </R>
            );
          })
        )}
        <SectionDivider />

        <R props={"pt-12 pb-12"}>
          {defaultTeam && (
            <EditableSelect
              name="defaultTeam"
              description="This is the team you will view by default when you log in to Fusion. You can change your default team any time."
              label="Default Team"
              value={{ label: defaultTeam.name, value: defaultTeam.value }}
              options={teamsAndRoles.map((team) => {
                return { label: team.teamName, value: team.teamId };
              })}
              onSave={(value) => {
                submitUser("defaultTeam", value);
              }}
            />
          )}
        </R>
        <R props="pt-32 pb-24">
          <Text size="sm" variant="secondary" weight="bold">
            Teams
          </Text>
        </R>
        {superAdmin ? (
          <div className="mr-16 ml-16 negative-margin-12">
            <Note>
              You are a Super admin user and have access to all teams and their content in Fusion by default, including new teams that will be added to Fusion
              in the future. To view all teams, please go to Admin Console.
            </Note>
          </div>
        ) : !teamsAndRoles.length ? (
          <R>
            <C props="pl-12 pt-12 pb-12">
              <IconEmptyBox style={{ height: "35px", width: "40px", marginRight: "12px" }} />
              <Text size="sm" variant="secondary" weight="regular">
                You currently have no teams. Please contact your administrator to be added as a member to a team.
              </Text>
            </C>
          </R>
        ) : (
          teamsAndRoles.map((teamAndRoles, index) => {
            return (
              <R key={index}>
                <C4 props="pl-12 pt-12 pb-12">
                  <ListItem>
                    <TeamItem avatar={teamAndRoles.teamName} body={`${teamAndRoles.membersCount} members`} header={teamAndRoles.teamName} />
                  </ListItem>
                </C4>
                <C props="pl-12 pt-12 pb-12">
                  {teamAndRoles.roles.map((role, index) => {
                    return <Badge key={index} text={role.name} />;
                  })}
                </C>
              </R>
            );
          })
        )}
      </PanelBody>
    </Panel>
  );
});

/**
 * 1. Get the roles grouped by team, excluding Admin Team. Each dataset must include:
 *   - Team name
 *   - Count of members per team
 *   - Roles for the user and team
 * 2. Also get all assigned Admin roles
 * @param {*} loggedInUser The currently logged in user
 * @returns Object containing array of roles grouped by team, including team name and count of members, excluding Admin Team,
 *          second array contains all admin roles
 */
const createTeamsAndRolesObject = (loggedInUser) => {
  let teamsAndRoles = [];
  let adminRoles = [];

  if (loggedInUser.admin) {
    const {
      adminRolesActions: { roles },
    } = loggedInUser || {};
    adminRoles = roles;
  }

  loggedInUser.teamRolesActions.forEach((teamRoleAction) => {
    teamsAndRoles.push({
      teamId: teamRoleAction.team.id,
      teamName: teamRoleAction.team.name,
      membersCount: teamRoleAction.userCount,
      roles: teamRoleAction.roles,
    });
  });

  return { teamsAndRoles: teamsAndRoles, adminRoles: adminRoles };
};

export { UserAccountSettings };
