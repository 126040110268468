import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { BaseLayout } from "@fundrecs/ui-library";
import { init as initApm } from "@elastic/apm-rum";
import { useStore } from "./store/Store";
import { Header } from "./components/layout/Header";
import { Sidebar } from "./components/oneProductSidebar/Sidebar";
import { FusionRecsRoutes } from "./components/Routes";
import { Toaster } from "./components/layout/Toaster";
import "./scrollbars.css";

const App = observer(() => {
  const { uiStore, meStore, tenantConfigStore, rolesStore, frontendPropertiesStore } = useStore();
  const { sideMenuOpen } = uiStore;
  console.log("React version: ", React.version)
  let [initialised, setInitialised] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await frontendPropertiesStore.requestFrontendProperties();
      await meStore.requestMe();
      await rolesStore.requestRoles();
      setInitialised(true);
      await tenantConfigStore.requestTenantConfig();
    };

    if (!initialised) {
      fetchData();
    }
  });

  useEffect(() => {
    //meStore.checkAuth();
  }, [meStore]);

  if (!initialised) {
    return null;
  }

  frontendPropertiesStore.getFrontendPropertyApmEnabled() &&
    initApm({
      // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
      serviceName: process.env.REACT_APP_ELASTIC_APM_RUM_SERVICE_NAME,
      environment: frontendPropertiesStore.getFrontendPropertyApmEnvironment(),
      serverUrl: frontendPropertiesStore.getFrontendPropertyApmServerUrl(),
      // Set service version (required for sourcemap feature)
      serviceVersion: frontendPropertiesStore.getFrontendPropertyApmServiceVersion(),
    });

  return (
    <Router>
      <Toaster />

      <BaseLayout
        renderHeader={() => <Header onMenuButtonClick={() => uiStore.toggleSideMenu()} />}
        renderSidebar={() => <Sidebar expanded={sideMenuOpen} />}
        renderMain={() => <FusionRecsRoutes />}
      />
    </Router>
  );
});

export default App;
