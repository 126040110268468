import { makeAutoObservable, toJS } from "mobx";
import { fusionRecsApi } from "../api";
import { uiStore, rolesStore } from "./Store";
import { isUserAuthorized } from "../components/AuthorizationWrapper";
import { AUTHORITIES } from "../utils/enums";

class AutoCompleteStore {
  autoCompleteRules = [];

  constructor() {
    makeAutoObservable(this);
  }

  getAutoCompleteRules = () => {
    return toJS(this.autoCompleteRules);
  };

  fetchAutoCompleteRules = async (teamId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_EDIT]) })) {
      console.log("fetchAutoCompleteRules: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.autoComplete.fetchAutoCompleteRules(teamId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    } else {
      this.autoCompleteRules = resp.data;
    }
    return resp;
  };
}

export { AutoCompleteStore };
