import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Text, IconCloseRemove } from "@fundrecs/ui-library";
import { AddCommentPanel } from "../sidePanels/AddCommentPanel";

const RowGroupDetailsPanel = ({ processedTableRef, updateTable, selectedRowGroups, setRowGroupDetailsOpen }) => {
  const [searchParams] = useSearchParams();
  const teamId = Number(searchParams.get("teamId"));
  const TAGS = "Tags";
  const COMMENT = "Comment";
  const [selectedTab, setSelectedTab] = useState(COMMENT);

  const tabs =
    processedTableRef && processedTableRef.current && processedTableRef.current.api && processedTableRef.current.api.getSelectedRows().length === 1
      ? [COMMENT] //[TAGS, COMMENT]
      : [COMMENT];

  const Tab = ({ text, selected }) => {
    return (
      <div
        className="ml-8 mr-8 pt-16 pb-16"
        style={{ cursor: "pointer", borderBottom: selected ? "3px solid #F54828" : "" }}
        onClick={() => {
          setSelectedTab(text);
        }}
      >
        <Text size="sm" weight={selected ? "bold" : "regular"}>
          {text}
        </Text>
      </div>
    );
  };

  const updateTableAndClosePanel = () => {
    updateTable();
    setRowGroupDetailsOpen(false);
  };

  return (
    <div id="rowGroupDetailsPanel" className="side-panel-style">
      <div style={{ borderBottom: "1px solid #E6E8EB" }} className="pl-16 pt-32 pr-16">
        <div className=" d-flex justify-content-between">
          <Text size="lg" element="div">{`${selectedRowGroups.length} items selected`}</Text>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setRowGroupDetailsOpen(false);
            }}
          >
            <IconCloseRemove className="btn-lg-svg" />
          </div>
        </div>
        <div className="d-flex mt-16">
          {tabs.map((it, index) => {
            return <Tab key={index} text={it} selected={it === selectedTab} />;
          })}
        </div>
      </div>
      <div className="pl-16 pr-16 pt-32">
        {selectedTab === TAGS ? (
          ""
        ) : selectedTab === COMMENT ? (
          <AddCommentPanel teamId={teamId} updateTable={updateTableAndClosePanel} rowGroups={selectedRowGroups} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export { RowGroupDetailsPanel };
