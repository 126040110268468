import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { modalInstance, R, C2, C10, Panel, PanelBody, PanelFooter, Heading, Text, TextArea, Input, Button } from "@fundrecs/ui-library";
import { MODALS } from "../../../utils/enums";
import { useStore } from "../../../store/Store";

const CreateDataVerificationRule = observer(({ recTypeName, showDvRuleBuilder=()=>{} }) => {
  const { dataVerificationStore, recTypeStore } = useStore();
  const ruleObject = { name: "", description: "" };
  const warningMessageObject = { name: "", description: "" };
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = searchParams.get("teamId");
  const [dvRule, setDvRule] = useState(ruleObject);
  const [warningMessage, setWarningMessage] = useState(warningMessageObject);
  const nameMaxLength = 100;
  const descriptionMaxLength = 150;

  const closeModal = () => {
    setWarningMessage(warningMessageObject);
    setDvRule(ruleObject);
    modalInstance(MODALS.CREATE_DATA_VERIFICATION_RULE).hide();
  };

  const createDataVerificationRule = async () => {
    let warningMessageObject = { name: "", description: "" };
    let warning = false;
    if (dvRule.name.length < 1) {
      warningMessageObject = { ...warningMessageObject, name: "Please enter a name for the rule" };
      warning = true;
    }

    if (dvRule.description.length < 1) {
      warningMessageObject = { ...warningMessageObject, description: "Please enter a description for the rule" };
      warning = true;
    }

    if (!warning) {
      const reqBody = { name: dvRule.name, description: dvRule.description };
      const selectedRecType = recTypeStore.getSelectedRecType();
      const response = await dataVerificationStore.createDataVerificationRule(teamId, selectedRecType ? selectedRecType.id : null, reqBody);
      if (response.status === 200) {
        closeModal();
        showDvRuleBuilder();
      }
    }
    setWarningMessage(warningMessageObject);
  };

  return (
    <Panel panelId={MODALS.CREATE_DATA_VERIFICATION_RULE}>
      <PanelBody>
        <div className="panel-header">
          <div className="panel-header-left">
            <Heading variant="h4" element="span">
              New Data Verification Rule
            </Heading>
          </div>
          <div className="panel-header-right">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {}}></button>
          </div>
          <div className="panel-header-clear"></div>
        </div>
        <R>
          <C10>
            <Text size="sm" variant="secondary" weight="regular">
              Data verification rules check data for accuracy and inconsistencies. Rules created here are the first rules to be run across funds and sub account
              data where the selected reconciliation type in enabled. Additional verification rules may be created at the fund/sub account level.
            </Text>
          </C10>
        </R>
        <div className="pt-32">
          <R props="mb-32">
            <C10>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div style={{ alignSelf: "flex-start" }}>
                  <Text size="sm" weight="medium" variant="secondary" element="div">
                    Rule name *
                  </Text>
                </div>
                <div style={{ alignSelf: "flex-end" }}>
                  <Text size="sm" weight="regular" variant="tertiary" element="div">{`${dvRule.name.length}/${nameMaxLength}`}</Text>
                </div>
              </div>

              <Input
                onChange={(event) => {
                  if (event.target.value.length <= nameMaxLength) {
                    setDvRule({ ...dvRule, name: event.target.value });
                  }
                }}
                value={dvRule.name}
                warning={warningMessage.name.length > 1}
                warningMessage={warningMessage.name}
              />
            </C10>
          </R>
          <R props="mb-32">
            <C10>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div style={{ alignSelf: "flex-start" }}>
                  <Text size="sm" weight="medium" variant="secondary" element="div">
                    Rule description *
                  </Text>
                </div>
                <div style={{ alignSelf: "flex-end" }}>
                  <Text size="sm" weight="regular" variant="tertiary" element="div">{`${dvRule.description.length}/${descriptionMaxLength}`}</Text>
                </div>
              </div>
              <TextArea
                onChange={(event) => {
                  if (event.target.value.length <= descriptionMaxLength) {
                    setDvRule({ ...dvRule, description: event.target.value });
                  }
                }}
                value={dvRule.description}
                warning={warningMessage.description.length > 1}
                warningMessage={warningMessage.description}
              />
            </C10>
          </R>
          <R props="mb-32">
            <C10>
              <Text size="sm" weight="medium" variant="secondary" element="div">
                Reconciliation type
              </Text>
              <Text size="sm" weight="regular" variant="secondary" element="div">
                {recTypeName}
              </Text>
            </C10>
          </R>
        </div>
      </PanelBody>
      <PanelFooter>
        <Button
          color="tertiary"
          onClick={() => {
            closeModal();
          }}
        >
          <Text size="sm">Cancel</Text>
        </Button>
        <Button
          onClick={() => {
            createDataVerificationRule();
          }}
          size="md"
          disabled={false}
        >
          <Text size="sm">Create verification rule</Text>
        </Button>
      </PanelFooter>
    </Panel>
  );
});

export { CreateDataVerificationRule };
