import React, { useState } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react-lite";
import { FullScreenModal, modalInstance, Button, Text, R, C2, C4, C8, Card, IconCheck, LinkText } from "@fundrecs/ui-library";
import { ModalHeader } from "../../layout/ModalHeader";
import { Step } from "../../reusable/step/step";
import { TextInput } from "../../reusable/TextInput";
import { SetupRecTypeDataStructure } from "./SetupRecTypeDataStructure";
import { SetupAggregators } from "./SetupAggregators";
import { SetupTags } from "./SetupTags";
import { SetupCategories } from "./SetupCategories";
import { MODALS } from "../../../utils/enums";
import { useStore } from "../../../store/Store";
import { Badge, Circle } from "../../layout/Layout";

/**
 * The CreateNewLookup component sets the initial state and renders
 * all the components required to create a new lookup.
 *
 * Props are passed down to the children as needed.
 */
const RecTypeSetup = observer(({}) => {
  //Default initial state for a new Lookup
  const { recTypeStore } = useStore();

  const recType = recTypeStore.getDraftRecType();
  const MENU = "MENU";
  const DATA_STRUCTURE = "DATA_STRUCTURE";
  const AGGREGATOR_RULES = "AGGREGATOR_RULES";
  const CATEGORIES = "CATEGORIES";
  const TAGS = "TAGS";
  const VIEW = "view";
  const EDIT = "edit";
  const [setupView, setSetupView] = useState({ view: MENU, mode: VIEW });

  const REC_SETUP_COMPONENTS = {
    DATA_STRUCTURE: SetupRecTypeDataStructure,
    AGGREGATOR_RULES: SetupAggregators,
    CATEGORIES: SetupCategories,
    TAGS: SetupTags,
  };

  const ComponentToRender = REC_SETUP_COMPONENTS[setupView.view];
  const dataStructureSaved = recTypeStore.getAllColumnsFromDraftRecType().length > 0;
  const aggregatorsSaved = recType.columns.length > 0;

  return (
    <>
      <Button
        onClick={() => {
          modalInstance(MODALS.RECTYPE_SETUP).show();
        }}
      >
        <Text>Rec Type Setup</Text>
      </Button>
      <FullScreenModal modalId={MODALS.RECTYPE_SETUP}>
        {setupView.view !== MENU ? (
          <ComponentToRender
            mode={setupView.mode}
            backToMenu={() => {
              setSetupView({ view: MENU, mode: VIEW });
            }}
          />
        ) : (
          <>
            <div className="pt-6 pb-24">
              <ModalHeader
                onCloseClick={() => {
                  modalInstance(MODALS.RECTYPE_SETUP).hide();
                }}
                title={recType.name}
                contentLeft={<Badge text={recType.status} />}
                contentRight={
                  <Button disabled={true}>
                    <Text>Publish & Exit</Text>
                  </Button>
                }
              />
            </div>
            <div style={{ background: "#F7F8F9" }}>
              <R props="pb-32 pt-32">
                <C2></C2>
                <C8>
                  <Card spacers={["pt-24", "pl-32", "pr-32", "pb-24"]}>
                    {dataStructureSaved ? (
                      <Circle content={<IconCheck style={{ color: "white" }} className="btn-lg-svg" />} color="#14a316" />
                    ) : (
                      <Circle
                        content={
                          <Text theme="dark" variant="primary">
                            1
                          </Text>
                        }
                        color="#1E5EFF"
                      />
                    )}

                    <span className="pl-8"></span>
                    <Text size="lg">Setup expected data structure</Text>
                    <R props="pl-32">
                      <Text size="sm" weight="regular">
                        Create the structure of the incoming data and of the expected results including calculated fields
                      </Text>
                    </R>
                    <R props="pl-32 pt-32">
                      <C8>
                        <Text size="sm" weight="regular">
                          Create data structure of both incoming data and results
                        </Text>
                      </C8>
                      <C4>
                        <span
                          onClick={() => {
                            setSetupView({ view: DATA_STRUCTURE, mode: VIEW });
                          }}
                        >
                          <LinkText>Preview</LinkText>
                        </span>
                      </C4>
                      <Button
                        color="tertiary"
                        onClick={() => {
                          setSetupView({ view: DATA_STRUCTURE, mode: EDIT });
                        }}
                      >
                        Create new Structure
                      </Button>
                    </R>
                  </Card>
                </C8>
                <C2></C2>
              </R>
              <R props="pb-32">
                <C2></C2>
                <C8>
                  <Card spacers={["pt-24", "pl-32", "pr-32", "pb-24"]}>
                    {aggregatorsSaved ? (
                      <Circle content={<IconCheck className="btn-md-svg" />} color="#14a316" />
                    ) : dataStructureSaved ? (
                      <Circle
                        content={
                          <Text theme="dark" variant="primary">
                            2
                          </Text>
                        }
                        color="#1E5EFF"
                      />
                    ) : (
                      <Circle content={<Text>2</Text>} color="#E6E7EB" />
                    )}

                    <span className="pl-8"></span>
                    <Text size="lg">Setup aggregator rule sets (Rollups)</Text>
                    <R props="pl-32">
                      <Text size="sm" weight="regular">
                        Configure rules to allow aggregation of line items, placing them into one single line item for the purpose of matching
                      </Text>
                    </R>
                    <R props="pl-32 pt-32">
                      {!dataStructureSaved ? (
                        ""
                      ) : (
                        <>
                          {" "}
                          <Text size="sm" weight="regular">
                            Create new aggregator rule set
                          </Text>
                          <Button
                            color="tertiary"
                            onClick={() => {
                              setSetupView({ view: AGGREGATOR_RULES, mode: EDIT });
                            }}
                          >
                            Create new aggregator rule set
                          </Button>
                        </>
                      )}
                    </R>
                  </Card>
                </C8>
                <C2></C2>
              </R>
              <R props="pb-32">
                <C2></C2>
                <C8>
                  <Card spacers={["pt-24", "pl-32", "pr-32", "pb-24"]}>
                    <Circle content={<Text>3</Text>} color="#E6E7EB" />
                    <span className="pl-8"></span>
                    <Text size="lg">Setup Categories</Text>
                    <R props="pl-32">
                      <Text size="sm" weight="regular">
                        Define the categories used to help classify the results of this reconciliation type
                      </Text>
                    </R>
                    <R props="pl-32 pt-32">
                      <Text size="sm" weight="regular">
                        Setup New Categories
                      </Text>
                      <Button
                        color="tertiary"
                        onClick={() => {
                          setSetupView({ view: CATEGORIES, mode: EDIT });
                        }}
                      >
                        Setup Categories
                      </Button>
                    </R>
                  </Card>
                </C8>
                <C2></C2>
              </R>
              <R props="pb-32">
                <C2></C2>
                <C8>
                  <Card spacers={["pt-24", "pl-32", "pr-32", "pb-24"]}>
                    <Circle content={<Text>4</Text>} color="#E6E7EB" />
                    <span className="pl-8"></span>
                    <Text size="lg">Setup Tags</Text>
                    <span className="pl-8"></span>
                    <Text size="sm" weight="regular">
                      Optional
                    </Text>
                    <R props="pl-32">
                      <Text size="sm" weight="regular">
                        Define the tags used to help classify the results of this reconciliation type
                      </Text>
                    </R>
                    <R props="pl-32 pt-32">
                      <Text size="sm" weight="regular">
                        Create new tags
                      </Text>
                      <Button
                        color="tertiary"
                        onClick={() => {
                          setSetupView({ view: TAGS, mode: EDIT });
                        }}
                      >
                        Create new tags
                      </Button>
                    </R>
                  </Card>
                </C8>
                <C2></C2>
              </R>
            </div>
          </>
        )}
        {/*
      <ModalHeader onCloseClick={closeModalAndResetFields} title="Create new reconciliation type" />
      <div className="mt-32 mb-72">
        <Step steps={steps} activeStep={state.step} />
      </div>
      {<state.step.component state={state} dispatch={dispatch} validationMessages={validationMessages} />}
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            validateInputs();
          }}
        >
          <Text>{state.step.nextStepText}</Text>
        </Button>
        </ModalFooter>
        */}
      </FullScreenModal>
    </>
  );
});

export { RecTypeSetup };
