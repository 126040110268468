import "./slideOverPanel.css";
const SlideOverPanel = ({ width, id, children }) => {
  const style = { width: width, maxWidth: width, minWidth: width };

  return (
    <div className="modal slideover-panel fade" id={id} role="dialog" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog" style={style}>
        <div className="modal-content panel" style={{ height: "100vh" }}>
          {children}
        </div>
      </div>
    </div>
  );
};

export { SlideOverPanel };
