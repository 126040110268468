import { makeAutoObservable, toJS } from "mobx";
import { fusionRecsApi } from "../api";
import { uiStore, rolesStore } from "./Store";
import { isUserAuthorized } from "../components/AuthorizationWrapper";
import { AUTHORITIES } from "../utils/enums";

/**
 * Contains data for clients for a team
 * Stored as a list of clients
 * Should be updated each time a team is changed
 */
class CarryForwardStore {
  carryForwardRules = [];
  carryForwardRowsRules = [];

  constructor() {
    makeAutoObservable(this);
  }

  getCarryForwardRules = () => {
    return toJS(this.carryForwardRules);
  };

  getCarryActions = async (teamId, recTypeId) => {
    if (
      !isUserAuthorized({
        teamId: teamId,
        oneRequired: rolesStore.getActions([AUTHORITIES.RECS_CARRY_MANUAL_GROUP_CREATE, AUTHORITIES.RECS_CARRY_AUTO_GROUP_CREATE]),
      })
    ) {
      console.log("getCarryActions: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.carryForward.getCarryActions(teamId, recTypeId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  createCarryForwardAutoGroups = async (teamId, body) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_CARRY_AUTO_GROUP_CREATE]) })) {
      console.log("createCarryForwardAutoGroups: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.carryForward.createCarryForwardAutoGroups(teamId, body);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  fetchCarryForwardRules = async (teamId, recTypeId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_CARRY_GROUP_VIEW]) })) {
      console.log("fetchCarryForwardRules: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.carryForward.fetchCarryForwardRules(teamId, recTypeId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    } else {
      this.carryForwardRules = resp.data;
    }
    return resp;
  };

  fetchCarryForwardRowsRules = async (teamId, recTypeId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_TYPE_CARRY_FORWARD_VIEW]) })) {
      console.log("fetchCarryForwardRowsRules: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.carryForward.fetchCarryForwardRowsRules(teamId, recTypeId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    } else {
      this.carryForwardRowsRules = resp.data;
    }
    return resp;
  };

  enableCarryForwardRows = async (teamId, version, body) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_TYPE_CARRY_FORWARD_ENABLE]) })) {
      console.log("enableCarryForwardRows: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.carryForward.enableCarryForwardRows(teamId, version, body);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  disableCarryForwardRows = async (teamId, version, recTypeId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_TYPE_CARRY_FORWARD_ENABLE]) })) {
      console.log("disableCarryForwardRows: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.carryForward.disableCarryForwardRows(teamId, version, recTypeId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  deleteRowGroupCarryForwardRule = async (teamId, id, version) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_CARRY_AUTO_GROUP_DELETE]) })) {
      console.log("deleteRowGroupCarryForwardRule: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.carryForward.deleteRowGroupCarryForwardRule(teamId, id, version);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  enableRowGroupCarryForwardRule = async (teamId, body) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_CARRY_AUTO_GROUP_ENABLE]) })) {
      console.log("enableRowGroupCarryForwardRule: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.carryForward.enableRowGroupCarryForwardRule(teamId, body);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  createCarryForwardManualActions = async (teamId, body) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_CARRY_MANUAL_GROUP_CREATE]) })) {
      console.log("createCarryForwardManualActions: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.carryForward.createCarryForwardManualActions(teamId, body);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  deleteManualActionCarryForwardRule = async (teamId, id, version) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_CARRY_MANUAL_GROUP_DELETE]) })) {
      console.log("deleteManualActionCarryForwardRule: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.carryForward.deleteManualActionCarryForwardRule(teamId, id, version);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  enableManualActionCarryForwardRule = async (teamId, body) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_CARRY_MANUAL_GROUP_ENABLE]) })) {
      console.log("enableManualActionCarryForwardRule: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.carryForward.enableManualActionCarryForwardRule(teamId, body);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };


  getCarryForwardRowsRules = () => {
    return toJS(this.carryForwardRowsRules);

  }
}

export { CarryForwardStore };
