const PATH = {
  REC_TYPES: "/recTypes",
  REC_TYPE_DASH: "/recTypeDash",
  REC_TYPE_CONFIG_DASH: "/recTypeConfigDash",
  REC_TYPE_CONFIG: "/recTypeConfig",
  REC: "/rec",
  REC_AUDIT_TRAIL: "/rec/audit",
  FUND: "/fund",
  SUB_ACCOUNT: "/subAccount",
  FUND_DETAILS: "/fundDetails",
  DASHBOARD: "/dashboard",
  WORKFLOWS: "/workflows",
  LOOKUPS: "/lookups",
  MAPPINGS: "/mappings",
  TEMPLATES: "/templates",
  MATCHING_RULE_BUILDER: "/matchingRule",
  UPLOADS: "/uploads",
  UPLOADED_FILE: "/uploadedFile",
  UPLOADED_FILE_ERRORS: "/uploadedFileErrors",
  IMPORTS: "/imports",
  GLOBAL_MAPPINGS: "/globalMappings"
};

const rowPageParams = (params) => {
  return {
    teamId: params.teamId ? params.teamId : "",
    recTypeId: params.recTypeId ? params.recTypeId : "",
    accId: params.accId ? params.accId : "",
    recId: params.recId ? params.recId : "",
    subAccId: params.subAccId ? params.subAccId : "",
    tab: params.tab ? params.tab : "Unprocessed",
  };
};

const recTypeDashParams = (params) => {
  return {
    teamId: params.teamId ? params.teamId : "",
    recTypeId: params.recTypeId ? params.recTypeId : "",
  };
};

const updateParamsWithNewValue = (searchParams, keyToUpdate, valueToUpdate) => {
  const it = searchParams.entries();
  let updatedParams = {};
  let result = it.next();
  while (!result.done) {
    updatedParams[result.value[0]] = result.value[1];
    result = it.next();
  }
  return { ...updatedParams, [keyToUpdate]: valueToUpdate };
};

const updateUrl = (navigate, path, params, replace) => {
  if (!navigate || [null, 0, undefined, ""].includes(navigate) || typeof navigate !== "function") {
    return;
  }
  let url = `${path}?`;
  Object.entries(params).forEach((keyValue, index) => {
    url = url.concat(`${keyValue[0]}=${keyValue[1]}${index + 1 < Object.keys(params).length ? "&" : ""}`);
  });
  navigate(url, { replace: replace });
};

const updateRowPageUrl = (navigate, params, replace) => {
  updateUrl(navigate, PATH.REC, rowPageParams(params), replace);
};

const updateRecTypeDashboardUrl = (navigate, params, replace) => {
  updateUrl(navigate, PATH.REC_TYPE_DASH, recTypeDashParams(params), replace);
};

export { PATH, updateParamsWithNewValue, updateRowPageUrl, updateRecTypeDashboardUrl };
