/**
 * Component to provide a full screen view on top of standard layout featuring sidebar and header.
 * To be used in place of the full screen modal.
 * @param {*} param0
 * @returns
 */
const FullScreenView = ({ children }) => {
  const style = {
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    height: "100%",
    width: "100%",
    position: "fixed",
    overflowY: "scroll",
    zIndex: "1000",
    backgroundColor: "#fff",
  };

  return <div style={style}>{children}</div>;
};

export { FullScreenView };
