import { useContext } from "react";
import { Text, R, IconCheck } from "@fundrecs/ui-library";
import { MatchingContext, MatchingDispatchContext, SET_TAGS } from "../../matchingReducer";

const TagsPanel = ({ tags }) => {
  const matchingConfig = useContext(MatchingContext);
  const distach = useContext(MatchingDispatchContext);

  const toggleTag = (tag) => {
    let updatedNotes = [];
    if (matchingConfig.tags.map((it) => it.id).includes(tag.id)) {
      updatedNotes = [...matchingConfig.tags.filter((it) => it.id !== tag.id)];
    } else {
      updatedNotes = [...matchingConfig.tags, tag];
    }
    distach({ type: SET_TAGS, tags: updatedNotes });
  };

  let displayString = "";
  if (matchingConfig.tags.length) {
    matchingConfig.tags.forEach((it) => (displayString += `${it.text}, `));
  }
  return (
    <>
      <R props="mt-16">
        <Text size="xs" weight="bold">
          Add tags
        </Text>
        <div className="dropdown w-100">
          <div title={displayString} data-bs-toggle="dropdown" className="btn dropdown-button dropdown-button-sm form-control form-control-md input-text-md">
            {displayString}
          </div>
          <ul className="dropdown-menu dropdown-menu-end box" aria-labelledby="dropdownMenuLink">
            {tags.map((tag, index) => {
              return (
                <li
                  key={index}
                  onClick={() => {
                    toggleTag(tag);
                  }}
                >
                  <a className="dropdown-item">
                    <div className="d-flex">
                      <div className="ellipsize-text width-95-pc">{tag.text}</div>
                      {matchingConfig.tags.map((it) => it.id).includes(tag.id) ? <IconCheck className="btn-sm-svg" color="#1e5eff" /> : ""}
                    </div>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </R>
    </>
  );
};

export { TagsPanel };
