import { fusionRequest } from "../fusionRequest";

const recType = {
  fetchRecTypes: (teamId) => fusionRequest.get(`team/${teamId}/recType`),
  getTagsForRecType: (teamId, recTypeId) => fusionRequest.get(`team/${teamId}/recType/${recTypeId}/note`),
  getDefaultSettings: (teamId) => fusionRequest.get(`team/${teamId}/recType/setting`),
  updateSetting: (teamId, recTypeId, version, body) => fusionRequest.put(`team/${teamId}/recType/${recTypeId}/setting?version=${version}`, body),
  createRecType: (teamId, body) => fusionRequest.post(`team/${teamId}/recType`, body), //{name: String, settings: Set<Setting>}}
  updateRecType: (teamId, body) => fusionRequest.put(`team/${teamId}/recType`, body), //{id: Long, name: String, Long version}
  addTmo: (teamId, recTypeId, version, body) => fusionRequest.post(`team/${teamId}/recType/${recTypeId}/tmo?version=${version}`, body), //{fusionId: Long, name: String, schema: Map<String, EnumColumnType>}
  enableAccountForRecType: (teamId, recTypeId, accountId, recTypeVersion, accountVersion) =>
    fusionRequest.put(`team/${teamId}/recType/${recTypeId}/account/${accountId}?version=${recTypeVersion}&accountVersion=${accountVersion}`),
  disableAccountForRecType: (teamId, recTypeId, accountId, recTypeVersion, accountVersion) =>
    fusionRequest.delete(`team/${teamId}/recType/${recTypeId}/account/${accountId}?version=${recTypeVersion}&accountVersion=${accountVersion}`),
  createAlias: (teamId, recTypeId, body) => fusionRequest.post(`team/${teamId}/recType/${recTypeId}/alias`, body),
  deleteAlias: (teamId, recTypeId, aliasId, tableType) =>
    fusionRequest.delete(`team/${teamId}/recType/${recTypeId}/alias?id=${aliasId}&tableType=${tableType}`),
  enableSubAccount: (teamId, recTypeId, accountId, subAccountId, recTypeVersion, accountVersion, subAccountVersion) =>
    fusionRequest.put(
      `team/${teamId}/recType/${recTypeId}/account/${accountId}/subaccount/${subAccountId}?version=${recTypeVersion}&accountVersion=${accountVersion}&subAccountVersion=${subAccountVersion}`
    ),
  disableSubAccount: (teamId, recTypeId, accountId, subAccountId, recTypeVersion, accountVersion, subAccountVersion) =>
    fusionRequest.delete(
      `team/${teamId}/recType/${recTypeId}/account/${accountId}/subaccount/${subAccountId}?version=${recTypeVersion}&accountVersion=${accountVersion}&subAccountVersion=${subAccountVersion}`
    ),
  getTmosForRecType: (teamId, recTypeId) => fusionRequest.get(`team/${teamId}/tmo?recTypeId=${recTypeId}`),
  getColumnsForTmo: (teamId, tmoId) => fusionRequest.get(`team/${teamId}/tmo/${tmoId}/column`),
  getWorkflowsAndTemplatesForTmo: (teamId, tmoId) => fusionRequest.get(`team/${teamId}/tmo/${tmoId}/workflow-and-template`),
  getGroupTmoColumns: (teamId, recTypeId) => fusionRequest.get(`team/${teamId}/recType/${recTypeId}/groupTmo`),
  fetchMatchingRules: (teamId, recTypeId) => fusionRequest.get(`team/${teamId}/recType/${recTypeId}/matchingdashboard`),
  fetchMatchingRuleDetails: (teamId, recTypeId) => fusionRequest.get(`team/${teamId}/recType/${recTypeId}/matching`),
  fetchClientsAccountsPerRecType: (teamId, recTypeId) => fusionRequest.get(`team/${teamId}/recType/${recTypeId}/structure`),
  copyMatchingRules: (teamId, recTypeId, accountId, subAccountId, body) =>
    fusionRequest.post(`team/${teamId}/recType/${recTypeId}/account/${accountId}/subaccount/${subAccountId}`, body),
  updateRestartRec: (teamId, recTypeId, accountId, subAccountId, body) =>
    fusionRequest.put(`team/${teamId}/recType/${recTypeId}/account/${accountId}/subaccount/${subAccountId}/recRestart`, body),
  getRowGroupSchema: (teamId, recTypeId) => fusionRequest.get(`team/${teamId}/recType/${recTypeId}/groupTmoView/schema`),
  fetchRecTypeAndSettings: (teamId, recTypeId) => fusionRequest.get(`team/${teamId}/recType/${recTypeId}`),
};

export { recType };
