import React, { useState, useRef, useEffect } from "react";
import Tippy from "@tippyjs/react";
import { Link } from "react-router-dom";
import { IconVerticalMenu, Text } from "@fundrecs/ui-library";

import styles from "./VerticalMenu.module.scss";

const VerticalMenu = ({ teamId, menuItems, onItemClick, getMenuItemsFromRowData = false, disableMenu = false, data = {}, ...props }) => {
  const tippyRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [menuOptions, setMenuOptions] = useState([]);

  /**
   * getMenuItemsFromRowData is used when the menu options is based off some value from the row
   * it ignores the menuItems value
   */
  useEffect(() => {
    if (!getMenuItemsFromRowData) {
      setMenuOptions(menuItems);
    } else {
      setMenuOptions(getMenuItemsFromRowData({ teamId, data }));
    }
  }, []);

  useEffect(() => {
    if (!menuOptions.some((v) => v.visible === true)) {
      setDisabled(true);
    } else {
      setDisabled(disableMenu);
    }
  }, [disableMenu, menuOptions]);

  const openMenu = () => {
    setIsOpen(true);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  /**
   * Render Menu based on menuItems
   * - disabled is set to false if value is not passed - it show the option but does not allow user to click
   * - visible is set to true if value is not passed - the option won't be displayed if is false - this can be used for access control
   */
  const menuContent = () => {
    const onClickHandler = (event, option, optionData) => {
      event.stopPropagation();
      closeMenu();
      onItemClick(option, optionData);
    };

    const createMenuItem = (label, disabled) => {
      return (
        <span className={["dropdown-item", styles.dropdownItem, disabled ? styles.cursorDefault : styles.cursorPointer].join(" ")}>
          <Text size="sm" variant={disabled ? "muted" : "primary"} weight="regular" theme="light">
            {label}
          </Text>
        </span>
      );
    };

    return (
      <div className={[styles.menuContent, "menu-container"].join(" ")}>
        {menuOptions.length > 0 &&
          menuOptions.map(({ key, label, disabled = false, visible = true, link = null, optionData }) => {
            return !visible ? (
              ""
            ) : [null, undefined].includes(link) ? (
              <div key={key} onClick={(event) => onClickHandler(event, key, optionData)}>
                {createMenuItem(label, disabled)}
              </div>
            ) : (
              <Link className="text-decoration-none" to={link}>{createMenuItem(label, disabled)}</Link>
            );
          })}
      </div>
    );
  };

  const handleClick = (event) => {
    event.stopPropagation();
    isOpen ? closeMenu() : openMenu();
  };

  return (
    <Tippy
      ref={tippyRef}
      content={menuContent()}
      visible={isOpen}
      onClickOutside={closeMenu}
      allowHTML={true}
      arrow={false}
      appendTo={document.body}
      interactive={true}
      placement="left"
    >
      <div onClick={handleClick} className={[styles.cursorPointer, disabled ? styles.disabled : ""].join(" ")}>
        <IconVerticalMenu className="btn-sm-svg" />
      </div>
    </Tippy>
  );
};

export { VerticalMenu };
