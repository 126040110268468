import { fusionRequest } from "../fusionRequest";
import { API_ENDPOINTS } from "../config";

const API_PREFIX = "/adminConsole";

const fusionAdmin = {
  me: () => fusionRequest.get(`${API_PREFIX}${API_ENDPOINTS.FUSION_ADMIN.ME}`),
  updateMe: (user) => fusionRequest.put(`${API_PREFIX}${API_ENDPOINTS.FUSION_ADMIN.UPDATE_ME}`, user),
  roles: () => fusionRequest.get(`${API_PREFIX}${API_ENDPOINTS.FUSION_ADMIN.ROLES}`),
  connectionSummary: (type, method) => fusionRequest.get(`${API_PREFIX}${API_ENDPOINTS.FUSION_ADMIN.CONNECTIONS_SUMMARY}&type=${type}&method=${method}`),
  getFrontendProperties: () => fusionRequest.get(`${API_PREFIX}${API_ENDPOINTS.FUSION_ADMIN.GET_FRONTEND_PROPERTIES}`),

};

export { fusionAdmin };
