import { Spinner } from "../../layout/Spinner";

const RunningMatchingRulesPlaceholder = ({text}) => {
  return (
    <div
      style={{
        color: "#1E5EFF",
        background: "#E8EEFF",
        width: "100%",
        height: "30px",
        paddingTop: "1em",
        paddingBottom: "3em",
        borderRadius: "4px",
        marginTop: "15px",
      }}
    >
      <Spinner color="white" backgroundColor="#086FFC" />
      <span>{text}</span>
    </div>
  );
};

export { RunningMatchingRulesPlaceholder };
