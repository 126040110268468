import { makeAutoObservable, toJS } from "mobx";
import { fusionRecsApi } from "../api";
import { uiStore, rolesStore } from "./Store";
import { isUserAuthorized } from "../components/AuthorizationWrapper";
import { AUTHORITIES } from "../utils/enums";

class GlobalMappingStore {
  globalMappings = [];

  constructor() {
    makeAutoObservable(this);
  }

  getGlobalMappings = () => {
    return toJS(this.globalMappings);
  };

  createGlobalMapping = async (teamId, body) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_GLOBAL_MAPPING_CREATE]) })) {
      console.log("createGlobalMapping: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.globalMapping.createGlobalMapping(teamId, body);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    } else {
      this.globalMappings = resp.data;
    }
    return resp;
  };

  approveGlobalMapping = async (teamId, gmId, version) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_GLOBAL_MAPPING_APPROVE]) })) {
      console.log("approveGlobalMapping: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.globalMapping.approveGlobalMapping(teamId, gmId, version);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    } else {
      this.globalMappings = resp.data;
    }
    return resp;
  };

  deleteGlobalMapping = async (teamId, globalMappingId, globalMappingVersion) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_GLOBAL_MAPPING_REJECT]) })) {
      console.log("deleteGlobalMapping: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.globalMapping.deleteGlobalMapping(teamId, globalMappingId, globalMappingVersion);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  fetchGlobalMappingByRecTypeId = async (teamId, recTypeId) => {
    if (
      !isUserAuthorized({
        teamId: teamId,
        oneRequired: rolesStore.getActions([
          AUTHORITIES.RECS_REC_VIEW,
          AUTHORITIES.RECS_ACCOUNT_VIEW,
          AUTHORITIES.RECS_MATCHING_RULE_VIEW,
          AUTHORITIES.RECS_GLOBAL_MAPPING_VIEW,
        ]),
      })
    ) {
      console.log("fetchGlobalMappingByRecTypeId: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.globalMapping.fetchGlobalMappingByRecTypeId(teamId, recTypeId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    } else {
      this.globalMappings = resp.data;
    }
    return resp;
  };

  fetchGlobalMappingValues = async (teamId, globalMappingId, accountId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_VIEW, AUTHORITIES.RECS_GLOBAL_MAPPING_VIEW]) })) {
      console.log("fetchGlobalMappingValues: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.globalMapping.fetchGlobalMappingValues(teamId, globalMappingId, accountId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  enableGlobalMapping = async (teamId, gmId, version) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_GLOBAL_MAPPING_ENABLE]) })) {
      console.log("enableGlobalMapping: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.globalMapping.enableGlobalMapping(teamId, gmId, version);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    } else {
      this.updateGlobalMapping(gmId, resp.data, true);
    }
    return resp;
  };

  disableGlobalMapping = async (teamId, gmId, version) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_GLOBAL_MAPPING_DISABLE]) })) {
      console.log("disableGlobalMapping: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.globalMapping.disableGlobalMapping(teamId, gmId, version);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    } else {
      this.updateGlobalMapping(gmId, resp.data, false);
    }
    return resp;
  };

  enableGlobalMappingForAccount = async (teamId, globalMappingId, accountId, globalMappingVersion, accountVersion) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_GLOBAL_MAPPING_ENABLE]) })) {
      console.log("enableGlobalMappingForAccount: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.globalMapping.enableGlobalMappingForAccount(teamId, globalMappingId, accountId, globalMappingVersion, accountVersion);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  disableGlobalMappingForAccount = async (teamId, globalMappingId, accountId, globalMappingVersion, accountVersion) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_GLOBAL_MAPPING_DISABLE]) })) {
      console.log("disableGlobalMappingForAccount: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.globalMapping.disableGlobalMappingForAccount(teamId, globalMappingId, accountId, globalMappingVersion, accountVersion);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  approveGlobalMappingValues = async (teamId, gmId, accountId, body) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_GLOBAL_MAPPING_ENTRY_APPROVE]) })) {
      console.log("approveGlobalMappingValues: unauthorised");
      return { status: 401 };
    }

    const resp = await fusionRecsApi.globalMapping.approveGlobalMappingValues(teamId, gmId, accountId, body);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  deleteGlobalMappingValues = async (teamId, gmId, accountId, body) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_GLOBAL_MAPPING_ENTRY_REJECT]) })) {
      console.log("deleteGlobalMappingValues: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.globalMapping.deleteGlobalMappingValues(teamId, gmId, accountId, body);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  addGlobalMappingValues = async (teamId, gmId, accountId, body) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_GLOBAL_MAPPING_ENTRY_CREATE]) })) {
      console.log("addGlobalMappingValues: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.globalMapping.addGlobalMappingValues(teamId, gmId, accountId, body);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  updateGlobalMapping = (gmId, version, enabled) => {
    this.globalMappings = this.globalMappings.map((gm) => {
      return gm.id === gmId ? { ...gm, version: version, enabled: enabled } : gm;
    });
  };
}

export { GlobalMappingStore };
