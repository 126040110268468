const ROW_FETCH_LIMIT = 2000;
const ALL_ROWS = "all";

/**
 * Returns the upper page number limit for batch fetching
 * Must return at least one
 * @param {*} numberOfRows The number of rows to be displayed. Selected by the user
 * @returns
 */
const getLastPageNo = (numberOfRows) => {
  const pageNo = Math.ceil(numberOfRows / ROW_FETCH_LIMIT);
  return pageNo === 0 ? pageNo : pageNo - 1;
};

const fetchBatches = async (numberOfRows, fetch) => {
  let fetchArray = [];
  for (let pageNo = 0; pageNo <= getLastPageNo(numberOfRows); pageNo++) {
    fetchArray.push(fetch(pageNo));
  }
  const responses = await Promise.all(fetchArray);
  return responses;
};

export { ROW_FETCH_LIMIT, ALL_ROWS, getLastPageNo, fetchBatches };
