import { makeAutoObservable, toJS } from "mobx";
import _ from "lodash";
import { fusionRecsApi } from "../api";

const { fusionAdmin } = fusionRecsApi;

class RolesStore {
  roles = [];
  roleId = "";
  allActions = new Map();

  constructor() {
    makeAutoObservable(this);
  }

  ping = () => {
    console.log("You have pinged the roles store");
  };

  /**
   * Set the Roles array and the allACtions Map using actions from each role
   * @param {Array} roles
   */
  setRoles = (roles) => {
    let allActions = new Map();
    roles.forEach((role) => {
      for (let i = 0; i < role.actions.length; i++) {
        const action = role.actions[i];
        if (allActions[action.authority]) continue;
        else allActions.set(action.authority, action);
      }
    });
    this.roles = roles;
    this.allActions = allActions;
  };

  setRoleId(value) {
    this.roleId = value;
  }

  getRoleId() {
    return this.roleId;
  }

  getRoles = () => {
    return this.roles;
  };

  findRole = (roles, id) => {
    let role = _.find(roles, { id });
    const { name } = role || "";
    return name;
  };

  findRoleByName(name) {
    let result;

    this.roles.forEach((role) => {
      if (role.name === name) {
        result = role;
        return;
      }
    });
    return toJS(result);
  }

  getRoleById(id) {
    let result;

    this.roles.forEach((role) => {
      if (role.id === id) {
        result = role;
        return;
      }
    });
    return toJS(result);
  }

  getActionsByRoleId(roles) {
    let actions = new Set();
    roles.forEach((role) => {
      this.getRoleById(role.id)["actions"].forEach((action) => {
        actions.add(action);
      });
    });
    return Array.from(actions);
  }

  getActionsByRoleName(roleNames) {
    if (!this.roles.length) {
      return [];
    }
    let actions = new Set();
    roleNames.forEach((roleName) => {
      this.findRoleByName(roleName)["actions"].forEach((action) => {
        actions.add(action);
      });
    });
    return Array.from(actions);
  }

  /**
   * From an array of authorities, find the corresponding Action objects in allActions map
   * @param {Array} authorities
   * @returns Array of Actions
   */
  getActions(authorities) {
    let actions = [];
    authorities.forEach((authority) => {
      const action = this.allActions.get(authority);
      if (action) actions.push(action);
    });
    return toJS(actions);
  }

  /**
   * Using a String authority, find the corresponding Action object in allActions map
   * @param {String} authority
   * @returns Single Action object or null if not found
   */
  findActionByAuthority(authority) {
    const action = this.allActions.get(authority);
    return action ? toJS(action) : null;
  }

  requestRoles = async () => {
    const { data } = await fusionAdmin.roles();
    this.setRoles(data);
  };
}

export { RolesStore };
