import { useState } from "react";
import { observer } from "mobx-react-lite";
import { LinkText, C4, C6, Text, R, C, SaveEditable, Toggle } from "@fundrecs/ui-library";

const EditableToggle = observer(({ originalValue, label, onSave, description = "", disabled }) => {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(originalValue);

  return (
    <R>
      {label ? (
        <C4>
          <Text variant="tertiary" size="sm" weight="regular" element="div">
            {label}
          </Text>
        </C4>
      ) : (
        ""
      )}
      <C6>
        <div className="d-flex" style={{ alignItems: "center" }}>
          <Toggle
            disabled={!editing}
            checked={value}
            onChange={() => {
              setValue(!value);
            }}
          />
          <span className="pl-16"></span>
          <Text>{value ? "Enabled" : "Disabled"}</Text>
        </div>
        {description ? (
          <>
            <div className="pb-4"></div>
            <Text variant="tertiary" size="xs" weight="regular" element="div">
              {description}
            </Text>
          </>
        ) : (
          ""
        )}
      </C6>
      {disabled ? (
        ""
      ) : editing ? (
        <C props="pt-4 pb-4">
          <SaveEditable
            onClickSave={() => {
              if (onSave) {
                onSave(value);
              }
              setEditing(false);
            }}
            onClickCancel={() => {
              setEditing(false);
              setValue(originalValue);
            }}
          />
        </C>
      ) : (
        <C>
          <span
            className="d-flex justify-content-end"
            onClick={() => {
              setEditing(true);
            }}
          >
            <LinkText>change</LinkText>
          </span>
        </C>
      )}
    </R>
  );
});

export { EditableToggle };
