import { useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, Link } from "react-router-dom";
import { Text, Heading, R, LinkText, modalInstance } from "@fundrecs/ui-library";
import { Table } from "../ag-grid/Ag-grid";
import { getDateStringFromTimestamp } from "../../utils/dates.js";
import { AUTHORITIES } from "../../utils/enums";
import { PATH } from "../../utils/urls";
import { useStore } from "../../store/Store.js";
import { VerticalMenu } from "../reusable/VerticalMenu/VerticalMenu";
import { DeleteRowsFromRec } from "./DeleteRowsFromRec";
import { isUserAuthorized } from "../AuthorizationWrapper";
import { StatusBadge } from "../reusable/StatusBadge";
import { DownloadTableButton } from "../reusable/DownloadTableButton";

const FundsImportSummary = observer(({ rowData, file, refreshData, disabled = false }) => {
  const gridRef = useRef(null);
  const navigate = useNavigate();
  const { teamStore, rolesStore } = useStore();
  const [selectedRow, setSelectedRow] = useState({ fundName: "", recId: null, recVersion: null });
  const DELETE_ROWS_MODAL_ID = "deleteRowsModal";
  const teamId = teamStore.getSelectedTeam().id;
  const processRecFundData = (data) => {
    return data.map((recData) => {
      return {
        ...recData,
        accountName: recData.accountName,
        status: recData.status,
        startDate: getDateStringFromTimestamp(recData.startDate),
        importedRowCount: recData.importedRowCount,
        unmatchedCount: recData.unmatchedCount,
      };
    });
  };

  const openRec = (recTypeId, accountId, recId) => {
    navigate(`${PATH.REC}?teamId=${teamStore.getSelectedTeam().id}&recTypeId=${recTypeId}&accId=${accountId}&recId=${recId}`, {
      replace: false,
    });
  };

  const deleteRowsFromRec = async (fundName, importedRowCount, recId, recVersion) => {
    if (!disabled) {
      setSelectedRow({ fundName: fundName, importedRowCount: importedRowCount, recId: recId, recVersion: recVersion });
      modalInstance(DELETE_ROWS_MODAL_ID).toggle();
    }
  };

  const getMenuItems = (params) => {
    const deleteRowsEnabled = isUserAuthorized({ teamId: teamId, allRequired: rolesStore.getActions([AUTHORITIES.RECS_UPLOADED_FILE_ROWS_DELETE]) });

    const menuItems = [
      {
        key: "openRec",
        label: "Go to reconciliation",
        disabled: false,
        visible: true,
        optionData: { recTypeId: file.recTypeId, accountId: params.data.accountId, recId: params.data.recId },
      },
      {
        key: "deleteRows",
        label: "Delete all imported rows from fund",
        disabled: !deleteRowsEnabled,
        visible: deleteRowsEnabled,
        optionData: {
          fundName: params.data.accountName,
          importedRowCount: params.data.importedRowCount,
          recId: params.data.recId,
          recVersion: params.data.recVersion,
        },
      },
    ];

    return menuItems;
  };

  const onItemClick = (option, optionData) => {
    const options = {
      openRec: () => {
        openRec(optionData.recTypeId, optionData.accountId, optionData.recId);
      },
      deleteRows: () => {
        deleteRowsFromRec(optionData.fundName, optionData.importedRowCount, optionData.recId, optionData.recVersion);
      },
    };
    return options[option]();
  };

  const columnDefs = [
    { headerName: "Fund", field: "accountName" },
    {
      headerName: "Rec Status",
      field: "status",
      cellRenderer: (params) => {
        return <StatusBadge status={params.data.status} recStatus={true} />;
      },
    },
    { headerName: "Rec Date", field: "startDate" },
    {
      headerName: "Imported Rows",
      field: "importedRowCount",
      cellRenderer: (params) => {
        return (
          <Link
            className="text-decoration-none"
            to={`${PATH.REC}?teamId=${teamStore.getSelectedTeam().id}&recTypeId=${file.recTypeId}&accId=${params.data.accountId}&recId=${params.data.recId}`}
          >
            <LinkText>{params.data.importedRowCount}</LinkText>
          </Link>
        );
      },
    },
    { headerName: "Unmatched Rows", field: "unmatchedCount" },
    {
      field: "expandRow",
      width: 50,
      maxWidth: 50,
      minWidth: 50,
      cellRenderer: (params) => {
        return <VerticalMenu teamId={teamStore.getSelectedTeam().id} data={params.data} getMenuItemsFromRowData={getMenuItems} onItemClick={onItemClick} />;
      },
    },
  ];

  const createFileName = () => {
    const fileName = `Imported data for ${file.fileName}`;
    const fullStopsRemoved = fileName.replaceAll(".", "");
    return fullStopsRemoved;
  };

  return (
    <>
      <div className="mt-32"></div>
      <div className="d-flex">
        <div>
          <Heading variant="h5">Funds import summary</Heading>
          <Text weight="regular" size="sm">
            Show all funds using this file
          </Text>
        </div>
        <div className="ml-16">
          <DownloadTableButton gridRef={gridRef} createFileName={createFileName} />
        </div>
      </div>

      <R props="mt-16">
        <Table columnDefs={columnDefs} rowData={rowData === null ? null : processRecFundData(rowData)} ref={gridRef} sizeToFit={true} />
      </R>
      <DeleteRowsFromRec
        modalId={DELETE_ROWS_MODAL_ID}
        teamId={teamId}
        fileId={file.fileId}
        fileVersion={file.fileVersion}
        fundName={selectedRow.fundName}
        recId={selectedRow.recId}
        recVersion={selectedRow.recVersion}
        importedRowCount={selectedRow.importedRowCount}
        refreshData={refreshData}
      />
    </>
  );
});

export { FundsImportSummary };
