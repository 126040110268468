import { makeAutoObservable, toJS } from "mobx";
import { fusionRecsApi } from "../api";
import { uiStore, rolesStore } from "./Store";
import { isUserAuthorized } from "../components/AuthorizationWrapper";
import { AUTHORITIES } from "../utils/enums";

class ComparatorFunctionStore {
  functions = [];
  sortedFunctions = {};

  constructor() {
    makeAutoObservable(this);
  }

  getFunctions = () => {
    return toJS(this.functions);
  };

  getSortedFunctions = () => {
    return toJS(this.sortedFunctions);
  };

  fetchFunctions = async (teamId) => {
    if (
      !isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_TYPE_CONFIG_VIEW, AUTHORITIES.RECS_MATCHING_RULE_VIEW]) })
    ) {
      console.log("fetchFunctions: unauthorised");
      return [];
    }
    const resp = await fusionRecsApi.comparatorFunction.getFunctions();
    let functions = [];
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    } else {
      functions = this.refactorFunctions(resp.data);
      this.functions = functions;
    }
    return functions;
  };

  /**
   * Each function is returned with a variety of different input/output signatures,
   * these must be iterated to create an array of all unique function/signature combinations
   * @param {*} functions
   * @returns
   */
  refactorFunctions = (functions) => {
    let refactoredFunctions = [];
    functions.forEach((toRefactor) => {
      toRefactor.signatures.forEach((signature) => {
        refactoredFunctions.push({ function: toRefactor.function, description: toRefactor.description, input: signature.input, output: signature.output });
      });
    });
    this.sortFunctionsByType(refactoredFunctions);
    return refactoredFunctions;
  };

  sortFunctionsByType = (functions) => {
    let sortedFunctions = { TEXT: [], NUMBER: [], DATE: [], BOOLEAN: [], MISC: [], NOTES: [] };
    functions.forEach((comparatorFunction) => {
      const params = comparatorFunction.input;
      if (params && params.length && params[0] === "STRING") {
        sortedFunctions["TEXT"].push(comparatorFunction);
      } else if (params && params.length && params[0] === "NUMBER") {
        sortedFunctions["NUMBER"].push(comparatorFunction);
      } else if (params && params.length && params[0] === "DATE") {
        sortedFunctions["DATE"].push(comparatorFunction);
      } else if (params && params.length && params[0] === "NOTES") {
        sortedFunctions["NOTES"].push(comparatorFunction);
      } else if (params && params.length && params[0] === "BOOLEAN") {
        sortedFunctions["BOOLEAN"].push(comparatorFunction);
      } else {
        sortedFunctions["MISC"].push(comparatorFunction);
      }
    });
    this.sortedFunctions = sortedFunctions;
  };
}

export { ComparatorFunctionStore };
