import { observer } from "mobx-react-lite";
import {
  Modal,
  ModalBody,
  modalInstance,
  Button,
  Text,
  ModalFooter,
  R,
} from "@fundrecs/ui-library";
import { MODALS } from "../../../utils/enums";
import { ModalHeader } from "../../layout/Modals";

const RunAllMatchingRulesModal = observer(({runMatchingRules}) => {
  const modalId = MODALS.RUN_ALL_MATCHING_RULES;

  return (
    <Modal modalId={modalId}>
      <ModalBody>
        <ModalHeader
          title="Run all matching rules"
          onCloseClick={() => {
            modalInstance(modalId).toggle();
          }}
        />
      </ModalBody>
      <div style={{ paddingLeft: "1em", paddingRight: "1em" }}>
        <R>
          <Text size="sm" weight="regular">
            Rerun all matching rules across <b>all unprocessed rows</b>
          </Text>
        </R>
        <R>
          <span className="mt-32"></span>
          <Text size="sm" weight="regular">
            This will run the matching rules for this reconciliation type, fund and sub-account across <b>all unprocessed rows</b> in this reconciliation.
          </Text>
        </R>
        <R>
          <span className="mt-32"></span>
          <Text size="sm" weight="regular">
            The existing matching rule filters will be applied.
          </Text>
        </R>
      </div>
      <ModalFooter>
        <Button
          color="tertiary"
          onClick={() => {
            modalInstance(modalId).toggle();
          }}
        >
          <Text>Cancel</Text>
        </Button>
        <Button
          color="primary"
          onClick={() => {
            runMatchingRules()
            modalInstance(modalId).toggle();
          }}
        >
          <Text>Run</Text>
        </Button>
      </ModalFooter>
    </Modal>
  );
});

export { RunAllMatchingRulesModal };
