/**
 * createBrowserDownload
 *
 * Downloads a file to the user's browser
 *
 * @param {string} fileName
 * @param {string} fileData
 * @param {string} mimeType
 */
const createBrowserDownload = (fileName, fileData, mimeType = "") => {
  const fileBlob = new Blob([fileData], { type: mimeType });
  const fileUrl = window.URL.createObjectURL(fileBlob);
  const downloadLink = document.createElement("a");

  downloadLink.setAttribute("href", fileUrl);
  downloadLink.setAttribute("download", fileName);

  // open the link in user's browser
  downloadLink.click();

  // memory cleanup
  window.URL.revokeObjectURL(fileUrl);
};

export { createBrowserDownload };
