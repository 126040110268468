import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useSearchParams } from "react-router-dom";
import { Text, Toggle } from "@fundrecs/ui-library";
import { useStore } from "../../store/Store";
import { isUserAuthorized } from "../AuthorizationWrapper";
import { AUTHORITIES } from "../../utils/enums";

const ReplaceFileAccountSetup = observer(({ recTypeId, accountId, accountVersion }) => {
  const { accountStore, rolesStore } = useStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = searchParams.get("teamId");
  const [initialised, setInitialised] = useState(false);
  const [localAccountVersion, setLocalAccountVersion] = useState(accountVersion);
  const [replaceFileEnabled, setReplaceFilesEnabled] = useState(false);

  const findRecTypeAccountSettings = async () => {
    const resp = await accountStore.findRecTypeAccountSettings(teamId, accountId, recTypeId);
    if (resp.status === 200 && resp.data && ![null, undefined].includes(resp.data.enableReplaceFiles)) {
      setReplaceFilesEnabled(resp.data.enableReplaceFiles);
    }
  };

  if (!initialised && recTypeId && accountId) {
    setInitialised(true);
    findRecTypeAccountSettings();
  }

  const toggleReplaceFile = async () => {
    const resp = await accountStore.setEnableReplaceFiles(teamId, accountId, recTypeId, localAccountVersion, {
      enableReplaceFiles: !replaceFileEnabled,
      replaceFilesInterval: "THIRTY_DAYS",
    });
    if (resp.status === 200) {
      setReplaceFilesEnabled(!replaceFileEnabled);
      accountStore.updateSelectedAccountVersion(resp.data);
      setLocalAccountVersion(localAccountVersion + 1);
    }
  };

  return (
    <div>
      <Text size="sm" weight="bold">
        Replace file
      </Text>
      <div className="d-flex mt-16 pb-16" style={{ alignItems: "center" }}>
        <Toggle
          disabled={
            !isUserAuthorized({
              teamId: teamId,
              allRequired: rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_GLOBAL_MAPPING_ENABLE, AUTHORITIES.RECS_ACCOUNT_GLOBAL_MAPPING_DISABLE]),
            })
          }
          checked={replaceFileEnabled}
          onChange={() => {
            toggleReplaceFile();
          }}
        />

        <div className="ml-16">
          <Text size="xs" weight="bold">
            Replace file:
          </Text>
          <Text size="xs" weight="bold" variant={replaceFileEnabled ? "success" : "error"}>
            {replaceFileEnabled ? " Enabled" : " Disabled"}
          </Text>
          <div>
            <div className="text-xs text-regular light-text-tertiary" style={{ whiteSpace: "normal" }}>
              When enabled, uploaded files will be replaced whenever a newer version of the file becomes available. Data will only be replaced on
              Reconciliations with an open status. Will only replace files within the previous 30 days
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export { ReplaceFileAccountSetup };
