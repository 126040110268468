import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  R,
  C6,
  modalInstance,
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownList,
  DropdownListItem,
  IconDownArrow,
  DropdownWarning,
  Text,
  Button,
  Panel,
  PanelBody,
  PanelHeader,
  PanelFooter,
} from "@fundrecs/ui-library";
import { MODALS } from "../../../utils/enums";
import { TextInput } from "../../reusable/TextInput";
import { useStore } from "../../../store/Store";

const CreateRecTypePanel = observer(({}) => {
  const { recTypeStore } = useStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = searchParams.get("teamId");
  const initialState = {
    name: "",
    description: "",
    type: "",
    defaultDateFormat: "",
    defaultDecimal: null,
  };

  const [state, setState] = useState({ ...initialState });
  const [validationMessages, setValidationMessages] = useState({});
  //Default initial state for a new Lookup
  const TWO_SIDED = "Two sided";
  const reconciliationTypes = [TWO_SIDED];
  const dateFormats = ["yyyy-MM-dd", "dd-mm-yyyy"];
  const decimalOptions = [
    { number: "TWO", text: "2 (0.00)" },
    { number: "THREE", text: "3 (0.000)" },
    { number: "FOUR", text: "4 (0.0000)" },
    { number: "FIVE", text: "5 (0.00000)" },
    { number: "SIX", text: "6 (0.000000)" },
    { number: "SEVEN", text: "7 (0.0000000)" },
    { number: "EIGHT", text: "8 (0.00000000)" },
  ];

  const createRecType = async () => {
    let validationMessages = {};

    //Step one validation
    if (["", " ", null, undefined].includes(state.name)) validationMessages.name = "Name is required";
    if (["", " ", null, undefined].includes(state.description)) validationMessages.description = "Description is required";
    if (["", " ", null, undefined].includes(state.type)) validationMessages.type = "Type is required";
    if (["", " ", null, undefined].includes(state.defaultDateFormat)) validationMessages.defaultDateFormat = "Default date format is required";
    if (["", " ", null, undefined].includes(state.defaultDecimal)) validationMessages.defaultDecimal = "Default decimal is required";

    setValidationMessages(validationMessages);
    if (!Object.keys(validationMessages).length) {
      const response = await recTypeStore.createRecType(teamId, { name: state.name, description: state.description, settings: createSettingsArray() });
      if (response.status === 200) {
        closeAndResetModal();
        modalInstance(MODALS.RECTYPE_SETUP).show();
      }
    }
  };

  const createSettingsArray = () => {
    return recTypeStore.getDefaultSettings().map((setting) => {
      if (setting.settingFor === "REC_TYPE_SIDES" && state.type === TWO_SIDED) {
        setting.value = 2;
      } else if (setting.settingFor === "REC_TYPE_DATE_FORMAT") {
        setting.value = state.defaultDateFormat === "yyyy-mm-dd" ? "YEARMMDD" : "DDMMYEAR";
      } else if (setting.settingFor === "REC_TYPE_NUMBER_FORMAT") {
        setting.value = state.defaultDecimal;
      }
      return setting;
    });
  };

  const closeAndResetModal = () => {
    setState({ ...initialState });
    modalInstance(MODALS.CREATE_RECTYPE).hide();
  };

  return (
    <>
      <Button
        onClick={() => {
          modalInstance(MODALS.CREATE_RECTYPE).show();
        }}
      >
        <Text>New reconciliation type</Text>
      </Button>
      <Panel panelId={MODALS.CREATE_RECTYPE}>
        <PanelBody>
          <PanelHeader
            description="Add the details for a new reconciliation type"
            header="New reconciliation type"
            onClick={() => {
              setState({ ...initialState });
            }}
          />
          <div className="mr-72 mt-48 text-left">
            <TextInput
              title="Rec Type Name *"
              update={(value) => {
                setState({ ...state, name: value });
              }}
              value={state.name}
              warningBoolean={validationMessages.name !== undefined}
              warningMessage={validationMessages.name}
              classes="mb-20"
              maxCharacter={100}
            />
            <TextInput
              title="Description *"
              update={(value) => {
                setState({ ...state, description: value });
              }}
              value={state.description}
              warningBoolean={validationMessages.description !== undefined}
              warningMessage={validationMessages.description}
              classes="mb-20"
              maxCharacter={150}
            />
            <R props="mt-32"></R>
            <C6>
              <Text size="sm" weight="medium" variant="secondary" element="div">
                Reconciliation type *
              </Text>
              <Dropdown>
                <DropdownButton size="sm">
                  <span>{state.type ? state.type : "Please select a type"}</span>
                  <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
                </DropdownButton>
                <DropdownList>
                  {reconciliationTypes.map((type, index) => {
                    return (
                      <DropdownListItem
                        onClick={() => {
                          setState({ ...state, type: type });
                        }}
                        key={index + 1}
                      >
                        <DropdownItem active={null} index={0}>
                          <span>{type}</span>
                        </DropdownItem>
                      </DropdownListItem>
                    );
                  })}
                </DropdownList>
                <DropdownWarning warning={validationMessages.type !== undefined} warningMessage={validationMessages.type} />
              </Dropdown>
            </C6>
            <R props="mt-32"></R>

            <Text size="sm" weight="medium" variant="secondary" element="div">
              Choose the expected default date format for this rec type
            </Text>
            <Dropdown>
              <DropdownButton size="sm">
                <span>{state.defaultDateFormat ? state.defaultDateFormat : "Please select a default date format"}</span>
                <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
              </DropdownButton>
              <DropdownList>
                {dateFormats.map((dateFormat, index) => {
                  return (
                    <DropdownListItem
                      onClick={() => {
                        setState({ ...state, defaultDateFormat: dateFormat });
                      }}
                      key={index + 1}
                    >
                      <DropdownItem active={null} index={0}>
                        <span>{dateFormat}</span>
                      </DropdownItem>
                    </DropdownListItem>
                  );
                })}
              </DropdownList>
              <DropdownWarning warning={validationMessages.defaultDateFormat !== undefined} warningMessage={validationMessages.defaultDateFormat} />
            </Dropdown>
            <R props="mt-32"></R>

            <Text size="sm" weight="medium" variant="secondary" element="div">
              Choose the required number of decimals
            </Text>
            <Dropdown>
              <DropdownButton size="sm">
                <span>{state.defaultDecimal ? state.defaultDecimal : "Please select required number of decimals"}</span>
                <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
              </DropdownButton>
              <DropdownList>
                {decimalOptions.map((decimal, index) => {
                  return (
                    <DropdownListItem
                      onClick={() => {
                        setState({ ...state, defaultDecimal: decimal.number });
                      }}
                      key={index + 1}
                    >
                      <DropdownItem active={null} index={0}>
                        <span>{decimal.text}</span>
                      </DropdownItem>
                    </DropdownListItem>
                  );
                })}
              </DropdownList>
              <DropdownWarning warning={validationMessages.defaultDecimal !== undefined} warningMessage={validationMessages.defaultDecimal} />
            </Dropdown>
            <R props="mt-32"></R>
          </div>
        </PanelBody>
        <PanelFooter>
          <Button
            color="tertiary"
            onClick={() => {
              closeAndResetModal();
            }}
          >
            <Text size="sm">Cancel</Text>
          </Button>
          <Button
            onClick={() => {
              createRecType();
            }}
            size="md"
            disabled={false}
          >
            <Text size="sm">Create reconciliation type</Text>
          </Button>
        </PanelFooter>
      </Panel>
    </>
  );
});

export { CreateRecTypePanel };
