import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useSearchParams } from "react-router-dom";
import { Text, Toggle, C6 } from "@fundrecs/ui-library";
import { useStore } from "../../../../store/Store";
import { isUserAuthorized } from "../../../AuthorizationWrapper";
import { AUTHORITIES } from "../../../../utils/enums";
import { EditableMultiSelect } from "../../../editable/EditableSelect";

const CarryForwardRowsSetup = observer(({ carryForwardRowsConfig }) => {
  const { rolesStore, recTypeStore, carryForwardStore } = useStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = searchParams.get("teamId");
  const recTypeId = searchParams.get("recType");
  const recTypeTags = recTypeStore.getRecTypeTags().filter((it) => it.noteType === "CATEGORY");

  const defaultCategories = () => {
    return carryForwardRowsConfig && carryForwardRowsConfig.categories && carryForwardRowsConfig.categories.length
      ? carryForwardRowsConfig.categories.map((it) => ({ id: it.id, text: it.name }))
      : [];
  };

  const [selectedCategories, setSelectedCategories] = useState(defaultCategories());

  const enableCarryForwardRows = async () => {
    const body = { recTypeId: recTypeId, categoryIds: selectedCategories.map((it) => it.id) };
    let resp = await carryForwardStore.enableCarryForwardRows(
      teamId,
      carryForwardRowsConfig && carryForwardRowsConfig.version ? carryForwardRowsConfig.version : 0,
      body
    );
    if (resp.status === 200) {
      carryForwardStore.fetchCarryForwardRowsRules(teamId, recTypeId);
    } else {
      setSelectedCategories(defaultCategories());
    }
  };

  const disableCarryForwardRows = async () => {
    let resp = await carryForwardStore.disableCarryForwardRows(
      teamId,
      carryForwardRowsConfig && carryForwardRowsConfig.version ? carryForwardRowsConfig.version : 0,
      recTypeId
    );
    if (resp.status === 200) {
      carryForwardStore.fetchCarryForwardRowsRules(teamId, recTypeId);
    }
  };

  const disabled = !isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_TYPE_CARRY_FORWARD_ENABLE]) });

  return (
    <>
      <div className="pb-16 pl-0 d-flex justify-content-between">
        <div className="d-flex justify-content-start">
          <Toggle
            disabled={disabled}
            checked={carryForwardRowsConfig.enabled}
            onChange={() => {
              carryForwardRowsConfig.enabled ? disableCarryForwardRows() : enableCarryForwardRows();
            }}
          />
          <div className="ml-16">
            <Text size="md" weight="regular">
              Carry forward rows:
            </Text>
            <span className="ml-8"></span>
            <Text weight="bold" size="sm" variant={carryForwardRowsConfig.enabled ? "success" : "error"}>
              {carryForwardRowsConfig.enabled ? "Active" : "Inactive"}
            </Text>
          </div>
        </div>
      </div>
      <C6 props="d-flex justify-content-between mt-16 mb-16">
        <EditableMultiSelect
          selected={selectedCategories}
          setSelected={setSelectedCategories}
          options={recTypeTags}
          onSave={enableCarryForwardRows}
          onCancel={() => {
            setSelectedCategories(defaultCategories());
          }}
          disabled={disabled}
        />
      </C6>
    </>
  );
});

export { CarryForwardRowsSetup };
