import { useState, useRef } from "react";
import { observer } from "mobx-react-lite";
import { useSearchParams } from "react-router-dom";
import { Toggle } from "@fundrecs/ui-library";
import { Table } from "../ag-grid/Ag-grid";
import { useStore } from "../../store/Store";
import { ExpandPanelIcon } from "../ag-grid/toggleFullWidthRow";
import { GlobalMappingAccountSetup } from "../globalMapping/GlobalMappingAccountSetup";
import { isUserAuthorized } from "../AuthorizationWrapper";
import { AUTHORITIES } from "../../utils/enums";
import { AutoCompleteAccountSetup } from "../autoComplete/AutoCompleteAccountSetup";
import { ifNullUndefinedArray } from "../../utils/utils";
import { ReplaceFileAccountSetup } from "./ReplaceFileAccountSetup";
import { FullWidthCellRenderer } from "../ag-grid/FullWidthCellRenderer";

/*
 Table to display rectypes and settings for a fund
*/
const RecTypesTable = observer(({ account, showAccountConfig = false }) => {
  const gridRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = searchParams.get("teamId");
  const { recTypeStore, accountStore, rolesStore } = useStore();
  const [recTypeRows, setRecTypeRows] = useState(null);
  const [enabledRecTypes, setEnabledRecTypes] = useState([]);
  const [initialised, setInitialised] = useState(false);
  const recTypesForTeam = ifNullUndefinedArray(recTypeStore.getRecTypes());

  const updateEnabledRecTypes = (recTypeId, updatedEnabledFlag) => {
    //If recType has just been enabled
    if (!enabledRecTypes.includes(recTypeId) && updatedEnabledFlag) {
      setEnabledRecTypes([...enabledRecTypes, recTypeId]);
    } else if (enabledRecTypes.includes(recTypeId) && !updatedEnabledFlag) {
      enabledRecTypes.splice(enabledRecTypes.indexOf(recTypeId), 1);
    }
  };

  const handleResponse = (response, recTypeId, updatedEnabledFlag) => {
    if (response.status === 200) {
      updateEnabledRecTypes(recTypeId, updatedEnabledFlag);
      recTypeStore.updateVersion(recTypeId, response.data.recTypeVersion);
      accountStore.updateSelectedAccountVersion(response.data.accountVersion);
      const updatedRows = recTypeRows.map((recTypeRow) => {
        let row = recTypeRow;
        //If this is the recType which has been enabled/disabled
        if (recTypeRow.recTypeId === recTypeId) {
          let onClick = updatedEnabledFlag ? recTypeStore.disableAccountForRecType : recTypeStore.enableAccountForRecType;
          row = {
            ...row,
            recTypeVersion: response.data.recTypeVersion,
            accountVersion: response.data.accountVersion,
            onClick: onClick,
            enabled: updatedEnabledFlag,
          };
        } else {
          row = {
            ...row,
            accountVersion: response.data.accountVersion,
          };
        }
        return row;
      });
      setRecTypeRows(updatedRows);
    }
  };

  const fullWidthCellRenderer = (rowNode) => {
    const rowData = rowNode.data.fullWidthData;
    return showAccountConfig ? (
      <FullWidthCellRenderer rowNode={rowNode} rowHeight={1100} gridRef={gridRef}>
        <GlobalMappingAccountSetup recTypeId={rowData.recTypeId} accountId={account.id} accountVersion={account.version} refreshRows={refreshRows} />

        <AutoCompleteAccountSetup
          recTypeId={rowData.recTypeId}
          recTypeAutocompleteEnabled={rowData.recTypeAutocompleteEnabled}
          autoCompleteProp={rowData.autoComplete}
          autoCompleteSchedulerProp={rowData.autoCompleteScheduler}
          recStartDateProp={rowData.recStartDate ? new Date(rowData.recStartDate) : null}
          accountId={account.id}
          accountVersionProp={account.version}
        />
        <ReplaceFileAccountSetup recTypeId={rowData.recTypeId} accountId={account.id} accountVersion={account.version} />
      </FullWidthCellRenderer>
    ) : (
      ""
    );
  };

  const onClickExpandRows = (params) => {
    return { ...params.data };
  };

  const cols = [
    { headerName: "Reconciliation Type", field: "recTypeName" },
    {
      field: "",
      sortable: false,
      filter: false,
      width: 50,
      maxWidth: 50,
      cellRenderer: (params) => {
        //id is required for toggleFullWidthRow
        params.data.id = params.data.recTypeId;
        return showAccountConfig ? ExpandPanelIcon(params, onClickExpandRows, gridRef) : "";
      },
    },
    { headerName: "Description", field: "recTypeDescription" },
    {
      field: "toggleSwitch",
      headerName: "Enable",
      width: 100,
      maxWidth: 100,
      sortable: false,
      cellRenderer: (params) => {
        return (
          <Toggle
            disabled={!isUserAuthorized({ teamId: teamId, allRequired: rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_REC_TYPE_ENABLE]) })}
            checked={params.data.enabled}
            onChange={async (event) => {
              const response = await params.data.onClick(
                params.data.teamId,
                params.data.recTypeId,
                params.data.accountId,
                params.data.recTypeVersion,
                params.data.accountVersion
              );
              handleResponse(response, params.data.recTypeId, !params.data.enabled);
            }}
          />
        );
      },
    },
  ];

  const refreshRows = async () => {
    let enabledRecTypes = [];
    if (account) {
      enabledRecTypes = await accountStore.fetchEnabledRecTypesForAccount(teamId, account.id);
    }
    const recTypeRows = recTypesForTeam.map((recType) => {
      let currentlyEnabled = false;
      const enabledRecType = enabledRecTypes.find((it) => it.id === recType.id);
      if (enabledRecType) {
        currentlyEnabled = true;
        recType = { ...recType, ...enabledRecType };
      }
      let onClick = currentlyEnabled ? recTypeStore.disableAccountForRecType : recTypeStore.enableAccountForRecType;

      return {
        teamId: teamId,
        recTypeId: recType.id,
        accountId: account.id,
        accountVersion: account.version,
        recTypeVersion: recType.version,
        recTypeName: recType.name,
        recTypeDescription: recType.description,
        onClick: onClick,
        enabled: currentlyEnabled,
        allRecTypeData: recType,
        recTypeAutocompleteEnabled: recType.recTypeAutocompleteEnabled ? recType.recTypeAutocompleteEnabled : false,
        accountAutocompleteEnabled: recType.accountAutocompleteEnabled ? recType.accountAutocompleteEnabled : false,
        autoComplete: recType.autoComplete ? recType.autoComplete : null,
        autoCompleteScheduler: recType.autoCompleteScheduler ? recType.autoCompleteScheduler : null,
        globalMappings: recType.globalMappings ? recType.globalMappings : null,
        recStartDate: recType.recStartDate,
      };
    });
    setRecTypeRows(recTypeRows);
  };

  if (recTypesForTeam.length && account && !initialised) {
    refreshRows();
    setInitialised(true);
  }

  return account ? (
    <Table
      columnDefs={cols}
      rowData={recTypeRows}
      ref={gridRef}
      colFlex={true}
      rowStyle={{ background: "white" }}
      fullWidthCellRenderer={fullWidthCellRenderer}
    />
  ) : (
    ""
  );
});

export { RecTypesTable };
