import { useState } from "react";
import { observer } from "mobx-react-lite";
import { IconCheck } from "@fundrecs/ui-library";
import { NOTES } from "./dataTransform";
import "./rules.css";

const MultiSelectDropdown = observer(({ value, setValue, dropdownData, width = "32rem", disabled = false }) => {
  const initialiseSelectedOptions = () => {
    let optionsArray = [];
    const idsArray = JSON.parse(value);
    if (idsArray && idsArray.length) {
      optionsArray = idsArray.map((it) => {
        const tag = dropdownData.find((tag) => tag.id === it);

        return tag ? tag : { id: null, text: "" };
      });
    }
    return optionsArray;
  };

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [initialised, setInitialised] = useState(false);

  if (!initialised && value && dropdownData.length) {
    setSelectedOptions(initialiseSelectedOptions());
    setInitialised(true);
  }

  const toggleOption = (option) => {
    let updatedNotes = [];
    if (selectedOptions.map((it) => it.id).includes(option.id)) {
      updatedNotes = [...selectedOptions.filter((it) => it.id !== option.id)];
    } else {
      updatedNotes = [...selectedOptions, option];
    }
    setSelectedOptions(updatedNotes);
    setValue(updatedNotes, NOTES);
  };

  let displayString = "";
  if (selectedOptions.length) {
    selectedOptions.forEach((it) => (displayString += `${it.text}, `));
  }

  return (
    <div style={{ width: width }}>
      <div data-bs-toggle="dropdown" className="dropdown-button-container ellipsize-text" title={displayString} disabled={disabled}>
        {displayString}
      </div>
      <ul className="dropdown-menu dropdown-sizing" aria-labelledby="dropdownMenuLink" style={{ width: width }}>
        {dropdownData.map((option) => {
          return (
            <li
              onClick={(event) => {
                event.stopPropagation();
                toggleOption(option);
              }}
            >
              <div className="d-flex">
                <div className="dropdown-item ellipsize-text width-95-pc">{option.text}</div>
                {selectedOptions.map((it) => it.id).includes(option.id) ? <IconCheck className="btn-sm-svg" color="#1e5eff" /> : ""}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
});

export { MultiSelectDropdown };
