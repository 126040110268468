import { useState, useEffect } from "react";
import Intercom from "react-intercom";
import { useNavigate, useSearchParams, useLocation, Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  OneProductSideNavButton,
  OneProductDashboardDataIcon,
  OneProductReportingDataIcon,
  OneProductReconciliationsDataIcon,
  OneProductLookupsDataIcon,
  OneProductRunResultsDataIcon,
  OneProductTemplatesDataIcon,
  OneProductWorkflowsDataIcon,
  OneProductGlobalMappingsIcon,
  SideNavHeading,
  OneProductOutputMappingDataIcon,
  OneProductFundsDataIcon,
  OneProductSubAccountsDataIcon,
  OneProductHelpDataIcon,
  OneProductLaunchDataIcon,
  OneProductUploadsIcon,
  OneProductSourcesAdminIcon,
  OneProductSideNavFooter,
  SideMenuChevron,
  SideNavDropdownButton,
  SideNavBar,
  SideNavColumn,
  HoverDropdown,
  HoverDropdownItem,
  ToolTip,
  TeamAvatar,
  HyperLink,
} from "@fundrecs/ui-library";
import { useStore } from "../../store/Store";
import { PATH } from "../../utils/urls";

const Sidebar = observer(({ expanded }) => {
  const { meStore, teamStore, clientStore, recTypeStore, tenantConfigStore } = useStore();

  const location = useLocation();
  const { pathname } = { ...location };
  let navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const fusionAdminUrl = tenantConfigStore.getFusionAdminUrl();
  const fusionDataUrl = tenantConfigStore.getFusionDataUrl();
  const reportingUrl = tenantConfigStore.getFusionReportingUrl();
  const allTeams = meStore.getTeams().sort((a, b) => a.name.localeCompare(b.name));

  const [active, setActive] = useState("");
  const [selectedTeam, setSelectedTeam] = useState();
  const teamId = searchParams.get("teamId");
  const PATH_NAME_MAP = {
    [PATH.REC_TYPE_CONFIG]: "Reconciliations",
    [PATH.FUND]: "Funds",
    [PATH.DASHBOARD]: "Dashboards",
    [PATH.UPLOADS]: "Uploads",
    [PATH.SUB_ACCOUNT]: "Sub accounts",
    [PATH.GLOBAL_MAPPINGS]: "Global mappings",
  };

  //Called to update data for the selected team
  useEffect(() => {
    const team = teamId ? meStore.getTeamById(teamId) : meStore.getDefaultTeamOrFirstInList();
    clientStore.fetchClientsForTeam(team.id);
    recTypeStore.fetchRecTypesForTeam(team.id);
    teamStore.setSelectedTeam(team);
    setSelectedTeam(team);

    //Update the active sidebar menu button to match the url pathname
    const initialPath = `/${pathname.split("/")[1]}`;
    const route = initialPath === "/" ? PATH.REC_TYPES : initialPath;
    setActive(PATH_NAME_MAP[route]);
  }, [teamId]);

  const updateTeam = (team) => {
    teamStore.setSelectedTeam(team);
    setSelectedTeam(team);
    navigate(`${PATH.REC_TYPES}?teamId=${team.id}`);
  };

  const renderSupport = () => {
    let localENV = window.location.hostname === "fundrecs-sso.fusion-recs.localhost" || window.location.hostname.indexOf("fundrecsdev.com") > -1;

    if (!localENV) {
      const userInfo = {
        email: meStore.getEmail(),
        name: meStore.getName(),
      };
      return <Intercom appID="2a73body" {...userInfo} />;
    }
  };

  const launchIntercom = () => {
    let localENV = window.location.hostname === "fundrecs-sso.fusion-recs.localhost" || window.location.hostname.indexOf("fundrecsdev.com") > -1;

    if (localENV) {
      let emailTo = "mailto:2a73body@incoming.intercom.io";
      window.open("mailto:" + emailTo);
    } else {
      window.Intercom("show", {
        hideDefaultLauncher: true,
      });
    }
  };

  const styles = {};

  return (
    <>
      <SideNavBar visible={expanded}>
        <SideNavColumn>
          <Link className="text-decoration-none" to={`${PATH.REC_TYPES}?teamId=${teamId}`}>
            <OneProductSideNavButton
              minimised={!expanded}
              buttonName={PATH_NAME_MAP[PATH.DASHBOARD]}
              ButtonIcon={<OneProductDashboardDataIcon />}
              onClick={() => {
                setActive(PATH_NAME_MAP[PATH.DASHBOARD]);
              }}
              active={active}
            />
          </Link>
          <HyperLink target="_blank" href={reportingUrl ? reportingUrl : ""}>
            <OneProductSideNavButton minimised={!expanded} buttonName={"Reporting"} ButtonIcon={<OneProductReportingDataIcon />} onClick={() => {}} />
          </HyperLink>
          <Link className="text-decoration-none" to={`${PATH.UPLOADS}?teamId=${teamId}`}>
            <OneProductSideNavButton
              minimised={!expanded}
              buttonName={PATH_NAME_MAP[PATH.UPLOADS]}
              ButtonIcon={<OneProductUploadsIcon />}
              onClick={() => {
                setActive(PATH_NAME_MAP[PATH.UPLOADS]);
              }}
              active={active}
            />
          </Link>
          <HyperLink href={`${fusionDataUrl}?teamId=${teamId}`}>
            <OneProductSideNavButton
              minimised={!expanded}
              buttonName="Workflows run results"
              ButtonIcon={<OneProductRunResultsDataIcon />}
              onClick={() => {}}
              active={active}
            />
          </HyperLink>
        </SideNavColumn>
        {expanded && <SideNavHeading HeadingText={"Manage"} />}
        <SideNavColumn>
          <Link className="text-decoration-none" to={`${PATH.REC_TYPE_CONFIG_DASH}?teamId=${teamId}`}>
            <OneProductSideNavButton
              minimised={!expanded}
              buttonName={PATH_NAME_MAP[PATH.REC_TYPE_CONFIG]}
              ButtonIcon={<OneProductReconciliationsDataIcon />}
              onClick={() => {
                setActive(PATH_NAME_MAP[PATH.REC_TYPE_CONFIG]);
              }}
              active={active}
            />
          </Link>
          <HyperLink href={`${fusionDataUrl}${PATH.WORKFLOWS}?teamId=${teamId}`}>
            <OneProductSideNavButton
              minimised={!expanded}
              buttonName="Workflows"
              ButtonIcon={<OneProductWorkflowsDataIcon />}
              onClick={() => {}}
              active={active}
            />
          </HyperLink>
          <HyperLink href={`${fusionDataUrl}${PATH.IMPORTS}?teamId=${teamId}`}>
            <OneProductSideNavButton
              ButtonIcon={
                expanded ? (
                  <OneProductSourcesAdminIcon />
                ) : (
                  <ToolTip text="Imports" direction="right">
                    <OneProductSourcesAdminIcon />
                  </ToolTip>
                )
              }
              minimised={!expanded}
              active={active}
              buttonName={"Imports"}
              onClick={() => {}}
            />
          </HyperLink>
          <Link className="text-decoration-none" to={`${PATH.FUND}?teamId=${teamId}`}>
            <OneProductSideNavButton
              minimised={!expanded}
              buttonName={PATH_NAME_MAP[PATH.FUND]}
              ButtonIcon={<OneProductFundsDataIcon />}
              onClick={() => {
                setActive(PATH_NAME_MAP[PATH.FUND]);
              }}
              active={active}
            />
          </Link>
          <Link className="text-decoration-none" to={`${PATH.SUB_ACCOUNT}?teamId=${teamId}`}>
            <OneProductSideNavButton
              minimised={!expanded}
              buttonName={PATH_NAME_MAP[PATH.SUB_ACCOUNT]}
              ButtonIcon={<OneProductSubAccountsDataIcon />}
              onClick={() => {
                setActive(PATH_NAME_MAP[PATH.SUB_ACCOUNT]);
              }}
              active={active}
            />
          </Link>
        </SideNavColumn>
        {expanded && <SideNavHeading HeadingText={"Resources"} />}
        <SideNavColumn>
          <HyperLink href={`${fusionDataUrl}${PATH.LOOKUPS}?teamId=${teamId}`}>
            <OneProductSideNavButton minimised={!expanded} buttonName="Lookups" ButtonIcon={<OneProductLookupsDataIcon />} onClick={() => {}} active={active} />
          </HyperLink>
          <HyperLink href={`${fusionDataUrl}${PATH.MAPPINGS}?teamId=${teamId}`}>
            <OneProductSideNavButton
              minimised={!expanded}
              buttonName="Output mappings"
              ButtonIcon={<OneProductOutputMappingDataIcon />}
              onClick={() => {}}
              active={active}
            />
          </HyperLink>
          <HyperLink href={`${fusionDataUrl}${PATH.TEMPLATES}?teamId=${teamId}`}>
            <OneProductSideNavButton
              minimised={!expanded}
              buttonName="Templates"
              ButtonIcon={<OneProductTemplatesDataIcon />}
              onClick={() => {}}
              active={active}
            />
          </HyperLink>
          <Link className="text-decoration-none" to={`${PATH.GLOBAL_MAPPINGS}?teamId=${teamId}`}>
            <OneProductSideNavButton
              minimised={!expanded}
              buttonName={PATH_NAME_MAP[PATH.GLOBAL_MAPPINGS]}
              ButtonIcon={<OneProductGlobalMappingsIcon />}
              onClick={() => {
                setActive(PATH_NAME_MAP[PATH.GLOBAL_MAPPINGS]);
              }}
              active={active}
            />
          </Link>

          <SideNavHeading></SideNavHeading>
        </SideNavColumn>
      </SideNavBar>
      <OneProductSideNavFooter
        expanded={expanded}
        topSection={
          selectedTeam && (
            <>
              <OneProductSideNavButton
                ButtonIcon={
                  expanded ? (
                    <OneProductHelpDataIcon />
                  ) : (
                    <ToolTip text="Help" direction="right">
                      <OneProductHelpDataIcon />
                    </ToolTip>
                  )
                }
                minimised={!expanded}
                active={""}
                buttonName={"Help"}
                onClick={() => {
                  setActive("Help");
                  launchIntercom();
                }}
                disabled={false}
              />
              <HyperLink href={fusionAdminUrl}>
                <OneProductSideNavButton
                  ButtonIcon={
                    expanded ? (
                      <OneProductLaunchDataIcon />
                    ) : (
                      <ToolTip text="Launch admin" direction="right">
                        <OneProductLaunchDataIcon />
                      </ToolTip>
                    )
                  }
                  minimised={!expanded}
                  active={""}
                  buttonName={"Launch admin"}
                  onClick={() => {}}
                />
              </HyperLink>
            </>
          )
        }
        bottomSection={
          selectedTeam && (
            <SideNavColumn>
              <li className="dropstart">
                <SideNavDropdownButton
                  minimised={!expanded}
                  body="Team workspace"
                  icon={<TeamAvatar text={selectedTeam.name} size="md" />}
                  arrowIcon={<SideMenuChevron width="20" height="20" />}
                  title={selectedTeam.name}
                />
                <HoverDropdown width="300px" title="Switch teams">
                  {allTeams.map((team, index) => {
                    return (
                      <HoverDropdownItem
                        key={index}
                        avatarText={team.name}
                        selected={selectedTeam.id === team.id}
                        title={team.name}
                        onClick={() => {
                          updateTeam(team);
                        }}
                      />
                    );
                  })}
                </HoverDropdown>
              </li>
            </SideNavColumn>
          )
        }
      />
      {renderSupport()}
    </>
  );
});

export { Sidebar };
