import { useState, useRef } from "react";
import { observer } from "mobx-react-lite";
import {
  Text,
  R,
  C2,
  C3,
  C4,
  C8,
  Button,
  Circle,
  EmptyLookups,
  Toggle,
  Heading,
  PanelFooter,
  Dropdown,
  DropdownButton,
  IconDownArrow,
  DropdownList,
  DropdownListItem,
  DropdownItem,
  ItemBody,
  IconAlertMessage,
  IconAdd,
  modalInstance,
} from "@fundrecs/ui-library";
import { useStore } from "../../../store/Store.js";
import { DropdownWithDescription } from "../../reusable/DropdownWithDescription.js";
import { ReactComponent as ReorderIcon } from "../../../icons/reorder.svg";
import { AUTHORITIES, MODALS, STATUS } from "../../../utils/enums.js";
import { SlideOverPanel } from "../../reusable/SlideOverPanel/SlideOverPanel.js";
import { Table } from "../../ag-grid/Ag-grid.js";
import { AuthWrapper, isUserAuthorized } from "../../AuthorizationWrapper.js";
import { InfoToolTip } from "../../reusable/InfoToolTip.js";
import { WarningModal } from "../../WarningModal.js";
import { StatusBadge } from "../../reusable/StatusBadge.js";

const MatchingRulePanel = observer(({ teamId, account }) => {
  const gridRef = useRef(null);
  const { accountStore, matchingRuleStore, recTypeStore, rolesStore } = useStore();
  const [selectedRecType, setSelectedRecType] = useState(null);
  const [currentlyDisplaying, setCurrentlyDisplaying] = useState({ recType: "", recTypeId: null, subAccount: "", subAccountId: null });
  const [subAccounts, setSubAccounts] = useState([]);
  const [selectedSubAccount, setSelectedSubAccount] = useState(null);
  const [recTypesAndSubAccounts, setRecTypesAndSubAccounts] = useState([]);
  const [matchingRules, setMatchingRules] = useState([]);
  const [matchingRulesPerRecType, setMatchingRulesPerRecType] = useState([]);
  const [accountsAndSubAccounts, setAccountsAndSubAccounts] = useState(null);
  const [selected, setSelected] = useState([]);
  const [initialised, setInitialised] = useState(false);

  const fetchData = async () => {
    const recTypesAndSubAccounts = await accountStore.fetchRecTypesSubAccountsAliases(teamId, account.id);
    setRecTypesAndSubAccounts(recTypesAndSubAccounts);
  };

  if (teamId && account && !initialised) {
    setInitialised(true);
    fetchData();
  }

  const fetchMatchingRules = (recTypeId, recTypeName, recTypeVersion, subAccountId, subAccountName, subAccountVersion, recRestartMode, recRestartFunction) => {
    fetchMatchingRulesPerRecType(recTypeId);
    fetchEnabledMatchingRules(recTypeId, recTypeName, recTypeVersion, subAccountId, subAccountName, subAccountVersion, recRestartMode, recRestartFunction);
  };

  const fetchEnabledMatchingRules = async (
    recTypeId,
    recTypeName,
    recTypeVersion,
    subAccountId,
    subAccountName,
    subAccountVersion,
    recRestartMode,
    recRestartFunction
  ) => {
    const response = await matchingRuleStore.fetchMatchingRules(teamId, recTypeId, account.id, subAccountId);
    if (response.status === 200) {
      setCurrentlyDisplaying({
        accountId: currentlyDisplaying.accountId ? currentlyDisplaying.accountId : account.id,
        accountVersion: currentlyDisplaying.accountVersion ? currentlyDisplaying.accountVersion : account.version,
        recType: recTypeName,
        recTypeId: recTypeId,
        recTypeVersion: recTypeVersion,
        subAccount: subAccountName,
        subAccountId: subAccountId,
        subAccountVersion: subAccountVersion,
        recRestartMode: recRestartMode,
        recRestartFunction: recRestartFunction,
      });
      setSelectedRecType(null);
      setSelectedSubAccount(null);
      setSubAccounts([]);
      setMatchingRules(response.data);
    }
  };

  const fetchMatchingRulesPerRecType = async (recTypeId) => {
    const recTypeRulesResponse = await recTypeStore.fetchMatchingRules(teamId, recTypeId);
    if (recTypeRulesResponse.status === 200) {
      setMatchingRulesPerRecType(recTypeRulesResponse.data);
    }
  };

  const fetchAccountsAndSubAccounts = async () => {
    const accountsAndSubAccountsResponse = await recTypeStore.fetchClientsAccountsPerRecType(teamId, currentlyDisplaying.recTypeId);
    if (accountsAndSubAccountsResponse.status === 200) {
      setAccountsAndSubAccounts(formatAccountSubAccountRows(accountsAndSubAccountsResponse.data, currentlyDisplaying.recType));
    }
  };

  const formatAccountSubAccountRows = (data, recTypeName) => {
    let rows = [];
    data.forEach((clientData) => {
      clientData.accountList.forEach((account) => {
        account.subAccountList.forEach((subAccount) => {
          if (subAccount !== null && !subAccount.deleted) {
            rows.push({
              accountName: account.account.name,
              accountId: account.account.id,
              subAccountName: subAccount.name,
              subAccountId: subAccount.id,
              recTypeName: recTypeName,
            });
          }
        });
      });
    });
    return rows;
  };

  const enableDisableMatchingRule = async (ruleId, enabled) => {
    const body = { applyBySubAccountAndAccount: { [account.id]: { [currentlyDisplaying.subAccountId]: enabled } } };
    const response = await matchingRuleStore.updateMatchingRule(teamId, ruleId, body);
    if (response.status === 200) {
      fetchMatchingRules(
        currentlyDisplaying.recTypeId,
        currentlyDisplaying.recType,
        currentlyDisplaying.recTypeVersion,
        currentlyDisplaying.subAccountId,
        currentlyDisplaying.subAccount,
        currentlyDisplaying.subAccountVersion,
        currentlyDisplaying.recRestartMode,
        currentlyDisplaying.recRestartFunction
      );
    }
  };

  const RecTypeAndSubAccountSelector = () => {
    return (
      <>
        <DropdownWithDescription
          description="Reconciliation type: "
          displayValue={selectedRecType ? selectedRecType.name : "Select type "}
          dropdownOptions={recTypesAndSubAccounts}
          selectOption={(recType) => {
            setSelectedRecType(recType);
            setSubAccounts(recType.subAccounts);
          }}
          width="24rem"
        />
        <span className="mr-16"></span>

        <DropdownWithDescription
          description="Show rules for: "
          displayValue={selectedSubAccount ? selectedSubAccount.name : "Select sub account "}
          dropdownOptions={subAccounts}
          selectOption={(subAccount) => {
            setSelectedSubAccount(subAccount);
          }}
          width="24rem"
        />
        <span className="mr-16"></span>
        <Button
          size="md"
          onClick={() => {
            fetchMatchingRules(
              selectedRecType.id,
              selectedRecType.name,
              selectedRecType.version,
              selectedSubAccount.id,
              selectedSubAccount.name,
              selectedSubAccount.version,
              selectedSubAccount.recRestartMode,
              selectedSubAccount.recRestartFunction
            );
          }}
          disabled={selectedRecType === null || selectedSubAccount === null}
          color="primary"
        >
          <Text size="sm" weight="regular">
            View rules
          </Text>
        </Button>
      </>
    );
  };

  const MatchingRulePanel = ({ rule, enabled, index }) => {
    const reorderEnabled = enabled && isUserAuthorized({ teamId: teamId, allRequired: rolesStore.getActions([AUTHORITIES.RECS_MATCHING_RULE_REORDER]) });
    return (
      <div className="pt-8 pb-8" matchingRuleIndex={index}>
        <div
          className="pt-16 pr-16 pb-16 pl-16"
          style={{ border: "#CDD0D7 2px solid", borderRadius: "12px", backgroundColor: !enabled ? "#F3F3F5" : "" }}
          draggable={reorderEnabled ? "true" : "false"}
          onDragStart={(event) => {
            if (reorderEnabled) {
              event.dataTransfer.clearData();
              event.dataTransfer.setData("text/plain", index);
            }
          }}
        >
          <R>
            <div className="d-flex" style={{ width: "8rem" }}>
              <span style={{ width: "40px", marginRight: "16px", cursor: "grab" }}>
                <ReorderIcon />
              </span>
              <Circle number={rule.ruleOrder >= 0 ? index + 1 : ""} />
            </div>

            <C4>
              <Text size="sm" weight="medium">
                {rule.name}
              </Text>
            </C4>
            <C4>
              <StatusBadge status={rule.state.status} />
            </C4>
            <C2>
              <div className="d-flex" style={{ justifyContent: "flex-end" }}>
                <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_MATCHING_RULE_ACCOUNT_ENABLE])}>
                  <Toggle
                    checked={enabled}
                    onChange={(event) => {
                      enableDisableMatchingRule(rule.id, !enabled);
                    }}
                  />
                </AuthWrapper>

                <div className="ml-16">
                  <Text size="xs" weight="medium">
                    {enabled ? "Enabled" : "Disabled"}
                  </Text>
                </div>
              </div>
            </C2>
          </R>
          <R>
            <div className="d-flex" style={{ width: "8rem" }}></div>
            <C8>
              <Text size="xs" weight="regular">
                {rule.description}
              </Text>
            </C8>
          </R>
        </div>
      </div>
    );
  };

  const reorderMatchingRules = async (event) => {
    const draggedIndex = event.dataTransfer.getData("text/plain") ? Number(event.dataTransfer.getData("text/plain")) : null;
    const dropElement = event.target.closest("div[matchingRuleIndex]");
    const dropElementIndex = dropElement ? Number(dropElement.getAttribute("matchingRuleIndex")) : null;
    const draggedRule = matchingRules[draggedIndex];
    if (draggedRule && dropElementIndex > -1 && dropElement) {
      matchingRules.splice(draggedIndex, 1);
      matchingRules.splice(dropElementIndex, 0, draggedRule);
      //setMatchingRules([...matchingRules]);
      const response = await matchingRuleStore.reorderMatchingRules(
        teamId,
        currentlyDisplaying.recTypeId,
        account.id,
        currentlyDisplaying.subAccountId,
        matchingRules.map((it) => it.id)
      );
      if (response.status === 200) {
        fetchMatchingRules(
          currentlyDisplaying.recTypeId,
          currentlyDisplaying.recType,
          currentlyDisplaying.recTypeVersion,
          currentlyDisplaying.subAccountId,
          currentlyDisplaying.subAccount,
          currentlyDisplaying.subAccountVersion,
          currentlyDisplaying.recRestartMode,
          currentlyDisplaying.recRestartFunction
        );
      }
    }
  };

  const columnDefs = [
    {
      field: "",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      width: 50,
      maxWidth: 50,
    },
    { headerName: "Fund", field: "accountName" },
    { headerName: "Sub account", field: "subAccountName" },
    { headerName: "Reconciliation type", field: "recTypeName" },
  ];

  const updateSelected = () => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    setSelected(selectedRows);
  };

  const applyMatchingRulesToFundsAndSubaccounts = async () => {
    let accountAndSubAccountIdsMap = {};

    selected.forEach((selection) => {
      const subAccountList = accountAndSubAccountIdsMap[selection["accountId"]]
        ? [...accountAndSubAccountIdsMap[selection["accountId"]], selection["subAccountId"]]
        : [selection["subAccountId"]];
      accountAndSubAccountIdsMap[selection["accountId"]] = subAccountList;
    });

    const response = await recTypeStore.copyMatchingRules(teamId, currentlyDisplaying.recTypeId, account.id, currentlyDisplaying.subAccountId, {
      accountAndSubAccountIdsMap: accountAndSubAccountIdsMap,
    });
    if (response.status === 200) {
      fetchData();
      deselectAllRows();
      modalInstance(MODALS.APPLY_MATCHING_RULES).hide();
    }
  };

  const deselectAllRows = () => {
    setSelected([]);
    const rows = gridRef.current.api.getSelectedNodes();
    rows.forEach((row) => {
      row.setSelected(false);
    });
  };

  return (
    <>
      {!matchingRules.length && !matchingRulesPerRecType.length ? (
        <>
          <div>
            <div style={{ textAlign: "center" }}>
              <EmptyLookups />
            </div>
            <div className="d-flex" style={{ justifyContent: "center" }}>
              <RecTypeAndSubAccountSelector />
            </div>
            <div className="d-flex mt-16" style={{ justifyContent: "center" }}>
              <Text weight="regular" size="md">
                To view matching rules, please select the reconciliation type and the sub account from the lists above.
              </Text>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="d-flex mt-16 mb-16">
            <RecTypeAndSubAccountSelector />
            <span className="ml-16">
              <Text weight="regular" size="sm">
                To view matching rules, please select the reconciliation type and the sub account.
              </Text>
            </span>
          </div>

          <div
            style={{
              borderTop: "1px solid #E6E7EB",
              borderBottom: "1px solid #E6E7EB",
              padding: "0px",
              marginLeft: "12px",
              paddingBottom: "8px",
              paddingTop: "16px",
            }}
            className="d-flex justify-content-between"
          >
            <div>
              <Text weight="bold" size="lg">
                {currentlyDisplaying.subAccount}
              </Text>
              <span className="ml-8"></span>
              <Text weight="regular" size="lg">
                {currentlyDisplaying.recType}
              </Text>
            </div>
            <div className="pr-32">
              <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_MATCHING_RULE_ACCOUNT_ENABLE])}>
                <Button
                  size="md"
                  onClick={() => {
                    fetchAccountsAndSubAccounts();
                    modalInstance(MODALS.APPLY_MATCHING_RULES).show();
                  }}
                >
                  <Text>Apply rules</Text>
                </Button>
              </AuthWrapper>
            </div>
          </div>

          <div className="row mt-8 mr-16">
            <RestartRecConfig teamId={teamId} fetchData={fetchData} setCurrentlyDisplaying={setCurrentlyDisplaying} currentlyDisplaying={currentlyDisplaying} />
            <div
              style={{ paddingLeft: "0px" }}
              onDrop={(event) => {
                if (isUserAuthorized({ teamId: teamId, allRequired: rolesStore.getActions([AUTHORITIES.RECS_MATCHING_RULE_REORDER]) })) {
                  reorderMatchingRules(event);
                }
              }}
              onDragOver={(event) => {
                event.preventDefault();
              }}
              onDragEnter={(event) => {
                event.preventDefault();
              }}
            >
              {matchingRules.map((rule, index) => {
                return <MatchingRulePanel rule={rule} enabled={true} index={index} />;
              })}
              {matchingRulesPerRecType
                .filter((it) => !matchingRules.map((mr) => mr.id).includes(it.id) && it.state.status === STATUS.PUBLISHED.status)
                .map((rule) => {
                  return <MatchingRulePanel rule={rule} enabled={false} />;
                })}
            </div>
          </div>
          <SlideOverPanel id={MODALS.APPLY_MATCHING_RULES} width="66%">
            <div className="panel-content" style={{ overflowX: "scroll" }}>
              <div className="panel-header">
                <div className="panel-header-left">
                  <Heading variant="h4" element="span">
                    Apply rules
                  </Heading>
                </div>
                <div className="panel-header-right">
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {}}></button>
                </div>
                <div className="panel-header-clear"></div>
              </div>
              <div className="panel-description">
                <Text size="sm" variant="secondary" weight="regular">
                  Apply this rule running order and enablement to the following funds and sub accounts
                </Text>
              </div>
              <div className="pt-16 pb-16 pl-16 pr-16" style={{ backgroundColor: "#FFF8F1", borderRadius: "4px" }}>
                <div>
                  <span className="pr-8">
                    <IconAlertMessage color="#D36133" />
                  </span>
                  <Text variant="warning">Warning</Text>
                </div>
                <div className="pl-32">
                  <Text variant="warning" weight="regular" size="sm">
                    Selecting apply will replace the existing matching rule order and enablements for the selected funds and sub accounts with the rule order
                    and enablements of the currently open fund/sub-account
                  </Text>
                  <div className="mt-16"></div>
                  <Text variant="warning" weight="regular" size="sm">
                    The current configuration for <b>Restart Reconciliation</b> will also be applied to the selected funds and sub-accounts
                  </Text>
                </div>
              </div>
              <div className="pt-32">
                <Table
                  ref={gridRef}
                  columnDefs={columnDefs}
                  rowData={accountsAndSubAccounts}
                  rowSelection={"multiple"}
                  updateSelected={updateSelected}
                  height="40vh"
                />
              </div>
            </div>
            <PanelFooter>
              <Button onClick={applyMatchingRulesToFundsAndSubaccounts}>
                <Text weight="regular">Apply to selected funds and sub accounts</Text>
              </Button>
            </PanelFooter>
          </SlideOverPanel>
        </>
      )}
    </>
  );
});

const RestartRecConfig = ({ teamId, fetchData, currentlyDisplaying, setCurrentlyDisplaying }) => {
  const NONE = "NONE";
  const IMPORT = "IMPORT";
  const RUN_RULES = "RUN_RULES";
  const CLEAR_ALL = "CLEAR_ALL";
  const CLEAR_AUTO = "CLEAR_AUTO";
  const RESTART_MODES = { [NONE]: "", [IMPORT]: "On file upload", [RUN_RULES]: "When rerun rules is actioned by a user" };
  const RESTART_FUNCTIONS = { [NONE]: "", [CLEAR_ALL]: "Undo all processed rows", [CLEAR_AUTO]: "Undo only automatically processed rows" };

  const recTypeId = currentlyDisplaying.recTypeId;
  const accountId = currentlyDisplaying.accountId;
  const subAccountId = currentlyDisplaying.subAccountId;
  const recTypeVersion = currentlyDisplaying.recTypeVersion;
  const accountVersion = currentlyDisplaying.accountVersion;
  const subAccountVersion = currentlyDisplaying.subAccountVersion;
  const recRestartMode = currentlyDisplaying.recRestartMode;
  const recRestartFunction = currentlyDisplaying.recRestartFunction;

  const { recTypeStore, rolesStore } = useStore();
  const restartEnabled = ![null, undefined, NONE].includes(recRestartMode);
  const [restartConfigOpen, setRestartConfigOpen] = useState(false);
  const [draftRestartSetting, setDraftRestartSetting] = useState({ mode: NONE, function: NONE });

  const updateRestartRec = async (restartSetting) => {
    const resp = await recTypeStore.updateRestartRec(teamId, recTypeId, accountId, subAccountId, {
      ...restartSetting,
      version: recTypeVersion,
      accountVersion: accountVersion,
      subAccountVersion: subAccountVersion,
    });
    if (resp.status === 200) {
      setRestartConfigOpen(false);
      fetchData();
      setCurrentlyDisplaying({
        ...currentlyDisplaying,
        recTypeVersion: resp.data.recTypeVersion,
        subAccountVersion: resp.data.subAccountVersion,
        accountVersion: resp.data.accountVersion,
        recRestartMode: restartSetting.mode,
        recRestartFunction: restartSetting.function,
      });
    }
  };

  return (
    <div className="pt-16 pb-16 pl-0">
      <WarningModal
        modalId={MODALS.WARNING}
        title="Are you sure you want to disable Restart Reconciliation?"
        description="This action will permanently delete the Restart Reconciliation settings for this fund/sub-account. This action cannot be undone."
        buttonClick={() => {
          updateRestartRec({ mode: NONE, function: NONE });
          modalInstance(MODALS.WARNING).hide();
        }}
        buttonText="Disable"
      />
      <div className="d-flex" style={{ justifyContent: "flex-start" }}>
        <Toggle
          disabled={!restartEnabled}
          checked={restartEnabled}
          onChange={() => {
            modalInstance(MODALS.WARNING).show();
          }}
        />

        <div className="ml-16 mr-8">
          <Text weight="bold" size="sm" variant="secondary">
            Restart reconciliation
          </Text>
        </div>
        <InfoToolTip text={"Enabling this option will trigger the rerun of rules under specific configured conditions. Enabling requires configuration."} />
        <span className="ml-32"></span>
        {restartEnabled ? (
          ""
        ) : (
          <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_MATCHING_RULE_ACCOUNT_ENABLE])}>
            <Button
              size="sm"
              color="primary-secondary"
              onClick={() => {
                setRestartConfigOpen(true);
              }}
            >
              <IconAdd className="btn-sm-svg" />
              <Text size="xs">Configure</Text>
            </Button>
          </AuthWrapper>
        )}
      </div>
      {restartEnabled ? (
        <R props="pl-0 mt-16">
          <Text variant="secondary" size="xs" weight="bold">{`When ${
            recRestartMode === IMPORT ? "a file is imported" : recRestartMode === RUN_RULES ? "rerun rules is actioned by a user" : ""
          } ${
            recRestartFunction === CLEAR_ALL ? "undo all processed rows" : recRestartFunction === CLEAR_AUTO ? "undo automatically processed rows" : ""
          } and then run the rules on all unprocessed data`}</Text>
        </R>
      ) : (
        ""
      )}

      {restartConfigOpen ? (
        <div>
          <R props="mt-32 pl-0">
            <C3 props="pl-0">
              <Text variant="secondary" weight="bold" size="xs">
                Restart
              </Text>
              <Dropdown spacers={["mr-12"]}>
                <DropdownButton disabled={false} size="sm">
                  {draftRestartSetting.mode !== NONE ? RESTART_MODES[draftRestartSetting.mode] : "Select when to restart"}
                  <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
                </DropdownButton>

                <DropdownList>
                  {Object.entries(RESTART_MODES)
                    .filter((keyValue) => keyValue[0] !== NONE)
                    .map((keyValue, index) => {
                      return (
                        <DropdownListItem
                          key={Math.random()}
                          onClick={() => {
                            setDraftRestartSetting({ ...draftRestartSetting, mode: keyValue[0] });
                          }}
                        >
                          <DropdownItem active={false} index={index}>
                            <ItemBody>{keyValue[1]}</ItemBody>
                          </DropdownItem>
                        </DropdownListItem>
                      );
                    })}
                </DropdownList>
              </Dropdown>
            </C3>
            <C3>
              <Text variant="secondary" weight="bold" size="xs">
                Before running rules
              </Text>
              <Dropdown spacers={["mr-12"]}>
                <DropdownButton disabled={false} size="sm">
                  {draftRestartSetting.function !== NONE ? RESTART_FUNCTIONS[draftRestartSetting.function] : "Select what to restart"}
                  <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
                </DropdownButton>

                <DropdownList>
                  {Object.entries(RESTART_FUNCTIONS)
                    .filter((keyValue) => keyValue[0] !== NONE)
                    .map((keyValue, index) => {
                      return (
                        <DropdownListItem
                          key={Math.random()}
                          onClick={() => {
                            setDraftRestartSetting({ ...draftRestartSetting, function: keyValue[0] });
                          }}
                        >
                          <DropdownItem active={false} index={index}>
                            <ItemBody>{keyValue[1]}</ItemBody>
                          </DropdownItem>
                        </DropdownListItem>
                      );
                    })}
                </DropdownList>
              </Dropdown>
            </C3>
          </R>
          <div className="mt-16">
            <Button size="sm" color="primary" onClick={() => updateRestartRec(draftRestartSetting)}>
              <Text size="xs">Save</Text>
            </Button>
            <span className="ml-8"></span>
            <Button
              size="sm"
              color="primary-secondary"
              onClick={() => {
                setRestartConfigOpen(false);
              }}
            >
              <Text size="xs">Cancel</Text>
            </Button>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export { MatchingRulePanel };
