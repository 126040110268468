import { makeAutoObservable, toJS } from "mobx";
import { fusionRecsApi } from "../api";
import { AUTH_ROUTES } from "../utils/enums";
import { uiStore } from "./Store";

const { fusionAdmin } = fusionRecsApi;

class MeStore {
  me = "";
  adminRoles = [];
  adminActions = [];
  roles = [];
  actions = [];
  teams = [];
  defaultTeam = "";
  teamRolesActions = [];
  DATE_FORMAT_CONST = "YYYY-MM-DD";
  meData = {};

  constructor() {
    makeAutoObservable(this);
  }

  setMe = (data) => {
    this.me = data;
    const { adminRolesActions = { actions: [], roles: [] }, teamRolesActions, defaultTeam: defaultTeamId } = data;

    this.adminRoles = adminRolesActions === null ? [] : adminRolesActions.roles;
    this.adminActions = adminRolesActions === null ? [] : adminRolesActions.actions;

    this.teamRolesActions = teamRolesActions;
    this.roles = teamRolesActions === undefined ? [] : teamRolesActions.map((x) => x.roles);
    this.teams = teamRolesActions === undefined ? [] : teamRolesActions.map((x) => x.team);
    let allTeamActions = [];
    if (teamRolesActions) {
      teamRolesActions.forEach((teamRolesAction) => (allTeamActions = [...allTeamActions, ...teamRolesAction.actions]));
    }
    this.actions = allTeamActions;

    this.defaultTeam = this.getTeamById(defaultTeamId);
  };

  getAllActions() {
    return toJS(this.adminActions).concat(toJS(this.actions));
  }

  getAllRoles() {
    return toJS(this.adminRoles.concat(this.roles));
  }

  getUserDateFormat() {
    return this.DATE_FORMAT_CONST;
  }

  getActionsByTeamId(teamId) {
    // ensure lookup ID is an integer
    const id = parseInt(teamId, 10);

    const rolesActions = toJS(this.teamRolesActions.find((teamRolesActions) => teamRolesActions.team.id === id));
    return rolesActions ? rolesActions.actions : [];
  }

  getTeams() {
    return toJS(this.teams);
  }

  getTeamById(teamId) {
    // ensure lookup ID is an integer
    const id = parseInt(teamId, 10);
    return toJS(this.teams.find((team) => team.id === id));
  }

  getDefaultTeam() {
    return toJS(this.defaultTeam);
  }

  /**
   * returns defaultTeam if present or else the first team in getTeams() by default sort.
   */
  getDefaultTeamOrFirstInList() {
    return toJS(this.defaultTeam ? this.defaultTeam : this.getTeams()[0]);
  }

  isSuperAdmin() {
    return this.me.superAdmin;
  }

  ping = () => {
    console.log("You have pinged the user store");
  };

  getMe = () => {
    return toJS(this.me);
  };

  getName = () => {
    const { name } = this.me;
    return name;
  };

  isAdmin = () => {
    const { admin } = this.me;
    return admin;
  };

  getEmail = () => {
    const { email } = toJS(this.me);
    return email;
  };

  getId = () => {
    const { id } = this.me;
    return id;
  };

  requestMe = async () => {
    const { data } = await fusionAdmin.me();
    this.setMe(data);

    const { teamRolesActions } = data;
    if (teamRolesActions === undefined || teamRolesActions.length === 0) {
      uiStore.addNotification("error", "You currently have no teams. Please contact your administrator to be added as a member to a team.");
    }
  };

  setMeData(value) {
    this.meData = value;
  }

  getMeData() {
    return this.meData;
  }

  checkAuth = async () => {
    const response = await fusionRecsApi.me.getMe();
    const { data, status } = response;
    if ([401, 403].includes(status)) {
      return (window.location.href = AUTH_ROUTES.LOGIN);
    }

    this.setMeData(data);
  };

  /**
   * This method sends a request to update the logged in user details
   *
   * If the request is successful a toast message will appear and a true/false will
   * be returned.
   *
   * We return a true/false so we can continue operations in the app or stall them.
   *
   * Request object contains data needed on the backend
   * @param  request
   *
   * toasts param are the for the success/error messages to show for the form field being updated.
   * You can only edit/updated one form field at a time so we need to track this and display relevant
   * toast messages.
   * @param  toasts
   *
   * @returns true/false if request succeeded/failed
   */
  update = async (request, toasts) => {
    const { success, error } = toasts;
    const response = await fusionAdmin.updateMe(request).catch((error) => {
      uiStore.addNotification("error", `${error}`);
      return { data: "", response: false };
    });

    if (response.status && response.status === 200) {
      uiStore.addNotification("success", `${success}`);
      return { ...response, ...{ success: true } };
    } else {
      uiStore.addNotification("error", `${error}`);
      return { ...response, ...{ success: false } };
    }
  };
}

export { MeStore };
