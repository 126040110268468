import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { StoreProvider } from "./store/Store";
import App from "./App";

import "./styles/app.scss";
import "@fundrecs/ui-library/dist/scss/styles.scss";

ReactDOM.render(
  /**
   * Had to disable this because the Toaster component was throwing an error
   */
  //<React.StrictMode>
  <StoreProvider>
    <App />
  </StoreProvider>,
  //</React.StrictMode>,
  document.getElementById("root")
);

// @see https://bit.ly/CRA-vitals
reportWebVitals(console.log);
