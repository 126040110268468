import { IconInformation, ToolTip } from "@fundrecs/ui-library";

const InfoToolTip = ({ text, direction="left" }) => {
  return (
    <ToolTip text={text} direction={direction} size="medium">
      <IconInformation className="btn-lg-svg" style={{ color: "#6A7388" }} />
    </ToolTip>
  );
};

export { InfoToolTip };
