import { useState } from "react";
import { IconDownArrow, IconLeftArrow } from "@fundrecs/ui-library";

let expandedRowIndexes = new Set();

const toggleFullWidthRow = (row, params, gridRef) => {
  const rowIndex = params.node.rowIndex;
  const rowId = params.data.id;

  //Add or remove the row to the table
  if (expandedRowIndexes.has(rowId)) {
    expandedRowIndexes.delete(rowId);
    // Get rowNode to delete
    const nodeToDelete = params.api.getDisplayedRowAtIndex(rowIndex + 1);
    if (nodeToDelete && nodeToDelete.data.id === row.id) {
      gridRef.current.api.applyTransaction({
        remove: [nodeToDelete.data],
      });
    }
  } else {
    expandedRowIndexes.add(rowId);
    params.api.applyTransaction({
      add: [row],
      addIndex: rowIndex + 1,
    });
  }
};

const ExpandPanelIcon = (params, onClick, gridRef) => {
  const [open, setOpen] = useState(false);
  const row = {
    id: params.data && params.data.id ? params.data.id : null,
    fullWidth: true,
  };
  return (
    <span
      onClick={async (event) => {
        event.stopPropagation();
        row.fullWidthData = await onClick(params);
        toggleFullWidthRow(row, params, gridRef);
        setOpen(!open);
      }}
      style={{ cursor: "pointer" }}
    >
      {open ? <IconDownArrow className="btn-sm-svg" /> : <IconLeftArrow className="btn-sm-svg" style={{ transform: "scaleX(-1)" }} />}
    </span>
  );
};

export { toggleFullWidthRow, ExpandPanelIcon };
