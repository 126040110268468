import { useState } from "react";
import { observer } from "mobx-react-lite";
import { R, Text, C6, IconCloseRemove, Checkbox } from "@fundrecs/ui-library";
import { FunctionSelector } from "./FunctionSelector";
import { FunctionEditor } from "./FunctionEditor";
import { FlexiInput } from "./FlexiInput";
import { ACTION } from "./dataTransform";

const ConditionAction = observer(
  ({
    conditions = [],
    conditionOptions = {},
    setConditions,
    actions = [],
    actionOptions = [],
    setActions,
    canAddConditions = true,
    canAddActions = true,
    index,
    dropdownData,
    notes = [],
    manualInput = true,
    disabled = false,
    removeConditionAction = null,
  }) => {
    const HAS_ADMIN_ROWS = "Has Admin Rows";
    const HAS_BROKER_ROWS = "Has Broker Rows";
    const [requiresData, setRequiresData] = useState({ admin: false, broker: false });

    const addCondition = () => {
      const newCondition = { isNew: true, function: "", params: [] };
      setConditions(conditions.length ? [...conditions, newCondition] : [newCondition]);
    };

    const removeCondition = (index) => {
      conditions.splice(index, 1);
      setConditions(conditions);
    };

    const updateCondition = (updatedCondition, index) => {
      conditions.splice(index, 1, updatedCondition);
      setConditions(conditions);
    };

    const addAction = () => {
      const newAction = { isNew: true };
      setActions(actions.length ? [...actions, newAction] : [newAction]);
    };

    const removeAction = (index) => {
      actions.splice(index, 1);
      setActions(actions);
    };

    const updateAction = (updatedAction, index) => {
      actions.splice(index, 1, updatedAction);
      setActions(actions);
    };

    /**
     * Function to ensure 2 columns ("Has Admin Rows", "Has Broker Rows") on results TMO 5 are excluded from the dropdown
     * @param {*} columnData
     * @returns
     */
    const checkDropdownForExcludedColumns = (columnData) => {
      let updatedColumnData = {};
      if (columnData["Results"]) {
        updatedColumnData["Results"] = columnData["Results"].filter((it) => ![HAS_ADMIN_ROWS, HAS_BROKER_ROWS].includes(it.name));
      } else {
        updatedColumnData = columnData;
      }
      return updatedColumnData;
    };

    const conditionPanel = () => {
      return (
        <>
          <R>
            <Text variant="muted" size="xs" weight="regular">
              Add condition
            </Text>
          </R>
          {conditions.map((condition, index) => {
            //Exclude from display conditions which use the columns ["Has Admin Rows" ,"Has Broker Rows"]
            if (condition.params.find((it) => [HAS_ADMIN_ROWS].includes(it.name))) {
              if (!requiresData.admin) {
                setRequiresData({ ...requiresData, admin: true });
              }
              return "";
            }

            if (condition.params.find((it) => [HAS_BROKER_ROWS].includes(it.name))) {
              if (!requiresData.broker) {
                setRequiresData({ ...requiresData, broker: true });
              }
              return "";
            }
            return (
              <div className="row mt-16 mb-32" style={{ alignItems: "center" }}>
                <div className="col-1">
                  {index === 0 ? (
                    <Text size="sm">If</Text>
                  ) : (
                    <span className="ml-32">
                      <Text size="sm">and</Text>
                    </span>
                  )}
                </div>
                {condition.isNew ? (
                  <FunctionSelector
                    index={index}
                    updateCondition={updateCondition}
                    removeCondition={() => {
                      removeCondition(index);
                    }}
                    sortedFunctions={conditionOptions}
                    disabled={disabled}
                  />
                ) : (
                  <>
                    <FunctionEditor
                      condition={condition}
                      updateCondition={(updatedCondition) => updateCondition(updatedCondition, index)}
                      removeCondition={() => removeCondition(index)}
                      manualInput={manualInput}
                      dropdownData={checkDropdownForExcludedColumns(dropdownData)}
                      tags={notes}
                      disabled={disabled}
                    />
                  </>
                )}
              </div>
            );
          })}
          {canAddConditions && !disabled ? (
            <R props="pl-0 mb-16">
              <div className="col-1">
                <Text size="sm">{conditions.length === 0 ? "If" : ""}</Text>
              </div>
              <C6>
                <span
                  onClick={() => {
                    if (disabled) {
                      return;
                    }
                    addCondition();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <Text variant="link" size="sm">
                    + add condition
                  </Text>
                </span>
              </C6>
            </R>
          ) : (
            ""
          )}
        </>
      );
    };

    const actionPanel = () => {
      return (
        <>
          <R>
            <Text variant="muted" size="xs" weight="regular">
              Add action
            </Text>
          </R>
          {actions.map((action, actionIndex) => {
            return (
              <div className="row mt-16 mb-32" style={{ alignItems: "center" }}>
                <div className="col-1">
                  {actionIndex === 0 ? (
                    <Text size="sm">Then</Text>
                  ) : (
                    <span className="ml-32">
                      <Text size="sm">and</Text>
                    </span>
                  )}
                </div>
                <FlexiInput
                  displayValue={action && action.id ? action.text : ""}
                  setValue={(option) => {
                    updateAction({ ...action, ...option }, actionIndex);
                  }}
                  dropdownData={actionOptions}
                  multiLevelDropdown={true}
                  manualInputEnabled={false}
                  type={ACTION}
                  width="32rem"
                  editType={action.noteType}
                  disabled={disabled}
                />
                <div className="col-1">
                  {disabled ? (
                    ""
                  ) : (
                    <IconCloseRemove
                      className="btn-lg-svg"
                      style={{ cursor: "pointer", color: "#838B9C" }}
                      onClick={() => {
                        removeAction(actionIndex);
                      }}
                    />
                  )}
                </div>
              </div>
            );
          })}
          {canAddActions && !disabled ? (
            <R props="pl-0 mb-16">
              <div className="col-1">
                <Text size="sm">{actions.length === 0 ? "Then" : ""}</Text>
              </div>
              <C6>
                <span
                  onClick={() => {
                    if (disabled) {
                      return;
                    }
                    addAction();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <Text variant="link" size="sm">
                    + add action
                  </Text>
                </span>
              </C6>
            </R>
          ) : (
            ""
          )}
        </>
      );
    };

    return (
      <div style={{ padding: "16px", marginTop: "8px", background: "#F3F3F5", borderRadius: "10px" }}>
        <div className="d-flex justify-content-between">
          <Text size="md" weight="bold">{`Condition/ Action ${index !== undefined ? index + 1 : ""}`}</Text>
          {removeConditionAction && !disabled ? (
            <IconCloseRemove
              className="btn-lg-svg"
              style={{ cursor: "pointer", marginBottom: "0px", color: "#838B9C" }}
              onClick={() => {
                removeConditionAction();
              }}
            />
          ) : (
            ""
          )}
        </div>

        <DataForBothSidesCheckbox
          dropdownData={dropdownData}
          conditions={conditions}
          setConditions={setConditions}
          disabled={disabled}
          alreadyChecked={requiresData.admin && requiresData.broker}
          HAS_ADMIN_ROWS={HAS_ADMIN_ROWS}
          HAS_BROKER_ROWS={HAS_BROKER_ROWS}
        />
        {conditionPanel()}
        {actionPanel()}
      </div>
    );
  }
);

const DataForBothSidesCheckbox = ({ dropdownData, conditions, setConditions, disabled, alreadyChecked = false, HAS_ADMIN_ROWS, HAS_BROKER_ROWS }) => {
  const [checked, setChecked] = useState(alreadyChecked);
  if (!dropdownData || !dropdownData["Results"]) {
    return "";
  }
  const adminColumn = dropdownData["Results"].find((it) => (it.name = HAS_ADMIN_ROWS));
  const brokerColumn = dropdownData["Results"].find((it) => (it.name = HAS_BROKER_ROWS));

  const updateCheckForDataOnBothSides = (updateBoolean) => {
    if (updateBoolean) {
      addCheck();
    } else {
      removeCheck();
    }
    setChecked(updateBoolean);
  };

  const addCheck = () => {
    const checkAdminBroker = [
      {
        function: "BOOLEAN_IS",
        description: "{var} equals to {var}",
        input: ["BOOLEAN", "BOOLEAN"],
        output: "BOOLEAN",
        params: [
          {
            type: "column",
            produces: "BOOLEAN",
            value: HAS_ADMIN_ROWS,
            name: HAS_ADMIN_ROWS,
            tmoType: "Results",
          },
          {
            type: "constant",
            produces: "BOOLEAN",
            value: "true",
            name: "true",
            tmoType: "",
          },
        ],
      },
      {
        function: "BOOLEAN_IS",
        description: "{var} equals to {var}",
        input: ["BOOLEAN", "BOOLEAN"],
        output: "BOOLEAN",
        params: [
          {
            type: "column",
            produces: "BOOLEAN",
            value: HAS_BROKER_ROWS,
            name: HAS_BROKER_ROWS,
            tmoType: "Results",
          },
          {
            type: "constant",
            produces: "BOOLEAN",
            value: "true",
            name: "true",
            tmoType: "",
          },
        ],
      },
    ];

    setConditions(conditions.length ? [...conditions, ...checkAdminBroker] : [...checkAdminBroker]);
  };

  const removeCheck = () => {
    let updatedConditions = [];
    conditions.forEach((condition) => {
      if (!condition.params.find((it) => [HAS_ADMIN_ROWS, HAS_BROKER_ROWS].includes(it.name))) {
        updatedConditions.push(condition);
      }
    });
    setConditions(updatedConditions);
  };

  return adminColumn && brokerColumn ? (
    <>
      <div className="ml-16 mb-32 mt-32">
        <Checkbox
          onClick={() => {
            updateCheckForDataOnBothSides(!checked);
          }}
          checked={checked}
          disabled={disabled}
        />
        <span className="ml-16"></span>
        <Text size="sm" weight="bold">
          This condition must have data from both sides
        </Text>
      </div>
    </>
  ) : (
    ""
  );
};

export { ConditionAction };
