import { useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  modalInstance,
  R,
  C2,
  C4,
  C6,
  C10,
  Panel,
  PanelBody,
  PanelFooter,
  Heading,
  Text,
  TextArea,
  Input,
  Button,
  IconCloseRemove,
  Dropdown,
  DropdownButton,
  IconDownArrow,
  DropdownList,
  DropdownListItem,
  DropdownItem,
  ItemBody,
} from "@fundrecs/ui-library";
import { MODALS } from "../../utils/enums";
import { useStore } from "../../store/Store";

const CreateFundIdentifier = observer(({ selectedRecType, setSelectedRecType, recTypes, accountId, updateTable }) => {
  const { recTypeStore } = useStore();
  const warningMessageObject = { recType: "", identifier: "" };
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = searchParams.get("teamId");
  const [draftIdentifier, setDraftIdentifier] = useState("");
  const [warningMessage, setWarningMessage] = useState(warningMessageObject);
  const [identifiersAdded, setIdentifiersAdded] = useState([]);

  const closeModal = () => {
    setIdentifiersAdded([]);
    setWarningMessage(warningMessageObject);
    setDraftIdentifier("");
    modalInstance(MODALS.CREATE_FUND_IDENTIFIER).hide();
  };

  const createFundIdentifier = async () => {
    let warningMessageObject = { recType: "", identifier: "" };
    let warning = false;
    if (selectedRecType === null) {
      warningMessageObject = { ...warningMessageObject, recType: "Please select a rec type" };
      warning = true;
    }

    if (draftIdentifier.length < 1) {
      warningMessageObject = { ...warningMessageObject, identifier: "Please enter a fund identifier" };
      warning = true;
    }

    if (!warning) {
      const reqBody = { alias: draftIdentifier, accountId: accountId };
      const response = await recTypeStore.createAlias(teamId, selectedRecType.id, reqBody);
      if (response.status === 200) {
        setIdentifiersAdded([...identifiersAdded, { ...response.data, recTypeName: selectedRecType.name, recTypeId: selectedRecType.id }]);
        setDraftIdentifier("");
        updateTable();
      }
    }
    setWarningMessage(warningMessageObject);
  };

  const removeFundIdentifier = async (fundIdentifier) => {
    const response = await recTypeStore.deleteAlias(teamId, fundIdentifier.recTypeId, fundIdentifier.id, "ACCOUNT");
    if (response.status === 200) {
      identifiersAdded.splice(identifiersAdded.indexOf(fundIdentifier), 1);
      setIdentifiersAdded(identifiersAdded);
      updateTable();
    }
  };

  return (
    <Panel panelId={MODALS.CREATE_FUND_IDENTIFIER}>
      <PanelBody>
        <div className="panel-header">
          <div className="panel-header-left">
            <Heading variant="h4" element="span">
              New fund identifier
            </Heading>
          </div>
          <div className="panel-header-right">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                closeModal();
              }}
            ></button>
          </div>
          <div className="panel-header-clear"></div>
        </div>
        <div className="panel-description">
          <Text size="sm" variant="secondary" weight="regular">
            Add any alias the fund could be identified as within the internal data.
          </Text>
          <Text size="sm" variant="secondary" weight="bold"></Text>
        </div>
        <div className="pt-32">
          <R props="mb-32">
            <C4>
              <Text size="sm" weight="medium" variant="secondary" element="div">
                Reconciliation Type
              </Text>
              <Dropdown spacers={["mr-12"]}>
                <DropdownButton warning={warningMessage.recType === null} disabled={false} size="sm">
                  {selectedRecType ? selectedRecType.name : "Select a rec type"}
                  <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
                </DropdownButton>

                <DropdownList>
                  {recTypes.map((recType, index) => {
                    return (
                      <DropdownListItem
                        key={Math.random()}
                        onClick={() => {
                          setSelectedRecType(recType);
                        }}
                      >
                        <DropdownItem active={false} index={index}>
                          <ItemBody>{recType.name}</ItemBody>
                        </DropdownItem>
                      </DropdownListItem>
                    );
                  })}
                </DropdownList>
              </Dropdown>
            </C4>
            <C6>
              <Text size="sm" weight="medium" variant="secondary" element="div">
                Fund Identifier
              </Text>
              <Input
                onChange={(event) => {
                  setDraftIdentifier(event.target.value);
                }}
                value={draftIdentifier}
                warning={warningMessage.identifier.length > 1}
                warningMessage={warningMessage.identifier}
              />
            </C6>
            <C2 props="pt-16">
              <Button
                size="lg"
                color="primary-secondary"
                onClick={() => {
                  createFundIdentifier();
                }}
              >
                <Text>Add</Text>
              </Button>
            </C2>
          </R>
          <R props="mb-16">
            <C10>
              <Text size="sm" weight="medium" variant="secondary" element="div">
                {`${identifiersAdded.length} fund identifiers added`}
              </Text>
            </C10>
          </R>
          {identifiersAdded.map((fundIdentifier) => {
            return (
              <span style={{ borderBottom: "1px solid #E6E7EB" }}>
                <R props="pb-16">
                  <C4>
                    <Text weight="regular">{fundIdentifier.recTypeName}</Text>
                  </C4>
                  <C6>
                    <Text>{fundIdentifier.alias}</Text>
                  </C6>
                  <C2>
                    <IconCloseRemove
                      className="btn-sm-svg"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        removeFundIdentifier(fundIdentifier);
                      }}
                    />
                  </C2>
                </R>
              </span>
            );
          })}
        </div>
      </PanelBody>
      <PanelFooter>
        <Button
          onClick={() => {
            closeModal();
          }}
        >
          <Text size="sm">Done</Text>
        </Button>
      </PanelFooter>
    </Panel>
  );
});

export { CreateFundIdentifier };
