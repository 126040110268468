import { useState } from "react";
import { observer } from "mobx-react-lite";
import { Text, R, C2, C3, C4, C8, Radio, IconCloseRemove } from "@fundrecs/ui-library";
import { useStore } from "../../../../store/Store";
import { COLUMN, getDisplayName } from "../../../rules/dataTransform";
import { FlexiInput } from "../../../rules/FlexiInput";
import { InfoToolTip } from "../../../reusable/InfoToolTip";
import { transformGlobalMappingFormat } from "../../../globalMapping/globalMappingUtils.js";
import { STATUS } from "../../../../utils/enums";

/**
 * Matching Rule Builder
 * Provides a view of all parts of a matching rule & enables user to add/edit each
 */
const Groupings = observer(({}) => {
  const { matchingRuleStore, recTypeStore, globalMappingStore } = useStore();
  const matchingRule = matchingRuleStore.getDraftMatchingRule();
  const tmoColumns = recTypeStore.getRecTypeColumnMap();
  const tmos = recTypeStore.getRecTypeTmos();
  const globalMappings = globalMappingStore.getGlobalMappings();
  const globalMapping = globalMappings.length ? globalMappings[0] : null;
  const globalMappingsEnabled =
    globalMapping && globalMapping.enabled && globalMapping.status && globalMapping.status === STATUS.APPROVED.status && matchingRule.tmoId === null ? true : false;
  const [globalMappingsUsed, setGlobalMappingsUsed] = useState(matchingRule && matchingRule.globalMappingId);
  const [initialised, setInitialised] = useState(false);

  if (matchingRule && matchingRule.id && !initialised) {
    setGlobalMappingsUsed(matchingRule && matchingRule.globalMappingId);
    setInitialised(true);
  }

  const addGrouping = () => {
    const groupings = matchingRule.groupings ? matchingRule.groupings : [];
    updateDraftMatchingRule({ ...matchingRule, groupings: [...groupings, {}] });
  };

  const updateGrouping = (index, tmoId, grouping) => {
    const groupings = matchingRule.groupings;
    groupings.splice(index, 1, { ...groupings[index], [tmoId]: grouping });
    updateDraftMatchingRule({ ...matchingRule, groupings: groupings });
  };

  const removeGrouping = (index) => {
    const groupings = matchingRule.groupings;
    groupings.splice(index, 1);
    updateDraftMatchingRule({ ...matchingRule, groupings: groupings });
  };

  const updateDraftMatchingRule = (matchingRule) => {
    matchingRuleStore.updateDraftMatchingRule(matchingRule);
  };

  const toggleGlobalMappings = (globalMappingId) => {
    setGlobalMappingsUsed(globalMappingId != null);
    updateDraftMatchingRule({ ...matchingRule, globalMappingId: globalMappingId });
  };

  const AddGroupingButton = ({ noGroupings }) => {
    return (
      <>
        <R props="mt-16 mb-16 pr-0">
          {matchingRule.locked ? (
            ""
          ) : (
            <>
              <C4>
                {noGroupings ? (
                  <span style={{ marginRight: "16px" }}>
                    <Text size="sm">Match on</Text>
                  </span>
                ) : (
                  ""
                )}
                <div
                  style={{ cursor: "pointer", width: "120px" }}
                  data-bs-toggle={globalMappingsEnabled && !globalMappingsUsed ? "dropdown" : ""}
                  onClick={() => {
                    if (globalMappingsUsed || !globalMappingsEnabled) {
                      addGrouping();
                    }
                  }}
                >
                  <Text size="sm" variant="link" weight="bold">
                    + add grouping
                  </Text>
                </div>
                <ul className="dropdown-menu dropdown-sizing" aria-labelledby="dropdownMenuLink" style={{ width: "300px" }}>
                  <li
                    onClick={(event) => {
                      toggleGlobalMappings(globalMapping.id);
                    }}
                  >
                    <div className="dropdown-item ellipsize-text">Use global mappings</div>
                  </li>
                  <li
                    onClick={(event) => {
                      addGrouping();
                    }}
                  >
                    <div className="dropdown-item ellipsize-text">Create new grouping</div>
                  </li>
                </ul>
              </C4>
              <C8 props="text-right">
                <InfoToolTip text={"Select the grouping criteria for the filtered data so that the individual rows can be put into groups of rows"} />
              </C8>
            </>
          )}
        </R>
      </>
    );
  };
  return matchingRule.tmoId === undefined ? (
    ""
  ) : (
    <>
      <R props="mb-16 pr-0">
        <C2>
          <Radio
            disabled={matchingRule.locked}
            checked={!matchingRule.proceedAsRows}
            onClick={() => {
              updateDraftMatchingRule({ ...matchingRule, proceedAsRows: false });
            }}
          />
          <Text size="sm">Proceed as a group</Text>
        </C2>
        <C2>
          <Radio
            disabled={matchingRule.locked}
            checked={matchingRule.proceedAsRows}
            onClick={() => {
              updateDraftMatchingRule({ ...matchingRule, groupings: [], proceedAsRows: true });
            }}
          />
          <Text size="sm">Proceed as individual rows</Text>
        </C2>
        <C8 props="text-right">
          <InfoToolTip text={"Decide if the filtered data should be grouped before running the rest of the rule"} />
        </C8>
      </R>

      {matchingRule.proceedAsRows ? (
        ""
      ) : (matchingRule.groupings && matchingRule.groupings.length) || globalMappingsUsed ? (
        <div style={{ backgroundColor: "#F4F7FF", border: "1px #1E5EFF solid", borderRadius: "1rem", padding: "16px" }}>
          <div style={{ paddingLeft: "16px", paddingBottom: "8px" }}>
            <Text size="sm" variant="link" weight="regular">
              Define what columns should be matched on
            </Text>
          </div>
          {globalMappingsUsed ? (
            <GlobalMappingInput
              globalMapping={globalMapping}
              tmos={tmos}
              recTypeColumns={tmoColumns}
              matchingRule={matchingRule}
              removeGlobalMapping={() => {
                toggleGlobalMappings(null);
              }}
            />
          ) : (
            ""
          )}
          {matchingRule.groupings.map((grouping, index) => {
            return matchingRule.tmoId === null ? (
              <div className="row mt-16 mb-16" style={{ alignItems: "center" }}>
                <div className="col-1 overwrite-bs-gutter-x">
                  {index === 0 && !globalMappingsUsed ? (
                    <Text size="sm">Match on</Text>
                  ) : (
                    <span className="ml-32">
                      <Text size="sm">and</Text>
                    </span>
                  )}
                </div>
                {tmos.map((tmo, tmoIndex) => {
                  return (
                    <>
                      <FlexiInput
                        displayValue={grouping[tmo.id] ? getDisplayName(grouping[tmo.id]["name"], tmoColumns[tmo.id]) : ""}
                        setValue={(value) => {
                          const grouping = value === null ? null : { type: COLUMN, name: value };
                          updateGrouping(index, tmo.id, grouping);
                        }}
                        dropdownData={{ [tmo.name]: tmoColumns[tmo.id] }}
                        multiLevelDropdown={true}
                        manualInputEnabled={false}
                        inputType={"all"}
                        editType={grouping[tmo.id] ? grouping[tmo.id]["type"] : ""}
                        disabled={matchingRule.locked}
                      />
                      {tmoIndex !== tmos.length - 1 ? (
                        <div style={{ width: "100px" }}>
                          <Text size="sm">is equal to</Text>
                        </div>
                      ) : (
                        <div className="col-1">
                          {matchingRule.locked ? (
                            ""
                          ) : (
                            <IconCloseRemove
                              className="btn-lg-svg"
                              style={{ cursor: "pointer", color: "#838B9C" }}
                              onClick={() => {
                                removeGrouping(index);
                              }}
                            />
                          )}
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            ) : (
              <div className="row mt-16 mb-16" style={{ alignItems: "center" }}>
                <div className="col-1">
                  {index === 0 && !globalMappingsUsed ? (
                    <Text size="sm">Match on</Text>
                  ) : (
                    <span className="ml-32">
                      <Text size="sm">and</Text>
                    </span>
                  )}
                </div>
                <C3>
                  <FlexiInput
                    displayValue={grouping[matchingRule.tmoId] ? getDisplayName(grouping[matchingRule.tmoId]["name"], tmoColumns[matchingRule.tmoId]) : ""}
                    setValue={(value) => {
                      const grouping = value === null ? null : { type: COLUMN, name: value };
                      updateGrouping(index, matchingRule.tmoId, grouping);
                    }}
                    dropdownData={{ [tmos.find((it) => it.id === matchingRule.tmoId)["name"]]: tmoColumns[matchingRule.tmoId] }}
                    multiLevelDropdown={true}
                    manualInputEnabled={false}
                    inputType={"all"}
                    editType={grouping[matchingRule.tmoId] ? grouping[matchingRule.tmoId]["type"] : ""}
                    disabled={matchingRule.locked}
                  />
                </C3>
                <div className="col-1">
                  {matchingRule.locked ? (
                    ""
                  ) : (
                    <IconCloseRemove
                      className="btn-lg-svg"
                      style={{ cursor: "pointer", color: "#838B9C" }}
                      onClick={() => {
                        removeGrouping(index);
                      }}
                    />
                  )}
                </div>
              </div>
            );
          })}
          <AddGroupingButton noGroupings={false} />
        </div>
      ) : (
        <AddGroupingButton noGroupings={true} />
      )}
    </>
  );
});

const GlobalMappingInput = ({ globalMapping, tmos, recTypeColumns, matchingRule, removeGlobalMapping }) => {
  const formattedMapping = transformGlobalMappingFormat(globalMapping, tmos, recTypeColumns);
  return (
    <div className="row mt-16 mb-16" style={{ alignItems: "center" }}>
      <div className="col-1 overwrite-bs-gutter-x">
        <Text size="sm">Match on</Text>
      </div>

      {tmos.map((tmo, tmoIndex) => {
        let column = null;
        let tmoColumns = recTypeColumns[tmo.id];
        Object.entries(formattedMapping).forEach((entry) => {
          if (entry[1]["tmo"] === tmo.id) {
            if (entry[1]["column"]) {
              column = tmoColumns.find((it) => it.id === entry[1]["column"]);
            }
          }
        });
        return (
          <>
            <FlexiInput
              displayValue={column ? column.displayName : ""}
              setValue={(value) => {}}
              dropdownData={{ [tmo.name]: tmo && tmoColumns ? tmoColumns : [] }}
              multiLevelDropdown={true}
              manualInputEnabled={false}
              inputType={"all"}
              editType={"column"}
              disabled={true}
            />
            {tmoIndex !== tmos.length - 1 ? (
              <div style={{ width: "100px" }}>
                <Text size="sm">is equal to</Text>
              </div>
            ) : (
              <C3>
                {matchingRule.locked ? (
                  ""
                ) : (
                  <IconCloseRemove
                    className="btn-lg-svg"
                    style={{ cursor: "pointer", color: "#838B9C" }}
                    onClick={() => {
                      removeGlobalMapping();
                    }}
                  />
                )}
                <span className="ml-32 mr-16">
                  <Text size="xs" weight="regular" variant="secondary">
                    Global mappings applied
                  </Text>
                </span>
                <InfoToolTip
                  direction={"right"}
                  text={
                    "Global mappings allow manually mapped fields to be mapped for future reconciliations. Check this reconciliation type under Manage Reconciliations to see what fields are mapped in the Global mappings configuration. Global mappings must be enabled for the fund to allow mappings to be available."
                  }
                />
              </C3>
            )}
          </>
        );
      })}
    </div>
  );
};

export { Groupings };
