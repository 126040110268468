import { useState } from "react";
import { observer } from "mobx-react-lite";
import {
  R,
  C6,
  C3,
  modalInstance,
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownList,
  DropdownListItem,
  IconDownArrow,
  DropdownWarning,
  Text,
  Button,
  Panel,
  PanelBody,
  PanelHeader,
  PanelFooter,
} from "@fundrecs/ui-library";
import { MODALS } from "../../../utils/enums";
import { TextInput } from "../../reusable/TextInput";
import "./columnMappingPanel.css";
import { fusionRecsApi } from "../../../api";

const ColumnMappingPanel = observer(({}) => {
  const validationMessages = {};
  const state = { name: "Name", description: "Description" };
  const mappingTypes = ["calculation", "display"];
  const [mappingType, setMappingType] = useState(mappingTypes[0]);
  const resetState = () => {};

  return (
    <>
      <Panel panelId={MODALS.COLUMN_MAPPING}>
        <PanelBody>
          <PanelHeader
            description="Rules will be applied to the output and run in order - top to bottom. The rules can be reordered by dragging a rule to the desired position."
            header="Rules"
            onClick={() => {}}
          />
          <div className="mr-72 mt-48 text-left">
            <R>
              <Text size="lg" weight="bold">
                Actions
              </Text>
            </R>
            <R>
              <Text size="sm" weight="regular">
                Select an action below to create a new rule
              </Text>
            </R>
            <R>
              <C3>
                <div
                  onClick={() => {
                    setMappingType(mappingTypes[0]);
                  }}
                  className={`column-mapping-type-selector ${mappingType === mappingTypes[0] ? "column-mapping-type-selected" : ""}`}
                >
                  Calculation
                </div>
                <div
                  onClick={() => {
                    setMappingType(mappingTypes[1]);
                  }}
                  className={`column-mapping-type-selector ${mappingType === mappingTypes[1] ? "column-mapping-type-selected" : ""}`}
                >
                  Display
                </div>
              </C3>
            </R>
          </div>
        </PanelBody>
        <PanelFooter>
          <Button
            color="tertiary"
            onClick={() => {
              resetState();
              modalInstance(MODALS.COLUMN_MAPPING).hide();
            }}
          >
            <Text size="sm">Cancel</Text>
          </Button>
          <Button onClick={() => {}} size="md" disabled={false}>
            <Text size="sm">Add Mapping</Text>
          </Button>
        </PanelFooter>
      </Panel>
    </>
  );
});

export { ColumnMappingPanel };
