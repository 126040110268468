import { observer } from "mobx-react-lite";
import { IconLeftArrow, IconRightArrow } from "@fundrecs/ui-library";
import "./animatedPanel.css";

const AnimatedPanelToggleButton = observer(({ open, onClick = () => {} }) => {
  return (
    <div id="sidePanelButton" onClick={onClick}>
      {open ? <IconRightArrow className="btn-md-svg" currentcolor="#fff" /> : <IconLeftArrow className="btn-md-svg" currentcolor="#fff" />}
    </div>
  );
});

export { AnimatedPanelToggleButton };
