import { observer } from "mobx-react-lite";
import { useSearchParams } from "react-router-dom";
import { R, C2, C8, Text, Toggle, Button, modalInstance } from "@fundrecs/ui-library";
import { useStore } from "../../store/Store";
import { EditGlobalMapping } from "./EditGlobalMapping";
import { WarningModal } from "../WarningModal";
import { AUTHORITIES, MODALS, STATUS } from "../../utils/enums";
import { transformGlobalMappingFormat } from "./globalMappingUtils";
import { AuthWrapper } from "../AuthorizationWrapper";

const ViewGlobalMapping = observer(() => {
  const { recTypeStore, globalMappingStore, meStore, rolesStore } = useStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = searchParams.get("teamId");
  const recTypeId = searchParams.get("recType");
  const tmos = recTypeStore.getRecTypeTmos();
  const recTypeColumns = recTypeStore.getRecTypeColumnMap();
  const globalMappings = globalMappingStore.getGlobalMappings();
  const globalMapping = globalMappings.length ? globalMappings[0] : null;
  const globalMappingsEnabled = globalMapping && globalMapping.enabled ? globalMapping.enabled : false;
  const PENDING = STATUS.PENDING.status;
  const APPROVED = STATUS.APPROVED.status;
  const globalMappingStatus = globalMapping.status;
  const canApprove = globalMapping.submittedBy && globalMappingStatus === PENDING ? globalMapping.submittedBy.userName !== meStore.getEmail() : false;

  const toggleGlobalMapping = async () => {
    if (globalMappingsEnabled) {
      modalInstance(MODALS.WARNING).show();
    } else if (globalMapping && globalMapping.id) {
      await globalMappingStore.enableGlobalMapping(teamId, globalMapping.id, globalMapping.version);
    }
  };

  const disableGlobalMappings = async () => {
    await globalMappingStore.disableGlobalMapping(teamId, globalMapping.id, globalMapping.version);
    modalInstance(MODALS.WARNING).hide();
  };

  const rejectGlobalMapping = async () => {
    const resp = await globalMappingStore.deleteGlobalMapping(teamId, globalMapping.id, globalMapping.version);
    if (resp.status === 200) {
      globalMappingStore.fetchGlobalMappingByRecTypeId(teamId, recTypeId);
    }
  };

  const approveGlobalMapping = async () => {
    const resp = await globalMappingStore.approveGlobalMapping(teamId, globalMapping.id, globalMapping.version);
    if (resp.status === 200) {
      globalMappingStore.fetchGlobalMappingByRecTypeId(teamId, recTypeId);
    }
  };

  return (
    <>
      <WarningModal title="Are you sure you want to deactivate global mappings?" buttonClick={disableGlobalMappings} buttonText="OK">
        <R>
          <C2></C2>
          <C8>
            <Text size="xs" weight="regular">
              Deactivating global mappings for this reconciliation type means that mappings from manually matched rows will not be saved for future
              reconciliations and existing global mappings will not be used in matching rules across any funds with both this reconciliation type and global
              mappings enabled.
            </Text>
            <div className="mt-16"></div>
            <Text size="xs" weight="regular">
              Previous global mapping fund settings will be reinstated when global mappings is reactivated.
            </Text>
            <div className="mb-32"></div>
          </C8>
          <C2></C2>
        </R>
      </WarningModal>
      <div className="d-flex pb-16" style={{ alignItems: "center" }}>
        <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_GLOBAL_MAPPING_DISABLE, AUTHORITIES.RECS_GLOBAL_MAPPING_ENABLE])}>
          <Toggle
            disabled={globalMapping === null || globalMappingStatus !== APPROVED}
            checked={globalMappingsEnabled}
            onChange={() => {
              toggleGlobalMapping();
            }}
          />
        </AuthWrapper>

        <div className="ml-16">
          <Text size="md" weight="regular">
            Global mappings:
          </Text>
          <span className="ml-8"></span>
          <Text size="md" weight="bold" variant={globalMappingStatus === PENDING ? "warning" : globalMappingsEnabled ? "success" : "error"}>
            {globalMapping === null ? "Unavailable" : globalMappingStatus === PENDING ? "Pending approval" : globalMappingsEnabled ? "Active" : "Inactive"}
          </Text>
        </div>
      </div>
      {globalMappingsEnabled ? (
        <>
          <EditGlobalMapping
            globalMapping={tmos && recTypeColumns && globalMapping ? transformGlobalMappingFormat(globalMapping, tmos, recTypeColumns) : {}}
            disabled={true}
          />

          <div className="d-flex justify-content-end">
            {globalMappingStatus === PENDING ? (
              <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_GLOBAL_MAPPING_REJECT])}>
                <Button
                  size="md"
                  onClick={() => {
                    rejectGlobalMapping();
                  }}
                  color="danger"
                >
                  <Text size="sm" weight="bold">
                    Reject
                  </Text>
                </Button>
              </AuthWrapper>
            ) : (
              ""
            )}
            <div className="ml-8"></div>
            {canApprove ? (
              <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_GLOBAL_MAPPING_APPROVE])}>
                <Button
                  size="md"
                  onClick={() => {
                    approveGlobalMapping();
                  }}
                  color="success"
                >
                  <Text size="sm" weight="bold">
                    Approve
                  </Text>
                </Button>
              </AuthWrapper>
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
});

export { ViewGlobalMapping };
