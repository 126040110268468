import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  modalInstance,
  R,
  C2,
  C4,
  C6,
  C10,
  Panel,
  PanelBody,
  PanelFooter,
  Heading,
  Text,
  Input,
  Button,
  IconCloseRemove,
  Dropdown,
  DropdownButton,
  DropdownList,
  DropdownListItem,
  DropdownItem,
  ItemBody,
  IconDownArrow,
} from "@fundrecs/ui-library";
import { MODALS } from "../../../utils/enums";
import { useStore } from "../../../store/Store";
import { ifNullUndefinedArray } from "../../../utils/utils";

const CreateSubAccountCode = observer(({ subAccount, updateTable }) => {
  const { recTypeStore } = useStore();
  const draftCodeObject = { recType: null, code: "" };
  const warningMessageObject = { recType: null, code: "" };
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = searchParams.get("teamId");
  const [draftCode, setDraftCode] = useState(draftCodeObject);
  const [warningMessage, setWarningMessage] = useState(warningMessageObject);
  const [codesAdded, setCodesAdded] = useState([]);

  const closeModal = () => {
    setWarningMessage(warningMessageObject);
    setDraftCode(draftCodeObject);
    setCodesAdded([]);
    modalInstance(MODALS.CREATE_SUB_ACCOUNT_CODE).hide();
  };

  const createSubAccountCode = async () => {
    let warningMessageObject = { recType: null, code: "" };
    let warning = false;

    if (draftCode.code.length < 1) {
      warningMessageObject = { ...warningMessageObject, code: "Please enter a fund alias" };
      warning = true;
    }

    if (!draftCode.recType) {
      warningMessageObject = { ...warningMessageObject, recType: "Please select a rec type" };
      warning = true;
    }

    if (!warning) {
      const reqBody = { alias: draftCode.code, subAccountId: subAccount.id };
      const response = await recTypeStore.createAlias(teamId, draftCode.recType.id, reqBody);
      if (response.status === 200) {
        setCodesAdded([...codesAdded, { ...response.data, recTypeId: draftCode.recType.id, recTypeName: draftCode.recType.name }]);
        setDraftCode({ ...draftCodeObject, recType: draftCode.recType });
        updateTable();
      }
    }
    setWarningMessage(warningMessageObject);
  };

  const removeCode = async (subAccountCode) => {
    const response = await recTypeStore.deleteAlias(teamId, subAccountCode.recTypeId, subAccountCode.id, "SUB_ACCOUNT");
    if (response.status === 200) {
      codesAdded.splice(codesAdded.indexOf(subAccountCode), 1);
      setCodesAdded(codesAdded);
      updateTable();
    }
  };

  return (
    <Panel panelId={MODALS.CREATE_SUB_ACCOUNT_CODE}>
      <PanelBody>
        <div className="panel-header">
          <div className="panel-header-left">
            <Heading variant="h4" element="span">
              New sub account code
            </Heading>
          </div>
          <div className="panel-header-right">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                closeModal();
              }}
            ></button>
          </div>
          <div className="panel-header-clear"></div>
        </div>
        <div className="panel-description">
          <Text size="sm" variant="secondary" weight="regular">
            Add a sub account code for the selected sub account
          </Text>
          <Text size="sm" variant="secondary" weight="bold"></Text>
        </div>
        <div className="pt-32">
          <R props="mb-32">
            <C4>
              <Text size="sm" weight="medium" variant="secondary" element="div">
                Reconciliation type
              </Text>
              <Dropdown>
                <DropdownButton size="sm">
                  {draftCode.recType ? draftCode.recType.name : "Select a rec type"}
                  <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
                </DropdownButton>

                <DropdownList>
                  {ifNullUndefinedArray(recTypeStore.getRecTypes()).map((recType, index) => {
                    return (
                      <DropdownListItem
                        key={Math.random()}
                        onClick={() => {
                          setDraftCode({ ...draftCode, recType: recType });
                        }}
                      >
                        <DropdownItem active={false} index={index}>
                          <ItemBody>{recType.name}</ItemBody>
                        </DropdownItem>
                      </DropdownListItem>
                    );
                  })}
                </DropdownList>
              </Dropdown>
            </C4>
            <C6>
              <Text size="sm" weight="medium" variant="secondary" element="div">
                Sub account code
              </Text>
              <Input
                onChange={(event) => {
                  setDraftCode({ ...draftCode, code: event.target.value });
                }}
                value={draftCode.code}
                warning={warningMessage.code.length > 1}
                warningMessage={warningMessage.code}
              />
            </C6>
            <C2 props="pt-16">
              <Button
                size="lg"
                color="primary-secondary"
                onClick={() => {
                  createSubAccountCode();
                }}
              >
                <Text>Add</Text>
              </Button>
            </C2>
          </R>
          <R props="mb-16">
            <C10>
              <Text size="sm" weight="medium" variant="secondary" element="div">
                {`${codesAdded.length} sub account codes added`}
              </Text>
            </C10>
          </R>
          {codesAdded.map((subAccountCode) => {
            return (
              <span style={{ borderBottom: "1px solid #E6E7EB" }}>
                <R props="pb-16">
                  <C4>
                    <Text weight="regular">{subAccountCode.recTypeName}</Text>
                  </C4>
                  <C6>
                    <Text>{subAccountCode.alias}</Text>
                  </C6>
                  <C2>
                    <IconCloseRemove
                      className="btn-sm-svg"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        removeCode(subAccountCode);
                      }}
                    />
                  </C2>
                </R>
              </span>
            );
          })}
        </div>
      </PanelBody>
      <PanelFooter>
        <Button
          onClick={() => {
            closeModal();
          }}
        >
          <Text size="sm">Done</Text>
        </Button>
      </PanelFooter>
    </Panel>
  );
});

export { CreateSubAccountCode };
