import { makeAutoObservable } from "mobx";
import { fusionRecsApi } from "../api";

const NO_URL_LOADED = "#";

class TenantConfigStore {
  fusionDataUrl = NO_URL_LOADED;
  fusionAdminUrl = NO_URL_LOADED;
  fusionRecsUrl = NO_URL_LOADED;
  fusionReportingUrl = NO_URL_LOADED;

  constructor() {
    makeAutoObservable(this);
  }

  setTenantConfig = (data) => {
    this.fusionDataUrl = data.fusionDataUrl;
    this.fusionAdminUrl = data.fusionAdminUrl;
    this.fusionRecsUrl = data.fusionRecsUrl;
    this.fusionReportingUrl = data.fusionReportingUrl;
  };

  getFusionDataUrl = () => {
    return this.fusionDataUrl;
  };

  getFusionAdminUrl = () => {
    return this.fusionAdminUrl;
  };

  getFusionRecsUrl = () => {
    return this.fusionRecsUrl;
  };

  getFusionReportingUrl = () => {
    return this.fusionReportingUrl;
  };

  getOutputWriters = () => {
    return this.outputWriters;
  };

  requestTenantConfig = async () => {
    const { data } = await fusionRecsApi.tenantConfig.getTenantConfig();
    this.setTenantConfig(data);
  };
}

export { TenantConfigStore };
