import { useState } from "react";
import { observer } from "mobx-react-lite";
import { Text, VerticalDivider, DropdownItem, DropdownList, DropdownListItem, IconDownArrow, ItemBody, Checkbox } from "@fundrecs/ui-library";
import { FlexiInput } from "../rules/FlexiInput";
import { useStore } from "../../store/Store";

const EditGlobalMapping = observer(({ globalMapping, setGlobalMapping, disabled, showOnlyColumns = false }) => {
  const { recTypeStore } = useStore();
  const tmos = recTypeStore.getRecTypeTmos();
  const recTypeColumns = recTypeStore.getRecTypeColumnMap();

  return (
    <div>
      <GlobalMappingColumns globalMapping={globalMapping} tmos={tmos} recTypeColumns={recTypeColumns} setGlobalMapping={setGlobalMapping} disabled={disabled} />
      {!showOnlyColumns ? (
        <AdditionalGlobalMappingOptions
          tmos={tmos}
          globalMapping={globalMapping}
          setGlobalMapping={setGlobalMapping}
          recTypeColumns={recTypeColumns}
          disabled={disabled}
        />
      ) : (
        ""
      )}
    </div>
  );
});

const GlobalMappingColumns = observer(({ globalMapping, setGlobalMapping, tmos, recTypeColumns, disabled }) => {
  const ONE = "one";
  const MANY = "many";
  const oneToManyOptions = [ONE, MANY];
  const style = disabled ? { pointerEvents: "none", opacity: "0.65" } : {};
  const [initialised, setInitialised] = useState(false);

  //If creating a new global mapping, initialise the first tmo as 'one' the other as 'many'
  if (!disabled && tmos.length && !initialised) {
    const newGlobalMapping = { requiresApproval: false };
    tmos.forEach((tmo, index) => {
      const mapping = { tmo: tmo.id, column: null, context: null };
      if (index === 0) {
        newGlobalMapping[ONE] = mapping;
      } else {
        newGlobalMapping[MANY] = mapping;
      }
    });
    setGlobalMapping(newGlobalMapping);
    setInitialised(true);
  }

  /**
   * If user has selected a new option for the tmo, toggle the one/many
   * @param {*} tmoId
   * @param {*} oneOrMany
   */
  const toggleOneToMany = (tmoId, oneOrMany) => {
    if (globalMapping[oneOrMany]["tmo"] !== tmoId) {
      const newGlobalMapping = { requiresApproval: globalMapping.requiresApproval };
      newGlobalMapping[ONE] = globalMapping[MANY];
      newGlobalMapping[MANY] = globalMapping[ONE];
      setGlobalMapping(newGlobalMapping);
    }
  };

  const updateSelectedColumns = (oneOrMany, tmoId, columnName) => {
    const tmoColumns = recTypeColumns[tmoId];
    if (tmoColumns) {
      const column = tmoColumns.find((it) => it.name === columnName);
      const tmoData = column ? { ...globalMapping[oneOrMany], column: column.id } : globalMapping[oneOrMany];
      setGlobalMapping({ ...globalMapping, [oneOrMany]: tmoData });
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#F4F7FF",
        border: "1px #1E5EFF solid",
        borderRadius: "1rem",
        padding: "24px",
        marginBottom: "16px",
        marginTop: "24px",
      }}
    >
      <Text size="sm" weight="medium" variant="link">
        Mapping settings
      </Text>
      <div className="d-flex mt-16" style={{ alignItems: "center" }}>
        {tmos.map((tmo, index) => {
          let oneOrMany = "";
          let column = null;
          let tmoColumns = recTypeColumns[tmo.id];
          Object.entries(globalMapping).forEach((entry) => {
            if (entry[1]["tmo"] === tmo.id) {
              oneOrMany = entry[0];
              if (entry[1]["column"]) {
                column = tmoColumns.find((it) => it.id === entry[1]["column"]);
              }
            }
          });

          return (
            <>
              <div className="dropdown mr-8" style={{ ...style, width: "80px" }}>
                <div data-bs-toggle="dropdown" className="dropdown-button-container ellipsize-text" title={oneOrMany} disabled={disabled}>
                  <span>{oneOrMany ? oneOrMany : "Select"}</span>
                  <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
                </div>
                <DropdownList>
                  {oneToManyOptions.map((text, index) => {
                    return (
                      <DropdownListItem
                        onClick={() => {
                          toggleOneToMany(tmo.id, text);
                        }}
                        key={index + 1}
                      >
                        <DropdownItem active={null} index={0}>
                          <ItemBody title={text}>{text}</ItemBody>
                        </DropdownItem>
                      </DropdownListItem>
                    );
                  })}
                </DropdownList>
              </div>
              <FlexiInput
                displayValue={column ? column.displayName : ""}
                disabled={disabled}
                setValue={(value) => {
                  updateSelectedColumns(oneOrMany, tmo.id, value);
                }}
                editType={"column"}
                dropdownData={{ [tmo.name]: tmo && tmoColumns ? tmoColumns : [] }}
                multiLevelDropdown={true}
                manualInputEnabled={false}
                inputType={"all"}
              />
              {index !== oneToManyOptions.length - 1 ? (
                <span className="ml-8 mr-8">
                  <Text weight="medium" size="sm">
                    mapped to
                  </Text>
                </span>
              ) : (
                ""
              )}
            </>
          );
        })}
      </div>
    </div>
  );
});

const AdditionalGlobalMappingOptions = ({ globalMapping, tmos, recTypeColumns, setGlobalMapping, disabled }) => {
  const updateSelectedContext = (oneOrMany, tmoId, columnName) => {
    const tmoColumns = recTypeColumns[tmoId];
    if (tmoColumns) {
      const column = tmoColumns.find((it) => it.name === columnName);
      const tmoData = column ? { ...globalMapping[oneOrMany], context: column.id } : globalMapping[oneOrMany];
      setGlobalMapping({ ...globalMapping, [oneOrMany]: tmoData });
    }
  };

  return (
    <>
      <div>
        <Text size="sm" weight="medium">
          Add an additional column
        </Text>
      </div>
      <div className="mb-16">
        <Text size="xs" weight="regular" variant="tertiary">
          Add a column from each side for additional context when approving global mappings. IE add description column
        </Text>
      </div>
      <div className="d-flex mb-16 pb-8">
        {tmos.map((tmo, index) => {
          let oneOrMany = "";
          let context = null;
          let tmoColumns = recTypeColumns[tmo.id];
          Object.entries(globalMapping).forEach((entry) => {
            if (entry[1]["tmo"] === tmo.id) {
              oneOrMany = entry[0];
              if (entry[1]["context"]) {
                context = tmoColumns.find((it) => it.id === entry[1]["context"]);
              }
            }
          });

          return (
            <div key={index}>
              <Text size="xs">{globalMapping && tmo ? `Add ${tmo.name} ` : ""}</Text>
              <Text size="xs" variant="tertiary" weight="regular">
                Optional
              </Text>
              <div className="d-flex">
                <FlexiInput
                  displayValue={context ? context.displayName : ""}
                  disabled={disabled}
                  setValue={(value) => {
                    updateSelectedContext(oneOrMany, tmo.id, value);
                  }}
                  editType={"column"}
                  dropdownData={{ [tmo.name]: tmo && tmoColumns ? tmoColumns : [] }}
                  multiLevelDropdown={true}
                  manualInputEnabled={false}
                  inputType={"all"}
                />
                {index > 0 ? "" : <VerticalDivider />}
              </div>
            </div>
          );
        })}
      </div>
      <div className="pt-32 pb-24" style={{ borderTop: "1px #E6E7EB solid" }}>
        <div>
          <Text size="sm" variant="secondary" weight="medium">
            Approval settings
          </Text>
        </div>
        <div className="mt-8">
          <Checkbox
            spacers={["mr-12", "mt-0"]}
            onClick={() => {
              setGlobalMapping({ ...globalMapping, requiresApproval: !globalMapping.requiresApproval });
            }}
            checked={globalMapping.requiresApproval}
            disabled={disabled}
          />
          <Text weight="bold" size="sm">
            Require 4-eyes approval
          </Text>
        </div>
        <div className="ml-32">
          <Text weight="regular" size="xs" variant="tertiary">
            Addition and removal of mappings to the global mappings table will require a second approval
          </Text>
        </div>
      </div>
    </>
  );
};

export { EditGlobalMapping };
