import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useSearchParams } from "react-router-dom";
import { Text, Button, IconAdd } from "@fundrecs/ui-library";
import { useStore } from "../../../../store/Store";
import { AuthWrapper } from "../../../AuthorizationWrapper";
import { AUTHORITIES } from "../../../../utils/enums";

const NewMinimumMatchingRule = observer(() => {
  const { rolesStore } = useStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = searchParams.get("teamId");
  const recTypeId = searchParams.get("recType");
  const [showPanel, setShowPanel] = useState(false);

  return showPanel ? (
    ""
  ) : (
    <div className="d-flex justify-content-between">
      <div></div>
      {/*
      <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([])}>
        <Button size="md" onClick={() => {}} color="primary-secondary" disabled={true}>
          <IconAdd className="btn-sm-svg" />
          <Text size="sm" weight="bold">
            New exclusion criteria
          </Text>
        </Button>
      </AuthWrapper>
  */}
    </div>
  );
});

export { NewMinimumMatchingRule };
