import React, { useState, useEffect } from "react";
import { R, C, C6, C4, Input, LinkText, Text, SaveEditable } from "@fundrecs/ui-library";

const EditableText = ({ name, label, value, onSave, onChange, warningText, disabled }) => {
  const [editing, setEditing] = useState(false);
  const [fieldValue, setFieldValue] = useState(value);

  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  return (
    <R props="pl-0">
      {label ? (
        <C4>
          <Text variant="tertiary" size="sm" weight="regular" element="div">
            {label}
          </Text>
        </C4>
      ) : (
        ""
      )}
      {editing ? (
        <>
          <C6>
            <Input
              name={name}
              value={fieldValue}
              onChange={(event) => {
                setFieldValue(event.target.value);

                if (onChange) {
                  onChange(event.target.value);
                }
              }}
              warning={Boolean(warningText)}
              warningMessage={warningText}
            />
          </C6>
          <C props="pt-4 pb-4">
            <SaveEditable
              onClickSave={() => {
                onSave(fieldValue);
                setEditing(false);
              }}
              onClickCancel={() => {
                setFieldValue(value);
                setEditing(false);
              }}
            />
          </C>
        </>
      ) : (
        <>
          <C>
            <Text variant="primary" size="sm" weight="medium" element="div">
              {value}
            </Text>
          </C>
          {disabled ? (
            ""
          ) : (
            <C>
              <span
                className="d-flex justify-content-end"
                onClick={() => {
                  setEditing(true);
                  setFieldValue(value);
                }}
              >
                <LinkText>change</LinkText>
              </span>
            </C>
          )}
        </>
      )}
    </R>
  );
};

export { EditableText };
