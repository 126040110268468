import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { modalInstance } from "@fundrecs/ui-library";
import { ToolBarButton } from "./ToolBarButton";
import { ReactComponent as TagsIcon } from "../../../icons/tag.svg";
import { useStore } from "../../../store/Store";
import { AddNotePanel } from "../sidePanels/AddNotePanel";
import { AUTHORITIES, MODALS } from "../../../utils/enums";
import { ReactComponent as CommentsIcon } from "../../../icons/comment.svg";
import { AuthWrapper } from "../../AuthorizationWrapper";

const ProcessedRowsToolbar = ({ processedTableRef, updateTable, rowGroupDetailsOpen, editComments, uiDisabled }) => {
  const { uiStore, rolesStore } = useStore();
  const [searchParams] = useSearchParams();
  const teamId = Number(searchParams.get("teamId"));
  const [selectedRow, setSelectedRow] = useState(null);

  const openTagsPanel = () => {
    if (processedTableRef && processedTableRef.current && processedTableRef.current.api && processedTableRef.current.api.getSelectedRows().length === 1) {
      setSelectedRow(processedTableRef.current.api.getSelectedRows()[0]);
      modalInstance(MODALS.ADD_NOTE).show();
    } else {
      uiStore.addNotification("error", "Please select 1 row");
    }
  };

  return (
    <>
      <AuthWrapper teamId={teamId} oneRequired={rolesStore.getActions([AUTHORITIES.RECS_ROW_GROUP_ADD_COMMENT])}>
        <ToolBarButton
          icon={<CommentsIcon />}
          onClick={() => {
            editComments(!rowGroupDetailsOpen);
          }}
          hoverText="Edit comments"
          disabled={uiDisabled}
        />
      </AuthWrapper>
      <AuthWrapper teamId={teamId} oneRequired={rolesStore.getActions([AUTHORITIES.RECS_ROW_GROUP_ADD_NOTE, AUTHORITIES.RECS_ROW_GROUP_REMOVE_NOTE])}>
        <ToolBarButton icon={<TagsIcon />} onClick={openTagsPanel} hoverText="Edit tags" disabled={uiDisabled} />
      </AuthWrapper>
      <AddNotePanel teamId={teamId} rowGroup={selectedRow} updateTable={updateTable} />
    </>
  );
};

export { ProcessedRowsToolbar };
