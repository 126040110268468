import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useSearchParams } from "react-router-dom";
import { Text, Button, IconAdd } from "@fundrecs/ui-library";
import { useStore } from "../../store/Store";
import { EditGlobalMapping } from "./EditGlobalMapping";
import { AuthWrapper } from "../AuthorizationWrapper";
import { AUTHORITIES } from "../../utils/enums";

const NewGlobalMapping = observer(() => {
  const { globalMappingStore, rolesStore } = useStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = searchParams.get("teamId");
  const recTypeId = searchParams.get("recType");
  const [showPanel, setShowPanel] = useState(false);
  const [draftGlobalMapping, setDraftGlobalMapping] = useState({
    one: { tmo: null, column: null, context: null },
    many: { tmo: null, column: null, context: null },
    requiresApproval: false,
  });

  const createGlobalMapping = async () => {
    const body = {
      recTypeId: recTypeId,
      manyTmoColumn: draftGlobalMapping.many.column,
      manyContext: draftGlobalMapping.many.context,
      oneTmoColumn: draftGlobalMapping.one.column,
      oneContext: draftGlobalMapping.one.context,
      requiresApproval: draftGlobalMapping.requiresApproval,
    };
    const resp = await globalMappingStore.createGlobalMapping(teamId, body);
    if (resp.status === 200) {
      globalMappingStore.fetchGlobalMappingByRecTypeId(teamId, recTypeId);
    }
  };

  return showPanel ? (
    <>
      <EditGlobalMapping globalMapping={draftGlobalMapping} setGlobalMapping={(value) => setDraftGlobalMapping(value)} disabled={false} />
      <div className="d-flex justify-content-end">
        <Button
          size="md"
          onClick={() => {
            setShowPanel(false);
          }}
          color="tertiary"
        >
          <Text size="sm" weight="bold">
            Cancel
          </Text>
        </Button>
        <span className="mr-8"></span>
        <Button
          size="md"
          onClick={() => {
            createGlobalMapping();
          }}
          color="success"
        >
          <Text size="sm" weight="bold">
            Submit for approval
          </Text>
        </Button>
      </div>
    </>
  ) : (
    <div className="d-flex justify-content-between">
      <div></div>
      <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_GLOBAL_MAPPING_CREATE])}>
      <Button
        size="md"
        onClick={() => {
          setShowPanel(true);
        }}
        color="primary-secondary"
      >
        <IconAdd className="btn-sm-svg" />
        <Text size="sm" weight="bold">
          New global mapping
        </Text>
      </Button>
      </AuthWrapper>
    </div>
  );
});

export { NewGlobalMapping };
