import { observer } from "mobx-react-lite";
import { Modal, R, C8, C2, C6, modalInstance, Button, Text, IconCloseRemove, IconAlertMessage } from "@fundrecs/ui-library";
import { MODALS } from "../utils/enums";

const DeleteModal = observer(({ title, description, onDelete, children }) => {
  const modalId = MODALS.DELETE;

  return (
    <Modal modalId={modalId}>
      <R props="ml-8 mt-16 mb-8 mr-8">
        <C2>
          <div style={{ width: "40px", height: "40px", borderRadius: "100%", background: "#FDE7EA", paddingTop: "6px", paddingLeft: "8px" }}>
            <IconAlertMessage color="#F0142F" />
          </div>
        </C2>

        <C8>
          <Text size="lg" weight="bold">
            {title ? title : "Delete"}
          </Text>
        </C8>
        <C2 props="pl-32">
          <IconCloseRemove
            className={"btn-lg-svg"}
            onClick={() => {
              modalInstance(modalId).toggle();
            }}
          />
        </C2>
      </R>
      <R props="ml-8 mt-8 mb-8 mr-8">
        <Text size="sm" weight="regular">
          {description}
        </Text>
      </R>
      {children}
      <R props="ml-8 mt-8 mb-8 mr-8">
        <C6></C6>
        <C6>
          <Button
            color="tertiary"
            onClick={() => {
              modalInstance(modalId).toggle();
            }}
          >
            <Text weight="regular" size="sm">
              Cancel
            </Text>
          </Button>
          <span className="ml-16"></span>
          <Button
            color="danger"
            onClick={() => {
              onDelete();
            }}
          >
            <Text weight="regular" size="sm">
              Delete
            </Text>
          </Button>
        </C6>
      </R>
    </Modal>
  );
});

export { DeleteModal };
