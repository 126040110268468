import { observer } from "mobx-react-lite";
import { useSearchParams } from "react-router-dom";
import { Text, modalInstance, IconDownArrow, HoverDropdown } from "@fundrecs/ui-library";
import { MODALS, MATCH_PREVIEW_TYPE, AUTHORITIES, STATUS } from "../../../utils/enums.js";
import { useStore } from "../../../store/Store.js";
import { ManualMatchModal } from "./ManualMatchModal/ManualMatchModal.js";
import { RunAllMatchingRulesModal } from "./RunAllMatchingRulesModal.js";
import { RunSelectedMatchingRulesModal } from "./RunSelectedMatchingRulesModal.js";
import { AuthWrapper, isUserAuthorized } from "../../AuthorizationWrapper.js";

const MatchingOptions = observer(
  ({
    uiDisabled,
    setMatchingRulesRunning,
    rec,
    pollRecStatus,
    updateMatchPreview,
    setMatchingRuleId,
    setManualCategorizationOptions,
    matchingRules,
    subAccountId,
    gridRef,
  }) => {
    const { recStore, teamStore, recTypeStore, uiStore, rolesStore } = useStore();
    const [searchParams, setSearchParams] = useSearchParams();
    const recTypeId = Number(searchParams.get("recTypeId"));
    const teamId = Number(searchParams.get("teamId"));
    const recTypeNotes = recTypeStore.getRecTypeTags();
    const buttonStyle = {
      fontSize: "1rem",
      padding: "0.6875rem",
      paddingTop: "0.6875rem",
      paddingBottom: "0.6875rem",
      lineHeight: "initial",
      color: "#ffffff",
      backgroundColor: "#1e5eff",
      border: "0",
      verticalAlign: "middle",
      maxHeight: "40px",
    };

    const runAllMatchingRules = async () => {
      setMatchingRulesRunning(true);
      const resp = await recStore.runAllAutomatchRules(teamStore.getSelectedTeam().id, rec.id, rec.version, subAccountId);
      if (resp.status !== 200) {
        setMatchingRulesRunning(false);
      } else {
        pollRecStatus();
      }
    };

    const createMatchPreview = async (matchType, updateData, data) => {
      if (!gridRef || !gridRef.current || !gridRef.current.api || gridRef.current.api.getSelectedRows().length < 1) {
        uiStore.addNotification("error", "Please select at least 1 row to match");
        return;
      }
      setMatchingRulesRunning(true);
      let response = { status: null };
      if (matchType === MATCH_PREVIEW_TYPE.MANUAL_CATEGORIZATION) {
        data.rowIdSet = gridRef.current.api.getSelectedRows().map((it) => it.id);
        data.toBePersisted = false;
        response = await recStore.manuallyCreateRowGroup(teamStore.getSelectedTeam().id, rec.id, rec.version, data);
      } else if (matchType === MATCH_PREVIEW_TYPE.SELECTED_MATCHING_RULE) {
        const rowIds = gridRef.current.api.getSelectedRows().map((row) => row.id);
        response = await recStore.runMatchingRule(teamStore.getSelectedTeam().id, rec.id, rec.version, data, { toBePersisted: false, rowIdSet: rowIds });
      }
      if (response.status === 200) {
        updateData(data);
        updateMatchPreview(matchType, response.data ? response.data : null);
      }
      setMatchingRulesRunning(false);
    };

    const dropdownOptionClick = (modalId) => {
      if (!uiDisabled) {
        if (!gridRef || !gridRef.current || !gridRef.current.api || gridRef.current.api.getSelectedRows().length < 1) {
          uiStore.addNotification("error", "Please select at least 1 row");
        } else {
          modalInstance(modalId).show();
        }
      }
    };

    const getMatchingOptions = () => {
      let options = [];
      if (isUserAuthorized({ teamId: teamId, allRequired: rolesStore.getActions([AUTHORITIES.RECS_MATCHING_RULES_RUN]) })) {
        options.push({
          name: "Run selected matching rules",
          onClick: () => {
            dropdownOptionClick(MODALS.RUN_SELECTED_MATCHING_RULES);
          },
        });
      }
      if (isUserAuthorized({ teamId: teamId, allRequired: rolesStore.getActions([AUTHORITIES.RECS_ROW_GROUP_MANUALLY_CREATE]) })) {
        options.push({
          name: "Make a manual match",
          onClick: () => {
            dropdownOptionClick(MODALS.CREATE_MANUAL_MATCH);
          },
        });
      }
      return options;
    };
    return (
      <span className="text-left">
        <AuthWrapper teamId={teamId} oneRequired={rolesStore.getActions([AUTHORITIES.RECS_MATCHING_RULES_RUN, AUTHORITIES.RECS_ROW_GROUP_MANUALLY_CREATE])}>
          <button
            disabled={uiDisabled}
            type="button"
            style={{ ...buttonStyle, backgroundColor: uiDisabled ? "#d7dbec" : "#1e5eff", borderRadius: "0.25rem 0rem 0rem 0.25rem" }}
            onClick={() => {
              if (isUserAuthorized({ teamId: teamId, allRequired: rolesStore.getActions([AUTHORITIES.RECS_MATCHING_RULES_RUN]) })) {
                modalInstance(MODALS.RUN_ALL_MATCHING_RULES).show();
              }
            }}
          >
            <Text size="sm" weight="medium">
              Run matching rules
            </Text>
          </button>
          <button
            disabled={uiDisabled}
            data-bs-toggle="dropdown"
            type="button"
            style={{
              ...buttonStyle,
              backgroundColor: uiDisabled ? "#d7dbec" : "#1e5eff",
              borderRadius: "0rem 0.25rem 0.25rem 0rem",
              borderLeft: uiDisabled ? "2px solid #d7dbec" : "2px solid  #1e5eff",
            }}
          >
            <IconDownArrow className="btn-lg-svg" />
          </button>
        </AuthWrapper>
        <HoverDropdown>
          {getMatchingOptions().map((option, index) => {
            return (
              <li
                key={index}
                onClick={() => {
                  option.onClick();
                }}
                className="hover-dropdown-item"
              >
                <span className="align-self-center">
                  <Text variant="primary" weight="regular" size="sm">
                    {option.name}
                  </Text>
                </span>
              </li>
            );
          })}
        </HoverDropdown>
        <RunAllMatchingRulesModal runMatchingRules={runAllMatchingRules} />
        <RunSelectedMatchingRulesModal
          matchingRules={matchingRules}
          runRule={(matchingRuleId) => {
            createMatchPreview(MATCH_PREVIEW_TYPE.SELECTED_MATCHING_RULE, setMatchingRuleId, matchingRuleId);
          }}
        />
        <ManualMatchModal
          categoryNotes={recTypeNotes.filter((note) => note.classifier === true)}
          nonCategoryNotes={recTypeNotes.filter((note) => !note.classifier)}
          manuallyCreateRowGroup={(matchOptions) => createMatchPreview(MATCH_PREVIEW_TYPE.MANUAL_CATEGORIZATION, setManualCategorizationOptions, matchOptions)}
        />
      </span>
    );
  }
);

export { MatchingOptions };
