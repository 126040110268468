import { useState } from "react";
import { Button, Text } from "@fundrecs/ui-library";

const ExpandCollapseButton = ({ gridRef }) => {
  const [expandCollapse, setExpandCollapse] = useState(true);

  const toggleExpandCollapse = () => {
    if (expandCollapse) {
      gridRef.current.api.expandAll();
    } else {
      gridRef.current.api.collapseAll();
    }
    setExpandCollapse(!expandCollapse);
  };

  return (
    <Button
      size="sm"
      onClick={() => {
        toggleExpandCollapse();
      }}
      disabled={false}
      color="tertiary"
    >
      <Text size="sm" weight="medium">
        {expandCollapse ? "Expand Rows" : "Collapse Rows"}
      </Text>
    </Button>
  );
};

export { ExpandCollapseButton };
