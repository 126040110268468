import { observer } from "mobx-react-lite";
import { Text, IconCloseRemove, C4, DropdownListItem, DropdownItem, ItemBody } from "@fundrecs/ui-library";
import "./rules.css";

const FunctionSelector = observer(({ index, updateCondition, removeCondition, sortedFunctions, disabled = false }) => {
  const newParamObject = (inputDataType) => {
    return { type: null, produces: inputDataType, value: "", name: null };
  };

  const createDropdownItemForFunction = (comparatorFunction, i, conditionIndex) => {
    const text = comparatorFunction.description.split(" ");
    return (
      <DropdownListItem
        key={Math.random()}
        onClick={() => {
          const params = comparatorFunction.input.map((inputDataType) => {
            return newParamObject(inputDataType);
          });
          updateCondition({ ...comparatorFunction, params: params }, conditionIndex);
        }}
      >
        <DropdownItem active={false} index={i}>
          <ItemBody>
            {text.map((word) => {
              const input = word === "{var}";
              return <Text weight={input ? "regular" : "bold"}>{(input ? "something" : word) + " "}</Text>;
            })}
          </ItemBody>
        </DropdownItem>
      </DropdownListItem>
    );
  };

  return (
    <>
      <C4>
        <div data-bs-toggle="dropdown" className="dropdown-button-container ellipsize-text" disabled={disabled}>
          Please select an option
        </div>
        <ul className="dropdown-menu dropdown-sizing" aria-labelledby="dropdownMenuLink">
          <li className="ml-16 mt-8">
            <Text weight="bold">TEXT</Text>
          </li>
          {sortedFunctions["TEXT"].map((comparatorFunction, i) => {
            return createDropdownItemForFunction(comparatorFunction, i, index);
          })}
          <li className="ml-16 mt-8">
            <Text weight="bold">NUMBER</Text>
          </li>
          {sortedFunctions["NUMBER"].map((comparatorFunction, i) => {
            return createDropdownItemForFunction(comparatorFunction, i, index);
          })}
          <li className="ml-16 mt-8">
            <Text weight="bold">DATE</Text>
          </li>
          {sortedFunctions["DATE"].map((comparatorFunction, i) => {
            return createDropdownItemForFunction(comparatorFunction, i, index);
          })}
          <li className="ml-16 mt-8">
            <Text weight="bold">TAGS</Text>
          </li>
          {sortedFunctions["NOTES"].map((comparatorFunction, i) => {
            return createDropdownItemForFunction(comparatorFunction, i, index);
          })}
          <li className="ml-16 mt-8">
            <Text weight="bold">BOOLEAN</Text>
          </li>
          {sortedFunctions["BOOLEAN"].map((comparatorFunction, i) => {
            return createDropdownItemForFunction(comparatorFunction, i, index);
          })}
          <li className="ml-16 mt-8">
            <Text weight="bold">MISC</Text>
          </li>
          {sortedFunctions["MISC"].map((comparatorFunction, i) => {
            return createDropdownItemForFunction(comparatorFunction, i, index);
          })}
        </ul>
      </C4>
      <div className="col-1">
        <IconCloseRemove
          className="btn-lg-svg"
          style={{ cursor: "pointer", color: "#838B9C"  }}
          onClick={() => {
            if (disabled) {
              return;
            }
            removeCondition();
          }}
        />
      </div>
    </>
  );
});

export { FunctionSelector };
