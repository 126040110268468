import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Dropdown,
  DropdownListItem,
  DropdownItem,
  DropdownButton,
  DropdownButtonText,
  DropdownList,
  ItemBody,
  LinkText,
  C4,
  C6,
  Text,
  IconDownArrow,
  C,
  C10,
  SaveEditable,
  IconCheck,
} from "@fundrecs/ui-library";
import { Badge } from "../layout/Layout";

const EditableSelect = observer(({ value: originalValue, label, options, onSave, description = "" }) => {
  const [editing, setEditing] = useState(false);

  const [value, setValue] = useState(originalValue.value);
  const selectedOption = options.find((option) => option.value === value) || { label: "Please select an option" };

  return (
    <>
      <C4>
        <Text variant="tertiary" size="sm" weight="regular" element="div">
          {label}
        </Text>
      </C4>

      {editing ? (
        <>
          <C6>
            <Dropdown>
              <DropdownButton size="sm">
                <DropdownButtonText>
                  <ItemBody>
                    <span>{selectedOption.label}</span>
                  </ItemBody>
                  <IconDownArrow className="btn-sm-svg" />
                </DropdownButtonText>
              </DropdownButton>
              <DropdownList>
                {options.map(({ value, label }, index) => (
                  <DropdownListItem
                    key={index}
                    onClick={() => {
                      setValue(value);
                    }}
                  >
                    <DropdownItem index={0}>
                      <ItemBody>{label}</ItemBody>
                    </DropdownItem>
                  </DropdownListItem>
                ))}
              </DropdownList>
            </Dropdown>
          </C6>
          <C props="pt-4 pb-4">
            <SaveEditable
              onClickSave={() => {
                if (onSave) {
                  onSave(value);
                }
                setEditing(false);
              }}
              onClickCancel={() => {
                setEditing(false);
                setValue(originalValue.value);
              }}
            />
          </C>
        </>
      ) : (
        <>
          <C6>
            <Text variant="primary" size="sm" weight="medium" element="div">
              {originalValue.label}
            </Text>
            {description ? (
              <>
                <div className="pb-4"></div>
                <Text variant="tertiary" size="xs" weight="regular" element="div">
                  {description}
                </Text>
              </>
            ) : (
              ""
            )}
          </C6>
          <C>
            <span
              className="d-flex justify-content-end"
              onClick={() => {
                setEditing(true);
              }}
            >
              <LinkText>change</LinkText>
            </span>
          </C>
        </>
      )}
    </>
  );
});

const EditableMultiSelect = observer(({ selected, setSelected, options, onSave = () => {}, onCancel = () => {}, disabled }) => {
  const [editing, setEditing] = useState(false);

  const alreadySelected = (option, key = "id") => {
    return selected && selected.length && selected.filter((it) => it[key] === option[key]).length > 0;
  };

  const toggleSelection = (option, key = "id") => {
    const newSelection = alreadySelected(option) ? selected.filter((it) => it[key] !== option[key]) : [...selected, option];
    setSelected(newSelection);
  };

  const generateDescriptionString = () => {
    let str = "";
    selected.forEach((obj, index) => {
      str += `${obj.text}${index >= selected.length - 1 ? "" : ", "} `;
    });
    return str;
  };

  return (
    <>
      {editing ? (
        <>
          <C10>
            <Dropdown>
              <DropdownButton size="sm" disabled={disabled}>
                {!selected.length ? "Please select" : generateDescriptionString()}
              </DropdownButton>
              <ul
                className="dropdown-menu dropdown-sizing"
                aria-labelledby="dropdownMenuLink"
                style={{ background: "#fff", height: "250px", overflowX: "scroll" }}
              >
                {options && options.length
                  ? options.map((option, index) => {
                      return (
                        <DropdownListItem
                          onClick={() => {
                            toggleSelection(option);
                          }}
                          key={index + 1}
                        >
                          <DropdownItem active={null} index={0}>
                            <ItemBody>{option.text}</ItemBody>
                            {alreadySelected(option) ? <IconCheck className="btn-sm-svg" /> : ""}
                          </DropdownItem>
                        </DropdownListItem>
                      );
                    })
                  : ""}
              </ul>
            </Dropdown>
          </C10>
          <C props="pt-4 pb-4">
            <SaveEditable
              onClickSave={() => {
                onSave();
                setEditing(false);
              }}
              onClickCancel={() => {
                onCancel();
                setEditing(false);
              }}
            />
          </C>
        </>
      ) : (
        <>
          <C10>
            {!selected.length ? (
              <Text size="xs" weight="regular">
                Nothing selected. Click 'change' to make a selection.
              </Text>
            ) : (
              selected.map((item) => {
                return <Badge text={item.text} style={{ backgroundColor: "#F1F5F9", color: "#6A7388", margin: "5px" }} />;
              })
            )}
          </C10>
          {disabled ? (
            ""
          ) : (
            <C>
              <span
                className="d-flex justify-content-end"
                onClick={() => {
                  setEditing(true);
                }}
              >
                <LinkText>change</LinkText>
              </span>
            </C>
          )}
        </>
      )}
    </>
  );
});

export { EditableSelect, EditableMultiSelect };
