import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import {
  CO,
  R,
  C,
  C2,
  C4,
  C6,
  C8,
  Heading,
  VerticalDivider,
  Text,
  Button,
  BorderBottom,
  IconAdd,
  List,
  ListItems,
  Card,
  IconLeftArrow,
  TabsSection,
  Tab,
} from "@fundrecs/ui-library";
import "./Layout.scss";

const MainContainer = ({ width = "100%", children }) => {
  return <div style={{ width: width }}>{children}</div>;
};

const PageTitleArea = ({ title, description, classes, borderBottom, backButtonEnabled = true, props }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className={["page-header d-flex align-items-center", `${classes}`].join(" ")}>
        {backButtonEnabled ? (
          <span
            onClick={() => {
              navigate(-1);
            }}
            style={{ cursor: "pointer", paddingBottom: "5px", paddingRight: "20px" }}
          >
            <IconLeftArrow className="btn-xl-svg" color="#1e5eff" />
          </span>
        ) : (
          ""
        )}

        <Heading variant="h4">{title}</Heading>
        <VerticalDivider />
        <Text variant="muted" size="md" element="span">
          {description}
        </Text>
        <div className="d-flex ms-auto">{props}</div>
      </div>
      {borderBottom && <BorderBottom />}
    </>
  );
};

PageTitleArea.propTypes = {
  borderBottom: PropTypes.bool,
  classes: PropTypes.string,
};

PageTitleArea.defaultProps = {
  borderBottom: true,
  classes: "",
};

/**
 * This is used to manage the main content section, it contains:
 * - Left list panel
 * - Tabs for entire panel
 * - Header for main content
 * - Tabs for main content
 */
const ManageLayout = ({ headerTabs, listHeader, listItems, pageTitleArea, panelTabs, rightToolbar, mainContentToolbar, topToolbar, children }) => {
  const mainTabs = headerTabs ? (
    <R props="pl-16 section-border-bottom">
      <C props="pl-0 pr-0">{headerTabs}</C>
    </R>
  ) : (
    ""
  );

  const leftListPanel =
    listHeader && listItems.length ? (
      <div style={{ borderTop: "1px solid #E6E8EB", width: "334px" }}>
        <List>
          {listHeader}
          <R>
            <ListItems>{listItems}</ListItems>
          </R>
        </List>
      </div>
    ) : (
      <></>
    );

  const mainContentTabs = panelTabs ? (
    <R props="pl-48 section-border-bottom">
      <C4 props="pl-0 pr-0">{panelTabs ? panelTabs : <C props="pb-24" />}</C4>
    </R>
  ) : (
    <></>
  );

  return (
    <>
      {pageTitleArea}
      <CO props="pr-0 pl-0 fill">
        {topToolbar}
        {mainTabs || rightToolbar ? (
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "16px", marginRight: "44px" }}>
            <div style={{ alignSelf: "flex-start" }}>{mainTabs}</div>
            <div style={{ alignSelf: "flex-end", paddingBottom: "16px" }}>{rightToolbar}</div>
          </div>
        ) : (
          ""
        )}

        <R>
          {leftListPanel}
          <C props="section-border-left pr-0 pl-0">
            <div className="section-border-left" style={{ borderTop: "1px solid #E6E8EB", height: "100vh", background: "#F7F8F9" }}>
              {mainContentTabs}
              {mainContentToolbar}
              <R props={"pl-32 pr-32 pb-32"}>{children}</R>
            </div>
          </C>
        </R>
      </CO>
    </>
  );
};

/**
 * This is used on empty state pages.
 */
const EmptyStateLayout = ({ icon, title, description, buttonText, customButtonIcon, onClick, showDescriptionAndButton = true }) => {
  return (
    <>
      <CO props="pr-0 pl-0 fill">
        <R props="pt-48 justify-content-center">
          <C6 props="text-center pt-32 pb-32 pl-48 pr-48">
            <span className="emptyState-icon">{icon}</span>

            <div className="pt-16">
              <Heading variant="h4" align="center">
                {title}
              </Heading>
            </div>
            {showDescriptionAndButton && (
              <>
                <div className="pt-16 pb-32">
                  <Text variant="tertiary" size="sm" align="center" weight="regular" element="p">
                    {description}
                  </Text>
                </div>
                <Button
                  onClick={() => {
                    onClick();
                  }}
                >
                  {customButtonIcon ? customButtonIcon : <IconAdd className="btn-md-svg" />}
                  <Text size="sm" weight="medium">
                    {buttonText}
                  </Text>
                </Button>
              </>
            )}
          </C6>
        </R>
      </CO>
    </>
  );
};

const TitleSection = ({ ...props }) => {
  const { children } = { ...props };

  return (
    <div className="pt-24 pb-16 pr-0 pl-0">
      {React.Children.toArray(children).map((child, index) => {
        return child;
      })}
    </div>
  );
};

const SectionLayout = ({ title, children, spacers }) => {
  return (
    <>
      <TitleSection>
        <Text size="sm" variant="secondary" weight="bold">
          {title}
        </Text>
      </TitleSection>
      <C props="pl-0 pr-0">
        <Card spacers={[...spacers]}>{children}</Card>
      </C>
    </>
  );
};

const TabsLayout = ({ tabs, activeTab }) => {
  return (
    <TabsSection>
      <ul className="nav">
        {tabs.map(function (tab, index) {
          const { text, onClick } = tab;
          return (
            <Tab
              key={index}
              text={text}
              active={activeTab === index}
              onClick={() => {
                onClick();
              }}
            />
          );
        })}
      </ul>
    </TabsSection>
  );
};

SectionLayout.defaultProps = {
  spacers: ["pl-16", "pr-16", "pt-32", "pb-32"],
};

const Badge = ({ text, style, children }) => {
  return (
    <span className="custom-badge-recs" style={style}>
      {text}
      {children}
    </span>
  );
};

const Circle = ({ color, content }) => {
  const diameter = 40;
  return (
    <div
      style={{
        display: "inline-block",
        padding: diameter / 5,
        backgroundColor: color,
        borderRadius: "100%",
        height: diameter,
        width: diameter,
        textAlign: "center",
      }}
    >
      {content}
    </div>
  );
};

const NumberedSection = ({ title, description, number, children, hidden }) => {
  const leftCol = "col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-3 pl-0";
  const rightCol = "col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-9";

  return (
    <div hidden={hidden}>
      <R props={["pb-32"]}>
        <C4 props={leftCol}>
          <R>
            <div className="d-flex pl-0 pr-0 addNewLayout-title">
              <C2 props="col-xs-1 col-sm-1 col-md-1 col-lg-2 col-xl-2">
                <Circle color="#E6E7EB" content={number.toString()} />
              </C2>
              <div className="addNewLayout-description">
                <Text variant="secondary" size="sm" weight="bold" element="div">
                  {title}
                </Text>
                <Text variant="tertiary" size="xs" weight="regular" element="div">
                  {description}
                </Text>
              </div>
            </div>
          </R>
        </C4>
        <C8 props={rightCol}>{children}</C8>
      </R>
    </div>
  );
};

export { MainContainer, PageTitleArea, ManageLayout, EmptyStateLayout, SectionLayout, TabsLayout, Badge, Circle, NumberedSection };
