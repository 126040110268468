import { makeAutoObservable, toJS } from "mobx";
import { fusionRecsApi } from "../api";
import { uiStore, rolesStore } from "./Store";
import { isUserAuthorized } from "../components/AuthorizationWrapper";
import { AUTHORITIES } from "../utils/enums";

/**
 * Contains data for clients for a team
 * Stored as a list of clients
 * Should be updated each time a team is changed
 */
class ClientStore {
  clients = [];

  constructor() {
    makeAutoObservable(this);
  }

  setClientsForTeam = (clients) => {
    console.log("initialised clients", clients);
    this.clients = clients;
  };

  getAllClients = () => {
    return toJS(this.clients);
  };

  findById(clientId) {
    return toJS(this.clients.find((client) => client.id.toString() === clientId));
  }

  createClient = async (teamId, reqBody) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_CLIENT_CREATE]) })) {
      console.log("createClient: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.client.createClient(teamId, reqBody);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    } else {
      uiStore.addNotification("success", "Client created successfully");
    }
    return resp;
  };

  fetchClientsForTeam = async (teamId) => {
    if (
      !isUserAuthorized({
        teamId: teamId,
        oneRequired: rolesStore.getActions([
          AUTHORITIES.RECS_REC_VIEW,
          AUTHORITIES.RECS_UPLOADED_FILE_VIEW,
          AUTHORITIES.RECS_REC_TYPE_CONFIG_VIEW,
          AUTHORITIES.RECS_ACCOUNT_VIEW,
          AUTHORITIES.RECS_SUB_ACCOUNT_VIEW,
          AUTHORITIES.RECS_GLOBAL_MAPPING_VIEW,
        ]),
      })
    ) {
      console.log("fetchClientsForTeam: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.client.fetchClientsForTeam(teamId);
    if (resp.status === 200) {
      this.setClientsForTeam(resp.data);
    } else {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };
}

export { ClientStore };
