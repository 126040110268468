import { observer } from "mobx-react-lite";
import { Text } from "@fundrecs/ui-library";
import { SubAccountTable } from "../SubAccountTable";

const SubAccounts = observer(({ teamId, account }) => {
  return (
    <>
      <div className="pt-32"></div>
      <Text size="lg">Sub Accounts</Text>
      <Text weight="regular" size="sm" variant="secondary">
        Sub accounts required for each reconciliation type. Add external fund aliases for each enabled reconciliation type. Fund aliases are any name used to
        identify a fund within the external (admin) data.
      </Text>
      <SubAccountTable account={account} />
    </>
  );
});

export { SubAccounts };
