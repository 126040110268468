import { useState } from "react";
import { observer } from "mobx-react-lite";
import { Text, modalInstance } from "@fundrecs/ui-library";
import { useStore } from "../../store/Store";
import { WarningModal } from "../WarningModal";

const DeleteRowsFromRec = observer(({ modalId, teamId, fileId, fileVersion, fundName = "", recId, recVersion, importedRowCount, refreshData }) => {
  const { uploadedFileStore } = useStore();

  const deleteRowsFromRec = async () => {
    const response = await uploadedFileStore.deleteRowsFromRec(teamId, fileId, recId, fileVersion, recVersion);
    if (response.status && response.status === 200) {
      refreshData();
      modalInstance(modalId).hide();
    }
  };

  return (
    <>
      <WarningModal
        modalId={modalId}
        title={"Are you sure you want to delete this imported data?"}
        buttonClick={deleteRowsFromRec}
        buttonText="Delete imported data"
      >
        <div className="pl-32 pr-32 pb-32">
          <Text size="sm" weight="regular">
            Deleting this imported data will remove the {importedRowCount} imported rows for <b>{fundName}</b>. This cannot be undone.
          </Text>
          <div className="mt-16"></div>
        </div>
      </WarningModal>
    </>
  );
});

export { DeleteRowsFromRec };
