import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../store/Store";
import { FundsImportSummary } from "./FundsImportSummary";
import { FileImportSummary } from "./FileImportSummary";
import { formatRowData } from "./uploadedFileLogic";

const UploadedFileDetails = observer(({ selectedFile, disabled, columnNumber = null }) => {
  const { uploadedFileStore, teamStore } = useStore();
  const [fundTableData, setFundTableData] = useState(null);
  const [initialised, setInitialised] = useState(false);
  const teamId = teamStore.getSelectedTeam().id;

  const getRecAndFundDataForFile = async () => {
    let response = await uploadedFileStore.findRecDataByUploadedFileId(teamId, selectedFile.fileId);
    if (response.status === 200) {
      setFundTableData(response.data);
    }
  };

  if (!initialised && selectedFile.fileId && teamId) {
    getRecAndFundDataForFile();
    setInitialised(true);
  }

  return (
    <>
      <FileImportSummary
        selectedFile={{ ...formatRowData(selectedFile), recCount: fundTableData !== null ? fundTableData.length : "" }}
        columnNumber={columnNumber}
        teamId={teamId}
      />
      <span className="pt-32"></span>
      <FundsImportSummary rowData={fundTableData} file={selectedFile} refreshData={getRecAndFundDataForFile} disabled={disabled} />
    </>
  );
});

export { UploadedFileDetails };
