import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  modalInstance,
  R,
  C2,
  C4,
  C6,
  C10,
  Panel,
  PanelBody,
  PanelFooter,
  Heading,
  Text,
  Button,
  IconCloseRemove,
  Dropdown,
  DropdownButton,
  IconDownArrow,
  DropdownList,
  DropdownListItem,
  DropdownItem,
  ItemBody,
} from "@fundrecs/ui-library";
import { MODALS } from "../../utils/enums";
import { useStore } from "../../store/Store";

const EnableSubAccount = observer(({ selectedRecType, setSelectedRecType, recTypes, subAccounts, accountId, accountVersion, updateTable }) => {
  const { recTypeStore, accountStore, subAccountStore } = useStore();

  const warningMessageObject = { recType: "", subAccount: "" };
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = searchParams.get("teamId");
  const [draftSubAccount, setDraftSubAccount] = useState(null);
  const [warningMessage, setWarningMessage] = useState(warningMessageObject);
  const [subAccountsAdded, setSubAccountsAdded] = useState([]);

  const closeModal = () => {
    setWarningMessage(warningMessageObject);
    setDraftSubAccount(null);
    setSubAccountsAdded([]);
    modalInstance(MODALS.ENABLE_SUB_ACCOUNT).hide();
  };

  const enableSubAccount = async () => {
    let warningMessageObject = { recType: "", identifier: "" };
    let warning = false;
    if (selectedRecType === null) {
      warningMessageObject = { ...warningMessageObject, recType: "Please select a rec type" };
      warning = true;
    }

    if (draftSubAccount === null) {
      warningMessageObject = { ...warningMessageObject, subAccount: "Please select a sub account" };
      warning = true;
    }

    if (!warning) {
      const response = await recTypeStore.enableSubAccount(
        teamId,
        selectedRecType.id,
        accountId,
        draftSubAccount.id,
        selectedRecType.version,
        accountVersion,
        draftSubAccount.version
      );
      if (response.status === 200) {
        subAccountStore.updateVersion(draftSubAccount.id, response.data.subAccountVersion);
        recTypeStore.updateVersion(selectedRecType.id, response.data.recTypeVersion);
        accountStore.updateSelectedAccountVersion(response.data.accountVersion);
        setSubAccountsAdded(
          updateSubAccountVersions(
            [
              ...subAccountsAdded,
              {
                ...response.data,
                recTypeName: selectedRecType.name,
                recTypeId: selectedRecType.id,
                subAccountName: draftSubAccount.name,
                subAccountId: draftSubAccount.id,
              },
            ],
            selectedRecType.id,
            response.data.recTypeVersion,
            response.data.accountVersion
          )
        );
        setSelectedRecType({ ...selectedRecType, version: response.data.recTypeVersion });
        updateTable();
      }
    }

    setWarningMessage(warningMessageObject);
  };

  const removeSubAccount = async (subAccount) => {
    const response = await recTypeStore.disableSubAccount(
      teamId,
      subAccount.recTypeId,
      accountId,
      subAccount.subAccountId,
      subAccount.recTypeVersion,
      subAccount.accountVersion,
      subAccount.subAccountVersion
    );
    if (response.status === 200) {
      subAccountStore.updateVersion(subAccount.subAccountId, response.data.subAccountVersion);
      recTypeStore.updateVersion(subAccount.recTypeId, response.data.recTypeVersion);
      accountStore.updateSelectedAccountVersion(response.data.accountVersion);
      subAccountsAdded.splice(subAccountsAdded.indexOf(subAccount), 1);
      setSubAccountsAdded(updateSubAccountVersions(subAccountsAdded, subAccount.recTypeId, response.data.recTypeVersion, response.data.accountVersion));
      updateTable();
    }
  };

  const updateSubAccountVersions = (subAccounts, recTypeId, recTypeVersion, accountVersion) => {
    return subAccounts.map((subAccount) => {
      subAccount.accountVersion = accountVersion;
      if (subAccount.recTypeId === recTypeId) {
        subAccount.recTypeVersion = recTypeVersion;
      }
      return subAccount;
    });
  };

  return (
    <Panel panelId={MODALS.ENABLE_SUB_ACCOUNT}>
      <PanelBody>
        <div className="panel-header">
          <div className="panel-header-left">
            <Heading variant="h4" element="span">
              Enable sub-account
            </Heading>
          </div>
          <div className="panel-header-right">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                closeModal();
              }}
            ></button>
          </div>
          <div className="panel-header-clear"></div>
        </div>
        <div className="panel-description">
          <Text size="sm" variant="secondary" weight="regular">
            Select sub account to enable for the selected reconciliation type. Add additional fund aliases per subaccount by selecting the sub account again.
          </Text>
          <Text size="sm" variant="secondary" weight="bold"></Text>
        </div>
        <div className="pt-32">
          <R props="mb-32">
            <C4>
              <Text size="sm" weight="medium" variant="secondary" element="div">
                Reconciliation Type
              </Text>
              <Dropdown spacers={["mr-12"]}>
                <DropdownButton warning={warningMessage.recType === null} disabled={false} size="sm">
                  {selectedRecType ? selectedRecType.name : "Select a rec type"}
                  <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
                </DropdownButton>

                <DropdownList>
                  {recTypes.map((recType, index) => {
                    return (
                      <DropdownListItem
                        key={Math.random()}
                        onClick={() => {
                          setSelectedRecType(recType);
                        }}
                      >
                        <DropdownItem active={false} index={index}>
                          <ItemBody>{recType.name}</ItemBody>
                        </DropdownItem>
                      </DropdownListItem>
                    );
                  })}
                </DropdownList>
              </Dropdown>
            </C4>
            <C6>
              <Text size="sm" weight="medium" variant="secondary" element="div">
                Sub account
              </Text>
              <Dropdown spacers={["mr-12"]}>
                <DropdownButton warning={warningMessage.subAccount === null} disabled={false} size="sm">
                  {draftSubAccount ? draftSubAccount.name : "Select a sub account"}
                  <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
                </DropdownButton>

                <DropdownList>
                  {subAccounts.map((subAccount, index) => {
                    return (
                      <DropdownListItem
                        key={Math.random()}
                        onClick={() => {
                          setDraftSubAccount(subAccount);
                        }}
                      >
                        <DropdownItem active={false} index={index}>
                          <ItemBody>{subAccount.name}</ItemBody>
                        </DropdownItem>
                      </DropdownListItem>
                    );
                  })}
                </DropdownList>
              </Dropdown>
            </C6>
            <C2 props="pt-16">
              <Button
                size="lg"
                color="primary-secondary"
                onClick={() => {
                  enableSubAccount();
                }}
              >
                <Text>Add</Text>
              </Button>
            </C2>
          </R>
          <R props="mb-16">
            <C10>
              <Text size="sm" weight="medium" variant="secondary" element="div">
                {`${subAccountsAdded.length} sub accounts enabled`}
              </Text>
            </C10>
          </R>
          {subAccountsAdded.map((subAccount) => {
            return (
              <span style={{ borderBottom: "1px solid #E6E7EB" }}>
                <R props="pb-16">
                  <C4>
                    <Text weight="regular">{subAccount.recTypeName}</Text>
                  </C4>
                  <C6>
                    <Text>{subAccount.subAccountName}</Text>
                  </C6>
                  <C2>
                    <IconCloseRemove
                      className="btn-sm-svg"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        removeSubAccount(subAccount);
                      }}
                    />
                  </C2>
                </R>
              </span>
            );
          })}
        </div>
      </PanelBody>
      <PanelFooter>
        <Button
          onClick={() => {
            closeModal();
          }}
        >
          <Text size="sm">Done</Text>
        </Button>
      </PanelFooter>
    </Panel>
  );
});

export { EnableSubAccount };
