import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Text, IconInformation } from "@fundrecs/ui-library";
import { useStore } from "../../store/Store";
import { PATH } from "../../utils/urls";
import { checkForPendingGlobalMappingEntries } from "./globalMappingUtils";

const PendingGlobalMappingsAlert = observer(({ teamId, globalMappingId, recTypeId, accountId }) => {
  const navigate = useNavigate();
  const { globalMappingStore } = useStore();
  const [pendingEntriesExist, setPendingEntriesExist] = useState(false);
  const [initialised, setInitialised] = useState(false);

  const fetchGlobalMappingEntries = async () => {
    const resp = await globalMappingStore.fetchGlobalMappingValues(teamId, globalMappingId, accountId);
    if (resp.status === 200) {
      setPendingEntriesExist(checkForPendingGlobalMappingEntries(resp.data));
    }
  };

  const goToGlobalMappingsPage = () => {
    navigate(`${PATH.GLOBAL_MAPPINGS}?teamId=${teamId}&globalMappingId=${globalMappingId}&recTypeId=${recTypeId}&accountId=${accountId}`, {
      replace: false,
    });
  };

  if (!initialised && globalMappingId) {
    fetchGlobalMappingEntries();
    setInitialised(true);
  }
  return pendingEntriesExist ? (
    <div style={{ background: "#E9EFFF", borderRadius: "4px" }} className="mt-16 pl-16 pt-16 pr-16 pb-16 d-flex justify-content-between">
      <div>
        <IconInformation className="btn-sm-svg light-text-link" />
        <span className="ml-8"></span>
        <Text weight="regular" size="sm" variant="link">
          There are pending mappings for this fund and reconciliation type
        </Text>
        <span className="ml-16"></span>
        <span style={{ cursor: "pointer" }} onClick={goToGlobalMappingsPage}>
          <Text variant="link" size="sm">
            Go to mappings table
          </Text>
        </span>
      </div>
    </div>
  ) : (
    ""
  );
});

export { PendingGlobalMappingsAlert };
