import { makeAutoObservable, toJS } from "mobx";

class TeamStore {
  team = {};

  constructor() {
    makeAutoObservable(this);
  }

  setSelectedTeam = (team) => {
    this.team = team;
  };

  getSelectedTeam = () => {
    return toJS(this.team);
  };
}

export { TeamStore };
