import { fusionRequest } from "../fusionRequest";

const dataVerification = {
  createDataVerificationRule: (teamId, recTypeId, body) => fusionRequest.post(`team/${teamId}/verification?recTypeId=${recTypeId}`, body),
  bulkUpdateDvRule: (teamId, ruleId, version, body) => fusionRequest.put(`team/${teamId}/verification/${ruleId}/bulk?version=${version}`, body),
  getDvRulesForRecType: (teamId, recTypeId) => fusionRequest.get(`team/${teamId}/verification?recTypeId=${recTypeId}`),
  deleteDvRule: (teamId, dvRuleId, version) => fusionRequest.delete(`team/${teamId}/verification/${dvRuleId}?version=${version}`),
};

export { dataVerification };
