import { observer } from "mobx-react-lite";
import { R, C2, C4, C6, Text } from "@fundrecs/ui-library";

const ListItem = observer(({ leftContent, title, rightContent }) => {
  return (
    <R props="pt-16 pb-16">
      <C2 props="pl-0">{leftContent}</C2>
      <C6>
        <Text size="sm" weight="regular" variant="secondary">
          {title}
        </Text>
      </C6>
      <C4 props="text-right">{rightContent}</C4>
    </R>
  );
});

export { ListItem };
