import { fusionAdmin } from "./entities/fusionAdmin";
import { me } from "./entities/me";
import { rec } from "./entities/rec";
import { client } from "./entities/client";
import { recType } from "./entities/recType";
import { account } from "./entities/account";
import { subAccount } from "./entities/subAccount";
import { currency } from "./entities/currency";
import { dataVerification } from "./entities/dataVerification";
import { comparatorFunction } from "./entities/comparatorFunction";
import { matchingRule } from "./entities/matchingRule";
import { tmoAggregator } from "./entities/tmoAggregator";
import { tenantConfig } from "./entities/tenantConfig";
import { uploadedFile } from "./entities/uploadedFile";
import { globalMapping } from "./entities/globalMapping";
import { viewProfile } from "./entities/viewProfile"
import { autoComplete } from "./entities/autoComplete"
import { manualMatchingCriteria }  from "./entities/manualMatchingCriteria"
import { carryForward } from "./entities/carryForward";

const fusionRecsApi = {
  fusionAdmin,
  me,
  rec,
  client,
  recType,
  account,
  subAccount,
  currency,
  dataVerification,
  comparatorFunction,
  matchingRule,
  tmoAggregator,
  tenantConfig,
  uploadedFile,
  globalMapping,
  viewProfile,
  autoComplete,
  manualMatchingCriteria,
  carryForward
};

export { fusionRecsApi };
