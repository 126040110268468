import { observer } from "mobx-react-lite";
import { Panel, PanelBody, PanelFooter, Heading, Text, modalInstance } from "@fundrecs/ui-library";
import { MODALS } from "../../utils/enums";
import { UploadedFilesList } from "./UploadedFilesList";

const UploadedFilesPanel = observer(({ uploadedFiles, accountName, date }) => {
  return (
    <Panel panelId={MODALS.UPLOADED_FILES}>
      <PanelBody>
        <div className="panel-header">
          <div className="panel-header-left">
            <Heading variant="h4" element="span">
              Uploaded Files
            </Heading>
          </div>
          <div className="panel-header-right">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {}}></button>
          </div>
          <div className="panel-header-clear"></div>
        </div>
        <div className="panel-description">
          <Text size="sm" variant="secondary" weight="regular">
            {`Uploaded files       `}
          </Text>
          <Text size="sm" variant="secondary" weight="bold">
            {`${accountName} ${date}`}
          </Text>
        </div>
        <div className="pt-32">
          <Heading variant="h5">{date}</Heading>
          <UploadedFilesList
            uploadedFiles={uploadedFiles}
            onClick={() => {
              modalInstance(MODALS.UPLOADED_FILES).hide();
            }}
          />
        </div>
      </PanelBody>
      <PanelFooter></PanelFooter>
    </Panel>
  );
});

export { UploadedFilesPanel };
