import { makeAutoObservable, toJS } from "mobx";
import { fusionRecsApi } from "../api";
import { uiStore, rolesStore } from "./Store";
import { isUserAuthorized } from "../components/AuthorizationWrapper";
import { AUTHORITIES } from "../utils/enums";

/**
 * Contains data for clients for a team
 * Stored as a list of clients
 * Should be updated each time a team is changed
 */
class ViewProfileStore {
  constructor() {
    makeAutoObservable(this);
  }

  fetchRowProfileById = async (teamId, id) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_VIEW_COLUMN_VIEW]) })) {
      console.log("fetchRowProfileById: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.viewProfile.fetchRowProfileById(teamId, id);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  fetchRowGroupProfileById = async (teamId, id) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_VIEW_COLUMN_VIEW]) })) {
      console.log("fetchRowGroupProfileById: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.viewProfile.fetchRowGroupProfileById(teamId, id);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  setFavouriteRowProfile = async (teamId, id) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_VIEW_COLUMN_VIEW]) })) {
      console.log("setFavouriteRowProfile: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.viewProfile.setFavouriteRowProfile(teamId, id);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  setFavouriteRowGroupProfile = async (teamId, id) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_VIEW_COLUMN_VIEW]) })) {
      console.log("setFavouriteRowGroupProfile: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.viewProfile.setFavouriteRowGroupProfile(teamId, id);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  createRowViewProfile = async (teamId, body) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_CREATE_COLUMN_VIEW]) })) {
      console.log("createRowViewProfile: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.viewProfile.createRowViewProfile(teamId, body);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  createRowGroupViewProfile = async (teamId, body) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_CREATE_COLUMN_VIEW]) })) {
      console.log("createRowGroupViewProfile: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.viewProfile.createRowGroupViewProfile(teamId, body);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  deleteRowViewProfile = async (teamId, rowViewProfileId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_DELETE_COLUMN_VIEW]) })) {
      console.log("deleteRowViewProfile: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.viewProfile.deleteRowViewProfile(teamId, rowViewProfileId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  deleteRowGroupViewProfile = async (teamId, rowGroupViewProfileId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_DELETE_COLUMN_VIEW]) })) {
      console.log("deleteRowGroupViewProfile: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.viewProfile.deleteRowGroupViewProfile(teamId, rowGroupViewProfileId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  setRowViewProfile = async (teamId, rowViewProfileId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_VIEW_COLUMN_VIEW]) })) {
      console.log("setRowViewProfile: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.viewProfile.setRowViewProfile(teamId, rowViewProfileId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  setRowGroupViewProfile = async (teamId, rowGroupViewProfileId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_VIEW_COLUMN_VIEW]) })) {
      console.log("setRowGroupViewProfile: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.viewProfile.setRowGroupViewProfile(teamId, rowGroupViewProfileId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  fetchRowViewProfiles = async (teamId, recTypeId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_VIEW_COLUMN_VIEW]) })) {
      console.log("fetchRowViewProfiles: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.viewProfile.fetchRowViewProfiles(teamId, recTypeId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  fetchRowGroupViewProfiles = async (teamId, recTypeId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_VIEW_COLUMN_VIEW]) })) {
      console.log("fetchRowGroupViewProfiles: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.viewProfile.fetchRowGroupViewProfiles(teamId, recTypeId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };
}

export { ViewProfileStore };
