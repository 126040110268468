import axios from "axios";
import { errorHandler } from "./errorHandler";
import { API_BASE_URL, API_REQUEST_TIMEOUT_MS } from "./config";

/**
 * Wraps a request to the backend using axios library
 *
 * @param {string} method - HTTP method, one of "GET" | "POST" | "PATCH" | "PUT" | "DELETE"
 * @param {string} path - Path for the resource endpoint e.g. "users"
 * @param {string | Object} [data] - Data to send with the request
 * @param {Object} [options] - Axios config options for the request
 *
 * @returns {Promise}
 */
const request = (method = "GET", path, data, responseType, params, options) => {
  const { headers = { "Content-type": "application/json" } } = options || {};
  let requestData = { responseType: responseType, params: params, data: data };
  return axios({
    baseURL: API_BASE_URL,
    url: path,
    method,
    headers,
    timeout: API_REQUEST_TIMEOUT_MS,
    ...requestData,
    ...options,
  }).catch(errorHandler);
};

const requestWithMethod =
  (method) =>
  (...args) =>
    request.apply(null, [method, ...args]);

const fusionRequest = {
  get: requestWithMethod("GET"),
  post: requestWithMethod("POST"),
  put: requestWithMethod("PUT"),
  delete: requestWithMethod("DELETE"),
};

export { fusionRequest };
