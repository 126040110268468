import { useState } from "react";
import { observer } from "mobx-react-lite";
import { Button, Text, IconDelete, modalInstance } from "@fundrecs/ui-library";
import { useStore } from "../../store/Store";
import { Spinner } from "../layout/Spinner";
import { AUTHORITIES, MODALS } from "../../utils/enums";
import { WarningModal } from "../WarningModal";
import { AuthWrapper } from "../AuthorizationWrapper";

const DeleteFile = observer(({ teamId, disabled = false, fileId, fileVersion }) => {
  const { uploadedFileStore, teamStore, rolesStore } = useStore();

  const [deleting, setDeleting] = useState(false);
  const deleteSelectedFiles = async () => {
    setDeleting(true);
    const response = await uploadedFileStore.deleteFiles(teamStore.getSelectedTeam().id, { list: [{ id: fileId, version: fileVersion }] });
    if (response.status && response.status === 200) {
      window.location.reload();
    }
    setDeleting(false);
  };

  return (
    <>
      <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_UPLOADED_FILE_DELETE])}>
        <Button
          size="md"
          onClick={() => {
            modalInstance(MODALS.WARNING).show();
          }}
          disabled={disabled}
          color="danger"
        >
          {deleting ? <Spinner color="white" backgroundColor="red" /> : <IconDelete className={`btn-md-svg`} />}
          <Text size="sm" weight="medium">
            Delete imported data
          </Text>
        </Button>
        <WarningModal title={"Are you sure you want to delete this imported data?"} buttonClick={deleteSelectedFiles} buttonText="Delete imported data">
          <div className="pl-32 pr-32 pb-32">
            <Text size="sm" weight="regular">
              Deleting this imported data will remove the data for <b>all funds</b>. This cannot be undone.
            </Text>
            <div className="mt-16"></div>
            <Text size="sm" weight="regular">
              Individual imported rows can be deleted per fund through the menu within the <b>Funds import summary</b> table.
            </Text>
          </div>
        </WarningModal>
      </AuthWrapper>
    </>
  );
});

export { DeleteFile };
