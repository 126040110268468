const Spinner = ({ color, backgroundColor }) => {
  const spinnerColor = color ? color : "red";
  const spinnerBackgroundColor = backgroundColor ? backgroundColor : "transparent";
  const spinnerStyle = {
    display: "inline-block",
    width: "1.5rem",
    height: "1.5rem",
    verticalAlign: "-0.125em",
    border: `0.25em solid ${spinnerColor}`,
    borderRightColor: `${spinnerBackgroundColor}`,
    borderRadius: "50%",
    animation: "0.75s linear infinite spinner-border",
    marginLeft: "10px",
    marginRight: "10px",
  };

  return <div style={spinnerStyle}></div>;
};

export { Spinner };
