import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useSearchParams } from "react-router-dom";
import { Text, Toggle } from "@fundrecs/ui-library";
import { useStore } from "../../store/Store";
import { EditGlobalMapping } from "./EditGlobalMapping";
import { transformGlobalMappingFormat } from "./globalMappingUtils";
import { PendingGlobalMappingsAlert } from "./PendingGlobalMappingsAlert";
import { isUserAuthorized } from "../AuthorizationWrapper";
import { AUTHORITIES, STATUS } from "../../utils/enums";

const GlobalMappingAccountSetup = observer(({ recTypeId, accountId, accountVersion }) => {
  const { globalMappingStore, recTypeStore, accountStore, rolesStore } = useStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = searchParams.get("teamId");
  const tmos = recTypeStore.getRecTypeTmos();
  const recTypeColumns = recTypeStore.getRecTypeColumnMap();
  const [initialised, setInitialised] = useState(false);
  const [globalMapping, setGlobalMapping] = useState(null);
  const [localAccountVersion, setLocalAccountVersion] = useState(accountVersion);
  const globalMappingsEnabled = globalMapping && globalMapping.enabled ? globalMapping.enabled && globalMapping.status === STATUS.APPROVED.status : false;
  const globalMappingsEnabledForAccount = globalMapping && globalMapping.enabled && globalMapping.accounts.find((it) => it.id === accountId) ? true : false;

  const getGlobalMapping = async () => {
    const resp = await globalMappingStore.fetchGlobalMappingByRecTypeId(teamId, recTypeId);
    if (resp.status === 200 && resp.data.length) {
      setGlobalMapping(resp.data[0]);
    }
  };

  if (!initialised && recTypeId && accountId) { 
    recTypeStore.fetchTmosForRecType(teamId, recTypeId);
    getGlobalMapping();
    setInitialised(true);
  }

  const toggleGlobalMapping = async () => {
    let resp = null;
    if (globalMappingsEnabledForAccount) {
      resp = await globalMappingStore.disableGlobalMappingForAccount(teamId, globalMapping.id, accountId, globalMapping.version, localAccountVersion);
    } else if (globalMapping && globalMapping.id) {
      resp = await globalMappingStore.enableGlobalMappingForAccount(teamId, globalMapping.id, accountId, globalMapping.version, localAccountVersion);
    }
    if (resp.status === 200) {
      await getGlobalMapping();
      accountStore.updateSelectedAccountVersion(resp.data.accountVersion);
      setLocalAccountVersion(localAccountVersion + 1);
    }
  };

  return (
    <div>
      <Text size="sm" weight="bold">
        Global Mappings
      </Text>
      <div className="d-flex mt-16 pb-16" style={{ alignItems: "center" }}>
        <Toggle
          disabled={
            !isUserAuthorized({
              teamId: teamId,
              allRequired: rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_GLOBAL_MAPPING_ENABLE, AUTHORITIES.RECS_ACCOUNT_GLOBAL_MAPPING_DISABLE]),
            }) || !globalMappingsEnabled
          }
          checked={globalMappingsEnabledForAccount}
          onChange={() => {
            toggleGlobalMapping();
          }}
        />

        <div className="ml-16">
          <Text size="xs" weight="bold">
            Global mappings:
          </Text>
          <Text size="xs" weight="bold" variant={globalMappingsEnabledForAccount ? "success" : "error"}>
            {globalMapping === null ? " Unavailable" : globalMappingsEnabledForAccount ? " Enabled" : " Disabled"}
          </Text>
          <div>
            <Text size="xs" weight="regular" variant="tertiary">
              When enabled the mappings created during manual matches, which correspond to the configured global mapping settings, are saved for future
              reconciliations of this type and can be used in matching rules for this fund.
            </Text>
          </div>
        </div>
      </div>
      <div className="ml-32 pl-32">
        {globalMapping && tmos && recTypeColumns ? (
          <>
            <EditGlobalMapping
              globalMapping={transformGlobalMappingFormat(globalMapping, tmos, recTypeColumns)}
              tmos={tmos}
              recTypeColumns={recTypeColumns}
              disabled={true}
              showOnlyColumns={true}
            />
            {globalMappingsEnabledForAccount ? (
              <PendingGlobalMappingsAlert teamId={teamId} globalMappingId={globalMapping.id} recTypeId={recTypeId} accountId={accountId} />
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
});

export { GlobalMappingAccountSetup };
