import { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import "react-calendar/dist/Calendar.css";
import { useStore } from "../../../store/Store";
import {
  Panel,
  PanelBody,
  PanelHeader,
  PanelFooter,
  LinkText,
  Text,
  R,
  C2,
  C8,
  Dropdown,
  DropdownButton,
  IconDownArrow,
  DropdownList,
  DropdownListItem,
  DropdownItem,
  Button,
  IconCloseRemove,
  Checkbox,
  ItemBody,
  IconCheck,
} from "@fundrecs/ui-library";
import { AUTHORITIES, MODALS } from "../../../utils/enums";
import { Badge } from "../../layout/Layout";
import "./TagsPanel.css";
import { AuthWrapper } from "../../AuthorizationWrapper";

const AddNotePanel = observer(({ teamId, rowGroup, updateTable }) => {
  const { recStore, recTypeStore, rolesStore } = useStore();
  const [selectedTags, setSelectedTags] = useState([]);
  const [initialised, setInitialised] = useState(false);
  const [tags, setTags] = useState([]);
  const [addTagsFlag, setAddTagsFlag] = useState(false);
  const [selectedRowGroup, setSelectedRowGroup] = useState({ tags: [] });

  useEffect(() => {
    if (rowGroup && rowGroup.tags) {
      setSelectedRowGroup(rowGroup);
      setSelectedTags([]);
    }
  }, [rowGroup]);

  const recTypeTags = recTypeStore.getRecTypeTags();
  const getTags = (recTypeTags) => {
    let tags = [];
    recTypeTags.forEach((tag) => {
      if (!tag.classifier) {
        tags.push(tag);
      }
    });
    return tags;
  };

  if (recTypeTags.length && rowGroup && rowGroup.tags && !initialised) {
    setTags(getTags(recTypeTags));
    setInitialised(true);
    setSelectedRowGroup(rowGroup);
  }

  const saveTags = async () => {
    if (selectedTags.length && teamId && selectedRowGroup.id && ![null, undefined].includes(selectedRowGroup.version)) {
      const response = await recStore.saveNotesToRowGroup(
        teamId,
        selectedRowGroup.id,
        selectedRowGroup.version,
        selectedTags.map((tag) => tag.id)
      );
      if (response.status === 200) {
        setSelectedTags([]);
        updateStateOnResponse(response.data);
      }
    }
  };

  const removeTag = async (tag) => {
    if (teamId && selectedRowGroup.id && selectedRowGroup.version > -1) {
      const response = await recStore.removeTagFromRowGroup(teamId, selectedRowGroup.id, tag.id, selectedRowGroup.version);
      if (response.status === 200) {
        updateStateOnResponse(response.data);
      }
    }
  };

  const updateStateOnResponse = (responseData) => {
    setSelectedRowGroup(responseData.rowGroup);
    updateTable();
  };

  const updateSelectedTags = (tag) => {
    let tempTags = [];
    if (selectedTags.includes(tag)) {
      selectedTags.forEach((it) => {
        if (it !== tag) {
          tempTags.push(it);
        }
      });
    } else {
      tempTags = selectedTags.concat([tag]);
    }
    setSelectedTags(tempTags);
  };

  const horizontalDivider = () => {
    return <div style={{ borderBottom: "1px solid #E6E7EB", margin: "1.5em", width: "90%" }}></div>;
  };

  return (
    <Panel panelId={MODALS.ADD_NOTE}>
      <PanelBody>
        <PanelHeader description="View all tags related to this row group" header="View Tags" />
        <div className="pt-12 pb-12"></div>

        <div className="pt-32">
          <R>
            <C2>
              <Text weight="regular">Tags</Text>
            </C2>

            <C8>
              {!addTagsFlag ? (
                ""
              ) : (
                <Dropdown spacers={["mr-12"]}>
                  <DropdownButton warning={false} disabled={false} size="sm">
                    {selectedTags.length
                      ? selectedTags.map((tag, index) => {
                          if (index === selectedTags.length - 1) {
                            return tag.text;
                          } else {
                            return tag.text + ", ";
                          }
                        })
                      : "Please select a tag"}
                    <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
                  </DropdownButton>

                  <DropdownList>
                    {tags.map((tag, index) => {
                      return (
                        <DropdownListItem key={Math.random()} onClick={() => {}}>
                          <DropdownItem active={false} index={index}>
                            <Checkbox
                              spacers={["mr-12", "mt-0"]}
                              onClick={(event) => {
                                event.stopPropagation();
                                updateSelectedTags(tag);
                              }}
                              checked={selectedRowGroup.tags.map((it) => it.id).includes(tag.id) || selectedTags.includes(tag)}
                              disabled={selectedRowGroup.tags.map((it) => it.id).includes(tag.id)}
                            />
                            <ItemBody>{tag.text}</ItemBody>
                          </DropdownItem>
                        </DropdownListItem>
                      );
                    })}
                  </DropdownList>
                </Dropdown>
              )}
              {rowGroup && rowGroup.category ? (
                <Badge text={rowGroup.category.name} style={{ backgroundColor: "#F1F5F9", color: "#6A7388", margin: "5px" }} />
              ) : (
                ""
              )}
              {selectedRowGroup.tags.map((tag) => {
                return (
                  <Badge text={tag.name} style={{ backgroundColor: "#F8FAFF", color: "#1E5EFF", margin: "5px" }}>
                    <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_ROW_GROUP_REMOVE_NOTE])}>
                      <IconCloseRemove
                        style={{ cursor: "pointer" }}
                        className="btn-sm-svg"
                        onClick={() => {
                          removeTag(tag);
                        }}
                      />
                    </AuthWrapper>
                  </Badge>
                );
              })}
            </C8>
            <C2>
              {addTagsFlag ? (
                <>
                  <Button
                    onClick={() => {
                      saveTags();
                    }}
                    size="md"
                  >
                    <IconCheck className="btn-md-svg" />
                  </Button>
                  <span className="ml-16"></span>
                  <Button
                    onClick={() => {
                      setSelectedTags([]);
                      setAddTagsFlag(false);
                    }}
                    size="md"
                  >
                    <IconCloseRemove className="btn-md-svg" />
                  </Button>
                </>
              ) : (
                <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_ROW_GROUP_ADD_NOTE])}>
                  <span
                    onClick={() => {
                      setAddTagsFlag(true);
                    }}
                  >
                    <LinkText>
                      <Text>Add Tag</Text>
                    </LinkText>
                  </span>
                </AuthWrapper>
              )}
            </C2>
          </R>
          {horizontalDivider()}
          <R>
            <C2>
              <Text weight="regular">Days old</Text>
            </C2>
            <C8>{rowGroup && ![undefined, null].includes(rowGroup.age) ? rowGroup.age : ""}</C8>
          </R>
        </div>
      </PanelBody>
      <PanelFooter></PanelFooter>
    </Panel>
  );
});

export { AddNotePanel };
