import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  R,
  C4,
  C6,
  Text,
  Card,
  Input,
  Button,
  Heading,
  Dropdown,
  DropdownButton,
  IconDownArrow,
  DropdownList,
  DropdownListItem,
  DropdownItem,
  ItemBody,
  modalInstance,
} from "@fundrecs/ui-library";
import { useStore } from "../../../store/Store";
import { NumberedSection } from "../../layout/Layout";
import { AUTHORITIES, MODALS } from "../../../utils/enums";
import { CreateClient } from "./CreateClient";
import { StickyFooter } from "../../layout/StickyFooter";
import "./account-setup.css";
import { AuthWrapper } from "../../AuthorizationWrapper";

const CreateAccount = observer(({ close, openNextStep }) => {
  const accountObject = { name: "", identifier: "", client: null, currency: null, businessWeek: "" };
  const warningMessageObject = { name: "", identifier: "", client: "", currency: "", businessWeek: "" };
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = searchParams.get("teamId");
  const [account, setAccount] = useState(accountObject);
  const { clientStore, currencyStore, accountStore, rolesStore } = useStore();
  const [warningMessage, setWarningMessage] = useState(warningMessageObject);
  const [initialised, setInitialised] = useState(false);
  const clients = clientStore.getAllClients();
  const currencies = currencyStore.getCurrencies();
  const defaultSettings = accountStore.getDefaultAccountSettings();
  const workingWeekSetting = defaultSettings.find((setting) => setting.type === "workingweek");
  const settings = workingWeekSetting ? [workingWeekSetting] : [];
  if (!initialised) {
    currencyStore.fetchAllCurrencies(teamId);
    accountStore.fetchDefaultSettings(teamId);
    setInitialised(true);
  }

  const clearInputs = () => {
    setAccount(accountObject);
    setWarningMessage(warningMessageObject);
  };

  const saveAccount = async () => {
    let warningMessageObject = { name: "", identifier: "", client: "", currency: "", businessWeek: "" };
    let warning = false;
    if (account.name.length < 1) {
      warningMessageObject = { ...warningMessageObject, name: "Please enter a name for the fund" };
      warning = true;
    }

    if (account.identifier.length < 1) {
      warningMessageObject = { ...warningMessageObject, identifier: "Please enter an identifier for the fund" };
      warning = true;
    }

    if (!account.client) {
      warningMessageObject = { ...warningMessageObject, client: "Please select or create a client for the fund" };
      warning = true;
    }

    if (account.businessWeek === null) {
      warningMessageObject = { ...warningMessageObject, businessWeek: "Please select a business week for the fund" };
      warning = true;
    }

    if (account.currency === null) {
      warningMessageObject = { ...warningMessageObject, currency: "Please select a currency for the fund" };
      warning = true;
    }
    if (!warning) {
      const reqBody = { currencyId: account.currency.id, name: account.name, identifier: account.identifier, settings: [account.businessWeek] };
      const response = await accountStore.createAccount(teamId, account.client.id, reqBody);
      if (response.status === 200) {
        clearInputs();
        openNextStep();
      }
    }
    setWarningMessage(warningMessageObject);
  };

  return (
    <>
      <R props="mb-32 pb-32">
        <Heading variant="h3">Setup a new fund</Heading>

        <Text weight="regular" size="sm" variant="secondary">
          Add basic fund account details and settings
        </Text>
      </R>
      <R>
        <NumberedSection title="Fund Name + Identifier" description="Choose a unique name and identifier for the fund" number={1} hidden={false}>
          <Card spacers={["pt-32", "pr-32", "pb-32", "pl-32"]}>
            <R props="mb-32">
              <C6>
                <Text size="sm" weight="medium" variant="secondary" element="div">
                  Fund name *
                </Text>
                <div className="mt-8"></div>
                <Input
                  onChange={(event) => {
                    setAccount({ ...account, name: event.target.value });
                  }}
                  value={account.name}
                  warning={warningMessage.name.length > 1}
                  warningMessage={warningMessage.name}
                />
              </C6>
            </R>
            <R props="mb-32">
              <C6>
                <Text size="sm" weight="medium" variant="secondary" element="div">
                  Fund Identifier *
                </Text>
                <div className="mt-8"></div>
                <Input
                  value={account.identifier}
                  onChange={(event) => {
                    setAccount({ ...account, identifier: event.target.value });
                  }}
                  warning={warningMessage.identifier.length > 1}
                  warningMessage={warningMessage.identifier}
                />
              </C6>
            </R>
            <R>
              <C6>
                <Text size="sm" weight="regular" variant="secondary" element="div">
                  Additional fund identifiers can be added later.
                </Text>
              </C6>
            </R>
          </Card>
        </NumberedSection>
      </R>
      <R>
        <NumberedSection title="Select Client" description="Select an existing client or create a new client." number={2} hidden={false}>
          <Card spacers={["pt-32", "pr-32", "pb-32", "pl-32"]}>
            <R>
              <C6>
                <Text size="sm" weight="regular" variant="secondary">
                  Select the client this fund is associated with or add a new client *
                </Text>
                <Dropdown spacers={["mt-8", "mr-12"]}>
                  <DropdownButton warning={warningMessage.client.length > 1} disabled={false} size="sm">
                    {account.client ? account.client.name : "Select a client"}
                    <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
                  </DropdownButton>

                  <ul
                    class="dropdown-menu dropdown-menu-end box w-100 dropdown-item-text-undefined"
                    aria-labelledby="dropdownMenuLink"
                    style={{ maxHeight: "300px", background: "#fff", overflowX: "scroll" }}
                  >
                    {clients.map((client, index) => {
                      return (
                        <DropdownListItem
                          key={Math.random()}
                          onClick={() => {
                            setAccount({ ...account, client: client });
                          }}
                        >
                          <DropdownItem active={false} index={index}>
                            <ItemBody>{client.name}</ItemBody>
                          </DropdownItem>
                        </DropdownListItem>
                      );
                    })}
                  </ul>
                </Dropdown>
              </C6>
              <C4 props="pl-0">
                <div className="mt-32 d-flex align-items-center">
                  <div style={{ borderLeft: "2px solid #e6e7eb", height: "1.5rem", paddingRight: "16px" }}></div>
                  <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_CLIENT_CREATE])}>
                    <Button
                      onClick={() => {
                        modalInstance(MODALS.CREATE_CLIENT).show();
                      }}
                      size="md"
                      disabled={false}
                    >
                      <Text>Create Client</Text>
                    </Button>
                  </AuthWrapper>
                </div>
              </C4>
            </R>
          </Card>
        </NumberedSection>
      </R>
      <R>
        <NumberedSection title="Additional Settings" description="Choose additional settings for the fund" number={3} hidden={false}>
          <Card spacers={["pt-32", "pr-32", "pb-32", "pl-32"]}>
            <R props="mb-32">
              <C6>
                <Text size="sm" weight="medium" variant="secondary" element="div">
                  Fund currency *
                </Text>
                <div className="mt-8"></div>
                <Dropdown spacers={["mr-12"]}>
                  <DropdownButton warning={warningMessage.currency.length > 1} disabled={false} size="sm">
                    {account.currency ? `${account.currency.code} - ${account.currency.description}` : "Select a currency"}
                    <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
                  </DropdownButton>

                  <ul
                    class="dropdown-menu dropdown-menu-end box w-100 dropdown-item-text-undefined"
                    aria-labelledby="dropdownMenuLink"
                    style={{ maxHeight: "300px", background: "#fff", overflowX: "scroll" }}
                  >
                    {currencies.map((currency, index) => {
                      return (
                        <DropdownListItem
                          key={Math.random()}
                          onClick={() => {
                            setAccount({ ...account, currency: currency });
                          }}
                        >
                          <DropdownItem active={false} index={index}>
                            <ItemBody>{`${currency.code} - ${currency.description}`}</ItemBody>
                          </DropdownItem>
                        </DropdownListItem>
                      );
                    })}
                  </ul>
                </Dropdown>
              </C6>
            </R>
            <R props="mb-32">
              <C6>
                <Text size="sm" weight="medium" variant="secondary" element="div">
                  Business week *
                </Text>
                <Dropdown spacers={["mt-8", "mr-12"]}>
                  <DropdownButton warning={warningMessage.businessWeek.length > 1} disabled={false} size="sm">
                    {account.businessWeek ? account.businessWeek.value : "Select a business week"}
                    <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
                  </DropdownButton>
                  <DropdownList>
                    {settings.map((businessWeek, index) => {
                      return (
                        <DropdownListItem
                          key={Math.random()}
                          onClick={() => {
                            setAccount({ ...account, businessWeek: businessWeek });
                          }}
                        >
                          <DropdownItem active={false} index={index}>
                            <ItemBody>{businessWeek.value}</ItemBody>
                          </DropdownItem>
                        </DropdownListItem>
                      );
                    })}
                  </DropdownList>
                </Dropdown>
              </C6>
            </R>
          </Card>
        </NumberedSection>
      </R>
      <R>
        <StickyFooter>
          <Button
            color="tertiary"
            onClick={() => {
              clearInputs();
              close();
            }}
          >
            <Text size="sm">Cancel</Text>
          </Button>
          <div className="mr-16"></div>
          <Button
            onClick={() => {
              saveAccount();
            }}
            size="md"
            disabled={false}
          >
            <Text size="sm">Create Fund</Text>
          </Button>
        </StickyFooter>
      </R>
      <CreateClient />
    </>
  );
});

export { CreateAccount };
