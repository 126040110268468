import React, { useState, useRef, useEffect } from "react";
import { useStore } from "../../../store/Store";
import { observer } from "mobx-react-lite";
import {
  Card,
  R,
  Button,
  Text,
  C4,
  C3,
  C5,
  Input,
  C2,
  C6,
  IconAdd,
  VerticalMenuDropdown,
  VerticalMenuListItem,
  Dropdown,
  DropdownButton,
  DropdownList,
  DropdownListItem,
  DropdownItem,
  IconDownArrow,
} from "@fundrecs/ui-library";
import { ModalHeader } from "../../layout/ModalHeader";
import { ModalFooter } from "../../layout/Modals";
import "./setupCategories.css";

/**

 */
const SetupCategories = observer(({ backToMenu }) => {
  const { recTypeStore } = useStore();
  const recType = recTypeStore.getDraftRecType();
  const initialCategoryObject = { name: "", description: "", colour: "" };
  const [draftCategory, setDraftCategory] = useState(initialCategoryObject);
  const [categories, setCategories] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef();

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setDropdownOpen(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideAlerter(dropdownRef);

  const categoryColours = {
    Red: "#FF562E",
    Orange: "#FF761B",
    Green: "#36B37F",
    Blue: "#2684FE",
    Yellow: "#FFA828",
  };

  const getDefaultCategories = () => {
    // call store / BE to get default tags
    return [
      {
        name: "Match",
        description: "This category is for matches of 2 or more transactions that cancel each other out",
      },
      {
        name: "Match with data quality issues",
        description: "This category is for matches of 2 or more transactions that cancel each other out that contain incomplete or inaccurate data",
      },
      {
        name: "Break",
        description: "This category is for excepted unmatched items",
      },
      {
        name: "Break with data quality issues",
        description: "This category is for excepted unmatched items with incomplete or inaccurate data",
      },
    ];
  };

  const addCategory = () => {
    // Call BE to add category
    setDraftCategory(initialCategoryObject);
    setCategories([...categories, draftCategory]);
  };

  const renderColourOption = (colour) => {
    return (
      <>
        <div
          style={{
            width: "20px",
            height: "20px",
            borderRadius: "100%",
            backgroundColor: categoryColours[colour],
            display: "inline-block",
            marginRight: "10px",
          }}
        ></div>
        <Text weight="regular" props="pb-8">
          {colour}
        </Text>
      </>
    );
  };

  return (
    <>
      <ModalHeader
        onCloseClick={() => {
          backToMenu();
        }}
        title="New Tags"
      />
      <div className="ml-72 mr-72 mt-48 text-left">
        <Text size="lg" weight="bold">
          Setup Categories
        </Text>
        <div className="mb-32">
          <Text weight="regular">Define the categories used to help classify the results of this reconciliation type</Text>
        </div>
        <Card>
          <R props="light-grey-bg">
            <C3 props="pt-16 pl-16 pr-16 pb-16">
              <Text weight="medium">Category Name</Text>
            </C3>
            <C4 props="pt-16 pl-16 pr-16 pb-16">
              <Text weight="medium">Category Description</Text>
            </C4>
            <C3 props="pt-16 pl-16 pr-16 pb-16">
              <Text weight="medium">Colour</Text>
            </C3>
          </R>
          <R>
            <C3 props="pt-16 pl-16 pr-16 pb-16">
              <Input
                onClick={() => {
                  setDropdownOpen(true);
                }}
                onChange={(event) => {
                  setDraftCategory({ ...draftCategory, name: event.target.value });
                }}
                value={draftCategory.name}
                placeholder="Add category name"
                warning={false}
                warningMessage=""
              />
              <div ref={dropdownRef} className={dropdownOpen ? "categories-names-dropdown show" : "categories-names-dropdown"}>
                <div className="categories-names-dropdown-item">
                  <Text variant="tertiary" weight="regular">
                    Select from predefined categories below or type to create your own
                  </Text>
                </div>
                {getDefaultCategories().map((category) => {
                  return (
                    <div
                      className="categories-names-dropdown-item"
                      onClick={() => {
                        setDraftCategory({ ...draftCategory, name: category.name, description: category.description });
                        setDropdownOpen(false);
                      }}
                    >
                      <Text weight="bold">{category.name}</Text>
                      <Text weight="regular">{`- ${category.description}`}</Text>
                    </div>
                  );
                })}
              </div>
            </C3>
            <C4 props="pt-16 pl-16 pr-16 pb-16">
              <Input
                onChange={(event) => {
                  setDraftCategory({ ...draftCategory, description: event.target.value });
                }}
                value={draftCategory.description}
                placeholder="Add category description"
                warning={false}
                warningMessage=""
              />
            </C4>
            <C3 props="pt-16 pl-16 pr-16 pb-16">
              <Dropdown>
                <DropdownButton size="sm">
                  <span>{draftCategory.colour ? renderColourOption(draftCategory.colour) : "Please select a color"}</span>
                  <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
                </DropdownButton>
                <DropdownList>
                  {Object.keys(categoryColours).map((colour, index) => {
                    return (
                      <DropdownListItem
                        onClick={() => {
                          setDraftCategory({ ...draftCategory, colour: colour });
                        }}
                        key={index + 1}
                      >
                        <DropdownItem active={null} index={0}>
                          {renderColourOption(colour)}
                        </DropdownItem>
                      </DropdownListItem>
                    );
                  })}
                </DropdownList>
              </Dropdown>
            </C3>
            <C2 props="pt-16 pl-16 pr-16 pb-16 text-right">
              <Button
                onClick={() => {
                  addCategory();
                }}
                size="md"
              >
                <IconAdd className="btn-md-svg" />
                <Text size="md">Add</Text>
              </Button>
            </C2>
          </R>
        </Card>
        <R props="mt-32 mb-16">
          <R props="pt-8 pb-8">
            <C6>
              <Text weight="medium">{`${categories.length} categories added`}</Text>
            </C6>
          </R>
          <Card>
            <R props="pt-8 pb-16">
              <C3>
                <Text weight="medium">Category Name</Text>
              </C3>
              <C5>
                <Text weight="medium">Category Description</Text>
              </C5>
              <C2>
                <Text weight="medium">Colour</Text>
              </C2>
              <C2>
                <Text weight="medium">Actions</Text>
              </C2>
            </R>
            {categories.map((categoryData) => {
              return (
                <R props="pt-8 pb-8">
                  <C3>
                    <Text weight="regular">{categoryData.name}</Text>
                  </C3>
                  <C5>
                    <Text weight="regular">{categoryData.description}</Text>
                  </C5>
                  <C2>{renderColourOption(categoryData.colour)}</C2>
                  <C2>
                    <VerticalMenuDropdown>
                      <VerticalMenuListItem text="Option" onClick={() => {}} />
                    </VerticalMenuDropdown>
                  </C2>
                </R>
              );
            })}
          </Card>
        </R>
      </div>

      <ModalFooter>
        <Button color="primary" onClick={() => {}}>
          <Text>Done</Text>
        </Button>
      </ModalFooter>
    </>
  );
});

export { SetupCategories };
