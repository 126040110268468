import { Text } from "@fundrecs/ui-library";
import { AgChartsReact } from "ag-charts-react";

const CategoryChart = ({ rowCounts, selectedCategory = null, updateSelectedCategory = () => {} }) => {
  let series = [];
  let data = { xKey: "" };
  let totalRowCount = 0;
  const categoryColours = {
    Break: { fill: "#F0142F", stroke: "#D4132B" },
    "Break with data quality issues": { fill: "#FF761B", stroke: "#D96315" },
    Match: { fill: "#15A251", stroke: "#0F793C" },
    "Match with data quality issues": { fill: "#74C05E", stroke: "#5E9E4C" },
  };

  rowCounts.forEach((rowCount) => {
    rowCount.widthPercentage = 5;
    if (rowCount.rowCount != null) {
      totalRowCount += rowCount.rowCount;
    }
  });

  const calculatePercentage = (rowCount) => {
    return Math.trunc((rowCount / totalRowCount) * 100);
  };

  // Each segment in the chart is given minimum 5% width.
  // Multiply the length by 5, subtract from 100 to find remaining precant width available
  // While there is still width available, iterate through the data,
  // if the portion of data represents a percentage greater than it's width, assign one more percentage of width
  let remainingWidthPercentage = 100 - rowCounts.length * 5;
  let index = 0;
  while (remainingWidthPercentage > 0) {
    let row = rowCounts[index];
    if (row) {
      const rowCountPercentage = calculatePercentage(row.rowCount);
      if (row.widthPercentage < rowCountPercentage) {
        row.widthPercentage += 1;
        remainingWidthPercentage -= 1;
      } else if (remainingWidthPercentage <= 2) {
        break;
      }
      index = index >= rowCounts.length - 1 ? 0 : index + 1;
    } else {
      break;
    }
  }

  const allColours = [
    { fill: "#2196F3", stroke: "#1D83D4" },
    { fill: "#895ECC", stroke: "#7751B3" },
    { fill: "#D333BE", stroke: "#B729A4" },
    { fill: "#FFA828", stroke: "#E09322" },
    { fill: "#FFE928", stroke: "#E0CD23" },
    { fill: "#00B8DA", stroke: "#03A1BE" },
    { fill: "#F0142F", stroke: "#D4132B" },
    { fill: "#FF761B", stroke: "#D96315" },
    { fill: "#15A251", stroke: "#0F793C" },
    { fill: "#74C05E", stroke: "#5E9E4C" },
  ];

  let colourIndex = 0;

  const updateColour = (categoryName, seriesObj, colourProfile) => {
    seriesObj.fill = colourProfile.fill;
    if (categoryName === selectedCategory) {
      seriesObj.stroke = colourProfile.stroke;
      seriesObj.strokeWidth = 5;
    }
  };

  if (rowCounts) {
    rowCounts.forEach((row, index) => {
      if (row.categoryName && !series.find((it) => it.yName === row.categoryName)) {
        const seriesObj = {
          stroke: "white",
          strokeWidth: 1,
          type: "bar",
          xKey: "xKey",
          yKey: row.categoryName,
          yName: row.categoryName,
          direction: "horizontal",
          stacked: true,
          label: {
            color: "#fff",
            formatter: (data) => {
              return `${row.rowCount} | ${calculatePercentage(row.rowCount)}%`;
            },
          },
          listeners: {
            nodeClick: () => {
              updateSelectedCategory(selectedCategory === row.categoryName ? null : row.categoryName);
            },
          },
          tooltip: {
            renderer: function ({ datum, xKey, yKey }) {
              return `
            <div class="ag-chart-tooltip-content">
                ${row.categoryName}:<b> ${row.rowCount} ${row.rowCount === 1 ? "row" : "rows"}</b> - ${calculatePercentage(row.rowCount)}%
            </div>`;
            },
          },
          highlightStyle: {
            item: { fill: "lightgray", fillOpacity: 0 },
          },
        };

        if (categoryColours[row.categoryName]) {
          updateColour(row.categoryName, seriesObj, categoryColours[row.categoryName]);
        } else {
          updateColour(row.categoryName, seriesObj, allColours[colourIndex]);
          colourIndex = colourIndex >= allColours.length - 1 ? 0 : colourIndex + 1;
        }
        series.push(seriesObj);
      }
      data[row.categoryName] = row.widthPercentage;
    });
  }

  const chartOptions = {
    axes: [
      {
        type: "number",
        position: "bottom",
        label: { enabled: false },
        //thickness: 0,
        //line: { enabled: false },
        //gridLine: { enabled: false, style: { strokeWidth: "0px" } },
        tick: { enabled: false },
      },
      {
        type: "category",
        position: "left",
        tick: { enabled: false },
      },
    ],
    data: [data],
    series: series,

    legend: {
      position: "top",
      maxHeight: 100,
      item: {
        paddingX: 40,
        marker: {
          padding: 5,
          size: 10,
          strokeWidth: 0,
          shape: "circle",
        },
        label: {
          fontFamily: "Muli Bold",
          fontSize: "14px",
          color: "#394561",
        },
      },
    },
  };

  return (
    <div
      style={{
        overflowY: "hidden",
        height: "150px",
        borderRadius: "12px",
        border: "1px solid #CDD0D7",
        background: "#fff",
        paddingTop: "8px",
        marginTop: "8px",
      }}
    >
      <div className="ml-24">
        <Text size="sm" weight="bold">{`${totalRowCount} rows processed`}</Text>
      </div>
      <AgChartsReact options={chartOptions} />
    </div>
  );
};

export { CategoryChart };
