import { observer } from "mobx-react-lite";
import { Text, Toggle, R, C1, C8, IconPencil } from "@fundrecs/ui-library";
import { useStore } from "../../store/Store";

import { stringifyDate } from "../../utils/dates";

const DisplayAccountAutoCompleteConfig = observer(
  ({ recTypeAutocompleteEnabled, autoComplete, setEditing, teamId, accountId, accountVersion, recTypeId, updateAccountConfig, recStartDate }) => {
    const { accountStore } = useStore();

    const disableAutoComplete = async () => {
      if (autoComplete !== null) {
        const resp = await accountStore.disableAutoCompleteRule(teamId, accountId, accountVersion, recTypeId);
        if (resp.status === 200) {
          updateAccountConfig(resp.data);
        }
      }
    };

    const renderDays = (days) => {
      let daysString = "";
      if (days && days.length) {
        const daysArray = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
        const daysIndexMap = { MON: 0, TUE: 1, WED: 2, THU: 3, FRI: 4, SAT: 5, SUN: 6 };
        const dayDataType = typeof days[0];
        const dayIndexArray = dayDataType === "string" ? [] : days;
        if (dayDataType === "string") {
          days.forEach((day) => {
            const dayIndex = daysIndexMap[day];
            if (![undefined, null].includes(dayIndex)) {
              dayIndexArray.push(dayIndex);
            }
          });
        }
        dayIndexArray.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0));
        dayIndexArray.forEach((dayIndex, index) => {
          daysString += `${daysArray[dayIndex]}${index >= dayIndexArray.length - 1 ? "" : ","} `;
        });
      }
      return daysString;
    };

    const renderNumber = (number) => {
      return number < 10 ? `0${number}` : number;
    };

    const convertUTCToLocalTime = (autoCompleteScheduler) => {
      const newDate = new Date();
      newDate.setUTCHours(autoCompleteScheduler.hour);
      newDate.setUTCMinutes(autoCompleteScheduler.minsPast);
      return `${renderNumber(newDate.getHours())}:${renderNumber(newDate.getMinutes())}`;
    };
    return (
      <>
        <div className="d-flex mt-16 pb-16">
          <Toggle disabled={!recTypeAutocompleteEnabled} checked={autoComplete !== null} onChange={disableAutoComplete} />
          <div className="ml-16">
            <Text size="xs" weight="bold">
              Autocomplete:
            </Text>
            <Text size="xs" weight="bold" variant={true ? "success" : "error"}>
              {true ? " Enabled" : " Disabled"}
            </Text>
            <div>
              <Text size="xs" weight="regular" variant="tertiary">
                When enabled a reconciliation will be allowed to autocomplete if the chosen rules are met
              </Text>
            </div>
          </div>
        </div>
        <div className="mt-16 pb-16">
          <div className="ml-32 pl-16">
            <div className="ml-16 d-flex justify-content-between">
              <div>
                <Text size="xs" weight="bold">
                  Autocomplete settings
                </Text>
              </div>
              <div>
                {true ? (
                  ""
                ) : (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setEditing(true);
                    }}
                  >
                    <IconPencil className="btn-md-svg" />
                  </span>
                )}
              </div>
            </div>
            <R props="mt-8">
              <C1>
                <Text size="xs" weight="regular">
                  Rule:
                </Text>
              </C1>
              <C8>
                <Text size="xs" weight="regular">
                  {autoComplete.name}
                </Text>
              </C8>
            </R>
            <R>
              <C1>
                <Text size="xs" weight="regular">
                  Schedule:
                </Text>
              </C1>
              <C8>
                <Text size="xs" weight="regular">
                  {autoComplete.scheduler
                    ? `Every ${renderDays(autoComplete.scheduler.daysOfTheWeek)} at ${convertUTCToLocalTime(autoComplete.scheduler)}`
                    : "Automated (run after matching rules)"}
                </Text>
              </C8>
            </R>
            {autoComplete.scheduler ? (
              <R>
                <C1>
                  <Text size="xs" weight="regular">
                    Target:
                  </Text>
                </C1>
                <C8>
                  <Text size="xs" weight="regular">
                    {autoComplete.scheduler && autoComplete.scheduler.target === "OLDEST_OPEN"
                      ? `Oldest open rec${recStartDate ? ` from ${stringifyDate(recStartDate)}` : ""}`
                      : ""}
                  </Text>
                </C8>
              </R>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="ml-32 pl-32"></div>
      </>
    );
  }
);

export { DisplayAccountAutoCompleteConfig };
