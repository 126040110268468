import { useContext} from 'react'
import { Text, Dropdown, DropdownButton, IconDownArrow, DropdownList, DropdownListItem, DropdownItem, ItemBody, R, C2, C10 } from "@fundrecs/ui-library";
import { useStore } from "../../../../store/Store";
import { observer } from "mobx-react-lite";
import { MatchingContext, MatchingDispatchContext, SET_AGGREGATORS, SET_PROCEED_AS_ROWS } from '../../matchingReducer';

const AggregatorPanel = observer(({}) => {
  const INDIVIDUAL = "individual rows";
  const FLATTENED = "flattened row";
  const matchOptions = useContext(MatchingContext);
  const dispatch = useContext(MatchingDispatchContext);

  return (
    <>
      {[INDIVIDUAL, FLATTENED].map((text, index) => {
        const thisOptionSelected = (text === INDIVIDUAL && matchOptions.proceedAsRows) || (text === FLATTENED && !matchOptions.proceedAsRows) ? true : false;
        return (
          <R key={index}>
            <span style={{ background: thisOptionSelected ? "#F7F8F9" : "" }}>
              <R props="pt-16 pb-16">
                <span
                  onClick={() => {
                    dispatch({ type: SET_PROCEED_AS_ROWS, proceedAsRows: !matchOptions.proceedAsRows });
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <R>
                    <C2>
                      <input type="radio" checked={thisOptionSelected} readOnly={true} />
                    </C2>
                    <C10>
                      <Text weight="bold" size="xs">
                        {`Submit as ${text}`}
                      </Text>
                    </C10>
                  </R>
                </span>
                {thisOptionSelected ? (
                  <AggregatorDropdowns
                  />
                ) : (
                  ""
                )}
              </R>
            </span>
          </R>
        );
      })}
    </>
  );
});

const AggregatorDropdowns = observer(({}) => {
  const { tmoAggregatorStore, recTypeStore } = useStore();
  const allAggregators = tmoAggregatorStore.getAggregators();
  const tmos = recTypeStore.getRecTypeTmos();
  const dispatch = useContext(MatchingDispatchContext);
  const aggregators = useContext(MatchingContext)["aggregators"];

  return (
    <>
      {tmos.map((tmo, index) => {
        return (
          <span key={index}>
            <R props="mt-32 mb-16">
              <Text weight="bold" size="xs">
                {`Select ${tmo.name} Aggregator`}
              </Text>
              <Dropdown>
                <DropdownButton disabled={false} size="sm">
                  {aggregators && aggregators[tmo.id] ? aggregators[tmo.id]["name"] : "Select an aggregator"}
                  <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
                </DropdownButton>

                <DropdownList>
                  {allAggregators
                    .filter((it) => it.tmoId === tmo.id)
                    .map((aggregator, index) => {
                      return (
                        <DropdownListItem
                          key={Math.random()}
                          onClick={() => {
                            dispatch({ type: SET_AGGREGATORS, aggregators: { ...aggregators, [tmo.id]: aggregator }});
                          }}
                        >
                          <DropdownItem active={false} index={index}>
                            <ItemBody>{aggregator.name}</ItemBody>
                          </DropdownItem>
                        </DropdownListItem>
                      );
                    })}
                </DropdownList>
              </Dropdown>
            </R>
          </span>
        );
      })}
    </>
  );
});

export { AggregatorPanel };
