import { useState } from "react";
import { observer } from "mobx-react-lite";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./reconciliations-calendar.scss";
import { Text, Button } from "@fundrecs/ui-library";
import { STATUS } from "../../../utils/enums";
import { stringifyDate } from "../../../utils/dates";

const RecsCalendar = observer(({ selectRec, selectedDate, selectedAccount = null, recList, updateCalendar, setCalendarStartDate = () => {} }) => {
  const [calendarKey, setCalendarKey] = useState(0);

  const getStyledTile = (date) => {
    let style = { margin: "auto", height: "30px", width: "30px", padding: "6px", borderRadius: "8px", fontWeight: 700 };
    let className = "date-tile";
    const recFound = recList.find((rec) => stringifyDate(new Date(rec.startDate)) === stringifyDate(date));
    if (recFound) {
      style = { ...style, color: STATUS[recFound.fourEyes.status]["color"], backgroundColor: STATUS[recFound.fourEyes.status]["backgroundColor"] };
      if (recFound.fourEyes.status === STATUS.SKIPPED.status) {
        style = { ...style, textDecoration: "line-through" };
      }
      className = `${recFound.fourEyes.status}-date-tile`;
    }

    return (
      <div className={className} style={style}>
        {date.getDate()}
      </div>
    );
  };

  return (
    <div className="calendar-container">
      <Calendar
        key={calendarKey}
        onActiveStartDateChange={(value) => {
          setCalendarStartDate(value.activeStartDate);
          updateCalendar(value.activeStartDate, selectedAccount.uuid);
        }}
        onChange={(date) => selectRec(date, selectedAccount.uuid)}
        value={selectedDate}
        tileClassName={({ date, view }) => {}}
        formatDay={() => {
          return "";
        }}
        tileContent={({ activeStartDate, date, view }) => {
          if (view === "month") {
            return getStyledTile(date);
          }
        }}
      />
      <span className="today-button-container">
        <Button
          size="md"
          onClick={() => {
            setCalendarKey(calendarKey + 1);
            updateCalendar(new Date(), selectedAccount.uuid, new Date());
          }}
          color="primary-secondary"
        >
          <Text size="sm" weight="medium">
            Today
          </Text>
        </Button>
      </span>
    </div>
  );
});

export { RecsCalendar };
