import { Routes, Route } from "react-router-dom";
import { ApmRoutes } from "@elastic/apm-rum-react";
import { Reconciliations } from "./reconciliations/Reconciliations";
import { RecTypeDash } from "./recType/RecTypeDashboard/RecTypeDash";
import { ReconciliationTypes } from "./recType/ReconciliationTypes";
import { RecTypeConfigLayout } from "./recType/recTypeConfig/RecTypeConfigLayout";
import { PATH } from "../utils/urls";
import { Accounts } from "./accounts/Accounts";
import { AccountConfig } from "./accounts/AccountConfig/AccountConfig";
import { SubAccounts } from "./subAccounts/SubAccounts";
import { MatchingRuleBuilder } from "./recType/MatchingRules/matchingRuleBuilder/MatchingRuleBuilder";
import { Uploads } from "./uploads/Uploads";
import { UploadedFile } from "./uploads/UploadedFile";
import { ImportErrors } from "./uploads/ImportErrors";
import { RecTypesConfigDash } from "./recType/recTypeConfig/RecTypesConfigDash";
import { GlobalMappings } from "./globalMapping/GlobalMappings";
import { RecAuditTrail } from "./reconciliations/RecAuditTrail";

const FusionRecsRoutes = () => {
  return (
    <Routes>
      <ApmRoutes>
        <Route path="/" element={<ReconciliationTypes />} />
        <Route path={`${PATH.REC_TYPE_DASH}`} element={<RecTypeDash />} />
        <Route path={`${PATH.REC_TYPE_CONFIG_DASH}`} element={<RecTypesConfigDash />} />
        <Route path={`${PATH.REC_TYPE_CONFIG}`} element={<RecTypeConfigLayout />} />
        <Route path={`${PATH.REC}`} element={<Reconciliations />} />
        <Route path={`${PATH.REC_AUDIT_TRAIL}`} element={<RecAuditTrail />} />
        <Route path={`${PATH.REC_TYPES}`} element={<ReconciliationTypes />} />
        <Route path={`${PATH.FUND}`} element={<Accounts />} />
        <Route path={`${PATH.FUND_DETAILS}`} element={<AccountConfig />} />
        <Route path={`${PATH.SUB_ACCOUNT}`} element={<SubAccounts />} />
        <Route path={`${PATH.MATCHING_RULE_BUILDER}`} element={<MatchingRuleBuilder />} />
        <Route path={`${PATH.UPLOADS}`} element={<Uploads />} />
        <Route path={`${PATH.UPLOADED_FILE}`} element={<UploadedFile />} />
        <Route path={`${PATH.UPLOADED_FILE_ERRORS}`} element={<ImportErrors />} />
        <Route path={`${PATH.GLOBAL_MAPPINGS}`} element={<GlobalMappings />} />
      </ApmRoutes>
    </Routes>
  );
};

export { FusionRecsRoutes };
