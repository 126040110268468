import { useState } from "react";
import { observer } from "mobx-react-lite";
import { Modal, ModalBody, modalInstance, Button, Text, ModalFooter, R, DatePicker, TextArea } from "@fundrecs/ui-library";
import { MODALS } from "../../../utils/enums";
import { ModalHeader } from "../../layout/Modals";
import { useStore } from "../../../store/Store";

const SetAsDayOneModal = observer(({ teamId, recType, initialDate, selectedFund, refreshDashboard }) => {
  const { meStore, recStore } = useStore();
  const [selectedDate, setSelectedDate] = useState(null);
  const modalId = MODALS.SET_AS_DAY_ONE;

  const closeModal = () => {
    setSelectedDate(null);
    modalInstance(modalId).hide();
  };

  const setAsDayOne = async () => {
    const body = { accountId: selectedFund.id, recTypeId: recType.id, date: selectedDate ? selectedDate : initialDate };
    const resp = await recStore.setAsDayOne(teamId, body);
    if (resp.status === 200) {
      refreshDashboard()
    }
    closeModal();
  };

  return (
    <Modal modalId={modalId}>
      <ModalBody>
        <ModalHeader
          title="Set as day one"
          onCloseClick={() => {
            closeModal();
          }}
        />
      </ModalBody>
      <div style={{ paddingLeft: "1em", paddingRight: "1em" }}>
        <R>
          <Text size="sm" weight="regular">
            Move the start date for reconciliations for a fund to the selected date
          </Text>
        </R>
        <R>
          <span className="mt-32"></span>
          <Text size="sm" weight="regular">
            When you select this date, the following will happen:
          </Text>
        </R>
        <div className="d-flex mt-16 ml-16">
          <Text size="sm" weight="bold" element="div">
            1.
          </Text>
          <span className="ml-8"></span>
          <Text size="sm" weight="regular">
            <b>Previous reconciliations will be undone:</b> Any reconciliations created for this fund before the selected date will be undone up until the last
            completed reconciliation.
          </Text>
        </div>
        <div className="d-flex ml-16">
          <Text size="sm" weight="bold" element="div">
            2.
          </Text>
          <span className="ml-8"></span>
          <Text size="sm" weight="regular">
            <b>No data will be carried forward:</b> Data from any previous dates will not be carried forward.
          </Text>
        </div>
        <div className="d-flex pb-16 ml-16" style={{ borderBottom: "1px solid #efefef" }}>
          <Text size="sm" weight="bold" element="div">
            3.
          </Text>
          <span className="ml-8"></span>
          <Text size="sm" weight="regular">
            <b>Previous dates will become cancelled:</b> Existing data will remain in place but new data cannot be uploaded to previous dates.
          </Text>
        </div>
        <div className="ml-16 pt-32 mb-16">
          <Text size="sm" weight="regular" element="div">
            Selected fund:
          </Text>
          <Text size="sm" weight="bold">
            {selectedFund ? selectedFund.name : ""}
          </Text>
        </div>
        <div className="ml-16 mb-16">
          <Text size="sm" weight="regular" element="div">
            Selected date:
          </Text>
          <DatePicker
            date={selectedDate ? selectedDate : initialDate}
            onDateChange={(date) => setSelectedDate(date)}
            dateFormat={meStore.getUserDateFormat()}
          />
        </div>
      </div>
      <ModalFooter>
        <Button
          color="tertiary"
          onClick={() => {
            closeModal();
          }}
        >
          <Text>Cancel</Text>
        </Button>
        <Button
          color="primary"
          onClick={() => {
            setAsDayOne();
          }}
        >
          <Text>OK</Text>
        </Button>
      </ModalFooter>
    </Modal>
  );
});

export { SetAsDayOneModal };
