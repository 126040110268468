import { observer } from "mobx-react-lite";
import { Modal, R, C8, C2, C4, C6, modalInstance, Button, Text, IconCloseRemove, IconAlertMessage } from "@fundrecs/ui-library";
import { MODALS } from "../utils/enums";

const WarningModal = observer(({ modalId = MODALS.WARNING, title, description, buttonClick, buttonText, children }) => {
  return (
    <Modal modalId={modalId}>
      <R props="ml-8 mt-16 mb-8 mr-8">
        <C2>
          <div style={{ width: "40px", height: "40px", borderRadius: "100%", background: "#FDE7EA", paddingTop: "6px", paddingLeft: "8px" }}>
            <IconAlertMessage color="#F0142F" />
          </div>
        </C2>

        <C8>
          <Text size="md" weight="bold">
            {title ? title : "Warning"}
          </Text>
        </C8>
        <C2 props="pl-32">
          <IconCloseRemove
            className={"btn-lg-svg"}
            onClick={() => {
              modalInstance(modalId).toggle();
            }}
          />
        </C2>
      </R>
      <R props="ml-32 mt-8 mb-8 mr-32">
        <C2></C2>
        <C8 props="pl-0">{description}</C8>
      </R>
      {children}
      <span style={{ background: "#F7F8F9", borderRadius: "0 0 0.75rem 0.75rem" }}>
        <div className="d-flex justify-content-end ml-16 mt-16 mb-16 mr-16">
          <Button
            color="tertiary"
            onClick={() => {
              modalInstance(modalId).toggle();
            }}
          >
            <Text weight="regular" size="sm">
              Cancel
            </Text>
          </Button>

          <span className="ml-16"></span>
          <Button
            color="danger"
            onClick={() => {
              buttonClick();
            }}
          >
            <Text weight="regular" size="sm">
              {buttonText}
            </Text>
          </Button>
        </div>
      </span>
    </Modal>
  );
});

export { WarningModal };
