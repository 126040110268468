import { observer } from "mobx-react-lite";
import { useSearchParams } from "react-router-dom";
import { R, Text, Button, Heading } from "@fundrecs/ui-library";
import { StickyFooter } from "../../layout/StickyFooter";
import { FundIdentifiersTable } from "../FundIdentifiersTable";

const AddIdentifiersToAccount = observer(({ account, close, openNextStep }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = searchParams.get("teamId");

  return (
    <>
      <R props="mb-32 pb-32">
        <Heading variant="h3">Add fund identifiers</Heading>

        <Text weight="regular" size="sm" variant="secondary">
          Add fund identifiers for each enabled reconciliation type. Fund identifiers are any aliases used to identify a fund within the internal (admin) data
          The primary fund identifier has already been added. Additional fund identifiers can also be added later.
        </Text>
      </R>
      <R>
        <FundIdentifiersTable account={account} teamId={teamId} />
      </R>
      <R>
        <StickyFooter>
          <Button
            onClick={() => {
              openNextStep();
            }}
            size="md"
            disabled={false}
          >
            <Text size="sm">Next</Text>
          </Button>
        </StickyFooter>
      </R>
    </>
  );
});

export { AddIdentifiersToAccount };
