import { useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { R, C2, LinkText, DatePicker, HyperLink } from "@fundrecs/ui-library";
import { Table } from "../ag-grid/Ag-grid";
import { convertToDate, stringifyDate } from "../../utils/dates.js";
import { MainContainer, ManageLayout, PageTitleArea } from "../layout/Layout";
import { useStore } from "../../store/Store.js";
import { PATH } from "../../utils/urls";
import { formatRowData } from "./uploadedFileLogic";
import { StatusBadge } from "../reusable/StatusBadge";
import { DownloadTableButton } from "../reusable/DownloadTableButton";

/**
 * Uploads file page
 */
const Uploads = observer(({}) => {
  const gridRef = useRef(null);
  const navigate = useNavigate();
  const { teamStore, uploadedFileStore, tenantConfigStore, meStore } = useStore();
  const [initialised, setInitialised] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const date = searchParams.get("date") ? convertToDate(searchParams.get("date")) : new Date();
  const teamId = searchParams.get("teamId");
  const fusionDataUrl = tenantConfigStore.getFusionDataUrl();

  /**
   * Get the files for the selected date
   * nb. As the dates are saved in UTC, the dates in the request are sent in UTC using .toISOString(), this accounts for different timezones.
   *     eg. A file uploaded at 3am on the 2nd in NY will be at 11pm on the 1st in UTC.
   *     The query therefore must be in UTC to return the files the user expects relative to their location
   * @param {*} newDate
   */
  const selectDate = async (newDate) => {
    if (rowData !== null) {
      setRowData(null);
    }
    //Create a timestamp for the start and end of the same day
    const dateFrom = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 0, 0, 0, 0);
    const dateTill = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 23, 59, 59, 999);
    const requestBody = {
      type: "findByDateObjRequestBody",
      dateFrom: dateFrom.toISOString(),
      dateTill: dateTill.toISOString(),
    };
    navigate(`${PATH.UPLOADS}?teamId=${teamStore.getSelectedTeam().id}&date=${stringifyDate(newDate)}`, {
      replace: true,
    });

    const response = await uploadedFileStore.getUploadedFilesByDate(teamId, requestBody);
    if (response.status === 200) {
      setRowData(response.data.map((fileData) => formatRowData(fileData)));
    }

    const response2 = await uploadedFileStore.getUploadedFileRowCountsByDate(teamId, requestBody);
    if (response2.status === 200) {
      setRowData(response.data.map((fileData) => formatRowData({ ...fileData, ...response2.data.find((it) => it.fileId === fileData.fileId) })));
    }
  };

  if (!initialised && date) {
    selectDate(date);
    setInitialised(true);
  }

  const columnDefs = [
    {
      headerName: "Status",
      field: "fileStatus",
      cellRenderer: (params) => {
        return <StatusBadge status={params.data.fileStatus} uploadedFileStatus={true} />;
      },
    },
    { headerName: "Reconciliation type", field: "recTypeName" },
    {
      headerName: "File name",
      field: "fileName",
      cellRenderer: (params) => {
        return (
          <Link className="text-decoration-none" to={`${PATH.UPLOADED_FILE}?teamId=${teamStore.getSelectedTeam().id}&fileId=${params.data.fileId}`}>
            <LinkText>{params.data.fileName}</LinkText>
          </Link>
        );
      },
    },
    {
      headerName: "Date uploaded",
      field: "startDateFormatted",
    },
    {
      headerName: "Time uploaded",
      field: "startTimeFormatted",
      sort: "desc",
    },
    {
      headerName: "Rec Date",
      field: "recStartDate",
      cellRenderer: (params) => {
        return <span>{params.data.recStartDate ? stringifyDate(new Date(params.data.recStartDate)) : ""}</span>;
      },
    },
    { headerName: "Total row count", field: "totalRowCount" },
    { headerName: "Imported row count", field: "importedRowCount" },
    { headerName: "Import errors", field: "importErrorCount" },
    { headerName: "Upload time (secs)", field: "timeTaken" },
    {
      headerName: "Workflow name",
      field: "workflowName",
      cellRenderer: (params) => {
        return (
          <HyperLink href={`${fusionDataUrl}${PATH.WORKFLOWS}/${params.data.workflowId}?teamId=${teamId}`}>
            <LinkText>{params.data.workflowName}</LinkText>
          </HyperLink>
        );
      },
    },
    { headerName: "Template applied", field: "templateName" },
    { headerName: "Uploaded by", field: "uploadedBy" },
    { headerName: "Source", field: "source" },

    {
      field: "expandRow",
      width: 50,
      maxWidth: 50,
      cellRenderer: (params) => {
        return ""; //<VerticalMenu teamId={teamStore.getSelectedTeam().id} menuItems={menuItems} onItemClick={onItemClick} />;
      },
    },
  ];

  const createFileName = () => {
    const fileName = `Uploaded files for ${teamStore.getSelectedTeam().name} on ${stringifyDate(date)}`;
    const fullStopsRemoved = fileName.replaceAll(".", "");
    return fullStopsRemoved;
  };

  return (
    <MainContainer>
      <PageTitleArea
        backButtonEnabled={false}
        title="Uploads"
        description="All uploaded data imported to Reconciliations"
        borderBottom={false}
        props={<DownloadTableButton gridRef={gridRef} createFileName={createFileName} />}
      />
      <ManageLayout
        mainContentToolbar={
          <R props="pt-24 pb-16">
            <C2 props="pl-32">
              <DatePicker onDateChange={selectDate} date={date} dateFormat={meStore.getUserDateFormat()} />
            </C2>
          </R>
        }
        panelHeader={<></>}
        pageTitleArea={<></>}
      >
        <Table columnDefs={columnDefs} rowData={rowData} ref={gridRef} rowSelection="single" updateSelected={() => {}} />
      </ManageLayout>
    </MainContainer>
  );
});

export { Uploads };
