const nodeChildren = (params) => {
  return params.node.allLeafChildren;
};

const getCountForParam = (childRows, validParam) => {
  let count = 0;
  childRows.forEach((row) => {
    if (row.data[validParam]) {
      count++;
    }
  });
  return count;
};

/**
 * Returns the header name with the correct count of grouped rows
 * isValid checks that there are children in the group, there will always be at least one, as the group itself is a row in the data.
 * Therefore isValidParam is used to check that the first row contains data
 * @param {*} params
 * @param {*} headerName
 * @param {*} isValidParam
 * @returns
 */

const renderHeaderWithCount = (params, headerName, validParam) => {
  const childRows = nodeChildren(params);
  let count = !params.data && childRows.length ? getCountForParam(childRows, validParam) : 0;
  return `${childRows[0].data[headerName]} (${count})`;
};

export { renderHeaderWithCount, nodeChildren };
