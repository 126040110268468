import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useSearchParams } from "react-router-dom";
import { Text, R, C3, C4, C8, C10, IconDelete, Circle, modalInstance } from "@fundrecs/ui-library";
import { useStore } from "../../../store/Store.js";
import { getDayAndTimeString } from "../../../utils/dates.js";
import { ReactComponent as ReorderIcon } from "../../../icons/reorder.svg";
import { BuildFunctionDescription } from "../../rules/FunctionEditor.js";
import { AuthWrapper } from "../../AuthorizationWrapper.js";
import { AUTHORITIES, MODALS } from "../../../utils/enums.js";
import { StatusBadge } from "../../reusable/StatusBadge.js";
import { WarningModal } from "../../WarningModal.js";

const DvRulesList = observer(({}) => {
  const { recTypeStore, dataVerificationStore, comparatorFunctionStore, rolesStore } = useStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const [ruleToDelete, setRuleToDelete] = useState(null);
  const teamId = searchParams.get("teamId");
  const dvRules = dataVerificationStore.getDvRulesForRecType();
  const selectedRecType = recTypeStore.getSelectedRecType();
  const comparatorFunctions = comparatorFunctionStore.getFunctions();
  const tmos = recTypeStore.getRecTypeTmos();

  const deleteDvRule = async (teamId, ruleId, version) => {
    const resp = await dataVerificationStore.deleteDvRule(teamId, ruleId, version);
    if (resp.status === 200) {
      dataVerificationStore.fetchDvRulesForRecType(teamId, selectedRecType.id);
      modalInstance(MODALS.WARNING).hide();
      setRuleToDelete(null);
    }
  };

  const cardStyle = {
    padding: "32px",
    paddingLeft: "16px",
    marginBottom: "16px",
    border: "1px solid #CDD0D7",
    borderRadius: "12px",
    background: "#fff",
  };

  return (
    <>
      <WarningModal
        title={"Are you sure you want to delete this rule?"}
        buttonClick={() => {
          deleteDvRule(teamId, ruleToDelete.id, ruleToDelete.version);
        }}
        buttonText="Delete rule"
      >
        <div className="pl-32 pr-32 pb-32">
          <Text size="sm" weight="bold">
            {ruleToDelete ? ruleToDelete.name : ""}
          </Text>
          <div className="mt-16"></div>
          <Text size="sm" weight="regular">
            This action will mean this rule will no longer be availabale to run on incoming data for funds/ sub accounts with this reconciliation type enabled.
            This action is <b>permanent</b> and cannot be reversed
          </Text>
        </div>
      </WarningModal>
      {dvRules.map((rule, ruleIndex) => {
        const tmo = tmos.find((it) => it.id === rule.tmoId);
        return (
          <div style={cardStyle}>
            <R>
              <span style={{ width: "40px", marginRight: "16px" }}>
                <ReorderIcon />
              </span>
              <Circle number={ruleIndex + 1} />

              <C8>
                <R>
                  <C10>
                    <Text>{rule.name}</Text>
                    <span className="ml-32"></span>
                    <StatusBadge status={rule.state.status} />
                  </C10>
                </R>
                <R>
                  <Text weight="regular" size="sm">
                    {tmo ? `When any ${tmo.name} data is available` : ""}
                  </Text>
                </R>
                {rule.conditions.map((condition, index) => {
                  const comparatorFunction = comparatorFunctions.find((it) => it.function === condition.function);
                  return (
                    <R>
                      <C10>{comparatorFunction ? BuildFunctionDescription(index, tmo, condition, comparatorFunction.description) : ""}</C10>
                    </R>
                  );
                })}
                <R>
                  <C10>
                    <Text weight="regular" size="sm">
                      {`Then tag as `}
                    </Text>
                    <Text weight="bold" size="sm">
                      {rule.actions && rule.actions.length === 1 && rule.actions[0]["noteText"] ? rule.actions[0]["noteText"] : ""}
                    </Text>
                  </C10>
                </R>
                <R props="mt-16">
                  <Text weight="regular" size="sm">{`Created at ${getDayAndTimeString(new Date(rule.createdBy.timeStamp))}`}</Text>
                </R>
              </C8>
              <C3>
                <R>
                  <C4>
                    <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_DATA_VERIFICATION_DELETE])}>
                      <IconDelete
                        className="btn-sm-svg"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setRuleToDelete(rule);
                          modalInstance(MODALS.WARNING).show();
                        }}
                      />
                    </AuthWrapper>
                  </C4>
                  <C4></C4>
                  <C4></C4>
                </R>
              </C3>
            </R>
          </div>
        );
      })}
      <Text size="sm" weight="regular">
        Data verification rules will run in order across all enabled funds and sub accounts. Verification rules will run before matching rules.
      </Text>
    </>
  );
});

export { DvRulesList };
