import { useState, useRef } from "react";
import { observer } from "mobx-react-lite";
import { useSearchParams } from "react-router-dom";
import { modalInstance, R, Text, Button, Heading, C10, C2 } from "@fundrecs/ui-library";
import { MODALS } from "../../../utils/enums";
import { Table } from "../../ag-grid/Ag-grid";
import { useStore } from "../../../store/Store";
import { VerticalMenu } from "../../reusable/VerticalMenu/VerticalMenu";
import { ModalFooterSticky } from "../../layout/Modals";

const AddCodesToSubAccount = observer(({ recTypeRows, refreshRows, close }) => {
  const gridRef = useRef(null);
  const { recTypeStore } = useStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = searchParams.get("teamId");
  const [initialised, setInitialised] = useState(false);

  const rowTypeIdentifier = (recTypeRow, subAccountCodeRow, data) => {
    let row = "";
    if (data.recTypeId && !data.subAccountCode) {
      row = recTypeRow;
    } else if (data.recTypeId && data.subAccountCode) {
      row = subAccountCodeRow;
    }
    return row;
  };

  const boldText = (text) => {
    return <span style={{ fontWeight: 600 }}>{text}</span>;
  };

  const mutedText = (text) => {
    return <span style={{ color: "#838B9C" }}>{text}</span>;
  };

  const cols = [
    {
      headerName: "Reconciliation Type",
      field: "recTypeName",
      cellRenderer: (params) => {
        const recTypeName = boldText(params.data.recTypeName);
        const mutedRecTypeName = mutedText(params.data.recTypeName);
        return rowTypeIdentifier(recTypeName, mutedRecTypeName, params.data);
      },
    },
    {
      headerName: "Sub account code",
      field: "subAccountCode",
      cellRenderer: (params) => {
        const subAccountCode = boldText(params.data.subAccountCode);
        const mutedSubAccountCode = mutedText(params.data.subAccountCode);
        return rowTypeIdentifier(mutedSubAccountCode, subAccountCode, params.data);
      },
    },
    {
      resizable: false,
      headerName: "Actions",
      field: "actions",
      width: 100,
      maxWidth: 100,
      cellRenderer: (params) => {
        const subAccountCodeMenu = (
          <div style={{ width: "25px" }}>
            <VerticalMenu teamId={teamId} data={params.data} getMenuItemsFromRowData={getMenuItems} onItemClick={onItemClick} />
          </div>
        );
        return rowTypeIdentifier("", subAccountCodeMenu, params.data);
      },
    },
  ];

  const deleteCode = async (optionData) => {
    const response = await recTypeStore.deleteAlias(teamId, optionData.recTypeId, optionData.subAccountCodeId, "SUB_ACCOUNT");
    if (response.status === 200) {
      refreshRows();
    }
  };

  const onItemClick = (option, optionData) => {
    const options = {
      deleteCode: () => {
        deleteCode(optionData);
      },
    };
    options[option]();
  };

  const getMenuItems = ({ teamId, data }) => {
    const menuItems = [{ key: "deleteCode", label: "Delete", disabled: false, visible: true, optionData: {} }];
    return menuItems.map((menuItem) => {
      menuItem.optionData = { teamId: teamId, recTypeId: data.recTypeId, subAccountCodeId: data.subAccountCodeId };
      return menuItem;
    });
  };

  if (!initialised) {
    refreshRows();
    setInitialised(true);
  }

  return (
    <>
      <R props="mb-32 mb-32">
        <C10>
          <Heading variant="h3">Add sub account codes</Heading>
          <Text weight="regular" size="sm" variant="secondary">
            Add sub account codes for the selected sub account. Sub account codes are any aliases used to identify a sub account within the external (broker)
            data.
          </Text>
        </C10>
        <C2></C2>
      </R>
      <div className="d-flex flex-row-reverse pb-32">
        <Button
          variant="primary"
          onClick={() => {
            modalInstance(MODALS.CREATE_SUB_ACCOUNT_CODE).show();
          }}
        >
          <Text>Add sub account code</Text>
        </Button>
      </div>
      <R>
        <Table columnDefs={cols} rowData={recTypeRows} ref={gridRef} colFlex={true} height="40vh" />
      </R>
      <R>
        <ModalFooterSticky>
          <Button
            onClick={() => {
              close();
            }}
            size="md"
            disabled={false}
          >
            <Text size="sm">Done</Text>
          </Button>
        </ModalFooterSticky>
      </R>
    </>
  );
});

export { AddCodesToSubAccount };
