import { useRef } from "react";
import { observer } from "mobx-react-lite";
import { PanelBody, PanelFooter, Heading, Text, IconCheck } from "@fundrecs/ui-library";
import { AUTHORITIES, MODALS } from "../../utils/enums";
import { Table } from "../ag-grid/Ag-grid";
import { VerticalMenu } from "../reusable/VerticalMenu/VerticalMenu";
import { useStore } from "../../store/Store";
import { SlideOverPanel } from "../reusable/SlideOverPanel/SlideOverPanel";
import { isUserAuthorized } from "../AuthorizationWrapper";

const ColumnViewPanel = observer(({ rowViewProfiles, rowGroupViewProfiles, refreshRows, resetColumnSettings }) => {
  const unmatchedText = "Unprocessed";
  const processedText = "Processed";
  const gridRef = useRef(null);
  const { teamStore, recTypeStore, viewProfileStore, rolesStore } = useStore();
  const recType = recTypeStore.getSelectedRecType();
  const team = teamStore.getSelectedTeam();
  const recTypeName = recType ? recType.name : "";

  const colDefs = [
    { headerName: "Name", field: "name" },
    { headerName: "View type", field: "type" },
    { headerName: "Reconcilaition Type", field: "recType" },
    {
      headerName: "Favourite",
      field: "favourite",
      cellRenderer: (params) => {
        return params.data && params.data.favourite ? <IconCheck className="btn-sm-svg" /> : "";
      },
    },
    {
      field: "expandRow",
      width: 50,
      maxWidth: 50,
      pinned: "right",
      cellRenderer: (params) => {
        return <VerticalMenu teamId={teamStore.getSelectedTeam().id} data={params.data} getMenuItemsFromRowData={getMenuItems} onItemClick={onItemClick} />;
      },
    },
  ];

  const deleteViewProfile = async (viewProfile) => {
    if (viewProfile.type === unmatchedText) {
      const response = await viewProfileStore.deleteRowViewProfile(team.id, viewProfile.id);
      if (response.status === 200) {
        if (viewProfile.favourite) {
          resetColumnSettings();
        }
        refreshRows();
      }
    } else if (viewProfile.type === processedText) {
      const response = await viewProfileStore.deleteRowGroupViewProfile(team.id, viewProfile.id);
      if (response.status === 200) {
        if (viewProfile.favourite) {
          resetColumnSettings();
        }
        refreshRows();
      }
    }
  };

  const onItemClick = (option, optionData) => {
    const options = {
      delete: () => {
        deleteViewProfile(optionData);
      },
    };
    options[option]();
  };

  const getMenuItems = ({ teamId, data }) => {
    const menuItems = isUserAuthorized({ teamId: teamId, allRequired: rolesStore.getActions([AUTHORITIES.RECS_DELETE_COLUMN_VIEW]) })
      ? [{ key: "delete", label: "Delete", disabled: false, visible: true, optionData: {} }]
      : [];
    return menuItems.map((menuItem) => {
      menuItem.optionData = data;
      return menuItem;
    });
  };

  //return null if the arrays are null to ensure the spinner displays onscreen
  const formatRows = () => {
    let rows = [];
    if (rowViewProfiles !== null) {
      rowViewProfiles.forEach((profile) => {
        rows.push({ ...profile, type: unmatchedText, recType: recTypeName });
      });
    }
    if (rowGroupViewProfiles !== null) {
      rowGroupViewProfiles.forEach((profile) => {
        rows.push({ ...profile, type: processedText, recType: recTypeName });
      });
    }
    return rowGroupViewProfiles === null && rowViewProfiles === null ? null : rows;
  };

  return (
    <SlideOverPanel id={MODALS.COLUMN_VIEWS} width="66%">
      <PanelBody>
        <div className="panel-header">
          <div className="panel-header-left">
            <Heading variant="h4" element="span">
              Saved views
            </Heading>
          </div>
          <div className="panel-header-right">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {}}></button>
          </div>
          <div className="panel-header-clear"></div>
        </div>
        <div className="panel-description">
          <Text size="sm" variant="secondary" weight="bold">
            The team default view will be used when any team member views a reconciliation for the first time. The last saved view chosen by the user will be
            the same view the user sees the next time they visit a reconciliation of this reconciliation type.
          </Text>
        </div>
        <div className="pt-32">
          <Heading variant="h6">Team views</Heading>
          <Table ref={gridRef} columnDefs={colDefs} rowData={formatRows()} sizeToFit={true} />
        </div>
      </PanelBody>
      <PanelFooter></PanelFooter>
    </SlideOverPanel>
  );
});

export { ColumnViewPanel };
