import { useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Text, Button, modalInstance } from "@fundrecs/ui-library";
import { PageTitleArea, ManageLayout, MainContainer } from "../layout/Layout";
import { Table } from "../ag-grid/Ag-grid";
import { useStore } from "../../store/Store";
import { VerticalMenu } from "../reusable/VerticalMenu/VerticalMenu";
import { AUTHORITIES, MODALS } from "../../utils/enums";
import { SubAccountSetup } from "./subAccountSetup/SubAccountSetup";
import { CreateSubAccountCode } from "./subAccountSetup/CreateSubAccountCode";
import { ExpandCollapseButton } from "../ag-grid/expandCollapse";
import { renderHeaderWithCount, nodeChildren } from "../ag-grid/utils";
import { WarningModal } from "../WarningModal";
import { AuthWrapper, isUserAuthorized } from "../AuthorizationWrapper";
import { DownloadTableButton } from "../reusable/DownloadTableButton";

const SubAccounts = observer(({}) => {
  const { teamStore, subAccountStore, recTypeStore, rolesStore } = useStore();
  const gridRef = useRef(null);
  const [initialised, setInitialised] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = searchParams.get("teamId") ? Number(searchParams.get("teamId")) : teamStore.getSelectedTeam().id;
  const [tableData, setTableData] = useState(null);
  const [setupView, setSetupView] = useState(false);
  const [selectedSubAccount, setSelectedSubAccount] = useState({});
  const [subAccountToDelete, setSubAccountToDelete] = useState(null);

  const toggleSetupView = () => {
    setSetupView(!setupView);
  };

  const populateTable = () => {
    let rows = [];
    tableData.forEach((subAccountData) => {
      const subAccountFields = { subAccountName: subAccountData.name, subAccountId: subAccountData.id, subAccountVersion: subAccountData.version };
      if (!subAccountData.recTypeList.length) {
        rows.push(subAccountFields);
        return;
      }
      subAccountData.recTypeList.forEach((recType) => {
        const recTypeFields = { recTypeName: recType.name, recTypeId: recType.id };
        //rows.push({ ...subAccountFields, ...recTypeFields });
        recType.aliasList.forEach((alias) => {
          rows.push({
            ...subAccountFields,
            ...recTypeFields,
            subAccountCodeId: alias.id,
            alias: alias.alias,
          });
        });
      });
    });
    return rows;
  };

  const fetchTableData = async () => {
    setTableData(null);
    const response = await subAccountStore.getSubAccountsAndAliasesForTeam(teamId);
    if (response.status === 200) {
      setTableData(response.data);
    }
  };

  if (!initialised && teamId) {
    fetchTableData();
    setInitialised(true);
  }

  const deleteSubAccountCode = async (optionData) => {
    const response = await recTypeStore.deleteAlias(teamId, optionData.recTypeId, optionData.subAccountCodeId, "SUB_ACCOUNT");
    if (response.status === 200) {
      fetchTableData();
    }
  };

  const deleteSubAccount = async () => {
    const response = await subAccountStore.deleteSubAccount(teamId, subAccountToDelete.subAccountId, subAccountToDelete.subAccountVersion);
    if (response.status === 200) {
      fetchTableData();
    }
    modalInstance(MODALS.DELETE_SUB_ACCOUNT).hide();
  };

  const onItemClick = (option, optionData) => {
    const options = {
      deleteSubAccountCode: () => {
        deleteSubAccountCode(optionData);
      },
      deleteSubAccount: () => {
        setSubAccountToDelete(optionData);
        modalInstance(MODALS.DELETE_SUB_ACCOUNT).show();
      },
    };
    options[option]();
  };

  const getSubAccountMenuItems = ({ teamId, data }) => {
    const deleteEnabled = isUserAuthorized({ teamId: teamId, allRequired: rolesStore.getActions([AUTHORITIES.RECS_SUB_ACCOUNT_DELETE]) });

    const menuItems = [{ key: "deleteSubAccount", label: "Delete", disabled: !deleteEnabled, visible: deleteEnabled, optionData: {} }];
    return menuItems.map((menuItem) => {
      menuItem.optionData = { subAccountVersion: data.subAccountVersion, subAccountId: data.subAccountId, subAccountName: data.subAccountName };
      return menuItem;
    });
  };

  const getSubAccountCodeMenuItems = ({ teamId, data }) => {
    const deleteEnabled = isUserAuthorized({ teamId: teamId, allRequired: rolesStore.getActions([AUTHORITIES.RECS_SUB_ACCOUNT_CODE_REMOVE]) });
    const menuItems = [{ key: "deleteSubAccountCode", label: "Delete", disabled: !deleteEnabled, visible: deleteEnabled, optionData: {} }];
    return menuItems.map((menuItem) => {
      menuItem.optionData = { recTypeId: data.recTypeId, subAccountCodeId: data.subAccountCodeId };
      return menuItem;
    });
  };

  const cols = [
    {
      headerName: "Sub account name",
      field: "subAccountName",
      width: 150,
      rowGroup: true,
      hide: true,
      valueFormatter: (params) => {
        return renderHeaderWithCount(params, "subAccountName", "alias");
      },
      suppressColumnsToolPanel: true,
    },
    {
      headerName: "Rec type",
      field: "recTypeName",
      rowGroup: true,
      hide: true,
      valueFormatter: (params) => {
        return renderHeaderWithCount(params, "recTypeName", "alias");
      },
      suppressColumnsToolPanel: true,
    },
    { headerName: "Sub account code", field: "alias", suppressColumnsToolPanel: true },
    {
      field: "addCode",
      headerName: "",
      width: 200,
      maxWidth: 200,
      cellRenderer: (params) => {
        const addCodeEnabled = isUserAuthorized({ teamId: teamId, allRequired: rolesStore.getActions([AUTHORITIES.RECS_SUB_ACCOUNT_CODE_ADD]) });
        return addCodeEnabled && params && !params.data && params.node.field === "subAccountName" ? (
          <Button
            size="sm"
            color="primary-secondary"
            onClick={() => {
              setSelectedSubAccount({ id: nodeChildren(params)[0].data.subAccountId });
              modalInstance(MODALS.CREATE_SUB_ACCOUNT_CODE).show();
            }}
          >
            <Text size="sm">Add sub account code</Text>
          </Button>
        ) : (
          ""
        );
      },
      suppressColumnsToolPanel: true,
    },
    {
      field: "actions",
      headerName: "",
      width: 50,
      maxWidth: 50,
      type: "rightAligned",
      cellRenderer: (params) => {
        return params && params.data && params.data.alias ? (
          <VerticalMenu
            teamId={teamStore.getSelectedTeam().id}
            data={params.data}
            getMenuItemsFromRowData={getSubAccountCodeMenuItems}
            onItemClick={onItemClick}
          />
        ) : params && !params.data && params.node.field === "subAccountName" ? (
          <VerticalMenu
            teamId={teamStore.getSelectedTeam().id}
            data={params.node.allLeafChildren[0]["data"]}
            getMenuItemsFromRowData={getSubAccountMenuItems}
            onItemClick={onItemClick}
          />
        ) : (
          ""
        );
      },
      suppressColumnsToolPanel: true,
    },
  ];

  const createFileName = () => {
    const fileName = `All sub accounts for ${teamStore.getSelectedTeam().name} `;
    const fullStopsRemoved = fileName.replaceAll(".", "");
    return fullStopsRemoved;
  };

  return (
    <MainContainer>
      <PageTitleArea
        backButtonEnabled={false}
        title="Sub accounts"
        description={<div className="text-medium pb-4">All sub accounts belonging to this team</div>}
        borderBottom={false}
        rightToolbar={""}
        props={
          <>
            <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_SUB_ACCOUNT_CREATE])}>
              <Button
                size="md"
                onClick={() => {
                  toggleSetupView();
                }}
                disabled={false}
                color="primary"
              >
                <Text size="sm" weight="medium">
                  New sub account
                </Text>
              </Button>
            </AuthWrapper>
            <span className="ml-16"></span>
            <DownloadTableButton gridRef={gridRef} createFileName={createFileName} />
          </>
        }
      />
      <ManageLayout
        mainContentToolbar={
          <div className="mt-8 mb-8" style={{ textAlign: "right", paddingRight: "2em" }}>
            <ExpandCollapseButton gridRef={gridRef} />
          </div>
        }
      >
        <Table rowSelection="single" columnDefs={cols} rowData={tableData === null ? null : populateTable()} ref={gridRef} colFlex={true} />
      </ManageLayout>
      <SubAccountSetup fetchTableData={fetchTableData} setupView={setupView} toggleSetupView={toggleSetupView} />
      <CreateSubAccountCode subAccount={selectedSubAccount} updateTable={fetchTableData} />
      <WarningModal
        modalId={MODALS.DELETE_SUB_ACCOUNT}
        title="Delete sub account?"
        description={`Deleting sub account "${
          subAccountToDelete ? subAccountToDelete.subAccountName : ""
        }" will remove the sub account from this team and will no longer be available to any funds.`}
        buttonClick={() => {
          deleteSubAccount();
        }}
        buttonText={"OK"}
      />
    </MainContainer>
  );
});

export { SubAccounts };
