import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useSearchParams } from "react-router-dom";
import { R, C2, C6, Text, Toggle } from "@fundrecs/ui-library";
import { useStore } from "../../store/Store";
import { AuthWrapper } from "../AuthorizationWrapper";
import { AUTHORITIES } from "../../utils/enums";

const ViewAutoCompleteConfig = observer(({ autoCompleteConfig }) => {
  const { rolesStore, recTypeStore } = useStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = searchParams.get("teamId");
  const selectedRecType = recTypeStore.getSelectedRecType();
  const [enabled, setEnabled] = useState(false);
  const [initialised, setInitialised] = useState(false);

  if (autoCompleteConfig && !initialised) {
    setInitialised(true);
    setEnabled(autoCompleteConfig.autoCompleteEnabled);
  }
  const toggleAutoComplete = async () => {
    const resp = await recTypeStore.updateSetting(teamId, selectedRecType.id, selectedRecType.version, {
      type: "boolean",
      id: null,
      settingFor: "REC_TYPE_AUTOCOMPLETE",
      value: !enabled,
    });
    if (resp.status === 200) {
      setEnabled(!enabled);
      recTypeStore.setSelectedRecType(resp.data);
    }
  };

  return (
    <>
      <div className="d-flex pb-16" style={{ alignItems: "center" }}>
        <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_REC_TYPE_AUTOCOMPLETE_ENABLE])}>
          <Toggle
            disabled={false}
            checked={enabled}
            onChange={() => {
              toggleAutoComplete();
            }}
          />
        </AuthWrapper>

        <div className="ml-16">
          <Text size="md" weight="regular">
            Auto complete:
          </Text>
          <span className="ml-8"></span>
          <Text size="md" weight="bold" variant={enabled ? "success" : "error"}>
            {enabled ? "Active" : "Inactive"}
          </Text>
        </div>
      </div>
      <div className="d-flex justify-content-between mt-32">
        <div>
          <Text variant="muted" weight="bold">
            AUTO COMPLETE CONFIGURATION
          </Text>
        </div>
        <div>
          {/*
          <Button disabled={true} color="primary-secondary">
            <IconAdd variant="link" className="btn-sm-svg" />
            <Text size="sm" weight="bold">
              Add new autocomplete rule
            </Text>
  </Button>*/}
        </div>
      </div>
      {autoCompleteConfig.autoCompleteList && autoCompleteConfig.autoCompleteList.length ? (
        <>
          {autoCompleteConfig.autoCompleteList.map((setting, index) => (
            <div style={{ borderBottom: "1px solid #E6E8EB" }} key={index}>
              <R props="mt-16 mb-16">
                <C2>
                  <Text size="sm" weight="bold">
                    {setting.name}
                  </Text>
                </C2>
                <C6>
                  <Text size="sm" weight="regular">
                    {setting.description}
                  </Text>
                </C6>
              </R>
            </div>
          ))}
        </>
      ) : (
        ""
      )}
    </>
  );
});

export { ViewAutoCompleteConfig };
