import { useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Modal,
  ModalBody,
  modalInstance,
  Button,
  Text,
  Dropdown,
  DropdownButton,
  IconDownArrow,
  DropdownList,
  DropdownListItem,
  DropdownItem,
  ModalFooter,
  R,
} from "@fundrecs/ui-library";
import { MODALS } from "../../../utils/enums";
import { ModalHeader } from "../../layout/Modals";

const RunSelectedMatchingRulesModal = observer(({ matchingRules, runRule }) => {
  const [selectedMatchingRule, setSelectedMatchingRule] = useState(null);
  const modalId = MODALS.RUN_SELECTED_MATCHING_RULES;

  return (
    <Modal modalId={modalId}>
      <ModalBody>
        <ModalHeader
          title="Run selected matching rule"
          onCloseClick={() => {
            modalInstance(modalId).toggle();
          }}
        />
      </ModalBody>
      <div style={{ paddingLeft: "1em", paddingRight: "1em" }}>
        <R>
          <Text size="sm" weight="regular">
            Select a specific matching rule to run against selected rows.
          </Text>
        </R>
        <R>
          <span className="mt-32"></span>
          <Text size="sm" weight="regular">
            This will run the selected matching rule for this reconciliation type, fund and sub-account across the selected unprocessed rows in this
            reconciliation.
          </Text>
        </R>
        <R>
          <span className="mt-32"></span>
          <Text size="sm" weight="regular">
            This selection will replace the existing matching rule filters.
          </Text>
        </R>
        <R>
          <span className="mt-32"></span>
          <Text>Select matching rule</Text>
        </R>
        <R props="mb-32">
          <Dropdown>
            <DropdownButton size="sm">
              <span>{selectedMatchingRule ? selectedMatchingRule.name : "Select rule"}</span>
              <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
            </DropdownButton>
            <DropdownList>
              {matchingRules.map((matchingRule, index) => {
                return (
                  <DropdownListItem
                    onClick={() => {
                      setSelectedMatchingRule(matchingRule);
                    }}
                    key={index + 1}
                  >
                    <DropdownItem active={null} index={0}>
                      <span>{matchingRule.name}</span>
                    </DropdownItem>
                  </DropdownListItem>
                );
              })}
            </DropdownList>
          </Dropdown>
        </R>
      </div>
      <ModalFooter>
        <Button
          color="tertiary"
          onClick={() => {
            modalInstance(modalId).toggle();
          }}
        >
          <Text>Cancel</Text>
        </Button>
        <Button
          color="primary"
          onClick={() => {
            runRule(selectedMatchingRule.id);
            modalInstance(modalId).toggle();
          }}
          disabled={!selectedMatchingRule}
        >
          <Text>Preview</Text>
        </Button>
      </ModalFooter>
    </Modal>
  );
});

export { RunSelectedMatchingRulesModal };
