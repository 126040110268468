import { fusionRequest } from "../fusionRequest";

const viewProfile = {
  fetchRowProfileById: (teamId, id) => fusionRequest.get(`team/${teamId}/viewProfile/rowTableViewProfile/${id}`),
  fetchRowGroupProfileById: (teamId, id) => fusionRequest.get(`team/${teamId}/viewProfile/groupTmoViewProfile/${id}`),
  setFavouriteRowProfile: (teamId, id) => fusionRequest.post(`team/${teamId}/viewProfile/rowTableViewProfile/${id}/setAsFavourite`),
  setFavouriteRowGroupProfile: (teamId, id) => fusionRequest.post(`team/${teamId}/viewProfile/groupTmoViewProfile/${id}/setAsFavourite`),
  createRowViewProfile: (teamId, body) => fusionRequest.post(`team/${teamId}/viewProfile/rowTableViewProfile`, body),
  createRowGroupViewProfile: (teamId, body) => fusionRequest.post(`team/${teamId}/viewProfile/groupTmoViewProfile`, body),
  deleteRowViewProfile: (teamId, rowViewProfileId) => fusionRequest.delete(`team/${teamId}/viewProfile/rowTableViewProfile/${rowViewProfileId}`),
  deleteRowGroupViewProfile: (teamId, rowGroupViewProfileId) => fusionRequest.delete(`team/${teamId}/viewProfile/groupTmoViewProfile/${rowGroupViewProfileId}`),
  setRowViewProfile: (teamId, rowViewProfileId) => fusionRequest.post(`team/${teamId}/viewProfile/rowTableViewProfile/${rowViewProfileId}/setAsFavourite`),
  setRowGroupViewProfile: (teamId, rowGroupViewProfileId) =>
    fusionRequest.post(`team/${teamId}/viewProfile/groupTmoViewProfile/${rowGroupViewProfileId}/setAsFavourite`),
  fetchRowViewProfiles: (teamId, recTypeId) => fusionRequest.get(`team/${teamId}/viewProfile/rowTableViewProfile/getViewProfilesForRecType/${recTypeId}`),
  fetchRowGroupViewProfiles: (teamId, recTypeId) => fusionRequest.get(`team/${teamId}/viewProfile/groupTmoViewProfile/getViewProfilesForRecType/${recTypeId}`),
};

export { viewProfile };
