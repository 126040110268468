import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useSearchParams } from "react-router-dom";
import { modalInstance, FullScreenModal, R } from "@fundrecs/ui-library";
import { ModalHeader } from "../../layout/ModalHeader";
import { Step } from "../../reusable/step/step";
import { MODALS } from "../../../utils/enums";
import { CreateAccount } from "./CreateAccount";
import { AddRecTypesToAccount } from "./AddRecTypesToAccount";
import { AddIdentifiersToAccount } from "./AddIdentifiersToAccount";
import { EnableSubAccounts } from "./AddSubAccounts";
import { useStore } from "../../../store/Store";

const AccountSetup = observer(({}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { accountStore, teamStore } = useStore();
  const teamId = searchParams.get("teamId") ? Number(searchParams.get("teamId")) : teamStore.getSelectedTeam().id;
  const steps = [
    { name: "STEP 01", index: 1 },
    { name: "STEP 02", index: 2 },
    { name: "STEP 03", index: 3 },
    { name: "STEP 04", index: 4 },
  ];

  const closeModalAndResetFields = () => {
    setActiveStep(steps[0]);
    accountStore.removeSelectedAccount();
    modalInstance(MODALS.ACCOUNT_SETUP).toggle();
    accountStore.fetchAccountsForTeam(teamId);
  };

  const [activeStep, setActiveStep] = useState(steps[0]);
  const account = accountStore.getSelectedAccount();

  return (
    <FullScreenModal modalId={MODALS.ACCOUNT_SETUP}>
      <ModalHeader onCloseClick={closeModalAndResetFields} title={account ? account.name : "New Fund"} />
      <R props="ml-72 mr-72 mt-32">
        <div className="mb-72">
          <Step steps={steps} activeStep={activeStep} />
        </div>
      </R>

      {activeStep.index === 1 ? (
        <CreateAccount
          close={closeModalAndResetFields}
          openNextStep={() => {
            setActiveStep(steps[1]);
          }}
        />
      ) : activeStep.index === 2 ? (
        <AddRecTypesToAccount
          account={account}
          close={closeModalAndResetFields}
          openNextStep={() => {
            setActiveStep(steps[2]);
          }}
        />
      ) : activeStep.index === 3 ? (
        <AddIdentifiersToAccount
          account={account}
          close={closeModalAndResetFields}
          openNextStep={() => {
            setActiveStep(steps[3]);
          }}
        />
      ) : activeStep.index === 4 ? (
        <EnableSubAccounts account={account} close={closeModalAndResetFields} openNextStep={() => {}} />
      ) : (
        ""
      )}
    </FullScreenModal>
  );
});

export { AccountSetup };
