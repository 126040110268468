import React, { createContext, useContext } from "react";
import { UIStore } from "./UIStore";
import { MeStore } from "./MeStore";
import { RecStore } from "./RecStore";
import { TeamStore } from "./TeamStore";
import { ClientStore } from "./ClientStore";
import { AccountStore } from "./AccountStore";
import { RecTypeStore } from "./RecTypeStore";
import { SubAccountStore } from "./SubAccountStore";
import { CurrencyStore } from "./CurrencyStore";
import { DataVerificationStore } from "./DataVerificationStore";
import { ComparatorFunctionStore } from "./ComparatorFunctionStore";
import { MatchingRuleStore } from "./MatchingRuleStore";
import { TmoAggregatorStore } from "./TmoAggregatorStore";
import { TenantConfigStore } from "./TenantConfigStore";
import { UploadedFileStore } from "./UploadedFileStore";
import { GlobalMappingStore } from "./GlobalMappingStore";
import { ViewProfileStore } from "./ViewProfileStore";
import { RolesStore } from "./RolesStore";
import { AutoCompleteStore } from "./AutoCompleteStore";
import { ManualMatchingCriteriaStore } from "./ManualMatchingCriteriaStore";
import { CarryForwardStore } from "./CarryForwardStore";
import { FrontendPropertiesStore } from "./FrontendPropertiesStore";

const StoreContext = createContext();

const useStore = () => {
  const context = useContext(StoreContext);

  if (context === undefined) {
    console.log("contrext", context);
    throw new Error("useStore must be used within StoresProvider");
  }

  return context;
};

const uiStore = new UIStore();
const meStore = new MeStore();
const recStore = new RecStore();
const teamStore = new TeamStore();
const clientStore = new ClientStore();
const accountStore = new AccountStore();
const recTypeStore = new RecTypeStore();
const subAccountStore = new SubAccountStore();
const currencyStore = new CurrencyStore();
const dataVerificationStore = new DataVerificationStore();
const comparatorFunctionStore = new ComparatorFunctionStore();
const matchingRuleStore = new MatchingRuleStore();
const tmoAggregatorStore = new TmoAggregatorStore();
const tenantConfigStore = new TenantConfigStore();
const uploadedFileStore = new UploadedFileStore();
const globalMappingStore = new GlobalMappingStore();
const viewProfileStore = new ViewProfileStore();
const rolesStore = new RolesStore();
const autoCompleteStore = new AutoCompleteStore();
const manualMatchingCriteriaStore = new ManualMatchingCriteriaStore();
const carryForwardStore = new CarryForwardStore();
const frontendPropertiesStore = new FrontendPropertiesStore();

const storeContext = {
  uiStore,
  meStore,
  recStore,
  teamStore,
  clientStore,
  accountStore,
  recTypeStore,
  subAccountStore,
  currencyStore,
  dataVerificationStore,
  comparatorFunctionStore,
  matchingRuleStore,
  tmoAggregatorStore,
  tenantConfigStore,
  uploadedFileStore,
  globalMappingStore,
  viewProfileStore,
  rolesStore,
  autoCompleteStore,
  manualMatchingCriteriaStore,
  carryForwardStore,
  frontendPropertiesStore,
};

const StoreProvider = ({ children }) => <StoreContext.Provider value={storeContext}>{children}</StoreContext.Provider>;

export { StoreProvider, useStore, uiStore, meStore, recStore, rolesStore };
