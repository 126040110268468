import { makeAutoObservable, toJS } from "mobx";
import { fusionRecsApi } from "../api";
import { uiStore, rolesStore } from "./Store";
import { isUserAuthorized } from "../components/AuthorizationWrapper";
import { AUTHORITIES } from "../utils/enums";

/**
 * Contains data for clients for a team
 * Stored as a list of clients
 * Should be updated each time a team is changed
 */
class CurrencyStore {
  currencies = [];

  constructor() {
    makeAutoObservable(this);
  }

  getCurrencies = () => {
    return toJS(this.currencies);
  };

  setCurrencies = (currencies) => {
    this.currencies = currencies;
  };

  fetchAllCurrencies = async (teamId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_VIEW]) })) {
      console.log("fetchAllCurrencies: unauthorised");
      return [];
    }
    let result = [];
    const resp = await fusionRecsApi.currency.fetchAllCurrencies(teamId);
    if (resp.status === 200) {
      result = resp.data;
      this.setCurrencies(result);
    } else {
      uiStore.errorNotification(resp.data);
    }
    return result;
  };
}

export { CurrencyStore };
