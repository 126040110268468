import { useState } from "react";
import { Text } from "@fundrecs/ui-library";
import { AgChartsReact } from "ag-charts-react";
import { allColours, updateColour } from "./chartUtils";

const InlineBarChart = ({ rows = [], selectChartSegment = () => {}, title = null, chartColours = {} }) => {
  const [selected, setSelected] = useState(null);
  let series = [];
  let data = { xKey: "" };
  let colourIndex = 0;

  if (rows) {
    rows.forEach((row, index) => {
      if (row.name && !series.find((it) => it.yName === row.name)) {
        const seriesObj = {
          stroke: "white",
          strokeWidth: 1,
          type: "bar",
          xKey: "xKey",
          yKey: row.name,
          yName: row.name,
          direction: "horizontal",
          stacked: true,
          label: {
            color: "#fff",
            formatter: (data) => {
              return `${data.value}`;
            },
          },
          listeners: {
            nodeClick: () => {
              const value = selected === row.name ? null : row.name;
              setSelected(value);
              selectChartSegment(row.tableColumn, value);
            },
          },
          tooltip: {
            renderer: function ({ datum, xKey, yKey }) {
              return `
            <div class="ag-chart-tooltip-content">
                ${row.name}:<b> ${row.count}</b>
            </div>`;
            },
          },
          highlightStyle: {
            item: { fill: "lightgray", fillOpacity: 0 },
          },
        };

        let colourProfile = chartColours[row.name];
        if (!colourProfile) {
          colourProfile = allColours[colourIndex];
          colourIndex = colourIndex >= allColours.length - 1 ? 0 : colourIndex + 1;
        }
        updateColour(row.name, seriesObj, colourProfile, selected);
        series.push(seriesObj);
      }
      data[row.name] = row.count;
    });
  }

  const chartOptions = {
    axes: [
      {
        type: "number",
        position: "bottom",
        label: { enabled: false },
        //thickness: 0,
        //line: { enabled: false },
        //gridLine: { enabled: false, style: { strokeWidth: "0px" } },
        tick: { enabled: false },
      },
      {
        type: "category",
        position: "left",
        tick: { enabled: false },
      },
    ],
    data: [data],
    series: series,

    legend: {
      position: "top",
      maxHeight: 100,
      item: {
        paddingX: 40,
        marker: {
          padding: 5,
          size: 10,
          strokeWidth: 0,
          shape: "circle",
        },
        label: {
          fontFamily: "Muli Bold",
          fontSize: "14px",
          color: "#394561",
        },
      },
    },
  };

  return (
    <div
      style={{
        overflowY: "hidden",
        height: "150px",
        borderRadius: "12px",
        border: "1px solid #CDD0D7",
        background: "#fff",
        paddingTop: "8px",
        marginTop: "8px",
      }}
    >
      {title ? (
        <div className="ml-24">
          <Text size="sm" weight="bold">
            {title}
          </Text>
        </div>
      ) : (
        ""
      )}
      <AgChartsReact options={chartOptions} />
    </div>
  );
};

export { InlineBarChart };
