import { useState } from "react";
import { observer } from "mobx-react-lite";
import { IconCloseRemove } from "@fundrecs/ui-library";
import { Badge } from "../layout/Layout";
import { LIST } from "./dataTransform";
import { formatStringArray } from "../../utils/utils";
import { ReactComponent as ListIcon } from "../../icons/view-list.svg";

const ListStringInput = observer(({ value = [], setValue, disabled = false, width = "32rem" }) => {
  if (value.length && typeof value === "string") {
    value = formatStringArray(value);
  } else {
    value = [];
  }

  const [list, setList] = useState(value);
  const [userInput, setUserIput] = useState("");

  const addToList = () => {
    if (userInput.length) {
      setList([...list, userInput]);
      setValue([...list, userInput], LIST);
      setUserIput("");
    }
  };

  const removeFromList = (value) => {
    setList(list.filter((it) => it !== value));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      addToList();
    }
  };

  return (
    <div style={{ width: width }}>
      {!disabled ? (
        <div style={{ width: width }} className="dropdown-button-container">
          <ListIcon style={{ margin: "0 3px 3px 0" }} />
          <input
            placeholder={`Type and hit return to add a value to list`}
            type="text"
            className="flexiInput-manual-input w-75"
            value={userInput}
            onChange={(e) => {
              setUserIput(e.target.value);
            }}
            disabled={disabled}
            onKeyDown={handleKeyDown}
          />
        </div>
      ) : (
        ""
      )}
      {!list.length ? (
        ""
      ) : (
        <div
          className="mt-8 mb-8 text-right"
          style={{ borderRadius: "6px", border: "1px solid #E6E8EB", background: "#fff", overflowY: "scroll", height: "6rem" }}
        >
          {list.map((listItem) => {
            return (
              <Badge text={listItem} style={{ backgroundColor: "#F8FAFF", color: "#1E5EFF", margin: "5px" }}>
                {!disabled ? (
                  <IconCloseRemove
                    style={{ cursor: "pointer" }}
                    className="btn-sm-svg"
                    onClick={() => {
                      if (!disabled) {
                        removeFromList(listItem);
                      }
                    }}
                  />
                ) : (
                  ""
                )}
              </Badge>
            );
          })}
        </div>
      )}
    </div>
  );
});

export { ListStringInput };
