import { useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Modal,
  ModalBody,
  modalInstance,
  Button,
  Text,
  ModalFooter,
  R,
  DatePicker,
  Dropdown,
  DropdownButton,
  DropdownListItem,
  DropdownItem,
  ItemBody,
  IconCheck,
} from "@fundrecs/ui-library";
import { MODALS } from "../../../utils/enums";
import { ModalHeader } from "../../layout/Modals";
import { useStore } from "../../../store/Store";

const SkipToDateModal = observer(({ initialDate, recTypeId, account, refreshDashboard }) => {
  const { meStore, recStore, teamStore, recTypeStore } = useStore();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDataTypes, setSelectedDataTypes] = useState([]);
  const teamId = teamStore.getSelectedTeam().id;
  const modalId = MODALS.SKIP_TO_DATE;
  const tmos = recTypeStore.getRecTypeTmos();
  const DONT_MERGE = "Don't merge";

  const closeModal = () => {
    setSelectedDate(null);
    setSelectedDataTypes([]);
    modalInstance(modalId).toggle();
  };

  const skipToDate = async () => {
    const body = {
      recTypeId: recTypeId,
      accountId: account.id,
      moveFiles: selectedDataTypes.length > 0,
      tmoIds: selectedDataTypes.map((it) => it.id),
      date: selectedDate ? selectedDate : initialDate,
    };
    const resp = await recStore.skipToDate(teamId, body);
    if (resp.status === 200) {
      refreshDashboard();
      closeModal();
    }
  };

  const alreadySelected = (option, key = "id") => {
    return selectedDataTypes && selectedDataTypes.length && selectedDataTypes.filter((it) => it[key] === option[key]).length > 0;
  };

  const toggleSelection = (option, key = "id") => {
    const newSelection =
      option.id === DONT_MERGE ? [] : alreadySelected(option) ? selectedDataTypes.filter((it) => it[key] !== option[key]) : [...selectedDataTypes, option];
    setSelectedDataTypes(newSelection);
  };

  const generateDescriptionString = () => {
    let str = "";
    selectedDataTypes.forEach((obj, index) => {
      str += `${obj.name}${index >= selectedDataTypes.length - 1 ? "" : ", "} `;
    });
    return str;
  };

  return (
    <Modal modalId={modalId}>
      <ModalBody>
        <ModalHeader title="Skip to date" onCloseClick={closeModal} />
      </ModalBody>
      <div style={{ paddingLeft: "1em", paddingRight: "1em" }}>
        <R props="mt-24 mb-24">
          <Text size="sm" weight="regular">
            Skip performing reconciliations between the last Completed reconciliation and the selected date for the chosen fund
          </Text>
        </R>
        {account ? (
          <R>
            <Text size="sm" weight="regular">
              {`Selected fund: `}
              <b>{account.name}</b>
            </Text>
          </R>
        ) : (
          ""
        )}

        <div className="mt-24 pt-24 pb-24 mb-24" style={{ borderTop: "1px solid #E6E9F4", borderBottom: "1px solid #E6E9F4" }}>
          <R>
            <Text size="sm" weight="regular">
              When days are skipped the following will happen:
            </Text>
          </R>
          <div className="d-flex mt-16 ml-16">
            <Text size="sm" weight="bold" element="div">
              1.
            </Text>
            <span className="ml-8"></span>
            <Text size="sm" weight="regular">
              <b>Dates between reconciliations will be marked as "Skipped":</b> Any dates between the last completed reconciliation and the new reconciliation
              date will have a status of "Skipped," and no new data can be uploaded to those dates.
            </Text>
          </div>
          <div className="d-flex ml-16">
            <Text size="sm" weight="bold" element="div">
              2.
            </Text>
            <span className="ml-8"></span>
            <Text size="sm" weight="regular">
              <b>Data carry forward:</b> All available data from the last completed reconciliation will automatically be carried forward to the date selected.
            </Text>
          </div>
        </div>
        <div className="ml-16 mb-16">
          <Text size="sm" weight="medium" element="div">
            Skip to a new reconciliation date:
          </Text>
          <DatePicker
            date={selectedDate ? selectedDate : initialDate}
            onDateChange={(date) => setSelectedDate(date)}
            dateFormat={meStore.getUserDateFormat()}
          />
        </div>
        <div className="d-flex ml-16 pt-16 pb-16">
          <div>
            <Text size="sm" weight="medium">
              Merge data
            </Text>
            <Dropdown>
              <DropdownButton size="sm">{!selectedDataTypes.length ? DONT_MERGE : generateDescriptionString()}</DropdownButton>
              <ul
                className="dropdown-menu dropdown-sizing"
                aria-labelledby="dropdownMenuLink"
                style={{ background: "#fff", height: "150px", overflowX: "scroll" }}
              >
                {[{ id: DONT_MERGE, name: DONT_MERGE }, ...tmos].map((tmo, index) => {
                  return (
                    <DropdownListItem
                      onClick={() => {
                        toggleSelection(tmo);
                      }}
                      key={index + 1}
                    >
                      <DropdownItem active={null} index={0}>
                        <ItemBody>{tmo.name}</ItemBody>
                        {alreadySelected(tmo) ? <IconCheck className="btn-sm-svg" currnetColor="pink" /> : ""}
                      </DropdownItem>
                    </DropdownListItem>
                  );
                })}
              </ul>
            </Dropdown>
            <div className="mt-8">
              <Text size="xs" weight="regular" element="div" variant="muted">
                Merge any data currently uploaded to any date between the last Completed reconciliation and the selected date and then move this merged data to
                the selected date
              </Text>
            </div>
          </div>
        </div>
      </div>
      <ModalFooter>
        <Button color="tertiary" onClick={closeModal}>
          <Text>Cancel</Text>
        </Button>
        <Button color="primary" onClick={skipToDate}>
          <Text>OK</Text>
        </Button>
      </ModalFooter>
    </Modal>
  );
});

export { SkipToDateModal };
