import { STATUS } from "../../utils/enums";

const StatusBadge = ({ status, onClick = null, recStatus = false, uploadedFileStatus = false }) => {
  //Recs require 'DRAFT' status to be displayed as 'OPEN'
  if (recStatus) {
    status = status === STATUS.DRAFT.status ? STATUS.OPEN.status : status;
  }
  //Uploaded files require 'DRAFT' status to be displayed as 'In progress'
  if (uploadedFileStatus) {
    status = status === STATUS.DRAFT.status ? STATUS.IN_PROGRESS.status : status;
  }
  const statusConfig = STATUS[status] ? STATUS[status] : { text: status, backgroundColor: "lightgray", color: "black", width: "80px" };

  return (
    <span
      className="custom-badge-recs"
      style={{
        fontWeight: "600",
        backgroundColor: statusConfig.backgroundColor,
        color: statusConfig.color,
        width: statusConfig.width,
        cursor: onClick ? "pointer" : "",
      }}
      onClick={onClick}
    >
      {statusConfig.text}
    </span>
  );
};

export { StatusBadge };
