export const API_BASE_URL = "/api";

// Updated from 30000 to match AWS timout of 3 mins (180000)
// This is because some requests were taking a long time to process on the back-end
// Needs to be revisited
export const API_REQUEST_TIMEOUT_MS = 180000;

export const API_ENDPOINTS = {
  FUSION_ADMIN: {
    ME: "/users/me",
    UPDATE_ME: "/withBody/users/updateMe",
    ROLES: "/roles",
    CONNECTIONS_SUMMARY: "/unrestricted/connections?projection=summary",
    GET_FRONTEND_PROPERTIES: "/frontend-properties/all"

  },
  ME: {
    GET_ME: "/",
  },
  RECS: {
    RECS_IN_DATE_RANGE: "rec/recsInDateRange",
  },
  TENANT_CONFIG: {
    GET_TENANT_CONFIG: "tenant",
  },
};
