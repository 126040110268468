import { useState } from "react";
import { observer } from "mobx-react-lite";
import "react-calendar/dist/Calendar.css";
import { useStore } from "../../../store/Store";
import { Text } from "@fundrecs/ui-library";
import { UploadedFilesList } from "../../reconciliations/UploadedFilesList";
import { RecOptions } from "./RecOptions";
import { RecsCalendar } from "../../reusable/Calendar/RecsCalendar";

const DashboardCalendar = observer(
  ({ selectedRec, selectRec, selectedDate, selectedAccount = null, recList, updateCalendar, uploadedFiles = [], setCalendarStartDate }) => {
    const { recTypeStore } = useStore();
    const recType = recTypeStore.getSelectedRecType();

    return (
      <div className="pt-24">
        <div className="pl-24 pr-24">
          <Text size="xs" weight="regular" element="div" props="pl-8">
            {recType ? recType.name : ""} / <b>Calendar</b>
          </Text>
          <Text size="lg" weight="bold" element="div" variant="secondary">
            {selectedAccount ? selectedAccount.name : ""}
          </Text>
        </div>
        <div className="pt-32">
          <RecsCalendar
            selectRec={selectRec}
            selectedDate={selectedDate}
            selectedAccount={selectedAccount}
            recList={recList}
            updateCalendar={updateCalendar}
            setCalendarStartDate={setCalendarStartDate}
          />
          <div className="pl-24 pr-24 pt-24 pb-24">
            <RecOptions recType={recType} selectedRec={selectedRec} selectedAccount={selectedAccount} selectedDate={selectedDate} />
            <Text size="sm" weight="bold">
              Uploaded files
            </Text>
            <UploadedFilesList uploadedFiles={uploadedFiles} />
          </div>
        </div>
      </div>
    );
  }
);

export { DashboardCalendar };
