import { observer } from "mobx-react-lite";
import { Modal, ModalBody, modalInstance, Button, Text, ModalFooter, R } from "@fundrecs/ui-library";
import { MODALS } from "../../../utils/enums";
import { ModalHeader } from "../../layout/Modals";
import { useStore } from "../../../store/Store";

const ReopenModal = observer(({ recType, accountId, selectedRec, refreshDashboard, date }) => {
  const { recStore, teamStore } = useStore();
  const teamId = teamStore.getSelectedTeam().id;
  const modalId = MODALS.REOPEN;

  const closeModal = () => {
    modalInstance(modalId).hide();
  };

  const reopenRec = () => {
    if (
      recType &&
      recType.settings &&
      recType.settings.length &&
      recType.settings.find((it) => it.settingFor === "REC_TYPE_TYPE" && it.value === "SEQUENTIAL")
    ) {
      reopenSequentialRec();
    } else {
      reopenAdHocRec();
    }
  };

  const reopenSequentialRec = async () => {
    const body = { recTypeId: recType.id, accountId: accountId, date: date };
    const resp = await recStore.reopenSequentialRec(teamId, body);
    if (resp.status === 200) {
      refreshDashboard(true);
      closeModal();
    }
  };

  const reopenAdHocRec = async () => {
    const resp = await recStore.reopenAdHocRec(teamId, selectedRec.id, selectedRec.version);
    if (resp.status === 200) {
      refreshDashboard(true);
      closeModal();
    }
  };

  return (
    <Modal modalId={modalId}>
      <ModalBody>
        <ModalHeader
          title="Reopen"
          onCloseClick={() => {
            closeModal();
          }}
        />
      </ModalBody>
      <div style={{ paddingLeft: "1em", paddingRight: "1em" }}>
        <R>
          <Text size="sm" weight="regular">
            Reopen a completed reconciliation
          </Text>
        </R>
        <R>
          <span className="mt-32"></span>
          <Text size="sm" weight="regular">
            During reopening the following will happen:
          </Text>
        </R>
        <div className="d-flex mt-16 ml-16">
          <Text size="sm" weight="bold" element="div">
            1.
          </Text>
          <span className="ml-8"></span>
          <Text size="sm" weight="regular">
            <b>Reopening of completed reconciliations:</b> Any reconciliations that have been completed between the most recent open reconciliation and the
            reconciliation being worked on will be reopened. All previously matched items in these reconciliations will be undone.
          </Text>
        </div>
        <div className="d-flex ml-16">
          <Text size="sm" weight="bold" element="div">
            2.
          </Text>
          <span className="ml-8"></span>
          <Text size="sm" weight="regular">
            <b>Carrying forward data:</b> Any data that was available from the last completed reconciliation will be carried forward to the selected
            reconciliation date.
          </Text>
        </div>
        <div className="d-flex pb-16 ml-16" style={{ borderBottom: "1px solid #efefef" }}>
          <Text size="sm" weight="bold" element="div">
            3.
          </Text>
          <span className="ml-8"></span>
          <Text size="sm" weight="regular">
            <b>Uploaded data:</b> Uploaded data will stay on the date it was originally uploaded. If needed, it is possible to move it to a different date via
            the Uploads page.
          </Text>
        </div>
      </div>
      <ModalFooter>
        <Button
          color="tertiary"
          onClick={() => {
            closeModal();
          }}
        >
          <Text>Cancel</Text>
        </Button>
        <Button
          color="primary"
          onClick={() => {
            reopenRec();
          }}
        >
          <Text>Reopen</Text>
        </Button>
      </ModalFooter>
    </Modal>
  );
});

export { ReopenModal };
