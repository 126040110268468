import React, { useState, useRef } from "react";
import { useStore } from "../../../store/Store";
import { observer } from "mobx-react-lite";
import {
  Card,
  R,
  Button,
  Text,
  C4,
  C3,
  Toggle,
  Input,
  C2,
  IconAdd,
  Dropdown,
  DropdownButton,
  DropdownList,
  DropdownListItem,
  DropdownItem,
  IconDownArrow,
  ItemBody,
  ToolTip,
  IconInformation,
  Checkbox,
  TabsSection,
  Tab,
  LinkText,
  modalInstance,
} from "@fundrecs/ui-library";
import { Table } from "../../ag-grid/Ag-grid";
import { ModalHeader } from "../../layout/ModalHeader";
import { MODALS } from "../../../utils/enums";
import { ColumnMappingPanel } from "./ColumnMappingPanel";

const VIEW = "view";
const EDIT = "edit";
/**

 */
const SetupRecTypeDataStructure = observer(({ backToMenu, mode }) => {
  console.log(mode);
  const { recTypeStore } = useStore();
  const recType = recTypeStore.getDraftRecType();

  const initialDraftColumn = {
    name: "",
    type: "",
    side: null,
    sides: [],
    required: false,
  };

  const columnsBySide = recType.columns;

  const [draftColumn, setDraftColumn] = useState({ ...initialDraftColumn });
  //const [columnsBySide, setColumnsBySide] = useState({ ...initialColumns });
  const dataTypes = ["String", "Number", "Date"];
  const dataSides = Object.keys(columnsBySide);

  const saveColumn = () => {
    draftColumn.sides.forEach((side) => {
      let columnArray = columnsBySide[side];
      if (
        [undefined, null].includes(columnArray) ||
        columnArray.find((col) => {
          return col.name === draftColumn.name;
        }) !== undefined
      ) {
        return;
      }
      const newColumn = { ...draftColumn };
      delete newColumn.sides;
      columnArray.push({ ...newColumn, side: side });
      recTypeStore.setDraftRecTypeColumns({ ...columnsBySide, [side]: columnArray });
    });

    setDraftColumn({ ...initialDraftColumn });
  };

  const updateDraftColumnSides = (event, side) => {
    event.stopPropagation();
    let sides = [];
    if (draftColumn.sides.includes(side)) {
      sides = draftColumn.sides.filter((it) => it !== side);
    } else {
      sides = [...draftColumn.sides, side];
    }
    setDraftColumn({ ...draftColumn, sides: sides });
  };

  const toggleRequired = (rowData) => {
    let columnArray = columnsBySide[rowData.side].map((column) => {
      if (column.name === rowData.name) {
        column.required = !column.required;
      }
      return column;
    });
    recTypeStore.setDraftRecTypeColumns({ ...columnsBySide, [rowData.side]: columnArray });
  };

  const allColumns = recTypeStore.getAllColumnsFromDraftRecType();

  return (
    <>
      <ColumnMappingPanel />
      <ModalHeader
        onCloseClick={() => {
          backToMenu();
          //modalInstance(MODALS.RECTYPE_SETUP).hide();
        }}
        title={recType.name}
      />
      <div className="ml-72 mr-72 mt-48 text-left">
        <Text size="lg" weight="medium">
          Set up the columns for the expected input and results
        </Text>
        <div>
          <Text weight="regular">
            Define the expected column headers for your input files and desired output format, including default aggregators and other calculations
          </Text>
        </div>
        <div className="mt-32">
          {mode !== EDIT ? (
            ""
          ) : (
            <Card>
              <R props="light-grey-bg">
                <C4 props="pt-16 pl-16 pr-16 pb-16">
                  <Text weight="medium">Column Name</Text>
                </C4>
                <C2 props="pt-16 pl-16 pr-16 pb-16">
                  <Text weight="medium">Data Type</Text>
                </C2>
                <C3 props="pt-16 pl-16 pr-16 pb-16">
                  <Text weight="medium">Side</Text>
                </C3>
              </R>
              <R>
                <C4 props="pt-16 pl-16 pr-16 pb-16">
                  <Input
                    onChange={(event) => {
                      setDraftColumn({ ...draftColumn, name: event.target.value });
                    }}
                    value={draftColumn.name}
                    placeholder="Add column name"
                    warning={false}
                    warningMessage=""
                  />
                </C4>
                <C2 props="pt-16 pl-16 pr-16 pb-16">
                  <Dropdown>
                    <DropdownButton size="sm">
                      <span>{draftColumn.type ? draftColumn.type : "Please select a data type"}</span>
                      <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
                    </DropdownButton>
                    <DropdownList>
                      {dataTypes.map((type, index) => {
                        return (
                          <DropdownListItem
                            onClick={() => {
                              setDraftColumn({ ...draftColumn, type: type });
                            }}
                            key={index + 1}
                          >
                            <DropdownItem active={null} index={0}>
                              <span>{type}</span>
                            </DropdownItem>
                          </DropdownListItem>
                        );
                      })}
                    </DropdownList>
                  </Dropdown>
                </C2>
                <C3 props="pt-16 pl-16 pr-16 pb-16">
                  <Dropdown spacers={["mr-12"]}>
                    <DropdownButton warning={false} disabled={false} size="sm">
                      {draftColumn.sides.length
                        ? draftColumn.sides.map((it, index) => {
                            if (index === draftColumn.sides.length - 1) {
                              return it;
                            } else {
                              return it + ", ";
                            }
                          })
                        : "Please select a side"}
                      <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
                    </DropdownButton>

                    <DropdownList>
                      {dataSides.map((side, index) => {
                        return (
                          <DropdownListItem key={Math.random()} onClick={() => {}}>
                            <DropdownItem active={false} index={index}>
                              <Checkbox
                                spacers={["mr-12", "mt-0"]}
                                onClick={(event) => {
                                  updateDraftColumnSides(event, side);
                                }}
                                checked={draftColumn.sides.includes(side)}
                                disabled={false}
                              />
                              <ItemBody>{side}</ItemBody>
                              <ToolTip text={side} direction="top">
                                <IconInformation className="light-text-muted icon-size" />
                              </ToolTip>
                            </DropdownItem>
                          </DropdownListItem>
                        );
                      })}
                    </DropdownList>
                  </Dropdown>
                </C3>
                <C2 props="pt-16 pl-16 pr-16 pb-16 text-right">
                  <Button
                    onClick={() => {
                      saveColumn();
                    }}
                    size="md"
                  >
                    <IconAdd className="btn-md-svg" />
                    <Text size="md">Add</Text>
                  </Button>
                </C2>
              </R>
            </Card>
          )}
          {!allColumns.length ? (
            ""
          ) : (
            <div className="pt-32">
              <TablePreview columnsBySide={columnsBySide} state={recType} mode={mode} />
              <ColumnEditor allColumns={allColumns} state={recType} dataSides={dataSides} toggleRequired={toggleRequired} mode={mode} />
            </div>
          )}
        </div>
      </div>
    </>
  );
});

const TablePreview = ({ columnsBySide, state, mode }) => {
  const sides = Object.keys(columnsBySide);
  const formatDefaultDecimalUI = () => {
    let string = "0.";
    for (let i = 0; i < state.defaultDecimal; i++) {
      string += "0";
    }
    return string;
  };

  const gridRef = useRef(null);
  const { recTypeStore } = useStore();
  const [selectedSide, setSelectedSide] = useState(sides[0]);
  const dataTypeMap = { String: "text", Number: formatDefaultDecimalUI(), Date: state.defaultDateFormat };

  const getColumnDefs = () => {
    let columns = [];
    if (columnsBySide[selectedSide] !== undefined && columnsBySide[selectedSide].length) {
      columnsBySide[selectedSide].forEach((column) => {
        columns.push({ headerName: column.name, field: column.name });
      });
    }
    return columns;
  };

  //TODO: There is a bug with this: selectedSide is not being updated
  const reorderColumns = (dragEvent) => {
    if (mode === VIEW) {
      return;
    }
    const columnsBySide = recTypeStore.getDraftRecType().columns;
    const existingColumns = recTypeStore.getDraftRecType().columns[selectedSide];
    const columns = dragEvent.columnApi.getAllGridColumns().map((reorderedColumn) => {
      const columnName = reorderedColumn.userProvidedColDef.headerName;
      return existingColumns.find((col) => col.name === columnName);
    });

    recTypeStore.setDraftRecTypeColumns({ ...columnsBySide, [selectedSide]: columns });
  };

  const getRowData = () => {
    let rows = [];
    if (columnsBySide[selectedSide] !== undefined && columnsBySide[selectedSide].length) {
      let row = {};
      columnsBySide[selectedSide].forEach((column) => {
        row[column.name] = dataTypeMap[column.type];
      });
      rows.push(row);
    }
    return rows;
  };

  const colDefs = getColumnDefs();

  return (
    <>
      <R props="mt-32 mb-16">
        <C2>
          <Text size="lg">Preview:</Text>
        </C2>
        <C2>
          <Dropdown>
            <DropdownButton size="sm">
              <span>{selectedSide ? selectedSide : "Please select a side"}</span>
              <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
            </DropdownButton>
            <DropdownList>
              {sides.map((side, index) => {
                return (
                  <DropdownListItem
                    key={index + 1}
                    onClick={() => {
                      setSelectedSide(side);
                    }}
                  >
                    <DropdownItem active={null} index={0}>
                      <span>{side}</span>
                    </DropdownItem>
                  </DropdownListItem>
                );
              })}
            </DropdownList>
          </Dropdown>
        </C2>
      </R>
      <R props="pb-32">
        {sides.map((side) => {
          return selectedSide !== side ? (
            ""
          ) : (
            <div style={{ width: `${colDefs.length * 200}px` }}>
              <Table
                columnDefs={colDefs}
                rowData={getRowData()}
                ref={gridRef}
                colFlex={true}
                height={"100px"}
                onDragStopped={(event) => {
                  reorderColumns(event);
                }}
              />
            </div>
          );
        })}
      </R>
      <R props="pb-16"></R>
    </>
  );
};

const ColumnEditor = ({ allColumns, state, dataSides, toggleRequired, mode }) => {
  const ALL_COLUMNS = "All Columns";
  const RequiredToggleCellRenderer = (rowData) => {
    return "Results" === rowData.side ? (
      "n/a"
    ) : mode === VIEW ? (
      <p>{rowData.required}</p>
    ) : (
      <Toggle
        checked={rowData.required}
        onChange={() => {
          toggleRequired(rowData);
        }}
      />
    );
  };

  const MappingCellRenderer = (rowData) => {
    return mode === VIEW ? (
      <p>view mode</p>
    ) : (
      <span
        onClick={() => {
          modalInstance(MODALS.COLUMN_MAPPING).show();
        }}
      >
        <LinkText>+ Add Mapping</LinkText>
      </span>
    );
  };
  const formatDefaultDecimalUI = () => {
    let string = "0.";
    for (let i = 0; i < state.defaultDecimal; i++) {
      string += "0";
    }
    return string;
  };

  const gridRef = useRef(null);
  const [selectedSide, setSelectedSide] = useState(dataSides[0]);
  const dataTypeMap = { Text: "text", Number: formatDefaultDecimalUI(), Date: state.defaultDateFormat };

  const columnDefs = () => {
    const columns = [
      //{ headerName: "", field: "index", sortable: false, filter: false, width: 70, maxWidth: 70 },
      //{ headerName: "", field: "draggable", sortable: false, filter: false, width: 70, maxWidth: 70, rowDrag: true },
      { headerName: "Column name", field: "name", sortable: false, filter: false },
      { headerName: "Data type", field: "type", sortable: false, filter: false },
      { headerName: "Side", field: "side", sortable: false, filter: false },
    ];

    if ("Results" !== selectedSide) {
      columns.push({
        headerName: "Required",
        field: "required",
        sortable: false,
        filter: false,
        cellRenderer: (params) => {
          return RequiredToggleCellRenderer(params.data);
        },
      });
    }
    return [
      ...columns,
      {
        headerName: "Mapping Rules",
        field: "mappingRules",
        sortable: false,
        filter: false,
        cellRenderer: (params) => {
          return MappingCellRenderer(params.data);
        },
      },
      { headerName: "Actions", field: "actions", sortable: false, filter: false },
    ];
  };

  const getRowData = () => {
    let rows = [];
    if (allColumns.length) {
      allColumns.forEach((column, index) => {
        if (column.side == selectedSide || selectedSide === ALL_COLUMNS) {
          let row = { index: "", draggable: "", aggregator: "", rules: "", actions: "" };
          row["index"] = index + 1;
          row["name"] = column.name;
          row["type"] = column.type;
          row["side"] = column.side;
          row["required"] = column.required;
          rows.push(row);
        }
      });
    }
    return rows;
  };

  const rows = getRowData();

  return (
    <>
      <TabsSection>
        <ul className={["nav"].join(" ")}>
          {dataSides.map((side) => {
            return (
              <Tab
                text={side}
                onClick={() => {
                  setSelectedSide(side);
                }}
                active={side === selectedSide}
              />
            );
          })}
          <Tab
            text={ALL_COLUMNS}
            onClick={() => {
              setSelectedSide(ALL_COLUMNS);
            }}
            active={ALL_COLUMNS === selectedSide}
          />
        </ul>
      </TabsSection>
      <Table columnDefs={columnDefs()} rowData={rows} ref={gridRef} colFlex={true} height={`${rows.length * 40 + 80}px`} />
      <R></R>
    </>
  );
};

export { SetupRecTypeDataStructure };
