import { fusionRequest } from "../fusionRequest";

const globalMapping = {
  createGlobalMapping: (teamId, body) => fusionRequest.post(`team/${teamId}/globalmapping`, body),
  approveGlobalMapping: (teamId, gmId, version) => fusionRequest.put(`team/${teamId}/globalmapping/${gmId}/approve?version=${version}`),
  deleteGlobalMapping: (teamId, gmId, version) => fusionRequest.delete(`team/${teamId}/globalmapping/${gmId}?version=${version}`),
  fetchGlobalMappingByRecTypeId: (teamId, recTypeId) => fusionRequest.get(`team/${teamId}/globalmapping?recTypeId=${recTypeId}`),
  fetchGlobalMappingValues: (teamId, gmId, accountId) => fusionRequest.get(`team/${teamId}/globalmapping/${gmId}/account/${accountId}/value`),
  enableGlobalMapping: (teamId, gmId, version) => fusionRequest.put(`team/${teamId}/globalmapping/${gmId}/enable?version=${version}`),
  disableGlobalMapping: (teamId, gmId, version) => fusionRequest.put(`team/${teamId}/globalmapping/${gmId}/disable?version=${version}`),
  enableGlobalMappingForAccount: (teamId, gmId, accountId, version, accountVersion) =>
    fusionRequest.put(`team/${teamId}/globalmapping/${gmId}/account/${accountId}?version=${version}&accountVersion=${accountVersion}`),
  disableGlobalMappingForAccount: (teamId, gmId, accountId, version, accountVersion) =>
    fusionRequest.delete(`team/${teamId}/globalmapping/${gmId}/account/${accountId}?version=${version}&accountVersion=${accountVersion}`),
  approveGlobalMappingValues: (teamId, gmId, accountId, body) =>
    fusionRequest.post(`team/${teamId}/globalmapping/${gmId}/account/${accountId}/approveValues`, body),
  deleteGlobalMappingValues: (teamId, gmId, accountId, body) =>
    fusionRequest.post(`team/${teamId}/globalmapping/${gmId}/account/${accountId}/deleteValues`, body),
  addGlobalMappingValues: (teamId, gmId, accountId, body) => fusionRequest.post(`team/${teamId}/globalmapping/${gmId}/account/${accountId}/value`, body),
};

export { globalMapping };
