import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useLocation, useSearchParams } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../../reusable/Calendar/reconciliations-calendar.scss";
import { useStore } from "../../../store/Store";
import { Panel, PanelBody, PanelHeader, PanelFooter, LinkText, Text, Button, Heading, HyperLink } from "@fundrecs/ui-library";
import { MODALS, STATUS } from "../../../utils/enums";
import { stringifyDate, getDayString, getFirstDayOfMonth } from "../../../utils/dates";
import { ListItem } from "../../layout/ListItem";
import { StatusBadge } from "../../reusable/StatusBadge";

const CalendarPanel = observer(({ selectedDate, selectedAccount }) => {
  const { recStore, teamStore, recTypeStore } = useStore();
  const [date, setDate] = useState(selectedDate ? new Date(selectedDate) : null);
  const [recList, setRecList] = useState([]);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [calendarKey, setCalendarKey] = useState(0);
  const [recCalendarInitialised, setRecCalendarInitialised] = useState(false);
  const recType = recTypeStore.getSelectedRecType();

  const getRecsInDateRange = async (startDate) => {
    const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);

    let data = {
      dateFrom: stringifyDate(startDate),
      dateTill: stringifyDate(endDate),
      accountList: [selectedAccount.uuid],
      recTypeId: recType.id,
    };
    const updatedRecList = await recStore.getRecsInDateRange(teamStore.getSelectedTeam().id, data);
    let recs = updatedRecList.map((accountAndRec) => accountAndRec.rec);
    recs = recs.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
    setRecList(recs);
  };

  if (selectedAccount && Object.keys(selectedAccount).length && selectedDate && !recCalendarInitialised) {
    setDate(new Date(selectedDate));
    getRecsInDateRange(getFirstDayOfMonth(selectedDate));
    setRecCalendarInitialised(true);
  }

  const getClassNameForDatesWithRecs = (date) => {
    const calendarTileStyles = {
      [STATUS.DRAFT.status]: "calendar-open-rec",
      [STATUS.PENDING.status]: "calendar-pending-rec",
      [STATUS.APPROVED.status]: "calendar-completed-rec",
      [STATUS.IN_PROGRESS.status]: "calendar-pending-rec",
      [STATUS.IMPORTING_DATA.status]: "calendar-pending-rec",
    };
    const recFound = recList.find((rec) => stringifyDate(new Date(rec.startDate)) === stringifyDate(date));
    if (recFound) {
      return calendarTileStyles[recFound.fourEyes.status] ? calendarTileStyles[recFound.fourEyes.status] : "calendar-pending-rec";
    }
  };

  return (
    <Panel panelId={MODALS.CALENDAR}>
      <PanelBody>
        <PanelHeader description={`Calendar view of ${recType ? recType.name : ""} reconciliations`} header="Calendar" />
        <div className="pt-12 pb-12"></div>

        <div className="pt-32">
          <div className="calendar-container">
            <Calendar
              key={calendarKey}
              onActiveStartDateChange={(value) => {
                if (value.view === "month") {
                  getRecsInDateRange(value.activeStartDate);
                }
              }}
              onChange={setDate}
              value={date}
              tileClassName={({ date, view }) => {
                return getClassNameForDatesWithRecs(date);
              }}
            />
            <span className="today-button-container">
              <Button
                size="md"
                onClick={() => {
                  setDate(new Date());
                  setCalendarKey(calendarKey + 1);
                  getRecsInDateRange(getFirstDayOfMonth(new Date()));
                }}
                color="primary-secondary"
              >
                <Text size="sm" weight="medium">
                  Today
                </Text>
              </Button>
            </span>
          </div>
          <div className="pt-12 pb-12"></div>
          {recList.map((rec) => {
            const hrefLink = `${location.pathname}?teamId=${searchParams.get("teamId")}&recTypeId=${recType.id}&recId=${rec.id}&accId=${searchParams.get(
              "accId"
            )}`;
            return (
              <>
                <Heading variant="h6">{getDayString(new Date(rec.startDate))}</Heading>
                <ListItem
                  leftContent={<StatusBadge status={rec && rec.fourEyes ? rec.fourEyes.status : ""} recStatus={true} />}
                  title={recType ? recType.name : ""}
                  rightContent={
                    <>
                      <HyperLink href={hrefLink}>
                        <LinkText onClick={() => {}} size="sm">
                          <Text>View</Text>
                        </LinkText>
                      </HyperLink>
                      <span className="pr-24"></span>
                      <HyperLink target="_blank" href={hrefLink}>
                        <LinkText onClick={() => {}} size="sm">
                          <Text>Open in new tab</Text>
                        </LinkText>
                      </HyperLink>
                    </>
                  }
                />
                <div className="pt-12 pb-12"></div>
              </>
            );
          })}
        </div>
      </PanelBody>
      <PanelFooter></PanelFooter>
    </Panel>
  );
});

export { CalendarPanel };
