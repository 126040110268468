import { makeAutoObservable } from "mobx";
import { v4 as uuidv4 } from "uuid";

class Notification {
  id = null;
  type = null;
  message = null;

  constructor(type, message) {
    this.id = uuidv4();
    this.type = type;
    this.message = message;
  }
}

class UIStore {
  notifications = [];
  sideMenuOpen = true;

  constructor() {
    makeAutoObservable(this);
  }

  errorNotification(data) {
    const formatStringForEllipsis = (str) => {
      let formattedStr = str;
      if ((str.length > 20 && !str.includes(" ")) || str.split(" ")[0].length > 20) {
        formattedStr = `${str.substring(0, 21)}...`;
      }
      return formattedStr;
    };

    if (data.general) {
      console.log("FUSION RECS SERVER ERROR - general: ", data.general);
      this.notifications.unshift(new Notification("error", formatStringForEllipsis(data.general)));
    }

    if (data.requestBody && Object.values(data.requestBody) && Object.values(data.requestBody).length) {
      Object.values(data.requestBody).forEach((errorArray) => {
        errorArray.forEach((error) => {
          console.log("FUSION RECS SERVER ERROR - requestBody: ", error);
          this.notifications.unshift(new Notification("error", formatStringForEllipsis(error)));
        });
      });
    }
  }

  addNotification(type, message) {
    const notification = new Notification(type, message);

    this.notifications.unshift(notification);
  }

  removeNotification(id) {
    const index = this.notifications.findIndex((n) => n.id === id);

    if (index !== -1) {
      this.notifications.splice(index, 1);
    }
  }

  removeAllNotifications() {
    this.notifications.clear();
  }

  toggleSideMenu() {
    this.sideMenuOpen = !this.sideMenuOpen;
  }
}

export { UIStore };
