import { useState } from "react";
import { observer } from "mobx-react-lite";
import { Text, PanelBody, PanelFooter, Heading, C2, C8 } from "@fundrecs/ui-library";
import { SlideOverPanel } from "../../reusable/SlideOverPanel/SlideOverPanel";
import { AUTHORITIES, MODALS } from "../../../utils/enums";
import { EditableText } from "../../editable/EditableText";
import { EditableToggle } from "../../editable/EditableToggle";
import { useStore } from "../../../store/Store";
import { isUserAuthorized } from "../../AuthorizationWrapper";

const RecTypeSettingsPanel = observer(({ teamId, recType }) => {
  const [initialised, setInitialised] = useState(false);
  const [recTypeAndSettings, setRecTypeAndSettings] = useState(null);
  const { recTypeStore, rolesStore } = useStore();
  const REC_APPROVAL = "REC_REQUIRES_APPROVAL";

  const cardStyle = {
    padding: "8px",
    marginBottom: "16px",
    border: "1px solid #CDD0D7",
    borderRadius: "6px",
    background: "#fff",
    marginTop: "32px",
  };

  const rowStyle = {
    display: "flex",
    marginLeft: "16px",
    paddingTop: "16px",
    paddingBottom: "16px",
  };

  const fetchRecTypeAndSettings = async () => {
    const resp = await recTypeStore.fetchRecTypeAndSettings(teamId, recType.id);
    if (resp.status === 200) {
      setRecTypeAndSettings(resp.data);
    }
  };

  if (!initialised && recType && recType.id) {
    setInitialised(true);
    fetchRecTypeAndSettings();
  }

  const rowStyleWithBorder = { ...rowStyle, borderBottom: "1px solid #E6E7EB" };

  const DetailsPanel = ({ style, title, details }) => {
    return (
      <div style={style}>
        <C2>
          <Text size="sm" weight="regular">
            {title}
          </Text>
        </C2>
        <C8>{details}</C8>
        <C2></C2>
      </div>
    );
  };

  const updateFourEyesApproval = async (recTypeAndSettings, value) => {
    const resp = await recTypeStore.updateSetting(teamId, recType.id, recType.version, {
      type: "boolean",
      id: null,
      settingFor: REC_APPROVAL,
      value: value,
    });
    if (resp.status === 200) {
      let recApprovalSetting = recTypeAndSettings.settings.find((it) => it.settingFor === REC_APPROVAL);
      recApprovalSetting.value = value;
      recTypeAndSettings.settings.splice(recTypeAndSettings.settings.indexOf(recApprovalSetting), 1);
      setRecTypeAndSettings({ ...recTypeAndSettings, settings: [...recTypeAndSettings.settings, recApprovalSetting] });
      recTypeStore.setSelectedRecType(resp.data);
    }
  };

  let recRequiresApproval = false;
  if (recTypeAndSettings && recTypeAndSettings.settings.length) {
    const recApprovalSetting = recTypeAndSettings.settings.find((it) => it.settingFor === REC_APPROVAL);
    recRequiresApproval = recApprovalSetting && recApprovalSetting.value;
  }

  return (
    <SlideOverPanel id={MODALS.REC_TYPE_SETTINGS} width="50%">
      <PanelBody>
        <div className="panel-header">
          <div className="panel-header-left">
            <Heading variant="h4" element="span">
              {`${recType ? recType.name : "Reconciliation type"} settings`}
            </Heading>
          </div>
          <div className="panel-header-right">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {}}></button>
          </div>
          <div className="panel-header-clear"></div>
        </div>

        <div style={cardStyle}>
          <DetailsPanel
            style={rowStyleWithBorder}
            title="Name"
            details={<EditableText name="name" label="" value={recType ? recType.name : ""} onSave={(value) => {}} warningText="" disabled={true} />}
          />
          <DetailsPanel
            style={rowStyleWithBorder}
            title="Description"
            details={<EditableText name="name" label="" value={recType ? recType.description : ""} onSave={(value) => {}} warningText="" disabled={true} />}
          />
          <DetailsPanel
            style={rowStyle}
            title="Reconciliation approval"
            details={
              <EditableToggle
                originalValue={recRequiresApproval}
                onSave={(value) => {
                  updateFourEyesApproval(recTypeAndSettings, value);
                }}
                disabled={!isUserAuthorized({ teamId: teamId, allRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_TYPE_REC_APPROVAL_ENABLE]) })}
                description="When reconciliation approval is enabled any fund with this reconciliation type will require a second approver (4 eyes) for all reconcilaitions"
              />
            }
          />
        </div>
      </PanelBody>
      <PanelFooter></PanelFooter>
    </SlideOverPanel>
  );
});

export { RecTypeSettingsPanel };
