import { ToolTip } from "@fundrecs/ui-library";
import "./toolbarButtons.css";

const ToolBarButton = ({ icon, onClick, hoverText, disabled = false }) => {
  return (
    <ToolTip text={disabled ? "" : hoverText} direction={"top"} size="medium">
      <div
        className={disabled ? "disabled-toolbar-button" : "toolbar-button"}
        onClick={(e) => {
          if (!disabled) {
            onClick(e);
          }
        }}
      >
        {icon}
      </div>
    </ToolTip>
  );
};

export { ToolBarButton };
