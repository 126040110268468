import { Text, IconCloseRemove } from "@fundrecs/ui-library";
import { FlexiInput } from "./FlexiInput";
import { MultiSelectDropdown } from "./MultiSelectDropdown.js";
import { formatInputParameter, getInputType, getDisplayValue, DATA_TYPES, NOTES, LIST } from "./dataTransform";
import { useStore } from "../../store/Store";
import { ListStringInput } from "./ListInput";
import { formatStringArray } from "../../utils/utils";

const FunctionEditor = ({ condition, updateCondition, removeCondition, dropdownData, manualInput, tags, disabled = false }) => {
  const words = condition.isNew ? [] : condition.description.split(" ");
  let paramIndex = 0;
  let htmlArray = [];
  let sentence = "";

  const buildSentence = (array, sentence) => {
    if (sentence.length) {
      array.push(
        <div style={{ width: `${sentence.length * 10}px`, marginLeft: "16px" }}>
          <Text size="sm">{sentence.slice(0)}</Text>
        </div>
      );
    }
    return array;
  };

  /**
   * Transforms the data received and sent to make it both UI & BE consumable
   * @param {*} paramIndex
   * @param {*} params
   * @param {*} inputs
   * @returns
   */
  const getParameterData = (paramIndex, params, inputs) => {
    const paramType = inputs[paramIndex] ? inputs[paramIndex] : null;
    const param = params[paramIndex] ? params[paramIndex] : null;
    if (!Object.keys(DATA_TYPES).includes(paramType)) {
      return "";
    }

    return {
      //value: param.value,
      type: param.type ? param.type : DATA_TYPES[paramType],
      tmoType: param.tmoType ? param.tmoType : "",
      setInputValue: (value, type, tmoType = "") => {
        formatInputParameter(condition.params[paramIndex], value, type, tmoType);
        updateCondition(condition);
      },
      displayValue: getDisplayValue(param, dropdownData),
    };
  };

  words.forEach((word) => {
    if (word === "{var}") {
      htmlArray = buildSentence(htmlArray, sentence);
      sentence = "";
      const paramData = getParameterData(paramIndex, condition.params, condition.input);
      if (paramData) {
        const inputType = getInputType(condition.input, paramData.type, paramIndex);
        htmlArray.push(
          inputType === NOTES ? (
            <MultiSelectDropdown value={paramData.displayValue} setValue={paramData.setInputValue} dropdownData={tags} disabled={disabled} />
          ) : inputType === LIST ? (
            <ListStringInput value={paramData.displayValue} setValue={paramData.setInputValue} disabled={disabled} />
          ) : (
            <FlexiInput
              displayValue={paramData.displayValue}
              setValue={paramData.setInputValue}
              dropdownData={dropdownData}
              multiLevelDropdown={true}
              inputType={inputType}
              manualInputEnabled={manualInput}
              editType={paramData.type}
              disabled={disabled}
            />
          )
        );
      }
      paramIndex++;
    } else {
      sentence += word + " ";
    }
  });
  htmlArray = buildSentence(htmlArray, sentence);

  return (
    <>
      {htmlArray.map((component) => {
        return component;
      })}
      <div style={{ width: "5%" }}>
        {disabled ? (
          ""
        ) : (
          <IconCloseRemove
            className="btn-lg-svg"
            style={{ cursor: "pointer", color: "#838B9C" }}
            onClick={() => {
              removeCondition();
            }}
          />
        )}
      </div>
    </>
  );
};

const getColumnDisplayValue = (columns = [], value) => {
  const column = columns.find((it) => it.name === value);
  return column && column.displayName ? column.displayName : value;
};

const createParamString = (paramType, condition, paramIndex, tmoName, columns) => {
  return paramType === "constant"
    ? `"${condition.params[paramIndex]["value"]}"`
    : paramType === "notes"
    ? `${condition.params[paramIndex]["notes"].map((it) => {
        return ` ${it.name}`;
      })}`
    : paramType === "list"
    ? `${formatStringArray(condition.params[paramIndex]["value"])}`
    : `${tmoName} ${getColumnDisplayValue(columns, condition.params[paramIndex]["name"])}`;
};

const buildFunctionString = (index, tmo, condition, description, columns, tmoName) => {
  let str = `${index === 0 ? "If " : "and "}`;
  let paramIndex = 0;
  description.split(" ").forEach((word) => {
    if (word === "{var}") {
      const paramType = condition.params[paramIndex]["type"];
      word = createParamString(paramType, condition, paramIndex, tmoName, columns);
      paramIndex++;
    }
    str += `${word} `;
  });

  str += "\n";
  return str;
};

const BuildFunctionDescription = (index, tmo, condition, comparatorFunctionDescription) => {
  const { recTypeStore } = useStore();
  const recTypeColumnMap = recTypeStore.getRecTypeColumnMap();
  const groupTmoColumns = recTypeStore.getGroupTmoColumns();
  const columns = tmo && tmo.id ? recTypeColumnMap[tmo.id] : groupTmoColumns;

  const tmoName = tmo ? tmo.name : "";
  const start =
    index === 0 ? (
      <Text weight="regular" size="sm">
        If{" "}
      </Text>
    ) : (
      <Text weight="regular" size="sm">
        and{" "}
      </Text>
    );
  let description = [start];
  let paramIndex = 0;
  comparatorFunctionDescription.split(" ").forEach((word) => {
    let fontFamily = "Muli Regular";
    if (word === "{var}") {
      const paramType = condition.params[paramIndex]["type"];
      word = createParamString(paramType, condition, paramIndex, tmoName, columns);
      paramIndex++;
      fontFamily = paramType === "constant" ? fontFamily : "Muli ExtraBold";
    }
    description.push(<span className="text-sm" style={{ fontFamily: fontFamily, whiteSpace: "normal" }}>{`${word} `}</span>);
  });
  return (
    <>
      {description.map((component) => {
        return component;
      })}
    </>
  );
};

export { FunctionEditor, BuildFunctionDescription, buildFunctionString };
