import { makeAutoObservable, toJS } from "mobx";
import { fusionRecsApi } from "../api";
import { uiStore, rolesStore } from "./Store";
import { isUserAuthorized } from "../components/AuthorizationWrapper";
import { AUTHORITIES } from "../utils/enums";

class DataVerificationStore {
  draftDvRule = {};
  dvRulesForRecType = [];

  constructor() {
    makeAutoObservable(this);
  }

  getDraftDvRule = () => {
    return toJS(this.draftDvRule);
  };

  getDvRulesForRecType = () => {
    return toJS(this.dvRulesForRecType);
  };

  createDataVerificationRule = async (teamId, recTypeId, body) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_DATA_VERIFICATION_CREATE]) })) {
      console.log("createDataVerificationRule: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.dataVerification.createDataVerificationRule(teamId, recTypeId, body);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    } else {
      this.draftDvRule = resp.data;
    }
    return resp;
  };

  bulkUpdateDvRule = async (teamId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_DATA_VERIFICATION_CREATE]) })) {
      console.log("bulkUpdateDvRule: unauthorised");
      return { status: 401 };
    }
    const requestBody = {
      tmoId: this.draftDvRule.tmoId,
      operations: this.draftDvRule.conditions.map((condition) => {
        return { function: condition.function, params: condition.params };
      }),
      tagIds: this.draftDvRule.actions.map((action) => action.id),
    };
    const resp = await fusionRecsApi.dataVerification.bulkUpdateDvRule(teamId, this.draftDvRule.id, this.draftDvRule.version, requestBody);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    } else {
      uiStore.addNotification("success", "Data verification rule saved successfully");
      this.draftDvRule = {};
    }
    return resp;
  };

  updateDraftDvRule = (dvRule) => {
    this.draftDvRule = dvRule;
  };

  clearDraftDvRule = () => {
    this.draftDvRule = {};
  };

  fetchDvRulesForRecType = async (teamId, recTypeId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_DATA_VERIFICATION_VIEW]) })) {
      console.log("fetchDvRulesForRecType: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.dataVerification.getDvRulesForRecType(teamId, recTypeId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    } else {
      this.dvRulesForRecType = resp.data;
    }
    return resp;
  };

  deleteDvRule = async (teamId, dvRuleId, version) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_DATA_VERIFICATION_DELETE]) })) {
      console.log("deleteDvRule: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.dataVerification.deleteDvRule(teamId, dvRuleId, version);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    } else {
      uiStore.addNotification("success", "Verification rule deleted");
    }
    return resp;
  };
}

export { DataVerificationStore };
