import { useState } from "react";
import { observer } from "mobx-react-lite";
import "react-calendar/dist/Calendar.css";
import { useStore } from "../../../store/Store";
import { Text, Button, TextArea, IconAlertMessage } from "@fundrecs/ui-library";

const AddCommentPanel = observer(({ teamId, rowGroups, updateTable }) => {
  const { recStore } = useStore();
  const [tempComment, setTempComment] = useState("");
  const [initialised, setInitialised] = useState(false);
  const ADD_COMMENT = "addComment";
  const REMOVE_COMMENT = "removeComment";
  const [commentState, setCommentState] = useState(null);

  if (rowGroups && rowGroups.length && !initialised) {
    setInitialised(true);
  }

  const updateStateOnResponse = (responseData) => {
    setTempComment("");
    setCommentState(null);
    updateTable();
  };

  const saveComment = async (rowGroupsToUpdate = rowGroups, comment = " ") => {
    const data = { comment: comment };
    let rowGroupData = [];
    rowGroupsToUpdate.forEach((rowGroup) => {
      rowGroupData.push({ id: rowGroup.id, version: rowGroup.version, infoVersion: rowGroup.auxiliaryInfo.version });
    });
    data.rowGroups = rowGroupData;
    const response = await recStore.saveCommentToRowGroups(teamId, data);
    if (response.status === 200) {
      updateStateOnResponse(response.data);
    }
  };

  const groupsWithExistingComments = () => {
    return rowGroups.filter((rowGroup) => rowGroup.auxiliaryInfo.comment && rowGroup.auxiliaryInfo.comment.trim().length > 0);
  };

  const WarningPanel = ({ text }) => {
    return (
      <div className="mt-32 pl-8 pt-8 pr-8 pb-8 d-flex" style={{ background: "#FFF8F1", borderRadius: "4px" }}>
        <div className="mr-8">
          <IconAlertMessage color="#D36133" />
        </div>
        <Text size="sm" variant="warning" weight="regular" element="div">
          {text}
        </Text>
      </div>
    );
  };

  const SubmitButtons = ({ onSubmit }) => {
    return (
      <div className="mt-16 d-flex justify-content-end">
        <Button
          size="md"
          onClick={() => {
            setCommentState(null);
          }}
          disabled={false}
          color="tertiary"
        >
          <Text size="sm" weight="medium">
            Cancel
          </Text>
        </Button>
        <span className="ml-16"></span>
        <Button size="md" onClick={onSubmit} disabled={false} color="primary">
          <Text size="sm" weight="medium">
            Confirm
          </Text>
        </Button>
      </div>
    );
  };

  const CommentOptions = () => {
    return (
      <>
        <Text weight="regular" size="sm">
          What would you like to do?
        </Text>

        <div className="mt-16">
          <Button
            size="md"
            onClick={() => {
              setCommentState(REMOVE_COMMENT);
            }}
            color="primary-secondary"
          >
            <Text size="sm" weight="medium">
              Remove comment
            </Text>
          </Button>
          <span className="ml-16"></span>
          <Button
            size="md"
            onClick={() => {
              setCommentState(ADD_COMMENT);
            }}
            color="primary-secondary"
          >
            <Text size="sm" weight="medium">
              {groupsWithExistingComments().length ? "Replace comment" : "Add comment"}
            </Text>
          </Button>
        </div>
      </>
    );
  };

  const RemoveComment = () => {
    return (
      <>
        <Text weight="regular" size="sm">
          Are you sure you want to delete the selected comments?
        </Text>
        <SubmitButtons onSubmit={() => saveComment(groupsWithExistingComments())} />
        <WarningPanel text="The existing comments will be deleted." />
      </>
    );
  };

  return (
    <>
      <div className="mt-8">
        {commentState === ADD_COMMENT ? (
          <>
            <Text size="sm">Add comment</Text>
            <div className="mt-16">
              <TextArea
                value={tempComment}
                onChange={(event) => {
                  setTempComment(event.target.value);
                }}
              />
              <SubmitButtons
                onSubmit={() => {
                  saveComment(rowGroups, tempComment);
                }}
              />
            </div>
            <WarningPanel text="Adding a comment will replace any existing comments." />
          </>
        ) : commentState === REMOVE_COMMENT ? (
          <RemoveComment />
        ) : (
          <CommentOptions />
        )}
      </div>
    </>
  );
});

export { AddCommentPanel };
