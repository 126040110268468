import { createContext } from "react";

const SET_CATEGORY = "SET_CATEGORY";
const SET_PROCEED_AS_ROWS = "SET_PROCEED_AS_ROWS";
const SET_AGGREGATORS = "SET_AGGREGATORS";
const SET_TAGS = "SET_TAGS";
const SET_COMMENT = "SET_COMMENT";
const RESET = "RESET";

const getInitialMatchingConfig = () => {
  return { category: null, proceedAsRows: false, aggregators: null, tags: [], comment: null };
};

const matchingActions = {
  [SET_CATEGORY]: (config, action) => {
    return { ...config, category: action.category };
  },
  [SET_PROCEED_AS_ROWS]: (config, action) => {
    return { ...config, proceedAsRows: action.proceedAsRows };
  },
  [SET_AGGREGATORS]: (config, action) => {
    return { ...config, aggregators: action.aggregators };
  },
  [SET_TAGS]: (config, action) => {
    return { ...config, tags: action.tags };
  },
  [SET_COMMENT]: (config, action) => {
    return { ...config, comment: action.comment };
  },
  [RESET]: (config, action) => {
    return { category: null, proceedAsRows: false, aggregators: config.aggregators, tags: [], comment: null };
  },
};

function matchingReducer(value, action) {
  return matchingActions[action.type] === undefined
    ? new Error(`Unrecognised matching update: ${action.type}. Params: ${action}`)
    : matchingActions[action.type](value, action);
}

const MatchingContext = createContext(null);
const MatchingDispatchContext = createContext(null);

export {
  matchingReducer,
  getInitialMatchingConfig,
  MatchingContext,
  MatchingDispatchContext,
  SET_AGGREGATORS,
  SET_CATEGORY,
  SET_COMMENT,
  SET_TAGS,
  RESET,
  SET_PROCEED_AS_ROWS,
};
