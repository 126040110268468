import { observer } from "mobx-react-lite";
import { Text } from "@fundrecs/ui-library";
import { FundIdentifiersTable } from "../FundIdentifiersTable";

const FundIdentifiers = observer(({ teamId, account }) => {
  return (
    <>
      <div className="pt-32"></div>
      <Text size="lg">Fund Identifiers</Text>

      <Text weight="regular" size="sm" variant="secondary">
        Fund identifiers are any aliases used to identify a fund within the internal (admin) data.
      </Text>
      <div className="pt-16"></div>
      <FundIdentifiersTable teamId={teamId} account={account} />
    </>
  );
});

export { FundIdentifiers };
