import { observer } from "mobx-react-lite";

const StickyFooter = observer(({ children }) => {
  return (
    <div
      style={{
        position: "fixed",
        bottom: "0",
        left: "0",
        right: "0",
        padding: "1rem 2rem 1rem 2rem",
        backgroundColor: "#f7f8f9",
        display: "flex",
        flexWrap: "wrap",
        flexShrink: "0",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      {children}
    </div>
  );
});

export { StickyFooter };
