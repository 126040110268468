import { observer } from "mobx-react-lite";
import { R, Text, Button, Heading } from "@fundrecs/ui-library";
import { StickyFooter } from "../../layout/StickyFooter";
import { RecTypesTable } from "../RecTypesTable";

const AddRecTypesToAccount = observer(({ account, close, openNextStep }) => {

  return (
    <>
      <R props="mb-32 pb-32">
        <Heading variant="h3">Enable Reconciliation Types</Heading>

        <Text weight="regular" size="sm" variant="secondary">
          Select the types of reconciliation you want to perform on this fund. This step can be skipped but reconciliation types must be enabled before a
          reconciliation can take place. Reconciliation types can also be enabled and disabled after the fund has been setup.
        </Text>
        <Text>{`Enabled rec types for fund ${account ? account.name : ""}`}</Text>
      </R>
      <R>
        <RecTypesTable account={account} />
      </R>
      <R>
        <StickyFooter>
          <Button
            onClick={() => {
              openNextStep();
            }}
            size="md"
          >
            <Text size="sm">Next</Text>
          </Button>
        </StickyFooter>
      </R>
    </>
  );
});

export { AddRecTypesToAccount };
