import { makeAutoObservable, toJS } from "mobx";
import { fusionRecsApi } from "../api";
import { uiStore, rolesStore } from "./Store";
import { isUserAuthorized } from "../components/AuthorizationWrapper";
import { AUTHORITIES } from "../utils/enums";

/**
 * Contains data for clients for a team
 * Stored as a list of clients
 * Should be updated each time a team is changed
 */
class TmoAggregatorStore {

  aggregators = []

  constructor() {
    makeAutoObservable(this);
  }

  getTmoAggregatorsForRecType = async (teamId, recTypeId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_VIEW, AUTHORITIES.RECS_MATCHING_RULE_VIEW]) })) {
      console.log("getTmoAggregatorsForRecType: unauthorised");
      return { status: 401 };
    }

    const resp = await fusionRecsApi.tmoAggregator.getAggregatorsForRecType(teamId, recTypeId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    } else {
      this.aggregators = resp.data
    }
    return resp;
  };

  getAggregators = () => {
    return toJS(this.aggregators)
  }
}

export { TmoAggregatorStore };
