import { Text, C, R } from "@fundrecs/ui-library";

const HelpSection = ({ title = "GUIDE", children }) => {
  return (
    <div
      style={{
        position: "fixed",
        height: "83%",
        width: "16%",
        top: "15%",
        right: "25px",
        backgroundColor: "#071639",
        borderRadius: "12px",
      }}
    >
      <div>
        <div
          style={{
            color: "white",
            display: "inline-block",
            marginTop: "32px",
            marginLeft: "32px",
          }}
        >
          <Text size="xs" weight="medium">
            {title}
          </Text>
        </div>
        <div
          style={{
            display: "inline-block",
            border: "80px solid transparent",
            borderTop: "0px",
            borderRight: "6rem solid #4B7EFF",
            float: "right",
            borderRadius: "0px 12px 0px 0px",
          }}
        ></div>
      </div>
      <div style={{ height: "65%", width: "100%", overflowY: "scroll", paddingLeft: "16px", paddingRight: "16px" }}>{children}</div>
      <div
        style={{
          border: "100px solid transparent",
          borderBottom: "0px",
          borderLeft: "6rem solid #FCCB80",
          position: "absolute",
          bottom: "0px",
          borderRadius: "0px 0px 0px 12px",
        }}
      ></div>
    </div>
  );
};

const FilterAndGroupHelpText = ({}) => {
  return (
    <div style={{ color: "white" }}>
      <R>
        <Text size="lg" weight="medium">
          Filter and group
        </Text>
      </R>
      <R>
        <div className="mt-16"></div>
        <Text size="sm" weight="regular">
          The first step in creating a matching rule is to decide when the rule should be triggered, what data the rule should run on and how that data should
          be grouped and flattened before applying any actions
        </Text>
      </R>
      <div className="mt-16"></div>

      <R>
        <div className="col-1">
          <Text size="sm" weight="regular">
            1.
          </Text>
        </div>
        <C>
          <Text size="sm" weight="regular">
            Select which incoming data this rule should apply to
          </Text>
        </C>
      </R>
      <R>
        <div className="col-1">
          <Text size="sm" weight="regular">
            2.
          </Text>
        </div>
        <C>
          <Text size="sm" weight="regular">
            Further filter the incoming data so that the rule runs on a subset of the original data
          </Text>
        </C>
      </R>
      <R>
        <div className="col-1">
          <Text size="sm" weight="regular">
            3.
          </Text>
        </div>
        <C>
          <Text size="sm" weight="regular">
            Decide if the filtered data should be further grouped before running the rest of the rule
          </Text>
        </C>
      </R>
      <R>
        <div className="col-1">
          <Text size="sm" weight="regular">
            4.
          </Text>
        </div>
        <C>
          <Text size="sm" weight="regular">
            Select the grouping criteria for the filtered data so that the individual rows can be put into groups of rows
          </Text>
        </C>
      </R>
      <R>
        <div className="col-1">
          <Text size="sm" weight="regular">
            5.
          </Text>
        </div>
        <C>
          <Text size="sm" weight="regular">
            Flatten each group of rows into one single row using the selected flatten rules. The flatten rules determine how the data should be treated when
            flattened
          </Text>
        </C>
      </R>
    </div>
  );
};

const ConditionActionHelpText = ({}) => {
  return (
    <div style={{ color: "white" }}>
      <R>
        <Text size="lg" weight="medium">
          Condition and Action
        </Text>
      </R>
      <R>
        <div className="mt-16"></div>
        <Text size="sm" weight="regular">
          The next step is to choose what rows this rule applies to.
        </Text>
      </R>
      <R>
        <div className="mt-16"></div>
        <Text size="sm" weight="regular">
          You can use multiple conditions to target the exact rows to ensure the rule only applies to the right data.
        </Text>
      </R>
      <R>
        <div className="mt-16"></div>
        <Text size="sm" weight="regular">
          Finally pick a single action. Actions allow you to automate adding tags or assigning rows to specific categories.
        </Text>
      </R>
    </div>
  );
};

export { HelpSection, FilterAndGroupHelpText, ConditionActionHelpText };
