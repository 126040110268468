import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useSearchParams } from "react-router-dom";
import { R } from "@fundrecs/ui-library";
import { ModalHeader } from "../../layout/ModalHeader";
import { Step } from "../../reusable/step/step";
import { CreateSubAccount } from "./CreateSubAccount";
import { useStore } from "../../../store/Store";
import { AddCodesToSubAccount } from "./AddCodesToSubAccount";
import { FullScreenView } from "../../reusable/FullScreenView";
import { CreateSubAccountCode } from "./CreateSubAccountCode";

const SubAccountSetup = observer(({ fetchTableData, setupView = false, toggleSetupView }) => {
  const { subAccountStore } = useStore();
  const [recTypeRows, setRecTypeRows] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = searchParams.get("teamId");

  const steps = [
    { name: "STEP 01", index: 1 },
    { name: "STEP 02", index: 2 },
  ];

  const closeModalAndResetFields = () => {
    setActiveStep(steps[0]);
    subAccountStore.removeNewSubAccount();
    toggleSetupView();
    fetchTableData();
  };

  const [activeStep, setActiveStep] = useState(steps[0]);
  const subAccount = subAccountStore.getNewSubAccount();

  const refreshRows = async () => {
    setRecTypeRows(null);
    let tableData = [];
    let rows = [];
    if (subAccount) {
      const response = await subAccountStore.getSubAccountsAndAliasesForTeam(teamId);
      if (response.status === 200) {
        const subAccountData = response.data.find((it) => it.id === subAccount.id);
        tableData = subAccountData ? [subAccountData] : [];
      }
    }
    tableData.forEach((subAccountData) => {
      subAccountData.recTypeList.forEach((recType) => {
        const subAccountRecTypeFields = {
          subAccountId: subAccountData.id,
          subAccountName: subAccountData.name,
          recTypeId: recType.id,
          recTypeName: recType.name,
        };
        rows.push(subAccountRecTypeFields);
        recType.aliasList.forEach((alias) => {
          rows.push({ ...subAccountRecTypeFields, subAccountCodeId: alias.id, subAccountCode: alias.alias });
        });
      });
    });
    setRecTypeRows(rows);
  };

  return !setupView ? (
    ""
  ) : (
    <>
      <FullScreenView>
        <div className="pt-32 pb-32 pr-32 pl-32">
          <ModalHeader onCloseClick={closeModalAndResetFields} title={subAccount ? subAccount.name : "New sub account"} />
          <R props="ml-72 mr-72 mt-32">
            <div className="mb-72">
              <Step steps={steps} activeStep={activeStep} />
            </div>
          </R>

          {activeStep.index === 1 ? (
            <CreateSubAccount
              close={closeModalAndResetFields}
              openNextStep={() => {
                setActiveStep(steps[1]);
              }}
            />
          ) : activeStep.index === 2 ? (
            <AddCodesToSubAccount recTypeRows={recTypeRows} refreshRows={refreshRows} close={closeModalAndResetFields} openNextStep={() => {}} />
          ) : (
            ""
          )}
        </div>
      </FullScreenView>
      <CreateSubAccountCode subAccount={subAccount} updateTable={refreshRows} />
    </>
  );
});

export { SubAccountSetup };
