import { STATUS } from "../../utils/enums";

const transformGlobalMappingFormat = (globalMapping, tmos, recTypeColumns) => {
  let formattedMapping = {
    one: { tmo: null, column: null, context: null },
    many: { tmo: null, column: null, context: null },
    requiresApproval: globalMapping && globalMapping.requiresApproval ? globalMapping.requiresApproval : false,
  };
  tmos.forEach((tmo) => {
    let oneOrMany = "";
    let column = null;
    let context = null;
    let tmoColumns = recTypeColumns[tmo.id];
    if (tmoColumns && globalMapping) {
      Object.entries(globalMapping).forEach((entry) => {
        if (["many", "one"].includes(entry[0])) {
          if (entry[1]["id"] === tmo.id) {
            oneOrMany = entry[0];
            column = tmoColumns.find((it) => it.id === entry[1]["column"]["id"]);
            if (entry[1]["context"]) {
              context = tmoColumns.find((it) => it.id === entry[1]["context"]["id"]);
            }
            formattedMapping[oneOrMany] = { tmo: tmo.id, column: null, context: null };
          }
        }
      });

      formattedMapping[oneOrMany] = { tmo: tmo.id, column: column ? column.id : null, context: context ? context.id : null };
    }
  });
  return formattedMapping;
};

const checkForPendingGlobalMappingEntries = (globalMappingEntries) => {
  let containsPendingEntries = false;
  for (let i = 0; i < globalMappingEntries.length; i++) {
    if (globalMappingEntries[i]["status"] === STATUS.PENDING.text) {
      containsPendingEntries = true;
      break;
    }
  }
  return containsPendingEntries;
};

export { transformGlobalMappingFormat, checkForPendingGlobalMappingEntries };
