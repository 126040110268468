import { makeAutoObservable, toJS } from "mobx";
import { fusionRecsApi } from "../api";
import { uiStore, rolesStore } from "./Store";
import { isUserAuthorized } from "../components/AuthorizationWrapper";
import { AUTHORITIES } from "../utils/enums";

class UploadedFileStore {
  constructor() {
    makeAutoObservable(this);
  }

  getUploadedFileById = async (teamId, fileId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_UPLOADED_FILE_VIEW]) })) {
      console.log("getUploadedFileById: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.uploadedFile.getUploadedFileById(teamId, fileId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  getUploadedFileRowCountsById = async (teamId, fileId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_UPLOADED_FILE_VIEW]) })) {
      console.log("getUploadedFileRowCountsById: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.uploadedFile.getUploadedFileRowCountsById(teamId, fileId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  getUploadedFilesByDate = async (teamId, body) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_UPLOADED_FILE_VIEW]) })) {
      console.log("getUploadedFilesByDate: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.uploadedFile.getUploadedFilesByDate(teamId, body);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  getUploadedFileRowCountsByDate = async (teamId, body) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_UPLOADED_FILE_VIEW]) })) {
      console.log("getUploadedFileRowCountsByDate: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.uploadedFile.getUploadedFileRowCountsByDate(teamId, body);
    if (resp.status !== 200) {
      //uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  findRecDataByUploadedFileId = async (teamId, fileId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_UPLOADED_FILE_VIEW]) })) {
      console.log("findRecDataByUploadedFileId: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.uploadedFile.findRecDataByUploadedFileId(teamId, fileId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  getRowDataWithErrors = async (teamId, fileId, diagnostic) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_UPLOADED_FILE_VIEW]) })) {
      console.log("getRowDataWithErrors: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.uploadedFile.getRowDataWithErrors(teamId, fileId, diagnostic);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  deleteFiles = async (teamId, data) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_UPLOADED_FILE_DELETE]) })) {
      console.log("deleteFiles: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.uploadedFile.deleteFiles(teamId, data);
    if (resp.status === 200) {
      uiStore.addNotification("success", "File(s) successfully deleted");
    } else {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  deleteRowsFromRec = async (teamId, fileId, recId, fileVersion, recVersion) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_UPLOADED_FILE_ROWS_DELETE]) })) {
      console.log("deleteRowsFromRec: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.uploadedFile.deleteRowsFromRec(teamId, fileId, recId, fileVersion, recVersion);
    if (resp.status === 200) {
      uiStore.addNotification("success", "File data successfully deleted");
    } else {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  downloadFile = async (teamId, fileId, responseType) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_UPLOADED_FILE_DOWNLOAD]) })) {
      console.log("downloadFile: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.uploadedFile.downloadFile(teamId, fileId, responseType);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  uploadFile = async (teamId, body) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_UPLOAD_FILE]) })) {
      console.log("uploadFile: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.uploadedFile.uploadFile(teamId, body);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  reimportData = async (teamId, body) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_UPLOAD_FILE]) })) {
      console.log("reimportData: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.uploadedFile.reimportData(teamId, body);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };
}

export { UploadedFileStore };
