import { Text, IconCloseRemove, C, C10, C2 } from "@fundrecs/ui-library";
import styles from "./Modals.module.scss";

/**
 * The ModalHeader component renders a close button and a title for the modal.
 *
 * Clicking the close button calls the `onCloseClick` callback which is passed in as a prop.
 *
 * @param {function} onCloseClick - function to be called when the close button is triggered
 * @param {string} title - title for the modal
 */
const ModalHeader = ({ onCloseClick, title, contentLeft, contentRight }) => (
  <>
    <C10>
      <Text size="md" weight="bold">
        {title}
      </Text>
    </C10>
    <C2 props="pl-32">
      <IconCloseRemove
        className={["btn-lg-svg", styles.iconCloseRemove].join(" ")}
        onClick={() => {
          onCloseClick();
        }}
      />
    </C2>
  </>
);

const ModalFooter = ({ children }) => (
  <div className="pt-48 ">
    <div className={styles.footer}>
      <div className="d-flex flex-row-reverse">{children}</div>
    </div>
  </div>
);

const ModalFooterSticky = ({ children }) => (
  <div className="pt-48 ">
    <div className={styles.stickyfooter}>
      <div className="d-flex flex-row-reverse">{children}</div>
    </div>
  </div>
);

export { ModalHeader, ModalFooter, ModalFooterSticky };
