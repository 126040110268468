import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { R, C6, Text, Card, Input, Button, Heading } from "@fundrecs/ui-library";
import { useStore } from "../../../store/Store";
import { NumberedSection } from "../../layout/Layout";
import "../../accounts/AccountSetup/account-setup.css";
import { ModalFooterSticky } from "../../layout/Modals";

const CreateSubAccount = observer(({ close, openNextStep }) => {
  const { subAccountStore } = useStore();
  const subAccountObject = { name: "" };
  const warningMessageObject = { name: "" };
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = searchParams.get("teamId");
  const [subAccount, setSubAccount] = useState(subAccountObject);
  const [warningMessage, setWarningMessage] = useState(warningMessageObject);

  const clearInputs = () => {
    setSubAccount(subAccountObject);
    setWarningMessage(warningMessageObject);
  };

  const saveSubAccount = async () => {
    let warningMessageObject = { name: "" };
    let warning = false;
    if (subAccount.name.length < 1) {
      warningMessageObject = { ...warningMessageObject, name: "Please enter a name for the sub account" };
      warning = true;
    }

    if (!warning) {
      const reqBody = { name: subAccount.name };
      const response = await subAccountStore.createSubAccount(teamId, reqBody);
      if (response.status === 200) {
        clearInputs();
        openNextStep();
      }
    }
    setWarningMessage(warningMessageObject);
  };

  return (
    <>
      <R props="mb-32 pb-32">
        <Heading variant="h3">Setup a sub account</Heading>
        <Text weight="regular" size="sm" variant="secondary">
          Add basic sub account details
        </Text>
      </R>
      <R>
        <NumberedSection title="Sub account name" description="Choose a unique name" number={1} hidden={false}>
          <Card spacers={["pt-32", "pr-32", "pb-32", "pl-32"]}>
            <R props="mb-32">
              <C6>
                <Text size="sm" weight="medium" variant="secondary" element="div">
                  Sub account name *
                </Text>
                <Input
                  onChange={(event) => {
                    setSubAccount({ ...subAccount, name: event.target.value });
                  }}
                  value={subAccount.name}
                  warning={warningMessage.name.length > 1}
                  warningMessage={warningMessage.name}
                />
              </C6>
            </R>
          </Card>
        </NumberedSection>
      </R>
      <R>
        <ModalFooterSticky>
          <Button
            color="tertiary"
            onClick={() => {
              clearInputs();
              close();
            }}
          >
            <Text size="sm">Cancel</Text>
          </Button>
          <div className="ml-16"></div>
          <Button
            onClick={() => {
              saveSubAccount();
            }}
            size="md"
            disabled={false}
          >
            <Text size="sm">Create sub account</Text>
          </Button>
        </ModalFooterSticky>
      </R>
    </>
  );
});

export { CreateSubAccount };
