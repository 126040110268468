import { useState, useRef } from "react";
import { IconDownArrow, IconRightArrow, Text } from "@fundrecs/ui-library";
import { Table } from "../ag-grid/Ag-grid";

const GlobalMappingEntriesPanel = ({ globalMappingEntries }) => {
  const gridRef = useRef(null);
  const [open, setOpen] = useState(false);
  let rowData = [];
  let columnDefs =
    globalMappingEntries && globalMappingEntries.length
      ? [
          { field: "oneSide", headerName: globalMappingEntries[0]["oneSide"] },
          { field: "oneContext", headerName: globalMappingEntries[0]["oneContext"] },
          { field: "manySide", headerName: globalMappingEntries[0]["manySide"] },
          { field: "manyContext", headerName: globalMappingEntries[0]["manyContext"] },
        ]
      : [];

  if (globalMappingEntries !== null) {
    globalMappingEntries.forEach((mapping) => {
      mapping.entries.forEach((entry) => {
        rowData.push(entry);
      });
    });
  }

  return (
    <div className="mt-16" style={{ borderTop: "1px solid #E6E8EB", borderBottom: "1px solid #E6E8EB" }}>
      <div className="pt-8 pb-8">
        <span
          style={{ cursor: "pointer", color: "#6A7388" }}
          onClick={() => {
            if (rowData.length) {
              setOpen(!open);
            }
          }}
        >
          {open ? <IconDownArrow className="btn-lg-svg" /> : <IconRightArrow className="btn-lg-svg" />}

          <Text variant="tertiary">{`${rowData.length} global ${rowData.length === 1 ? "mapping" : "mappings"} to be created`}</Text>
        </span>
      </div>
      {open ? (
        <Table
          columnDefs={columnDefs}
          rowData={globalMappingEntries === null ? null : rowData}
          ref={gridRef}
          sizeToFit={true}
          paginationSize={10}
          height={`${17 + (rowData.length - 1) * 8}vh`}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export { GlobalMappingEntriesPanel };
