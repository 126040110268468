import { observer } from "mobx-react-lite";
import { R, Text, Button, Heading } from "@fundrecs/ui-library";
import { StickyFooter } from "../../layout/StickyFooter";
import { SubAccountTable } from "../SubAccountTable";

const EnableSubAccounts = observer(({ account, close, openNextStep }) => {
  return (
    <>
      <R props="mb-32 pb-32">
        <Heading variant="h3">Enable sub-accounts</Heading>
        <Text weight="regular" size="sm" variant="secondary">
          Enable any sub accounts required for each reconciliation type. Add external fund aliases for each enabled reconciliation type. Fund aliases are any
          name used to identify a fund within the external (admin) data. These can be added later.
        </Text>
      </R>
      <R>
        <SubAccountTable account={account} />
      </R>
      <R>
        <StickyFooter>
          <Button
            onClick={() => {
              close();
            }}
            size="md"
            disabled={false}
          >
            <Text size="sm">Done</Text>
          </Button>
        </StickyFooter>
      </R>
    </>
  );
});

export { EnableSubAccounts };
