import { IconDownload, ToolTip } from "@fundrecs/ui-library";

const DownloadTableButton = ({ gridRef, createFileName, direction="left" }) => {
  return (
    <ToolTip text="Download Table" direction={direction} size="large">
      <span
        style={{ cursor: "pointer" }}
        onClick={() => {
          gridRef.current.api.exportDataAsCsv({ fileName: createFileName() });
        }}
      >
        <IconDownload className="btn-lg-svg" />
      </span>
    </ToolTip>
  );
};

export { DownloadTableButton };
