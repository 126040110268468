import { useState, useReducer } from "react";
import { observer } from "mobx-react-lite";
import { useSearchParams, Link } from "react-router-dom";
import { Text, Button, ToolTip, VerticalMenuDropdown, VerticalMenuListItem, modalInstance } from "@fundrecs/ui-library";
import { MainContainer, PageTitleArea } from "../layout/Layout";
import { MODALS, STATUS } from "../../utils/enums.js";
import { useStore } from "../../store/Store.js";
import { getDateStringFromTimestamp } from "../../utils/dates";
import { PendingCompletedRecView } from "./PendingCompletedRecView.js";
import { OpenRecView } from "./OpenRecView.js";
import { PATH } from "../../utils/urls.js";
import { ManualFileUploadModal } from "../uploads/ManualFileUploadModal.js";
import { StatusBadge } from "../reusable/StatusBadge.js";
import { ifNullUndefinedArray } from "../../utils/utils";
import { matchingReducer, getInitialMatchingConfig, MatchingContext, MatchingDispatchContext } from "./matchingReducer";

const Reconciliations = observer(() => {
  const { recStore, recTypeStore, teamStore, globalMappingStore, uiStore, accountStore } = useStore();
  const rec = recStore.getSelectedRec();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const accountId = Number(searchParams.get("accId"));
  const recId = Number(searchParams.get("recId"));
  const recTypeId = Number(searchParams.get("recTypeId"));
  const teamId = Number(searchParams.get("teamId"));
  const [selectedAccount, setSelectedAccount] = useState({});
  const [initialised, setInitialised] = useState(false);
  const [globalMapping, setGlobalMapping] = useState(null);
  const [subAccounts, setSubAccounts] = useState([]);
  const [rowGroupSchema, setRowGroupSchema] = useState([]);
  const [diffCalcHidden, setDiffCalcHidden] = useState(false);

  const globalMappingsEnabledForAccount =
    globalMapping && globalMapping.enabled && globalMapping.status === "APPROVED" && globalMapping.accounts.find((it) => it.id === accountId) ? true : false;

  let recType = recTypeStore.getSelectedRecType();
  recType = recType ? recType : recTypeStore.fetchRecTypeAndSettings(teamId, recTypeId);

  const getUploadedFilesForRec = async () => {
    let uploadedFiles = await recStore.getUploadedFilesForRec(teamId, recId);
    uploadedFiles = uploadedFiles.length ? uploadedFiles.filter((file) => file.status !== "DELETED") : uploadedFiles;
    setUploadedFiles(uploadedFiles);
  };

  const recStatus = rec.fourEyes && rec.fourEyes.status ? rec.fourEyes.status : "";

  const getGlobalMapping = async () => {
    const resp = await globalMappingStore.fetchGlobalMappingByRecTypeId(teamId, recTypeId);
    if (resp.status === 200 && resp.data.length) {
      setGlobalMapping(resp.data[0]);
    }
  };

  const initaliseClientsAccountsAndRec = async () => {
    setInitialised(true);
    //Ensure the clients, accounts & sub accounts have been fetched
    const clientsAccountsPerRecType = recTypeStore.getClientsAccountsPerRecType();
    if (!clientsAccountsPerRecType.length) {
      await recTypeStore.fetchClientsAccountsPerRecType(teamId, recTypeId);
    }
    const selectedAccount = recTypeStore.getAccountById(accountId);
    if (!recType) {
      recTypeStore.fetchRecTypeAndSettings(teamId, recTypeId);
    }
    if (!selectedAccount) {
      let fundName = "this fund";
      const accountsPerTeam = await accountStore.fetchAccountsForTeam(teamId);
      if (accountsPerTeam.length) {
        const fund = accountsPerTeam.find((it) => it.id === accountId);
        fundName = fund ? fund.name : fundName;
      }
      uiStore.addNotification("error", `To view this rec, please enable ${fundName} for ${recType.name}`);
    }
    recStore.getRecById(teamId, recId);
    setSelectedAccount(selectedAccount);
    const subAccountsResp = await recTypeStore.getSubAccounts(accountId);
    setSubAccounts(subAccountsResp);
    const rowGroupSchemaResp = await recTypeStore.getRowGroupSchema(teamId, recTypeId);
    setRowGroupSchema(rowGroupSchemaResp);
    getGlobalMapping();
    getUploadedFilesForRec();
  };

  const pollRecStatus = async (status = recStatus) => {
    //Check that the user is still on the rec page
    if (window.location.href.includes("/rec?")) {
      const resp = await recStore.pollRecStatus(teamStore.getSelectedTeam().id, rec.id);
      if (resp.status === 200 && status !== resp.data.status) {
        window.location.reload();
      } else {
        setTimeout(() => {
          pollRecStatus(status);
        }, 10000);
      }
    }
  };

  const recTypes = ifNullUndefinedArray(recTypeStore.getRecTypes());
  if (recTypes.length && !initialised) {
    initaliseClientsAccountsAndRec();
  }

  const [matchingConfig, dispatch] = useReducer(matchingReducer, getInitialMatchingConfig());

  const openRecView = ![STATUS.APPROVED.status, STATUS.PENDING.status, STATUS.REPORT_CREATE.status, STATUS.REPORT_CLEAR.status].includes(recStatus);
  const recLoaded = Object.keys(rec).length;

  return (
    <MainContainer>
      <MatchingContext.Provider value={matchingConfig}>
        <MatchingDispatchContext.Provider value={dispatch}>
          <PageTitleArea
            title={`${recType && recType.name ? recType.name : ""} > ${selectedAccount && selectedAccount.name ? selectedAccount.name : ""} > ${
              !rec || !rec.startDate ? "" : getDateStringFromTimestamp(rec.startDate)
            }`}
            description={<StatusBadge status={recStatus} recStatus={true} />}
            borderBottom={false}
            props={
              <>
                {!openRecView ? (
                  <ToolTip text="View Calendar" direction="bottom" size="medium">
                    <Button
                      size="md"
                      onClick={() => {
                        modalInstance(MODALS.CALENDAR).show();
                      }}
                      disabled={false}
                    >
                      <Text size="sm" weight="medium">
                        View Calendar
                      </Text>
                    </Button>
                  </ToolTip>
                ) : (
                  ""
                )}
                <span className="pl-32"></span>
                <div style={{ borderRadius: "100%", height: "40px", width: "40px", border: "1px solid #CDD0D7", paddingTop: "5px", textAlign: "center" }}>
                  <VerticalMenuDropdown>
                    {openRecView ? (
                      <VerticalMenuListItem
                        text={`${diffCalcHidden ? "Show" : "Hide"} Difference Calculator`}
                        onClick={() => {
                          setDiffCalcHidden(!diffCalcHidden);
                        }}
                      />
                    ) : (
                      <>
                        <VerticalMenuListItem
                          text="View Uploaded Files"
                          onClick={() => {
                            modalInstance(MODALS.UPLOADED_FILES).show();
                          }}
                        />
                        {rec && rec.startDate ? (
                          <Link
                            className="text-decoration-none"
                            to={`${PATH.REC_AUDIT_TRAIL}?teamId=${teamId}&recType=${recType.name}&account=${
                              selectedAccount.name
                            }&recId=${recId}&date=${getDateStringFromTimestamp(rec.startDate)}`}
                          >
                            <VerticalMenuListItem text="View Audit Trail" onClick={() => {}} />
                          </Link>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </VerticalMenuDropdown>
                </div>
              </>
            }
          />
          {recLoaded && !openRecView ? (
            <PendingCompletedRecView
              globalMappingsEnabledForAccount={globalMappingsEnabledForAccount}
              globalMapping={globalMapping}
              selectedAccount={selectedAccount}
              uploadedFiles={uploadedFiles}
              pollRecStatus={pollRecStatus}
              subAccounts={subAccounts}
              rowGroupSchema={rowGroupSchema}
            />
          ) : recLoaded ? (
            <>
              <OpenRecView
                recType={recType}
                selectedAccount={selectedAccount}
                uploadedFiles={uploadedFiles}
                pollRecStatus={pollRecStatus}
                globalMappingsEnabledForAccount={globalMappingsEnabledForAccount}
                globalMapping={globalMapping}
                subAccounts={subAccounts}
                rowGroupSchema={rowGroupSchema}
                diffCalcHidden={diffCalcHidden}
              />
              <ManualFileUploadModal teamId={teamId} recType={recType} />
            </>
          ) : (
            ""
          )}
        </MatchingDispatchContext.Provider>
      </MatchingContext.Provider>
    </MainContainer>
  );
});

export { Reconciliations };
