import { useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { modalInstance, R, C10, Panel, PanelBody, PanelFooter, Heading, Text, TextArea, Input, Button } from "@fundrecs/ui-library";
import { ModalHeader } from "../../layout/ModalHeader";
import { Step } from "../../reusable/step/step";
import { MODALS } from "../../../utils/enums";
import { Table } from "../../ag-grid/Ag-grid";
import { CreateAccount } from "./CreateAccount";
import { AddRecTypesToAccount } from "./AddRecTypesToAccount";
import { useStore } from "../../../store/Store";

const CreateClient = observer(({}) => {
  const { clientStore } = useStore();
  const clientObject = { name: "", description: "" };
  const warningMessageObject = { name: "" };
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = searchParams.get("teamId");
  const [client, setClient] = useState(clientObject);
  const [warningMessage, setWarningMessage] = useState(warningMessageObject);

  const closeModal = () => {
    setWarningMessage(warningMessageObject);
    setClient(clientObject);
    modalInstance(MODALS.CREATE_CLIENT).hide();
  };

  const createClient = async () => {
    let warningMessageObject = { name: "" };
    let warning = false;
    if (client.name.length < 1) {
      warningMessageObject = { ...warningMessageObject, name: "Please enter a name for the client" };
      warning = true;
    }

    if (!warning) {
      const reqBody = { name: client.name, description: client.description };
      const response = await clientStore.createClient(teamId, reqBody);
      if (response.status === 200) {
        closeModal();
        clientStore.fetchClientsForTeam(teamId);
      }
    }
    setWarningMessage(warningMessageObject);
  };

  return (
    <Panel panelId={MODALS.CREATE_CLIENT}>
      <PanelBody>
        <div className="panel-header">
          <div className="panel-header-left">
            <Heading variant="h4" element="span">
              New Client
            </Heading>
          </div>
          <div className="panel-header-right">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {}}></button>
          </div>
          <div className="panel-header-clear"></div>
        </div>
        <div className="panel-description">
          <Text size="sm" variant="secondary" weight="regular"></Text>
          <Text size="sm" variant="secondary" weight="bold"></Text>
        </div>
        <div className="pt-32">
          <R props="mb-32">
            <C10>
              <Text size="sm" weight="medium" variant="secondary" element="div">
                Client name *
              </Text>
              <Input
                onChange={(event) => {
                  setClient({ ...client, name: event.target.value });
                }}
                value={client.name}
                warning={warningMessage.name.length > 1}
                warningMessage={warningMessage.name}
              />
            </C10>
          </R>
          <R props="mb-32">
            <C10>
              <Text size="sm" weight="medium" variant="secondary" element="div">
                Client description
              </Text>
              <TextArea
                onChange={(event) => {
                  setClient({ ...client, description: event.target.value });
                }}
                value={client.description}
                warning={false}
                warningMessage={null}
              />
            </C10>
          </R>
        </div>
      </PanelBody>
      <PanelFooter>
        <Button
          color="tertiary"
          onClick={() => {
            closeModal();
          }}
        >
          <Text size="sm">Cancel</Text>
        </Button>
        <Button
          onClick={() => {
            createClient();
          }}
          size="md"
          disabled={false}
        >
          <Text size="sm">Create Client</Text>
        </Button>
      </PanelFooter>
    </Panel>
  );
});

export { CreateClient };
