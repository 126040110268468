
const ifNullUndefinedArray = (data) => {
    return [null, undefined].includes(data) ? [] : data
}

/**
 * Take a stringified array of strings & convert back to array
 * @param {*} value 
 * @returns 
 */
const formatStringArray= (value) => {
    let text = value.split("[");
    text = text[1].split("]")[0];
    text = text.replaceAll('"', "");
    text = text.split(",");
    return text.map((it) => it.trim());
}


export {ifNullUndefinedArray, formatStringArray}