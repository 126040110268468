import { useState } from "react";
import { observer } from "mobx-react-lite";
import { IconCloseRemove } from "@fundrecs/ui-library";
import { ReactComponent as ColumnIcon } from "../../icons/table.svg";
import { ReactComponent as ValueIcon } from "../../icons/value.svg";
import { generateInitialDropdownOptions, generateDropdownData, MANUAL_INPUT_VALUE, COLUMN } from "./dataTransform";
import "./rules.css";

const FlexiInput = observer(
  ({
    displayValue,
    setValue,
    dropdownData,
    multiLevelDropdown = true,
    manualInputEnabled = true,
    inputType = "text",
    type,
    width = "16rem",
    editType,
    disabled = false,
  }) => {
    const initialDropdownOptions = generateInitialDropdownOptions(manualInputEnabled, Object.keys(dropdownData));
    const EDITING_PARAM_TYPE = { CONSTANT: "constant", COLUMN: "column", TAG: "TAG", CATEGORY: "CATEGORY" };

    /**
     * If a param is being edited check the edit type to
     * populate firstDropdownSelection accordingly
     * @returns
     */
    const initialiseFirstDropdownSelection = () => {
      let value = "";
      if (editType === EDITING_PARAM_TYPE.CONSTANT) {
        value = "Value";
      } else if (editType === EDITING_PARAM_TYPE.COLUMN) {
        value = Object.keys(dropdownData).length === 1 ? Object.keys(dropdownData)[0] : "Column";
      } else if (editType === EDITING_PARAM_TYPE.TAG) {
        value = "Tag as ";
      } else if (editType === EDITING_PARAM_TYPE.CATEGORY) {
        value = "Assign to ";
      }
      return value;
    };
    dropdownData = generateDropdownData(dropdownData, type);
    const DROPDOWN_INPUT = "dropdownInput";
    const MANUAL_INPUT = "manualInput";
    const [inputFormat, setInputFormat] = useState(editType === EDITING_PARAM_TYPE.CONSTANT ? MANUAL_INPUT : DROPDOWN_INPUT);
    const [firstDropdownSelection, setFirstDropdownSelection] = useState(initialiseFirstDropdownSelection());
    const [dropdownOptions, setDropdownOptions] = useState(initialDropdownOptions);

    const sortAlphabetically = (a, b) => {
      if (a.uiValue < b.uiValue) {
        return -1;
      }
      if (a.uiValue > b.uiValue) {
        return 1;
      }
      return 0;
    };

    /**
     * Called when dropdown is clicked for the first time on a multiLevelDropdown - this determines the next step for the dropdown
     * - Stop propagation to keep the dropdown open
     * - Set the description text with what has been selected
     * - Determine if a manual input has been selected, or if the dropdown list needs to be updated
     * - If dropdown list is updated, ensure all options coeespond to inputType
     * @param {*} event
     * @param {*} option
     */
    const firstOptionDropdownClick = (event, option) => {
      event.stopPropagation();
      setFirstDropdownSelection(option);
      setValue(null);
      if (manualInputEnabled && option === MANUAL_INPUT_VALUE.dataValue) {
        setInputFormat(MANUAL_INPUT);
      } else {
        const orderedOptions = inputType === "all" ? dropdownData[option] : dropdownData[option].filter((it) => it.dataType === inputType);

        orderedOptions.sort((a, b) => {
          return sortAlphabetically(a, b);
        });
        setDropdownOptions(orderedOptions);
      }
    };

    /**
     * Called when dropdown is clicked for the final time - this selects the value for the input
     * - Set the value for the input
     * - Reset the dropdown list
     * @param {*} event
     * @param {*} option
     */
    const secondOptionDropdownClick = (option) => {
      setValue(option, COLUMN, firstDropdownSelection);
      setDropdownOptions(initialDropdownOptions);
    };

    /**
     * Called whenever a dropdown option is clicked
     * If this is a multilevel dropdown and the selected option is in initialDropdownOptions, use firstOptionDropdownClick to determine next step
     * Otherwise set the value using secondOptionDropdownClick
     * @param {*} event
     * @param {*} option
     */
    const dropdownClick = (event, option) => {
      if (multiLevelDropdown && initialDropdownOptions.map((it) => it.dataValue).includes(option)) {
        firstOptionDropdownClick(event, option);
      } else {
        secondOptionDropdownClick(option);
      }
    };

    const iconStyle = { margin: "0 3px 3px 0" };

    return (
      <div style={disabled ? { pointerEvents: "none", opacity: "0.65", width: width } : { width: width }}>
        {inputFormat === DROPDOWN_INPUT ? (
          <>
            <div
              data-bs-toggle={inputFormat === DROPDOWN_INPUT ? "dropdown" : ""}
              className="dropdown-button-container ellipsize-text"
              title={displayValue}
              disabled={disabled}
            >
              {firstDropdownSelection ? <ColumnIcon style={iconStyle} /> : <span style={{ color: "#838B9C" }}>Select</span>}
              {firstDropdownSelection} <b>{displayValue}</b>
            </div>
            <ul className="dropdown-menu dropdown-sizing" aria-labelledby="dropdownMenuLink" style={{ width: width }}>
              {dropdownOptions.map((option) => {
                return (
                  <li
                    onClick={(event) => {
                      dropdownClick(event, option.dataValue);
                    }}
                  >
                    <div className="dropdown-item ellipsize-text">{option.uiValue}</div>
                  </li>
                );
              })}
            </ul>
          </>
        ) : (
          <div data-bs-toggle={inputFormat === DROPDOWN_INPUT ? "dropdown" : ""} style={{ width: width }} className="dropdown-button-container">
            <ValueIcon style={iconStyle} />
            {firstDropdownSelection}
            <input
              placeholder={`Enter ${inputType} value`}
              type={inputType}
              className="flexiInput-manual-input"
              value={displayValue}
              onChange={(e) => {
                setValue(e.target.value, inputType);
              }}
              disabled={disabled}
            />
            {/*
            <IconCloseRemove
              className="btn-sm-svg"
              style={{ cursor: "pointer", marginLeft: "0.5rem" }}
              onClick={() => {
                setValue("");
                setFirstDropdownSelection("");
                setInputFormat(DROPDOWN_INPUT);
                setDropdownOptions(initialDropdownOptions);
              }}
            />*/}
          </div>
        )}
      </div>
    );
  }
);

export { FlexiInput };
