import React, { useState, useRef } from "react";
import { useStore } from "../../../store/Store";
import { observer } from "mobx-react-lite";
import {
  Card,
  R,
  Button,
  Text,
  C4,
  C3,
  Toggle,
  Input,
  C2,
  C10,
  IconAdd,
  DropdownButton,
  DropdownList,
  DropdownListItem,
  DropdownItem,
  DropdownWarning,
  IconDownArrow,
} from "@fundrecs/ui-library";
import { Table } from "../../ag-grid/Ag-grid";
import { ModalHeader } from "../../layout/ModalHeader";
import { ModalFooter } from "../../layout/Modals";
import { Badge } from "../../layout/Layout";
import { Step } from "../../reusable/step/step";

/**

 */
const SetupTags = observer(({ backToMenu }) => {
  const { recTypeStore } = useStore();
  const recType = recTypeStore.getDraftRecType();

  const [tagText, setTagText] = useState("");
  const [tags, setTags] = useState([]);

  const getDefaultTags = () => {
    // call store / BE to get default tags
  };

  const addTag = () => {
    const tagsArray = tagText.split(",");
    // Call BE to add tags
    setTagText("");
    setTags([...tags, ...tagsArray]);
  };

  const removeTag = (index) => {
    const newTags = tags;
    newTags.splice(index, 1);
    setTags([...newTags]);
  };

  const tagStyle = {
    borderRadius: "4px",
    paddingLeft: "8px",
    paddingRight: "8px",
    margin: "4px",
    display: "inline-block",
    backgroundColor: "#E9EFFF",
    color: "#1E5EFF",
  };

  const cursorPointer = { cursor: "pointer" };

  return (
    <>
      <ModalHeader
        onCloseClick={() => {
          backToMenu();
        }}
        title="New Tags"
      />
      <div className="ml-72 mr-72 mt-48 text-left">
        <Text size="lg" weight="medium">
          Setup Tags
        </Text>
        <div>
          <Text weight="regular">Define the tags used to help classify the results of this reconciliation type</Text>
        </div>
        <Card>
          <R props="light-grey-bg">
            <C10 props="pt-16 pl-16 pr-16 pb-16">
              <Text weight="medium">Tag Name</Text>
              <Text weight="normal"> - Put a comma between each one to add multiple tags</Text>
            </C10>
          </R>
          <R>
            <C10 props="pt-16 pl-16 pr-16 pb-16">
              <Input
                onChange={(event) => {
                  setTagText(event.target.value);
                }}
                value={tagText}
                placeholder="Add tag"
                warning={false}
                warningMessage=""
              />
            </C10>
            <C2 props="pt-16 pl-16 pr-16 pb-16 text-right">
              <Button
                onClick={() => {
                  addTag();
                }}
                size="md"
              >
                <IconAdd className="btn-md-svg" />
                <Text size="md">Add</Text>
              </Button>
            </C2>
          </R>
          <R props="mt-32 mb-16">
            <div>
              {tags.map((tag, index) => {
                return (
                  <span style={tagStyle}>
                    {tag}
                    <span
                      style={cursorPointer}
                      onClick={() => {
                        removeTag(index);
                      }}
                    >
                      {" "}
                      x
                    </span>
                  </span>
                );
              })}
            </div>
          </R>
        </Card>
      </div>

      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            //toColumnAggregation();
          }}
        >
          <Text>Done</Text>
        </Button>
      </ModalFooter>
    </>
  );
});

const ColumnEditor = ({ columns, aggregatorFunctions, updateAggregatorFunction }) => {
  const gridRef = useRef(null);

  const FunctionDropdownCellRenderer = (rowData) => {
    const functions = aggregatorFunctions[rowData.type];
    const optionSelected = rowData.aggregator;
    return (
      <select
        className="form-select"
        onChange={(event) => {
          updateAggregatorFunction(event.target.value, rowData);
        }}
      >
        {["", undefined, null].includes(optionSelected) ? (
          <option value={null} selected>
            Please select a function
          </option>
        ) : (
          ""
        )}
        {functions.map((value, index) => {
          return (
            <option key={index} value={value} selected={optionSelected === value}>
              {value}
            </option>
          );
        })}
      </select>
    );
  };

  const columnDefs = [
    { headerName: "Column name", field: "name", sortable: false, filter: false },
    { headerName: "Data type", field: "type", sortable: false, filter: false },
    {
      headerName: "Function",
      field: "function",
      sortable: false,
      filter: false,
      cellClass: "ag-grid-custom-cell-dropdown",
      cellRenderer: (params) => {
        return FunctionDropdownCellRenderer(params.data);
      },
    },
    { headerName: "Actions", field: "side", sortable: false, filter: false },
  ];

  const getRowData = () => {
    let rows = [];
    if (columns.length) {
      columns.forEach((column, index) => {
        let row = {};
        row["index"] = index + 1;
        row["name"] = column.name;
        row["type"] = column.type;
        row["aggregator"] = column.aggregator;
        rows.push(row);
      });
    }
    return rows;
  };

  const rows = getRowData();

  return <Table columnDefs={columnDefs} rowData={rows} ref={gridRef} colFlex={true} height={`${rows.length * 40 + 80}px`} />;
};

export { SetupTags };
