import { observer } from "mobx-react-lite";
import { useSearchParams } from "react-router-dom";
import { R, Button, Text, Dropdown, DropdownButton, DropdownItem, DropdownList, DropdownListItem, IconDownArrow, ItemBody, C2, C4 } from "@fundrecs/ui-library";
import { ModalHeader } from "../../layout/ModalHeader";
import { useStore } from "../../../store/Store";
import { ManageLayout, TabsLayout } from "../../layout/Layout";
import { ConditionAction } from "../../rules/ConditionAction";
import { NOTE_TYPE } from "../../../utils/enums";
import { VERIFICATION } from "../../rules/dataTransform";
import { FullScreenView } from "../../reusable/FullScreenView";

const DataVerificationRuleBuilder = observer(({ tmos, tmoColumns, show = false, toggleShow }) => {
  const { dataVerificationStore, recTypeStore, comparatorFunctionStore, uiStore } = useStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = searchParams.get("teamId");
  const dvRule = dataVerificationStore.getDraftDvRule();
  const selectedRecType = recTypeStore.getSelectedRecType();

  const saveDvRule = async () => {
    if (!dvRule.tmoId || !dvRule.tmoId) {
      uiStore.addNotification("error", "Please select an option for the filter");
      return;
    }
    if (!dvRule.conditions || dvRule.conditions.length < 1) {
      uiStore.addNotification("error", "Please add one or more conditions");
      return;
    }
    if (!dvRule.actions || dvRule.actions.length !== 1 || !dvRule.actions[0].id) {
      uiStore.addNotification("error", "Please select a tag");
      return;
    }
    if (dvRule.actions && dvRule.actions.length > 1) {
      uiStore.addNotification("error", "Please only include one tag");
      return;
    }
    const resp = await dataVerificationStore.bulkUpdateDvRule(teamId);
    if (resp.status === 200) {
      closeModalAndResetFields();
    }
  };

  const updateDraftDvRule = (dvRule) => {
    dataVerificationStore.updateDraftDvRule(dvRule);
  };

  const closeModalAndResetFields = () => {
    toggleShow();
    dataVerificationStore.fetchDvRulesForRecType(teamId, selectedRecType.id);
    dataVerificationStore.clearDraftDvRule();
  };

  return !show ? (
    ""
  ) : (
    <FullScreenView>
      <div className="pt-8 pb-8 pr-8 pl-8">
        <div className="pt-16 pb-32 pr-16 pl-16">
          <ModalHeader
            onCloseClick={closeModalAndResetFields}
            title={dvRule.name}
            contentRight={
              <>
                <Button
                  onClick={() => {
                    closeModalAndResetFields();
                  }}
                >
                  <Text>Cancel</Text>
                </Button>
                <Button
                  color="tertiary"
                  onClick={() => {
                    saveDvRule();
                  }}
                >
                  <Text>Save and exit</Text>
                </Button>
              </>
            }
          />
        </div>
        <div style={{ background: "#F7F8F9", paddingTop: "20px" }}>
          <ManageLayout
            headerTabs={
              <TabsLayout
                tabs={[
                  {
                    text: "Rule Builder",
                    onClick: () => {},
                  },
                ]}
                activeTab={0}
              />
            }
          >
            <div style={{ padding: "16px", marginTop: "16px", background: "#F3F3F5", border: "1px solid #E6E8EB", borderRadius: "10px" }}>
              <R>
                <Text variant="muted" size="sm">
                  Add filter
                </Text>
              </R>
              <R props="mt-16">
                <C2>
                  <Text size="sm">When</Text>
                </C2>
                <C4>
                  <Dropdown>
                    <DropdownButton warning={""} disabled={dvRule.conditions && dvRule.conditions.length > 0} size="sm">
                      {dvRule.tmoId ? `any ${tmos.find((tmo) => tmo.id === dvRule.tmoId)["name"]} data is available` : "Please select an option"}
                      <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
                    </DropdownButton>

                    <DropdownList>
                      {tmos.map((tmo, index) => {
                        return (
                          <DropdownListItem
                            key={Math.random()}
                            onClick={() => {
                              updateDraftDvRule({ ...dvRule, tmoId: tmo.id });
                            }}
                          >
                            <DropdownItem active={false} index={index}>
                              <ItemBody>{`any ${tmo.name} data is available`}</ItemBody>
                            </DropdownItem>
                          </DropdownListItem>
                        );
                      })}
                    </DropdownList>
                  </Dropdown>
                </C4>
              </R>
            </div>

            <ConditionAction
              conditions={dvRule.conditions}
              conditionOptions={comparatorFunctionStore.getSortedFunctions()}
              setConditions={(conditions) => {
                updateDraftDvRule({ ...dvRule, conditions: conditions });
              }}
              canAddConditions={dvRule.tmoId ? true : false}
              actions={dvRule.actions}
              actionOptions={{
                "Tag as": recTypeStore.getRecTypeTags().filter((it) => it.noteType === VERIFICATION),
              }}
              canAddActions={dvRule.actions && dvRule.actions.length < 1 ? true : false}
              setActions={(actions) => {
                updateDraftDvRule({ ...dvRule, actions: actions });
              }}
              dropdownData={dvRule.tmoId && tmoColumns[dvRule.tmoId] ? { [tmos.find((it) => it.id === dvRule.tmoId)["name"]]: tmoColumns[dvRule.tmoId] } : {}}
              manualInput={true}
              notes={recTypeStore.getRecTypeTags().filter((it) => it.noteType === NOTE_TYPE.VERIFICATION)}
            />
          </ManageLayout>
        </div>
      </div>
    </FullScreenView>
  );
});

export { DataVerificationRuleBuilder };
