import { observer } from "mobx-react-lite";
import { SubAccountSection } from "./SubAccountSection";
import { CalendarSection } from "./CalendarSection";
import { UploadedFilesSection } from "./UploadedFilesSection";

const RecDetailsPanel = observer(({ rec, date, recType, account, recDetailsOpen, setRecDetailsOpen, subAccounts, rowCounts, uploadedFiles, updateUrl,uiDisabled }) => {
  return (
    <div id="recDetailsPanel" className="side-panel-style">
      <SubAccountSection
        panelOpen={recDetailsOpen}
        openOnClick={() => {
          setRecDetailsOpen("subAccounts");
        }}
        allSubAccounts={subAccounts}
        rowCounts={rowCounts}
        updateUrl={updateUrl}
      />
      <CalendarSection
        rec={rec}
        date={date}
        recType={recType}
        account={account}
        panelOpen={recDetailsOpen}
        openOnClick={() => {
          setRecDetailsOpen("calendar");
        }}
      />
      <UploadedFilesSection
        panelOpen={recDetailsOpen}
        openOnClick={() => {
          setRecDetailsOpen("uploads");
        }}
        uploadedFiles={uploadedFiles}
        uiDisabled={uiDisabled}
      />
    </div>
  );
});

export { RecDetailsPanel };
