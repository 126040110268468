import { CATEGORY_FILTER_COLUMN } from "../../utils/enums";
import { ReactComponent as ForwardIcon } from "../../icons/forward.svg";

const TEXT_FILTER = "agTextColumnFilter";
const FILTERS = { STRING: TEXT_FILTER, NUMBER: "agNumberColumnFilter", DATE: "agDateColumnFilter" };

/**
 * Returns a number formatted to decimal place.
 * Whole numbers are rendered as is, floats are rendered with as many decimal places as required, minimum 2.
 * @param {*} number
 * @returns
 */
const formatNumberToDecimal = (number) => {
  const numberTest = Number(number);

  if (isNaN(numberTest) || [undefined, null, ""].includes(number)) {
    return number;
  }
  const str = number.toString();
  const decimalIndex = str.indexOf(".");
  //Get the length of decimal portion of the string to determine the number of required places
  const numberOfDecimals = str.slice(decimalIndex + 1).length;
  //If no decimal places, leave as whole number, otherwise ensure at least 2 decimal places
  const decimals = decimalIndex < 0 ? 0 : numberOfDecimals < 2 ? 2 : numberOfDecimals;
  const ret = new Intl.NumberFormat("en-US", { minimumFractionDigits: decimals }).format(number);
  return ret;
};

const CarriedForwardIcon = ({ rowData }) => {
  // Had tried using Ag-Grid toolTipValueGetter, but the tooltip was very slow to display
  return rowData.carriedForwardRecId !== null ? (
    <div title={`Carried forward from Rec with id: ${rowData.carriedForwardRecId}`}>
      <ForwardIcon style={{ width: "16px", height: "16px", color: "#1E5EFF" }} />{" "}
    </div>
  ) : null;
};

const getTableHeaders = (columns, masterDetail = false, categoryFilterColumn = false) => {
  const headers = [];
  if (columns) {
    columns.forEach((header, index) => {
      const carriedForwardColumn = header.name === "carriedForward";
      const filter = FILTERS[header.produces] ? FILTERS[header.produces] : TEXT_FILTER;
      let cellRenderer =
        index === 0 && masterDetail
          ? { cellRenderer: "agGroupCellRenderer" }
          : carriedForwardColumn
          ? {
              cellRenderer: (row) => {
                return <CarriedForwardIcon rowData={row.data} />;
              },
            }
          : {};
      headers.push({
        ...cellRenderer,
        headerName: carriedForwardColumn ? "" : header.name,
        field: header.name,
        filter: filter,
        cellStyle: header.name.indexOf("Difference") >= 0 ? { backgroundColor: "#F3F3F5" } : "",
        type: header.produces === "NUMBER" ? "rightAligned" : "",
        suppressColumnsToolPanel: header.name === "Sub Account",
        valueFormatter: (params) => {
          return header.produces === "NUMBER" ? formatNumberToDecimal(params.data[header.name]) : params.data[header.name];
        },
      });
    });

    if (categoryFilterColumn) {
      headers.push({ field: CATEGORY_FILTER_COLUMN, filter: TEXT_FILTER, suppressColumnsToolPanel: true, hide: true });
    }
  }
  return headers;
};

const createTagsString = (notes, nameKey) => {
  let tagsString = "";
  notes.forEach((note) => {
    if (![null, undefined].includes(note)) {
      tagsString = `${tagsString}${tagsString.length ? "," : ""} ${note[nameKey]}`;
    }
  });
  return tagsString;
};

const getUnmatchedRows = (unmatchedRows) => {
  return unmatchedRows.map((row) => {
    let rowData = { id: row.id, carriedForwardRecId: row.carriedFrom };
    Object.entries(row.data).forEach((keyValue) => {
      rowData[keyValue[0]] = keyValue[1];
    });
    return rowData;
  });
};

const getMatchedRows = (rowGroups, matchingRules) => {
  let categoryChartData = [];

  const updateCategoryChartData = (rowGroup) => {
    if (rowGroup.category && rowGroup.category.id && rowGroup.category.name) {
      const existingChartData = categoryChartData.find((it) => it.categoryId === rowGroup.category.id);
      if (existingChartData) {
        existingChartData.rowCount += rowGroup.rowCount;
      } else {
        categoryChartData.push({ categoryName: rowGroup.category.name, categoryId: rowGroup.category.id, rowCount: rowGroup.rowCount });
      }
    }
  };

  const rowData = rowGroups.map((rowGroup) => {
    const matchingRule = matchingRules.find((it) => it.id === Number(rowGroup.matchingRuleId));
    let rowData = {
      ...rowGroup,
      id: rowGroup.id,
      version: rowGroup.version,
      tags: rowGroup.tags,
      Type: rowGroup.category ? rowGroup.category.name : "",
      Tags: createTagsString(rowGroup.tags, "name"),
      "Matching Rule": !rowGroup.matchingRuleId ? "Manually matched" : matchingRule ? matchingRule.name : "- Rule not found -",
      auxiliaryInfo: rowGroup.auxiliaryInfo,
      classifier: rowGroup.category,
      [CATEGORY_FILTER_COLUMN]: rowGroup.category && rowGroup.category.name ? rowGroup.category.name : "",
    };
    updateCategoryChartData(rowGroup);
    Object.entries(rowGroup.data).forEach((keyValue) => {
      rowData[keyValue[0]] = keyValue[1];
    });
    return rowData;
  });

  return { rowData: rowData, chartData: categoryChartData };
};

const formatUnmatchedRowsForTable = (rowData) => {
  return {
    columns: getTableHeaders([{ name: "carriedForward" }, ...rowData.schema]),
    rows: getUnmatchedRows(rowData.rows),
  };
};

const formatMatchedRowsForTable = (columns, rowData, matchingRules, masterDetail = false) => {
  const formattedRowData = getMatchedRows(rowData, matchingRules);

  return {
    columns: getTableHeaders(columns, masterDetail, true),
    rows: formattedRowData.rowData,
    chartData: formattedRowData.chartData,
  };
};

export { formatUnmatchedRowsForTable, formatMatchedRowsForTable };
