import React, { useState, useRef } from "react";
import { useStore } from "../../../store/Store";
import { observer } from "mobx-react-lite";
import {
  Card,
  R,
  Button,
  Text,
  C4,
  C3,
  Toggle,
  Input,
  C2,
  C6,
  Dropdown,
  DropdownButton,
  DropdownList,
  DropdownListItem,
  DropdownItem,
  DropdownWarning,
  IconDownArrow,
} from "@fundrecs/ui-library";
import { Table } from "../../ag-grid/Ag-grid";
import { ModalHeader } from "../../layout/ModalHeader";
import { ModalFooter } from "../../layout/Modals";
import { TextInput } from "../../reusable/TextInput";
import { Step } from "../../reusable/step/step";

/**

 */
const SetupAggregators = observer(({ backToMenu }) => {
  const { recTypeStore } = useStore();
  const recType = recTypeStore.getDraftRecType();
  const sides = ["Internal", "External", "Results"];

  const initialState = {
    name: "",
    description: "",
    side: null,
    default: false,
  };
  const steps = [{ index: 1 }, { index: 2 }];
  const [activeStep, setActiveStep] = useState(steps[0]);
  const [aggregatorState, setAggregatorState] = useState({ ...initialState });
  const [validationMessages, setValidationMessages] = useState({});
  const aggregatorFunctions = {
    String: ["Take first", "Concatenate"],
    Number: ["Take first", "Multiply", "Add", "Largest"],
    Date: ["Take first", "Most recent", "Oldest"],
  };

  const toggleDefault = () => {
    setAggregatorState({ ...aggregatorState, default: !aggregatorState.default });
  };

  const toColumnAggregation = () => {
    let validationMessages = {};

    //Step one validation
    if (["", " ", null, undefined].includes(aggregatorState.name)) validationMessages.name = "Name is required";
    if (["", " ", null, undefined].includes(aggregatorState.description)) validationMessages.description = "Description is required";
    if (["", " ", null, undefined].includes(aggregatorState.side)) validationMessages.side = "Side is required";

    setValidationMessages(validationMessages);
    if (!Object.keys(validationMessages).length) {
      setActiveStep(steps[1]);
    }
  };

  /**
   * Creates a new array of columns for the selected 'side' - with the chosen aggregator added to the chosen column
   * Updates the selected recType in the store with the new array of columns
   * @param {*} value Selected sggregator function value from the dropdown
   * @param {*} columnData
   */
  const updateAggregatorFunction = (value, columnData) => {
    const columnArray = recType.columns[aggregatorState.side].map((column) => {
      if (column.name === columnData.name) {
        column.aggregator = value;
      }
      return column;
    });
    const updatedColumns = { ...recType.columns, [aggregatorState.side]: columnArray };
    recTypeStore.setDraftRecTypeColumns(updatedColumns);
  };


  return (
    <>
      <ModalHeader
        onCloseClick={() => {
          backToMenu();
          //modalInstance(MODALS.RECTYPE_SETUP).hide();
        }}
        title="New Aggregator"
      />
      <Step steps={steps} activeStep={activeStep} />
      {activeStep.index === steps[0]["index"] ? (
        <div className="ml-72 mr-72 mt-48 text-left">
          <Text size="lg" weight="medium">
            Create new aggregator
          </Text>
          <div>
            <Text weight="regular">This aggregator enables you to flatten the incoming data</Text>
          </div>
          <div className="mr-72 mt-48 text-left">
            <TextInput
              title="Aggregator Name *"
              update={(value) => {
                setAggregatorState({ ...aggregatorState, name: value });
              }}
              value={aggregatorState.name}
              warningBoolean={validationMessages.name !== undefined}
              warningMessage={validationMessages.name}
              classes="mb-20"
              maxCharacter={100}
            />
            <TextInput
              title="Description *"
              update={(value) => {
                setAggregatorState({ ...aggregatorState, description: value });
              }}
              value={aggregatorState.description}
              warningBoolean={validationMessages.description !== undefined}
              warningMessage={validationMessages.description}
              classes="mb-20"
              maxCharacter={150}
            />
            <R props="mt-32"></R>
            <C6>
              <Text size="sm" weight="medium" variant="secondary" element="div">
                Reconciliation type *
              </Text>
              <Dropdown>
                <DropdownButton size="sm">
                  <span>{aggregatorState.side ? aggregatorState.side : "Please select a side"}</span>
                  <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
                </DropdownButton>
                <DropdownList>
                  {sides.map((side, index) => {
                    return (
                      <DropdownListItem
                        onClick={() => {
                          setAggregatorState({ ...aggregatorState, side: side });
                        }}
                        key={index + 1}
                      >
                        <DropdownItem active={null} index={0}>
                          <span>{side}</span>
                        </DropdownItem>
                      </DropdownListItem>
                    );
                  })}
                </DropdownList>
                <DropdownWarning warning={validationMessages.side !== undefined} warningMessage={validationMessages.side} />
              </Dropdown>
            </C6>
            <R props="mt-32"></R>

            <Text size="sm" weight="medium" variant="secondary" element="div">
              Set this as default for this side
            </Text>
            <Toggle
              checked={aggregatorState.default}
              onChange={() => {
                toggleDefault();
              }}
            />

            <R props="mt-32"></R>
          </div>
        </div>
      ) : activeStep.index === steps[1]["index"] ? (
        <div className="ml-72 mr-72 mt-48 text-left">
          <Text size="lg" weight="medium">
            Add your functions
          </Text>
          <div>
            <Text weight="regular">
              Select how you would like the rows flattened for the selected side. Functions have been pre-selected based on the column data-type. All columns
              must have a function
            </Text>
          </div>
          <div className="mr-72 mt-48 text-left">
            <ColumnEditor
              columns={recType["columns"][aggregatorState.side]}
              aggregatorFunctions={aggregatorFunctions}
              updateAggregatorFunction={updateAggregatorFunction}
            />
            <R props="mt-32"></R>

            <R props="mt-32"></R>

            <Text size="sm" weight="medium" variant="secondary" element="div">
              Set this as default for this side
            </Text>
            <Toggle
              checked={aggregatorState.default}
              onChange={() => {
                toggleDefault();
              }}
            />

            <R props="mt-32"></R>
          </div>
        </div>
      ) : (
        ""
      )}
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            toColumnAggregation();
          }}
        >
          <Text>Next - Add functions</Text>
        </Button>
      </ModalFooter>
    </>
  );
});

const ColumnEditor = ({ columns, aggregatorFunctions, updateAggregatorFunction }) => {
  const gridRef = useRef(null);

  const FunctionDropdownCellRenderer = (rowData) => {
    const functions = aggregatorFunctions[rowData.type];
    const optionSelected = rowData.aggregator;
    return (
      <select
        className="form-select"
        onChange={(event) => {
          updateAggregatorFunction(event.target.value, rowData);
        }}
      >
        {["", undefined, null].includes(optionSelected) ? (
          <option value={null} selected>
            Please select a function
          </option>
        ) : (
          ""
        )}
        {functions.map((value, index) => {
          return (
            <option key={index} value={value} selected={optionSelected === value}>
              {value}
            </option>
          );
        })}
      </select>
    );
  };

  const columnDefs = [
    { headerName: "Column name", field: "name", sortable: false, filter: false },
    { headerName: "Data type", field: "type", sortable: false, filter: false },
    {
      headerName: "Function",
      field: "function",
      sortable: false,
      filter: false,
      cellClass: "ag-grid-custom-cell-dropdown",
      cellRenderer: (params) => {
        return FunctionDropdownCellRenderer(params.data);
      },
    },
    { headerName: "Actions", field: "side", sortable: false, filter: false },
  ];

  const getRowData = () => {
    let rows = [];
    if (columns.length) {
      columns.forEach((column, index) => {
        let row = {};
        row["index"] = index + 1;
        row["name"] = column.name;
        row["type"] = column.type;
        row["aggregator"] = column.aggregator;
        rows.push(row);
      });
    }
    return rows;
  };

  const rows = getRowData();

  return <Table columnDefs={columnDefs} rowData={rows} ref={gridRef} colFlex={true} height={`${rows.length * 40 + 80}px`} />;
};

export { SetupAggregators };
