import { fusionRequest } from "../fusionRequest";

const subAccount = {
  getAllSubAccountsForTeam: (teamId) => fusionRequest.get(`team/${teamId}/subaccount`),
  getSubAccountsAndAliasesForTeam: (teamId) => fusionRequest.get(`team/${teamId}/subaccount/aliases`),
  createSubAccount: (teamId, body) => fusionRequest.post(`team/${teamId}/subaccount`, body),
  deleteSubAccount: (teamId, subAccountId, version) => fusionRequest.delete(`team/${teamId}/subaccount/${subAccountId}?version=${version}`),
};

export { subAccount };
