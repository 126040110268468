/**
 * Encapsulates the components to be rendered in a full width row.
 * This component handles the dynamic height adjustment for the full width cell, by setting the height & updating the grid
 * @param {*} param0
 * @returns
 */
const FullWidthCellRenderer = ({ rowNode, rowHeight, gridRef, children }) => {
  rowNode.node.setRowHeight(rowHeight);
  setTimeout(() => {
    gridRef.current.api.onRowHeightChanged();
  }, 0);
  return children;
};

export { FullWidthCellRenderer };
