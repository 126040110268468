import { useContext } from "react";
import { Text, R, TextArea } from "@fundrecs/ui-library";
import { MatchingContext, MatchingDispatchContext, SET_COMMENT } from "../../matchingReducer";

const CommentPanel = ({}) => {
  const matchingConfig = useContext(MatchingContext);
  const dispatch = useContext(MatchingDispatchContext);
  return (
    <>
      <R props="mt-16">
        <Text size="xs" weight="bold">
          Add a comment
        </Text>
      </R>
      <R>
        <TextArea
          value={matchingConfig.comment !== null ? matchingConfig.comment : ""}
          onChange={(event) => {
            dispatch({ type: SET_COMMENT, comment: event.target.value });
          }}
        />
      </R>
    </>
  );
};

export { CommentPanel };
