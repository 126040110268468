import { getTimeFromDate, stringifyDate } from "../../utils/dates";

const formatRowData = (fileData) => {
  let importErrorCount = 0;
  if (fileData.errors) {
    fileData.errors.forEach((error) => {
      importErrorCount += error.count;
    });
  }
  return {
    ...fileData,
    dateUploaded: fileData.startTime ? stringifyDate(new Date(fileData.startTime)) : "",
    startTime: fileData.startTime ? fileData.startTime : "",
    uploadedBy: fileData.uploadedBy && fileData.uploadedBy.name ? fileData.uploadedBy.name : "",
    source:
      fileData.sourceSummary && fileData.sourceSummary.via && fileData.sourceSummary.source
        ? `${fileData.sourceSummary.via}: ${fileData.sourceSummary.source}`
        : "",
    importErrorCount: importErrorCount,
    timeTaken: fileData.timeTaken ? fileData.timeTaken / 1000 : "",
    startTimeFormatted: fileData.startTime ? getTimeFromDate(new Date(fileData.startTime)) : "",
    startDateFormatted: fileData.startTime ? stringifyDate(new Date(fileData.startTime)) : "",
  };
};

export { formatRowData };
