import { useState } from "react";
import { observer } from "mobx-react-lite";
import { R, C10, C6, Text, IconAdd, Button, IconDownArrow, IconRightArrow, Toggle } from "@fundrecs/ui-library";

const MatchingExclusionCriteria = observer(({ minimumCriteriaList }) => {
  return (
    <>
      <div className="d-flex justify-content-between mt-32">
        <div>
          <Text variant="muted" weight="bold">
            EXCLUSION CRITERIA FOR MANUAL MATCHING
          </Text>
        </div>
        <div>
          {/*
          <Button disabled={true} color="primary-secondary">
            <IconAdd variant="link" className="btn-sm-svg" />
            <Text size="sm" weight="bold">
              New exclusion criteria
            </Text>
  </Button>*/}
        </div>
      </div>
      {minimumCriteriaList && minimumCriteriaList.length ? (
        <>
          {minimumCriteriaList.map((minimumCriteria) => (
            <MinimumMatchingCriteriaDetail minimumCriteria={minimumCriteria} />
          ))}
        </>
      ) : (
        ""
      )}
    </>
  );
});

const MinimumMatchingCriteriaDetail = ({ minimumCriteria }) => {
  const [open, setOpen] = useState(false);

  return (
    <div style={{ borderBottom: "1px solid #E6E8EB" }} key={Math.random()}>
      <R props="mt-16 mb-16">
        <C6>
          <span
            onClick={() => {
              setOpen(!open);
            }}
            style={{ cursor: "pointer" }}
            className="mr-8"
          >
            {open ? <IconDownArrow className="btn-lg-svg" color="#6A7388" /> : <IconRightArrow className="btn-lg-svg" color="#6A7388" />}
          </span>
          <Text size="sm" weight="bold" variant="secondary">
            {minimumCriteria.name}
          </Text>
        </C6>
        <C6>
          <Toggle disabled={true} checked={true} onChange={() => {}} />
        </C6>
      </R>
      {open ? (
        <R>
          <C10 props="ml-32">
            <Text size="sm" weight="regular">
              Do not allow rows to be assigned if
            </Text>
            <span className="ml-8"></span>
            <Text size="sm" weight="bold">
              {minimumCriteria.description}
            </Text>
          </C10>
        </R>
      ) : (
        ""
      )}
    </div>
  );
};

export { MatchingExclusionCriteria };
