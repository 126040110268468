import { makeAutoObservable, toJS } from "mobx";
import { fusionRecsApi } from "../api";
import { uiStore, rolesStore } from "./Store";
import { isUserAuthorized } from "../components/AuthorizationWrapper";
import { AUTHORITIES } from "../utils/enums";

class MatchingRuleStore {
  draftMatchingRule = {};
  matchingRules = [];

  constructor() {
    makeAutoObservable(this);
  }

  getDraftMatchingRule = () => {
    return toJS(this.draftMatchingRule);
  };

  getMatchingRules = () => {
    return toJS(this.matchingRules);
  };

  updateDraftMatchingRule = (matchingRule) => {
    this.draftMatchingRule = matchingRule;
  };

  createMatchingRule = async (teamId, recTypeId, body) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_MATCHING_RULE_CREATE]) })) {
      console.log("createMatchingRule: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.matchingRule.createMatchingRule(teamId, recTypeId, body);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    } else {
      this.draftMatchingRule = { ...resp.data, tmoId: null, globalMappingId: null, proceedAsRows: false, groupings: [] };
    }
    return resp;
  };

  clearDraftMatchingRule = () => {
    this.draftMatchingRule = {};
  };

  fetchMatchingRules = async (teamId, recTypeId, accountId, subAccountId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_VIEW, AUTHORITIES.RECS_MATCHING_RULE_VIEW]) })) {
      console.log("fetchMatchingRules: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.matchingRule.getMatchingRules(teamId, recTypeId, accountId, subAccountId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    } else {
      this.matchingRules = resp.data;
    }
    return resp;
  };

  bulkUpdateMatchingRule = async (teamId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_MATCHING_RULE_CREATE]) })) {
      console.log("bulkUpdateMatchingRule: unauthorised");
      return { status: 401 };
    }
    const draftMatchingRule = toJS(this.draftMatchingRule);
    const requestBody = {
      id: draftMatchingRule.id,
      filterOperations: draftMatchingRule.conditions,
      groupBy: { groupings: draftMatchingRule.groupings },
      proceedAsRows: draftMatchingRule.proceedAsRows,
      globalMappingId: draftMatchingRule.globalMappingId,
      tmoAggregatorIdList: Object.values(draftMatchingRule.tmoAggregatorIdMap),
      tagSteps: draftMatchingRule.conditionsActions.map((conditionAction) => {
        return { noteId: conditionAction.actions[0]["id"], conditions: conditionAction.conditions };
      }),
    };

    const resp = await fusionRecsApi.matchingRule.bulkUpdateMatchingRule(teamId, draftMatchingRule.id, draftMatchingRule.version, requestBody);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    } else {
      uiStore.addNotification("success", "Matching rule saved successfully");
      this.draftMatchingRule = {};
    }
    return resp;
  };

  updateMatchingRule = async (teamId, matchingRuleId, requestBody) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_MATCHING_RULE_ACCOUNT_ENABLE]) })) {
      console.log("updateMatchingRule: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.matchingRule.updateMatchingRule(teamId, matchingRuleId, requestBody);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  reorderMatchingRules = async (teamId, recTypeId, accountId, subAccountId, body) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_MATCHING_RULE_REORDER]) })) {
      console.log("reorderMatchingRules: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.matchingRule.reorderMatchingRules(teamId, recTypeId, accountId, subAccountId, body);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  deleteMatchingRule = async (teamId, matchingRuleId, version) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_MATCHING_RULE_DELETE]) })) {
      console.log("deleteMatchingRule: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.matchingRule.deleteMatchingRule(teamId, matchingRuleId, version);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    } else {
      uiStore.addNotification("success", "Matching rule deleted");
    }
    return resp;
  };
}

export { MatchingRuleStore };
