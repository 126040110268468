import { observer } from "mobx-react-lite";
import { IconDownArrow } from "@fundrecs/ui-library";

const DropdownWithDescription = observer(({ description, displayValue, dropdownOptions, selectOption, width = "16rem" }) => {
  return (
    <div style={{ width: width }}>
      <div data-bs-toggle="dropdown" className="d-flex dropdown-button-container ellipsize-text" title={displayValue}>
        <div className="ellipsize-text width-95-pc">
          {description} {displayValue}
        </div>
        <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
      </div>

      <ul className="dropdown-menu dropdown-sizing" aria-labelledby="dropdownMenuLink" style={{ width: width }}>
        {dropdownOptions.map((option, index) => {
          return (
            <li
              key={index}
              onClick={() => {
                selectOption(option);
              }}
            >
              <div className="dropdown-item ellipsize-text">{option.name}</div>
            </li>
          );
        })}
      </ul>
    </div>
  );
});

export { DropdownWithDescription };
