import { fusionRequest } from "../fusionRequest";

const matchingRule = {
  createMatchingRule: (teamId, recTypeId, body) => fusionRequest.post(`team/${teamId}/matching?recTypeId=${recTypeId}`, body),
  getMatchingRules: (teamId, recTypeId, accountId, subAccountId) =>
    fusionRequest.get(`team/${teamId}/matching?recTypeId=${recTypeId}&accountId=${accountId}&subAccountId=${subAccountId}`),
  bulkUpdateMatchingRule: (teamId, matchingRuleId, version, body) =>
    fusionRequest.put(`team/${teamId}/matching/${matchingRuleId}/filter?version=${version}`, body),
  updateMatchingRule: (teamId, matchingRuleId, body) => fusionRequest.put(`team/${teamId}/matching/${matchingRuleId}`, body),
  reorderMatchingRules: (teamId, recTypeId, accountId, subAccountId, body) =>
    fusionRequest.put(`team/${teamId}/matching?recTypeId=${recTypeId}&accountId=${accountId}&subAccountId=${subAccountId}`, body),
  deleteMatchingRule: (teamId, matchingRuleId, version) => fusionRequest.delete(`team/${teamId}/matching/${matchingRuleId}?version=${version}`),
};

export { matchingRule };
