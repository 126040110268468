import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { MainContainer, ManageLayout, TabsLayout } from "../../layout/Layout.js";
import { PageTitleArea } from "../../layout/Layout.js";
import { useStore } from "../../../store/Store.js";
import { MatchingRulePanel } from "./MatchingRulePanel.js";
import { AccountDetails } from "./AccountDetails.js";
import { EnabledRecTypes } from "./EnabledRecTypes.js";
import { FundIdentifiers } from "./FundIdentifiers.js";
import { SubAccounts } from "./SubAccounts.js";

const AccountConfig = observer(({}) => {
  const { teamStore, accountStore } = useStore();
  const [activeTab, setActiveTab] = useState(0);
  const selectedAccount = accountStore.getSelectedAccount();
  const [searchParams, setSearchParams] = useSearchParams();
  const [initialised, setInitialised] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const accountId = searchParams.get("accountId");
  const teamId = teamStore.getSelectedTeam().id;

  const updateUrl = (tab, replace) => {
    navigate(`${location.pathname}?teamId=${teamId}&accountId=${accountId}&tab=${tab}`, { replace: replace });
  };

  if (teamId && accountId && !initialised) {
    //Reset selectedAccount if a different account is to be viewed
    if (selectedAccount && selectedAccount.id !== accountId) {
      accountStore.setSelectedAccount(null);
    }
    accountStore.fetchAccountById(teamId, accountId);
    const tab = Number(searchParams.get("tab"));
    setActiveTab(tab);
    updateUrl(tab, true);
    setInitialised(true);
  }

  const mainTabs = (tab) => {
    return (
      <TabsLayout
        tabs={[
          {
            text: `Fund Details`,
            onClick: () => {
              setActiveTab(0);
              updateUrl(0, true);
            },
          },
          {
            text: `Reconciliation Types`,
            onClick: () => {
              setActiveTab(1);
              updateUrl(1, true);
            },
          },
          {
            text: `Fund Identifiers`,
            onClick: () => {
              setActiveTab(2);
              updateUrl(2, true);
            },
          },
          {
            text: `Sub Accounts`,
            onClick: () => {
              setActiveTab(3);
              updateUrl(3, true);
            },
          },
          {
            text: `Matching Rules`,
            onClick: () => {
              setActiveTab(4);
              updateUrl(4, true);
            },
          },
        ]}
        activeTab={tab}
      />
    );
  };

  return (
    <MainContainer>
      <PageTitleArea title={selectedAccount ? selectedAccount.name : ""} description={""} borderBottom={false} />
      <ManageLayout headerTabs={mainTabs(activeTab)} panelHeader={<></>} pageTitleArea={<></>}>
        {activeTab === 0 ? <AccountDetails /> : ""}
        {activeTab === 1 ? <EnabledRecTypes teamId={teamId} account={selectedAccount} /> : ""}
        {activeTab === 2 ? <FundIdentifiers teamId={teamId} account={selectedAccount} /> : ""}
        {activeTab === 3 ? <SubAccounts teamId={teamId} account={selectedAccount} /> : ""}
        {activeTab === 4 ? <MatchingRulePanel teamId={teamId} account={selectedAccount} /> : ""}
      </ManageLayout>
    </MainContainer>
  );
});

export { AccountConfig };
